@charset "UTF-8";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline
}

html {
    font-size: 80%;
    line-height: 1
}

ol,
ul {
    list-style: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

caption,
th,
td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle
}

q,
blockquote {
    quotes: none
}

q:before,
q:after,
blockquote:before,
blockquote:after {
    content: "";
    content: none
}

a img {
    border: none
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block
}

audio,
canvas,
video {
    display: inline-block
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden] {
    display: none
}

html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

body {
    margin: 0
}

a:focus {
    outline: thin dotted
}

a:active,
a:hover {
    outline: 0
}

h1 {
    font-size: 2em;
    margin: 0.67em 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
strong {
    font-weight: bold
}

dfn {
    font-style: italic
}

hr {
    box-sizing: content-box;
    height: 0
}

mark {
    background: #ff0;
    color: #000
}

code,
kbd,
pre,
samp {
    font-family: monospace, serif;
    font-size: 1em
}

pre {
    white-space: pre-wrap
}

q {
    quotes: "\201C" "\201D" "\2018" "\2019"
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -0.5em
}

sub {
    bottom: -0.25em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 0
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em
}

legend {
    border: 0;
    padding: 0
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0
}

button,
input {
    line-height: normal
}

button,
select {
    text-transform: none
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],
html input[disabled] {
    cursor: default
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0
}

input[type="search"] {
    -webkit-appearance: textfield;
    box-sizing: content-box
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

textarea {
    overflow: auto;
    vertical-align: top
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

*,
*:before,
*:after {
    box-sizing: border-box
}

html {
    /* font-size: 62.5%; */
    -webkit-tap-highlight-color: transparent
}

/* body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    line-height: 1.42857;
    color: #777777;
    background-color: #fff
} */

/* input,
button,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
} */

button,
input,
select[multiple],
textarea {
    background-image: none
}

a {
    color: #777;
    text-decoration: none
}

a:hover,
a:focus {
    color: #515151;
    text-decoration: underline
}

a:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

img {
    vertical-align: middle
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto
}

.img-rounded {
    border-radius: 6px
}

.img-thumbnail,
.thumbnail {
    padding: 4px;
    line-height: 1.42857;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
    display: inline-block;
    max-width: 100%;
    height: auto
}

.img-circle {
    border-radius: 50%
}

hr {
    margin-top: 18px;
    margin-bottom: 18px;
    border: 0;
    border-top: 1px solid #eeeeee
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0
}

p {
    margin: 0 0 9px
}

.lead {
    margin-bottom: 18px;
    font-size: 14.95px;
    font-weight: 200;
    line-height: 1.4
}

@media (min-width:768px) {
    .lead {
        font-size: 19.5px
    }
}

small {
    font-size: 85%
}

cite {
    font-style: normal
}

.text-muted {
    color: #999999
}

.text-primary {
    color: #428bca
}

.text-warning {
    color: #fff
}

.text-danger {
    color: #fff
}

.text-success {
    color: #fff
}

.text-info {
    color: #fff
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    line-height: 1.1
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small {
    font-weight: normal;
    line-height: 1;
    color: #999999
}


.page-header {
    padding-bottom: 8px;
    margin: 36px 0 18px;
    border-bottom: 1px solid #eeeeee
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 9px
}

ul ul,
ul ol,
ol ul,
ol ol {
    margin-bottom: 0
}

.list-unstyled,
.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline>li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px
}

dl {
    margin-bottom: 18px
}

dt,
dd {
    line-height: 1.42857
}

dt {
    font-weight: bold
}

dd {
    margin-left: 0
}

@media (min-width:768px) {
    .dl-horizontal dt {
        float: left;
        width: 160px;
        clear: left;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap
    }

    .dl-horizontal dd {
        margin-left: 180px
    }

    .dl-horizontal dd:before,
    .dl-horizontal dd:after {
        content: " ";
        display: table
    }

    .dl-horizontal dd:after {
        clear: both
    }
}

abbr[title],
abbr[data-original-title] {
    cursor: help;
    border-bottom: 1px dotted #999999
}

abbr.initialism {
    font-size: 90%;
    text-transform: uppercase
}

blockquote {
    padding: 9px 18px;
    margin: 0 0 18px;
    border-left: 5px solid #eeeeee
}

blockquote p {
    font-size: 16.25px;
    font-weight: 300;
    line-height: 1.25
}

blockquote p:last-child {
    margin-bottom: 0
}

blockquote small {
    display: block;
    line-height: 1.42857;
    color: #999999
}

blockquote small:before {
    content: '\2014 \00A0'
}

blockquote.pull-right {
    padding-right: 15px;
    padding-left: 0;
    border-right: 5px solid #eeeeee;
    border-left: 0
}

blockquote.pull-right p,
blockquote.pull-right small {
    text-align: right
}

blockquote.pull-right small:before {
    content: ''
}

blockquote.pull-right small:after {
    content: '\00A0 \2014'
}

q:before,
q:after,
blockquote:before,
blockquote:after {
    content: ""
}

address {
    display: block;
    margin-bottom: 18px;
    font-style: normal;
    line-height: 1.42857
}

code,
pre {
    font-family: Monaco, Menlo, Consolas, "Courier New", monospace
}

code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    white-space: nowrap;
    border-radius: 0
}

pre {
    display: block;
    padding: 8.5px;
    margin: 0 0 9px;
    font-size: 12px;
    line-height: 1.42857;
    word-break: break-all;
    word-wrap: break-word;
    color: #333333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 0
}

pre.prettyprint {
    margin-bottom: 18px
}

pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border: 0
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 10px;
    padding-right: 10px
}

.container:before,
.container:after {
    content: " ";
    display: table
}

.container:after {
    clear: both
}

.row {
    margin-left: -10px;
    margin-right: -10px
}

.row:before,
.row:after {
    content: " ";
    display: table
}

.row:after {
    clear: both
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-ms-1,
.col-ms-2,
.col-ms-3,
.col-ms-4,
.col-ms-5,
.col-ms-6,
.col-ms-7,
.col-ms-8,
.col-ms-9,
.col-ms-10,
.col-ms-11,
.col-ms-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px
}

.container {
    max-width: 100%
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11 {
    float: left
}

.col-xs-1 {
    width: 8.33333%
}

.col-xs-2 {
    width: 16.66667%
}

.col-xs-3 {
    width: 25%
}

.col-xs-4 {
    width: 33.33333%
}

.col-xs-5 {
    width: 41.66667%
}

.col-xs-6 {
    width: 50%
}

.col-xs-7 {
    width: 58.33333%
}

.col-xs-8 {
    width: 66.66667%
}

.col-xs-9 {
    width: 75%
}

.col-xs-10 {
    width: 83.33333%
}

.col-xs-11 {
    width: 91.66667%
}

.col-xs-12 {
    width: 100%
}

.col-xs-15 {
    width: 20%;
    float: left
}

@media (min-width:480px) {
    .container {
        max-width: 100%
    }

    .col-ms-1,
    .col-ms-2,
    .col-ms-3,
    .col-ms-4,
    .col-ms-5,
    .col-ms-6,
    .col-ms-7,
    .col-ms-8,
    .col-ms-9,
    .col-ms-10,
    .col-ms-11 {
        float: left
    }

    .col-ms-1 {
        width: 8.33333%
    }

    .col-ms-2 {
        width: 16.66667%
    }

    .col-ms-3 {
        width: 25%
    }

    .col-ms-4 {
        width: 33.33333%
    }

    .col-ms-5 {
        width: 41.66667%
    }

    .col-ms-6 {
        width: 50%
    }

    .col-ms-7 {
        width: 58.33333%
    }

    .col-ms-8 {
        width: 66.66667%
    }

    .col-ms-9 {
        width: 75%
    }

    .col-ms-10 {
        width: 83.33333%
    }

    .col-ms-11 {
        width: 91.66667%
    }

    .col-ms-12 {
        width: 100%
    }

    .col-ms-15 {
        width: 20%;
        float: left
    }

    .col-ms-push-1 {
        left: 8.33333%
    }

    .col-ms-push-2 {
        left: 16.66667%
    }

    .col-ms-push-3 {
        left: 25%
    }

    .col-ms-push-4 {
        left: 33.33333%
    }

    .col-ms-push-5 {
        left: 41.66667%
    }

    .col-ms-push-6 {
        left: 50%
    }

    .col-ms-push-7 {
        left: 58.33333%
    }

    .col-ms-push-8 {
        left: 66.66667%
    }

    .col-ms-push-9 {
        left: 75%
    }

    .col-ms-push-10 {
        left: 83.33333%
    }

    .col-ms-push-11 {
        left: 91.66667%
    }

    .col-ms-pull-1 {
        right: 8.33333%
    }

    .col-ms-pull-2 {
        right: 16.66667%
    }

    .col-ms-pull-3 {
        right: 25%
    }

    .col-ms-pull-4 {
        right: 33.33333%
    }

    .col-ms-pull-5 {
        right: 41.66667%
    }

    .col-ms-pull-6 {
        right: 50%
    }

    .col-ms-pull-7 {
        right: 58.33333%
    }

    .col-ms-pull-8 {
        right: 66.66667%
    }

    .col-ms-pull-9 {
        right: 75%
    }

    .col-ms-pull-10 {
        right: 83.33333%
    }

    .col-ms-pull-11 {
        right: 91.66667%
    }

    .col-ms-offset-1 {
        margin-left: 8.33333%
    }

    .col-ms-offset-2 {
        margin-left: 16.66667%
    }

    .col-ms-offset-3 {
        margin-left: 25%
    }

    .col-ms-offset-4 {
        margin-left: 33.33333%
    }

    .col-ms-offset-5 {
        margin-left: 41.66667%
    }

    .col-ms-offset-6 {
        margin-left: 50%
    }

    .col-ms-offset-7 {
        margin-left: 58.33333%
    }

    .col-ms-offset-8 {
        margin-left: 66.66667%
    }

    .col-ms-offset-9 {
        margin-left: 75%
    }

    .col-ms-offset-10 {
        margin-left: 83.33333%
    }

    .col-ms-offset-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:768px) {
    .container {
        max-width: 100%
    }

    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11 {
        float: left
    }

    .col-sm-1 {
        width: 8.33333%
    }

    .col-sm-2 {
        width: 16.66667%
    }

    .col-sm-3 {
        width: 25%
    }

    .col-sm-4 {
        width: 33.33333%
    }

    .col-sm-5 {
        width: 41.66667%
    }

    .col-sm-6 {
        width: 50%
    }

    .col-sm-7 {
        width: 58.33333%
    }

    .col-sm-8 {
        width: 66.66667%
    }

    .col-sm-9 {
        width: 75%
    }

    .col-sm-10 {
        width: 83.33333%
    }

    .col-sm-11 {
        width: 91.66667%
    }

    .col-sm-12 {
        width: 100%
    }

    .col-sm-15 {
        width: 20%;
        float: left
    }

    .col-sm-push-1 {
        left: 8.33333%
    }

    .col-sm-push-2 {
        left: 16.66667%
    }

    .col-sm-push-3 {
        left: 25%
    }

    .col-sm-push-4 {
        left: 33.33333%
    }

    .col-sm-push-5 {
        left: 41.66667%
    }

    .col-sm-push-6 {
        left: 50%
    }

    .col-sm-push-7 {
        left: 58.33333%
    }

    .col-sm-push-8 {
        left: 66.66667%
    }

    .col-sm-push-9 {
        left: 75%
    }

    .col-sm-push-10 {
        left: 83.33333%
    }

    .col-sm-push-11 {
        left: 91.66667%
    }

    .col-sm-pull-1 {
        right: 8.33333%
    }

    .col-sm-pull-2 {
        right: 16.66667%
    }

    .col-sm-pull-3 {
        right: 25%
    }

    .col-sm-pull-4 {
        right: 33.33333%
    }

    .col-sm-pull-5 {
        right: 41.66667%
    }

    .col-sm-pull-6 {
        right: 50%
    }

    .col-sm-pull-7 {
        right: 58.33333%
    }

    .col-sm-pull-8 {
        right: 66.66667%
    }

    .col-sm-pull-9 {
        right: 75%
    }

    .col-sm-pull-10 {
        right: 83.33333%
    }

    .col-sm-pull-11 {
        right: 91.66667%
    }

    .col-sm-offset-1 {
        margin-left: 8.33333%
    }

    .col-sm-offset-2 {
        margin-left: 16.66667%
    }

    .col-sm-offset-3 {
        margin-left: 25%
    }

    .col-sm-offset-4 {
        margin-left: 33.33333%
    }

    .col-sm-offset-5 {
        margin-left: 41.66667%
    }

    .col-sm-offset-6 {
        margin-left: 50%
    }

    .col-sm-offset-7 {
        margin-left: 58.33333%
    }

    .col-sm-offset-8 {
        margin-left: 66.66667%
    }

    .col-sm-offset-9 {
        margin-left: 75%
    }

    .col-sm-offset-10 {
        margin-left: 83.33333%
    }

    .col-sm-offset-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:1000px) and (max-width:1319px) {
    .container {
        max-width: 1020px
    }
}

@media (min-width:1000px) {

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11 {
        float: left
    }

    .col-md-1 {
        width: 8.33333%
    }

    .col-md-2 {
        width: 16.66667%
    }

    .col-md-3 {
        width: 25%
    }

    .col-md-4 {
        width: 33.33333%
    }

    .col-md-5 {
        width: 41.66667%
    }

    .col-md-6 {
        width: 50%
    }

    .col-md-7 {
        width: 58.33333%
    }

    .col-md-8 {
        width: 66.66667%
    }

    .col-md-9 {
        width: 75%
    }

    .col-md-10 {
        width: 83.33333%
    }

    .col-md-11 {
        width: 91.66667%
    }

    .col-md-12 {
        width: 100%
    }

    .col-md-15 {
        width: 20%;
        float: left
    }

    .col-md-push-0 {
        left: auto
    }

    .col-md-push-1 {
        left: 8.33333%
    }

    .col-md-push-2 {
        left: 16.66667%
    }

    .col-md-push-3 {
        left: 25%
    }

    .col-md-push-4 {
        left: 33.33333%
    }

    .col-md-push-5 {
        left: 41.66667%
    }

    .col-md-push-6 {
        left: 50%
    }

    .col-md-push-7 {
        left: 58.33333%
    }

    .col-md-push-8 {
        left: 66.66667%
    }

    .col-md-push-9 {
        left: 75%
    }

    .col-md-push-10 {
        left: 83.33333%
    }

    .col-md-push-11 {
        left: 91.66667%
    }

    .col-md-pull-0 {
        right: auto
    }

    .col-md-pull-1 {
        right: 8.33333%
    }

    .col-md-pull-2 {
        right: 16.66667%
    }

    .col-md-pull-3 {
        right: 25%
    }

    .col-md-pull-4 {
        right: 33.33333%
    }

    .col-md-pull-5 {
        right: 41.66667%
    }

    .col-md-pull-6 {
        right: 50%
    }

    .col-md-pull-7 {
        right: 58.33333%
    }

    .col-md-pull-8 {
        right: 66.66667%
    }

    .col-md-pull-9 {
        right: 75%
    }

    .col-md-pull-10 {
        right: 83.33333%
    }

    .col-md-pull-11 {
        right: 91.66667%
    }

    .col-md-offset-0 {
        margin-left: 0
    }

    .col-md-offset-1 {
        margin-left: 8.33333%
    }

    .col-md-offset-2 {
        margin-left: 16.66667%
    }

    .col-md-offset-3 {
        margin-left: 25%
    }

    .col-md-offset-4 {
        margin-left: 33.33333%
    }

    .col-md-offset-5 {
        margin-left: 41.66667%
    }

    .col-md-offset-6 {
        margin-left: 50%
    }

    .col-md-offset-7 {
        margin-left: 58.33333%
    }

    .col-md-offset-8 {
        margin-left: 66.66667%
    }

    .col-md-offset-9 {
        margin-left: 75%
    }

    .col-md-offset-10 {
        margin-left: 83.33333%
    }

    .col-md-offset-11 {
        margin-left: 91.66667%
    }
}

@media (min-width:1320px) {
    .container {
        max-width: 1270px
    }

    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11 {
        float: left
    }

    .col-lg-1 {
        width: 8.33333%
    }

    .col-lg-2 {
        width: 16.66667%
    }

    .col-lg-3 {
        width: 25%
    }

    .col-lg-4 {
        width: 33.33333%
    }

    .col-lg-5 {
        width: 41.66667%
    }

    .col-lg-6 {
        width: 50%
    }

    .col-lg-7 {
        width: 58.33333%
    }

    .col-lg-8 {
        width: 66.66667%
    }

    .col-lg-9 {
        width: 75%
    }

    .col-lg-10 {
        width: 83.33333%
    }

    .col-lg-11 {
        width: 91.66667%
    }

    .col-lg-12 {
        width: 100%
    }

    .col-lg-15 {
        width: 20%;
        float: left
    }

    .col-lg-push-0 {
        left: auto
    }

    .col-lg-push-1 {
        left: 8.33333%
    }

    .col-lg-push-2 {
        left: 16.66667%
    }

    .col-lg-push-3 {
        left: 25%
    }

    .col-lg-push-4 {
        left: 33.33333%
    }

    .col-lg-push-5 {
        left: 41.66667%
    }

    .col-lg-push-6 {
        left: 50%
    }

    .col-lg-push-7 {
        left: 58.33333%
    }

    .col-lg-push-8 {
        left: 66.66667%
    }

    .col-lg-push-9 {
        left: 75%
    }

    .col-lg-push-10 {
        left: 83.33333%
    }

    .col-lg-push-11 {
        left: 91.66667%
    }

    .col-lg-pull-0 {
        right: auto
    }

    .col-lg-pull-1 {
        right: 8.33333%
    }

    .col-lg-pull-2 {
        right: 16.66667%
    }

    .col-lg-pull-3 {
        right: 25%
    }

    .col-lg-pull-4 {
        right: 33.33333%
    }

    .col-lg-pull-5 {
        right: 41.66667%
    }

    .col-lg-pull-6 {
        right: 50%
    }

    .col-lg-pull-7 {
        right: 58.33333%
    }

    .col-lg-pull-8 {
        right: 66.66667%
    }

    .col-lg-pull-9 {
        right: 75%
    }

    .col-lg-pull-10 {
        right: 83.33333%
    }

    .col-lg-pull-11 {
        right: 91.66667%
    }

    .col-lg-offset-0 {
        margin-left: 0
    }

    .col-lg-offset-1 {
        margin-left: 8.33333%
    }

    .col-lg-offset-2 {
        margin-left: 16.66667%
    }

    .col-lg-offset-3 {
        margin-left: 25%
    }

    .col-lg-offset-4 {
        margin-left: 33.33333%
    }

    .col-lg-offset-5 {
        margin-left: 41.66667%
    }

    .col-lg-offset-6 {
        margin-left: 50%
    }

    .col-lg-offset-7 {
        margin-left: 58.33333%
    }

    .col-lg-offset-8 {
        margin-left: 66.66667%
    }

    .col-lg-offset-9 {
        margin-left: 75%
    }

    .col-lg-offset-10 {
        margin-left: 83.33333%
    }

    .col-lg-offset-11 {
        margin-left: 91.66667%
    }
}

.col-xs-15,
.col-ms-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px
}

table {
    max-width: 100%;
    background-color: transparent
}

th {
    text-align: left
}

.table {
    width: 100%;
    margin-bottom: 18px
}

.table thead>tr>th,
.table thead>tr>td,
.table tbody>tr>th,
.table tbody>tr>td,
.table tfoot>tr>th,
.table tfoot>tr>td {
    padding: 10px 11px 10px 11px;
    line-height: 1.42857;
    vertical-align: top;
    border-top: 1px solid #d6d4d4
}

.table thead>tr>th {
    vertical-align: bottom;
    border-bottom: 2px solid #d6d4d4
}

.table caption+thead tr:first-child th,
.table caption+thead tr:first-child td,
.table colgroup+thead tr:first-child th,
.table colgroup+thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
    border-top: 0
}

.table tbody+tbody {
    border-top: 2px solid #d6d4d4
}

.table .table {
    background-color: #fff
}

.table-condensed thead>tr>th,
.table-condensed thead>tr>td,
.table-condensed tbody>tr>th,
.table-condensed tbody>tr>td,
.table-condensed tfoot>tr>th,
.table-condensed tfoot>tr>td {
    padding: 5px
}

.table-bordered {
    border: 1px solid #d6d4d4
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>tfoot>tr>td {
    border: 1px solid #d6d4d4
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td {
    border-bottom-width: 2px
}

.table-striped>tbody>tr:nth-child(odd)>td,
.table-striped>tbody>tr:nth-child(odd)>th {
    background-color: #f9f9f9
}

.table-hover>tbody>tr:hover>td,
.table-hover>tbody>tr:hover>th {
    background-color: #f5f5f5
}

table col[class*="col-"] {
    float: none;
    display: table-column
}

table td[class*="col-"],
table th[class*="col-"] {
    float: none;
    display: table-cell
}

.table>thead>tr>td.active,
.table>thead>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th {
    background-color: #f5f5f5
}

.table>thead>tr>td.success,
.table>thead>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th {
    background-color: #55c65e;
    border-color: #48b151
}

.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover,
.table-hover>tbody>tr.success:hover>td {
    background-color: #42c04c;
    border-color: #419f49
}

.table>thead>tr>td.danger,
.table>thead>tr>th.danger,
.table>thead>tr.danger>td,
.table>thead>tr.danger>th,
.table>tbody>tr>td.danger,
.table>tbody>tr>th.danger,
.table>tbody>tr.danger>td,
.table>tbody>tr.danger>th,
.table>tfoot>tr>td.danger,
.table>tfoot>tr>th.danger,
.table>tfoot>tr.danger>td,
.table>tfoot>tr.danger>th {
    background-color: #f3515c;
    border-color: #d4323d
}

.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover,
.table-hover>tbody>tr.danger:hover>td {
    background-color: #f13946;
    border-color: #c32933
}

.table>thead>tr>td.warning,
.table>thead>tr>th.warning,
.table>thead>tr.warning>td,
.table>thead>tr.warning>th,
.table>tbody>tr>td.warning,
.table>tbody>tr>th.warning,
.table>tbody>tr.warning>td,
.table>tbody>tr.warning>th,
.table>tfoot>tr>td.warning,
.table>tfoot>tr>th.warning,
.table>tfoot>tr.warning>td,
.table>tfoot>tr.warning>th {
    background-color: #fe9126;
    border-color: #e4752b
}

.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover,
.table-hover>tbody>tr.warning:hover>td {
    background-color: #fe840d;
    border-color: #da681c
}

@media (max-width:768px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 15px;
        overflow-y: hidden;
        overflow-x: scroll;
        border: 1px solid #d6d4d4
    }

    .table-responsive>.table {
        margin-bottom: 0
    }

    .table-responsive>.table>thead>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>tfoot>tr>td {
        white-space: nowrap
    }

    .table-responsive>.table-bordered {
        border: 0
    }

    .table-responsive>.table-bordered>thead>tr>th:first-child,
    .table-responsive>.table-bordered>thead>tr>td:first-child,
    .table-responsive>.table-bordered>tbody>tr>th:first-child,
    .table-responsive>.table-bordered>tbody>tr>td:first-child,
    .table-responsive>.table-bordered>tfoot>tr>th:first-child,
    .table-responsive>.table-bordered>tfoot>tr>td:first-child {
        border-left: 0
    }

    .table-responsive>.table-bordered>thead>tr>th:last-child,
    .table-responsive>.table-bordered>thead>tr>td:last-child,
    .table-responsive>.table-bordered>tbody>tr>th:last-child,
    .table-responsive>.table-bordered>tbody>tr>td:last-child,
    .table-responsive>.table-bordered>tfoot>tr>th:last-child,
    .table-responsive>.table-bordered>tfoot>tr>td:last-child {
        border-right: 0
    }

    .table-responsive>.table-bordered>thead>tr:last-child>th,
    .table-responsive>.table-bordered>thead>tr:last-child>td,
    .table-responsive>.table-bordered>tbody>tr:last-child>th,
    .table-responsive>.table-bordered>tbody>tr:last-child>td,
    .table-responsive>.table-bordered>tfoot>tr:last-child>th,
    .table-responsive>.table-bordered>tfoot>tr:last-child>td {
        border-bottom: 0
    }
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 18px;
    font-size: 19.5px;
    line-height: inherit;
    color: #333333;
    border: 0;
    border-bottom: 1px solid #e5e5e5
}

label {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: bold
}

input[type="search"] {
    box-sizing: border-box
}

input[type="radio"],
input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal
}

input[type="file"] {
    display: block
}

select[multiple],
select[size] {
    height: auto
}

select optgroup {
    font-size: inherit;
    font-style: inherit;
    font-family: inherit
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    height: auto
}

.form-control:-moz-placeholder {
    color: #999999
}

.form-control::-moz-placeholder {
    color: #999999
}

.form-control:-ms-input-placeholder {
    color: #999999
}


.radio,
.checkbox {
    display: block;
    min-height: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px;
    vertical-align: middle
}

.radio label,
.checkbox label {
    display: inline;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    float: left;
    margin-left: -20px
}

.radio+.radio,
.checkbox+.checkbox {
    margin-top: -5px
}

.radio-inline,
.checkbox-inline {
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer
}

.radio-inline+.radio-inline,
.checkbox-inline+.checkbox-inline {
    margin-top: 0;
    margin-left: 10px
}


.has-error .input-group-addon {
    color: #fff;
    border-color: #fff;
    background-color: #f3515c
}

.has-success .help-block,
.has-success .control-label {
    color: #fff
}

.has-success .form-control {
    border-color: #fff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.has-success .form-control:focus {
    border-color: #e6e6e6;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px white
}

.has-success .input-group-addon {
    color: #fff;
    border-color: #fff;
    background-color: #55c65e
}

.form-control-static {
    margin-bottom: 0;
    padding-top: 7px
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #b7b7b7
}

@media (min-width:768px) {

    .form-inline .form-group,
    .navbar-form .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle
    }

    .form-inline .form-control,
    .navbar-form .form-control {
        display: inline-block
    }

    .form-inline .radio,
    .navbar-form .radio,
    .form-inline .checkbox,
    .navbar-form .checkbox {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0
    }

    .form-inline .radio input[type="radio"],
    .navbar-form .radio input[type="radio"],
    .form-inline .checkbox input[type="checkbox"],
    .navbar-form .checkbox input[type="checkbox"] {
        float: none;
        margin-left: 0
    }
}

.form-horizontal .control-label,
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 7px
}

.form-horizontal .form-group {
    margin-left: -10px;
    margin-right: -10px
}

.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
    content: " ";
    display: table
}

.form-horizontal .form-group:after {
    clear: both
}

@media (min-width:768px) {
    .form-horizontal .control-label {
        text-align: right
    }
}



.btn:focus {
    outline: thin dotted #333;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

.btn:hover,
.btn:focus {
    color: #333;
    text-decoration: none
}

.btn:active,
.btn.active {
    outline: 0;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.65;
    filter: alpha(opacity=65);
    box-shadow: none
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active {
    color: #333;
    background-color: #ebebeb;
    border-color: #adadad
}

.open .btn-default.dropdown-toggle {
    color: #333;
    background-color: #ebebeb;
    border-color: #adadad
}

.btn-default:active,
.btn-default.active {
    background-image: none
}

.open .btn-default.dropdown-toggle {
    background-image: none
}

.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
    background-color: #fff;
    border-color: #ccc
}

.btn-primary {
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    color: #fff;
    background-color: #3276b1;
    border-color: #285e8e
}

.open .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #3276b1;
    border-color: #285e8e
}

.btn-primary:active,
.btn-primary.active {
    background-image: none
}

.open .btn-primary.dropdown-toggle {
    background-image: none
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
    background-color: #428bca;
    border-color: #357ebd
}

.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active {
    color: #fff;
    background-color: #ed9c28;
    border-color: #d58512
}

.open .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ed9c28;
    border-color: #d58512
}

.btn-warning:active,
.btn-warning.active {
    background-image: none
}

.open .btn-warning.dropdown-toggle {
    background-image: none
}

.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
    background-color: #f0ad4e;
    border-color: #eea236
}

.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active {
    color: #fff;
    background-color: #d2322d;
    border-color: #ac2925
}

.open .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #d2322d;
    border-color: #ac2925
}

.btn-danger:active,
.btn-danger.active {
    background-image: none
}

.open .btn-danger.dropdown-toggle {
    background-image: none
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
    background-color: #d9534f;
    border-color: #d43f3a
}

.btn-success {
    color: #fff;
    background-color: #46a74e;
    border-color: #3e9546
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active {
    color: #fff;
    background-color: #3a8a41;
    border-color: #2c6a31
}

.open .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3a8a41;
    border-color: #2c6a31
}

.btn-success:active,
.btn-success.active {
    background-image: none
}

.open .btn-success.dropdown-toggle {
    background-image: none
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
    background-color: #46a74e;
    border-color: #3e9546
}

.btn-info {
    color: #fff;
    background-color: #5192f3;
    border-color: #3983f1
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active {
    color: #fff;
    background-color: #2b7af0;
    border-color: #0f62de
}

.open .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #2b7af0;
    border-color: #0f62de
}

.btn-info:active,
.btn-info.active {
    background-image: none
}

.open .btn-info.dropdown-toggle {
    background-image: none
}

.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled]:active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
    background-color: #5192f3;
    border-color: #3983f1
}

.btn-link {
    color: #777;
    font-weight: normal;
    cursor: pointer;
    border-radius: 0
}

.btn-link,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
    background-color: transparent;
    box-shadow: none
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
    border-color: transparent
}

.btn-link:hover,
.btn-link:focus {
    color: #515151;
    text-decoration: underline;
    background-color: transparent
}

.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus {
    color: #999999;
    text-decoration: none
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 10px 16px;
    font-size: 17px;
    line-height: 1.33;
    border-radius: 6px
}

.btn-sm,
.btn-group-sm>.btn,
.btn-xs,
.btn-group-xs>.btn {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px
}

.btn-xs,
.btn-group-xs>.btn {
    padding: 1px 5px
}

.btn-block {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0
}

.btn-block+.btn-block {
    margin-top: 5px
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%
}


.collapse {
    display: none
}

.collapse.in {
    display: block
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px solid #000;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-bottom: 0 dotted;
    content: ""
}


.open>a {
    outline: 0
}

.dropdown-header {
    display: block;
    padding: 3px 20px;
    font-size: 12px;
    line-height: 1.42857;
    color: #999999
}

.dropdown-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 990
}

.pull-right>.dropdown-menu {
    right: 0;
    left: auto
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle
}

.btn-group>.btn,
.btn-group-vertical>.btn {
    position: relative;
    float: left
}

.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
    z-index: 2
}

.btn-group>.btn:focus,
.btn-group-vertical>.btn:focus {
    outline: none
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
    margin-left: -1px
}

.btn-toolbar:before,
.btn-toolbar:after {
    content: " ";
    display: table
}

.btn-toolbar:after {
    clear: both
}

.btn-toolbar .btn-group {
    float: left
}

.btn-toolbar>.btn+.btn,
.btn-toolbar>.btn+.btn-group,
.btn-toolbar>.btn-group+.btn,
.btn-toolbar>.btn-group+.btn-group {
    margin-left: 5px
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0
}

.btn-group>.btn:first-child {
    margin-left: 0
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.btn-group>.btn-group {
    float: left
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
    border-radius: 0
}

.btn-group>.btn-group:first-child>.btn:last-child,
.btn-group>.btn-group:first-child>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.btn-group>.btn-group:last-child>.btn:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
    outline: 0
}

.btn-group>.btn+.dropdown-toggle {
    padding-left: 8px;
    padding-right: 8px
}

.btn-group>.btn-lg+.dropdown-toggle,
.btn-group-lg.btn-group>.btn+.dropdown-toggle {
    padding-left: 12px;
    padding-right: 12px
}

.btn-group.open .dropdown-toggle {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}

.btn .caret {
    margin-left: 0
}

.btn-lg .caret,
.btn-group-lg>.btn .caret {
    border-width: 5px 5px 0;
    border-bottom-width: 0
}

.dropup .btn-lg .caret,
.dropup .btn-group-lg>.btn .caret {
    border-width: 0 5px 5px
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%
}

.btn-group-vertical>.btn-group:before,
.btn-group-vertical>.btn-group:after {
    content: " ";
    display: table
}

.btn-group-vertical>.btn-group:after {
    clear: both
}

.btn-group-vertical>.btn-group>.btn {
    float: none
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0
}

.btn-group-vertical>.btn:not(:first-child):not(:last-child) {
    border-radius: 0
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child):not(:last-child)>.btn {
    border-radius: 0
}

.btn-group-vertical>.btn-group:first-child>.btn:last-child,
.btn-group-vertical>.btn-group:first-child>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:last-child>.btn:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate
}

.btn-group-justified .btn {
    float: none;
    display: table-cell;
    width: 1%
}

[data-toggle="buttons"]>.btn>input[type="radio"],
[data-toggle="buttons"]>.btn>input[type="checkbox"] {
    display: none
}



.nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none
}

.nav:before,
.nav:after {
    content: " ";
    display: table
}

.nav:after {
    clear: both
}

.nav>li {
    position: relative;
    display: block
}

.nav>li>a {
    position: relative;
    display: block;
    padding: 10px 15px
}

.nav>li>a:hover,
.nav>li>a:focus {
    text-decoration: none;
    background-color: #eeeeee
}

.nav>li.disabled>a {
    color: #999999
}

.nav>li.disabled>a:hover,
.nav>li.disabled>a:focus {
    color: #999999;
    text-decoration: none;
    background-color: transparent;
    cursor: not-allowed
}

.nav .open>a,
.nav .open>a:hover,
.nav .open>a:focus {
    background-color: #eeeeee;
    border-color: #777
}

.nav .nav-divider {
    height: 1px;
    margin: 8px 0;
    overflow: hidden;
    background-color: #e5e5e5
}

.nav>li>a>img {
    max-width: none
}

.nav-tabs {
    border-bottom: 1px solid #ddd
}

.nav-tabs>li {
    float: left;
    margin-bottom: -1px
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857;
    border: 1px solid transparent;
    border-radius: 0 0 0 0
}

.nav-tabs>li>a:hover {
    border-color: #eeeeee #eeeeee #ddd
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:hover,
.nav-tabs>li.active>a:focus {
    color: #555555;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
    cursor: default
}

.nav-pills>li {
    float: left
}

.nav-pills>li>a {
    border-radius: 5px
}

.nav-pills>li+li {
    margin-left: 2px
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:hover,
.nav-pills>li.active>a:focus {
    color: #fff;
    background-color: #428bca
}

.nav-stacked>li {
    float: none
}

.nav-stacked>li+li {
    margin-top: 2px;
    margin-left: 0
}

.nav-justified,
.nav-tabs.nav-justified {
    width: 100%
}

.nav-justified>li,
.nav-tabs.nav-justified>li {
    float: none
}

.nav-justified>li>a,
.nav-tabs.nav-justified>li>a {
    text-align: center
}

@media (min-width:768px) {

    .nav-justified>li,
    .nav-tabs.nav-justified>li {
        display: table-cell;
        width: 1%
    }
}

.nav-tabs-justified,
.nav-tabs.nav-justified {
    border-bottom: 0
}

.nav-tabs-justified>li>a,
.nav-tabs.nav-justified>li>a {
    border-bottom: 1px solid #ddd;
    margin-right: 0
}

.nav-tabs-justified>.active>a,
.nav-tabs.nav-justified>.active>a {
    border-bottom-color: #fff
}

.tabbable:before,
.tabbable:after {
    content: " ";
    display: table
}

.tabbable:after {
    clear: both
}

.tab-content>.tab-pane,
.pill-content>.pill-pane {
    display: none
}

.tab-content>.active,
.pill-content>.active {
    display: block
}

.nav .caret {
    border-top-color: #777;
    border-bottom-color: #777
}

.nav a:hover .caret {
    border-top-color: #515151;
    border-bottom-color: #515151
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.navbar {
    position: relative;
    z-index: 1000;
    min-height: 50px;
    margin-bottom: 18px;
    border: 1px solid transparent
}

.navbar:before,
.navbar:after {
    content: " ";
    display: table
}

.navbar:after {
    clear: both
}

@media (min-width:768px) {
    .navbar {
        border-radius: 0
    }
}

.navbar-header:before,
.navbar-header:after {
    content: " ";
    display: table
}

.navbar-header:after {
    clear: both
}

@media (min-width:768px) {
    .navbar-header {
        float: left
    }
}

.navbar-collapse {
    max-height: 340px;
    overflow-x: visible;
    padding-right: 10px;
    padding-left: 10px;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    -webkit-overflow-scrolling: touch
}

.navbar-collapse:before,
.navbar-collapse:after {
    content: " ";
    display: table
}

.navbar-collapse:after {
    clear: both
}

.navbar-collapse.in {
    overflow-y: auto
}

@media (min-width:768px) {
    .navbar-collapse {
        width: auto;
        border-top: 0;
        box-shadow: none
    }

    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important
    }

    .navbar-collapse.in {
        overflow-y: visible
    }

    .navbar-collapse .navbar-nav.navbar-left:first-child {
        margin-left: -10px
    }

    .navbar-collapse .navbar-nav.navbar-right:last-child {
        margin-right: -10px
    }

    .navbar-collapse .navbar-text:last-child {
        margin-right: 0
    }
}

.container>.navbar-header,
.container>.navbar-collapse {
    margin-right: -10px;
    margin-left: -10px
}

@media (min-width:768px) {

    .container>.navbar-header,
    .container>.navbar-collapse {
        margin-right: 0;
        margin-left: 0
    }
}

.navbar-static-top {
    border-width: 0 0 1px
}

@media (min-width:768px) {
    .navbar-static-top {
        border-radius: 0
    }
}

.navbar-fixed-top,
.navbar-fixed-bottom {
    position: fixed;
    right: 0;
    left: 0;
    border-width: 0 0 1px
}

@media (min-width:768px) {

    .navbar-fixed-top,
    .navbar-fixed-bottom {
        border-radius: 0
    }
}

.navbar-fixed-top {
    z-index: 1030;
    top: 0
}

.navbar-fixed-bottom {
    bottom: 0;
    margin-bottom: 0
}

.navbar-brand {
    float: left;
    padding: 16px 10px;
    font-size: 17px;
    line-height: 18px
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none
}

@media (min-width:768px) {
    .navbar>.container .navbar-brand {
        margin-left: -10px
    }
}

.navbar-toggle {
    position: relative;
    float: right;
    margin-right: 10px;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0
}

.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px
}

.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px
}

@media (min-width:768px) {
    .navbar-toggle {
        display: none
    }
}

.navbar-nav {
    margin: 8px -10px
}

.navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 18px
}

@media (max-width:479px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        box-shadow: none
    }

    .navbar-nav .open .dropdown-menu>li>a,
    .navbar-nav .open .dropdown-menu .dropdown-header {
        padding: 5px 15px 5px 25px
    }

    .navbar-nav .open .dropdown-menu>li>a {
        line-height: 18px
    }

    .navbar-nav .open .dropdown-menu>li>a:hover,
    .navbar-nav .open .dropdown-menu>li>a:focus {
        background-image: none
    }
}

@media (min-width:768px) {
    .navbar-nav {
        float: left;
        margin: 0
    }

    .navbar-nav>li {
        float: left
    }

    .navbar-nav>li>a {
        padding-top: 16px;
        padding-bottom: 16px
    }
}

@media (min-width:768px) {
    .navbar-left {
        float: left !important
    }

    .navbar-right {
        float: right !important
    }
}

.navbar-form {
    margin-left: -10px;
    margin-right: -10px;
    padding: 10px 10px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
    margin-top: 9px;
    margin-bottom: 9px
}

@media (max-width:479px) {
    .navbar-form .form-group {
        margin-bottom: 5px
    }
}

@media (min-width:768px) {
    .navbar-form {
        width: auto;
        border: 0;
        margin-left: 0;
        margin-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        box-shadow: none
    }
}

.navbar-nav>li>.dropdown-menu {
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.navbar-fixed-bottom .navbar-nav>li>.dropdown-menu {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.navbar-nav.pull-right>li>.dropdown-menu,
.navbar-nav>li>.dropdown-menu.pull-right {
    left: auto;
    right: 0
}

.navbar-btn {
    margin-top: 9px;
    margin-bottom: 9px
}

.navbar-text {
    float: left;
    margin-top: 16px;
    margin-bottom: 16px
}

@media (min-width:768px) {
    .navbar-text {
        margin-left: 10px;
        margin-right: 10px
    }
}

.navbar-default {
    background-color: #f8f8f8;
    border-color: #e7e7e7
}

.navbar-default .navbar-brand {
    color: #777
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: #5e5e5e;
    background-color: transparent
}

.navbar-default .navbar-text {
    color: #777
}

.navbar-default .navbar-nav>li>a {
    color: #777
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
    color: #333;
    background-color: transparent
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #555;
    background-color: #e7e7e7
}

.navbar-default .navbar-nav>.disabled>a,
.navbar-default .navbar-nav>.disabled>a:hover,
.navbar-default .navbar-nav>.disabled>a:focus {
    color: #ccc;
    background-color: transparent
}

.navbar-default .navbar-toggle {
    border-color: #ddd
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #ddd
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #ccc
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: #e6e6e6
}

.navbar-default .navbar-nav>.dropdown>a:hover .caret,
.navbar-default .navbar-nav>.dropdown>a:focus .caret {
    border-top-color: #333;
    border-bottom-color: #333
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:hover,
.navbar-default .navbar-nav>.open>a:focus {
    background-color: #e7e7e7;
    color: #555
}

.navbar-default .navbar-nav>.open>a .caret,
.navbar-default .navbar-nav>.open>a:hover .caret,
.navbar-default .navbar-nav>.open>a:focus .caret {
    border-top-color: #555;
    border-bottom-color: #555
}

.navbar-default .navbar-nav>.dropdown>a .caret {
    border-top-color: #777;
    border-bottom-color: #777
}

@media (max-width:479px) {
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: #777
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus {
        color: #333;
        background-color: transparent
    }

    .navbar-default .navbar-nav .open .dropdown-menu>.active>a,
    .navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus {
        color: #555;
        background-color: #e7e7e7
    }

    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a,
    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus {
        color: #ccc;
        background-color: transparent
    }
}

.navbar-default .navbar-link {
    color: #777
}

.navbar-default .navbar-link:hover {
    color: #333
}

.navbar-inverse {
    background-color: #222;
    border-color: #090909
}

.navbar-inverse .navbar-brand {
    color: #999999
}

.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
    color: #fff;
    background-color: transparent
}

.navbar-inverse .navbar-text {
    color: #999999
}

.navbar-inverse .navbar-nav>li>a {
    color: #999999
}

.navbar-inverse .navbar-nav>li>a:hover,
.navbar-inverse .navbar-nav>li>a:focus {
    color: #fff;
    background-color: transparent
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:hover,
.navbar-inverse .navbar-nav>.active>a:focus {
    color: #fff;
    background-color: #090909
}

.navbar-inverse .navbar-nav>.disabled>a,
.navbar-inverse .navbar-nav>.disabled>a:hover,
.navbar-inverse .navbar-nav>.disabled>a:focus {
    color: #444;
    background-color: transparent
}

.navbar-inverse .navbar-toggle {
    border-color: #333
}

.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
    background-color: #333
}

.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #fff
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
    border-color: #101010
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:hover,
.navbar-inverse .navbar-nav>.open>a:focus {
    background-color: #090909;
    color: #fff
}

.navbar-inverse .navbar-nav>.dropdown>a:hover .caret {
    border-top-color: #fff;
    border-bottom-color: #fff
}

.navbar-inverse .navbar-nav>.dropdown>a .caret {
    border-top-color: #999999;
    border-bottom-color: #999999
}

.navbar-inverse .navbar-nav>.open>a .caret,
.navbar-inverse .navbar-nav>.open>a:hover .caret,
.navbar-inverse .navbar-nav>.open>a:focus .caret {
    border-top-color: #fff;
    border-bottom-color: #fff
}

@media (max-width:479px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
        border-color: #090909
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
        color: #999999
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus {
        color: #fff;
        background-color: transparent
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus {
        color: #fff;
        background-color: #090909
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus {
        color: #444;
        background-color: transparent
    }
}

.navbar-inverse .navbar-link {
    color: #999999
}

.navbar-inverse .navbar-link:hover {
    color: #fff
}

.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 18px;
    list-style: none;
    background-color: #f6f6f6;
    border-radius: 0
}

.breadcrumb>li {
    display: inline-block
}

.breadcrumb>li+li:before {
    content: "/\00a0";
    padding: 0 5px;
    color: #333
}

.breadcrumb>.active {
    color: #333
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 18px 0;
    border-radius: 0
}

.pagination>li {
    display: inline
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.pagination>li>a:hover,
.pagination>li>a:focus,
.pagination>li>span:hover,
.pagination>li>span:focus {
    background-color: #f6f6f6
}

.pagination>.active>a,
.pagination>.active>a:hover,
.pagination>.active>a:focus,
.pagination>.active>span,
.pagination>.active>span:hover,
.pagination>.active>span:focus {
    z-index: 2;
    color: #fff;
    background-color: #428bca;
    border-color: #428bca;
    cursor: default
}

.pagination>.disabled>span,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus {
    color: #999999;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed
}

.pagination-lg>li>a,
.pagination-lg>li>span {
    padding: 10px 16px;
    font-size: 17px
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px
}

.pagination-sm>li>a,
.pagination-sm>li>span {
    padding: 5px 10px;
    font-size: 12px
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px
}

.pager {
    padding-left: 0;
    margin: 18px 0;
    list-style: none;
    text-align: center
}

.pager:before,
.pager:after {
    content: " ";
    display: table
}

.pager:after {
    clear: both
}

.pager li {
    display: inline
}

.pager li>a,
.pager li>span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px
}

.pager li>a:hover,
.pager li>a:focus {
    text-decoration: none;
    background-color: #f6f6f6
}

.pager .next>a,
.pager .next>span {
    float: right
}

.pager .previous>a,
.pager .previous>span {
    float: left
}

.pager .disabled>a,
.pager .disabled>a:hover,
.pager .disabled>a:focus,
.pager .disabled>span {
    color: #999999;
    background-color: #fff;
    cursor: not-allowed
}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 100%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0
}

.label[href]:hover,
.label[href]:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer
}

.label:empty {
    display: none
}

.label-default {
    background-color: #999999
}

.label-default[href]:hover,
.label-default[href]:focus {
    background-color: gray
}

.label-primary {
    background-color: #428bca
}

.label-primary[href]:hover,
.label-primary[href]:focus {
    background-color: #3071a9
}

.label-success {
    background-color: #55c65e;
    border: 1px solid #36943e
}

.label-success[href]:hover,
.label-success[href]:focus {
    background-color: #3aae43
}

.label-info {
    background-color: #5192f3;
    border: 1px solid #4b80c3
}

.label-info[href]:hover,
.label-info[href]:focus {
    background-color: #2174f0
}

.label-warning {
    background-color: #fe9126;
    border: 1px solid #e4752b
}

.label-warning[href]:hover,
.label-warning[href]:focus {
    background-color: #f07701
}

.label-danger {
    background-color: #f3515c;
    border: 1px solid #d4323d
}

.label-danger[href]:hover,
.label-danger[href]:focus {
    background-color: #f0212f
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    vertical-align: baseline;
    white-space: nowrap;
    text-align: center;
    background-color: #999999;
    border-radius: 10px
}

.badge:empty {
    display: none
}

a.badge:hover,
a.badge:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer
}

.btn .badge {
    position: relative;
    top: -1px
}

a.list-group-item.active>.badge,
.nav-pills>.active>a>.badge {
    color: #777;
    background-color: #fff
}

.nav-pills>li>a>.badge {
    margin-left: 3px
}

.thumbnail {
    display: block
}

.thumbnail>img {
    display: block;
    max-width: 100%;
    height: auto
}

a.thumbnail:hover,
a.thumbnail:focus {
    border-color: #777
}

.thumbnail>img {
    margin-left: auto;
    margin-right: auto
}

.thumbnail .caption {
    padding: 9px;
    color: #777777
}

.alert {
    padding: 15px;
    margin-bottom: 18px;
    border: 1px solid transparent;
    border-radius: 0
}

.alert h4 {
    margin-top: 0;
    color: inherit
}

.alert .alert-link {
    font-weight: bold
}

.alert>p,
.alert>ul {
    margin-bottom: 0
}

.alert>p+p {
    margin-top: 5px
}

.alert-dismissable {
    padding-right: 35px
}

.alert-dismissable .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit
}

.alert-success {
    background-color: #55c65e;
    border-color: #48b151;
    color: #fff
}

.alert-success hr {
    border-top-color: #419f49
}

.alert-success .alert-link {
    color: #e6e6e6
}

.alert-info {
    background-color: #5192f3;
    border-color: #4b80c3;
    color: #fff
}

.alert-info hr {
    border-top-color: #3d73b7
}

.alert-info .alert-link {
    color: #e6e6e6
}

.alert-warning {
    background-color: #fe9126;
    border-color: #e4752b;
    color: #fff
}

.alert-warning hr {
    border-top-color: #da681c
}

.alert-warning .alert-link {
    color: #e6e6e6
}

.alert-danger {
    background-color: #f3515c;
    border-color: #d4323d;
    color: #fff
}

.alert-danger hr {
    border-top-color: #c32933
}

.alert-danger .alert-link {
    color: #e6e6e6
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }

    to {
        background-position: 0 0
    }
}

.progress {
    overflow: hidden;
    height: 18px;
    margin-bottom: 18px;
    background-color: #f5f5f5;
    border-radius: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1)
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    color: #fff;
    text-align: center;
    background-color: #428bca;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    transition: width 0.6s ease
}

.progress-striped .progress-bar {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
    background-size: 40px 40px
}

.progress.active .progress-bar {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite
}

.progress-bar-success {
    background-color: #46a74e
}

.progress-striped .progress-bar-success {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0))
}

.progress-bar-info {
    background-color: #5192f3
}

.progress-striped .progress-bar-info {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0))
}

.progress-bar-warning {
    background-color: #f0ad4e
}

.progress-striped .progress-bar-warning {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0))
}

.progress-bar-danger {
    background-color: #d9534f
}

.progress-striped .progress-bar-danger {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0))
}

.media,
.media-body {
    overflow: hidden;
    zoom: 1
}

.media,
.media .media {
    margin-top: 15px
}

.media:first-child {
    margin-top: 0
}

.media-object {
    display: block
}

.media-heading {
    margin: 0 0 5px
}

.media>.pull-left {
    margin-right: 10px
}

.media>.pull-right {
    margin-left: 10px
}

.media-list {
    padding-left: 0;
    list-style: none
}

.list-group {
    margin-bottom: 20px;
    padding-left: 0
}

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd
}

.list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.list-group-item>.badge {
    float: right
}

.list-group-item>.badge+.badge {
    margin-right: 5px
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
    z-index: 2;
    color: #fff;
    background-color: #428bca;
    border-color: #428bca
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading {
    color: inherit
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
    color: #e1edf7
}

a.list-group-item {
    color: #555
}

a.list-group-item .list-group-item-heading {
    color: #333
}

a.list-group-item:hover,
a.list-group-item:focus {
    text-decoration: none;
    background-color: #f5f5f5
}

.list-group-item-heading {
    margin-top: 0;
    margin-bottom: 5px
}

.list-group-item-text {
    margin-bottom: 0;
    line-height: 1.3
}

.panel {
    margin-bottom: 18px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05)
}

.panel-body {
    padding: 15px
}

.panel-body:before,
.panel-body:after {
    content: " ";
    display: table
}

.panel-body:after {
    clear: both
}

.panel>.list-group {
    margin-bottom: 0
}

.panel>.list-group .list-group-item {
    border-width: 1px 0
}

.panel>.list-group .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.panel>.list-group .list-group-item:last-child {
    border-bottom: 0
}

.panel-heading+.list-group .list-group-item:first-child {
    border-top-width: 0
}

.panel>.table {
    margin-bottom: 0
}

.panel>.panel-body+.table {
    border-top: 1px solid #d6d4d4
}

.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: -1px;
    border-top-left-radius: -1px
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px
}

.panel-title>a {
    color: inherit
}

.panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: -1px;
    border-bottom-left-radius: -1px
}

.panel-group .panel {
    margin-bottom: 0;
    border-radius: 0;
    overflow: hidden
}

.panel-group .panel+.panel {
    margin-top: 5px
}

.panel-group .panel-heading {
    border-bottom: 0
}

.panel-group .panel-heading+.panel-collapse .panel-body {
    border-top: 1px solid #ddd
}

.panel-group .panel-footer {
    border-top: 0
}

.panel-group .panel-footer+.panel-collapse .panel-body {
    border-bottom: 1px solid #ddd
}

.panel-default {
    border-color: #ddd
}

.panel-default>.panel-heading {
    color: #333333;
    background-color: #f5f5f5;
    border-color: #ddd
}

.panel-default>.panel-heading+.panel-collapse .panel-body {
    border-top-color: #ddd
}

.panel-default>.panel-footer+.panel-collapse .panel-body {
    border-bottom-color: #ddd
}

.panel-primary {
    border-color: #428bca
}

.panel-primary>.panel-heading {
    color: #fff;
    background-color: #428bca;
    border-color: #428bca
}

.panel-primary>.panel-heading+.panel-collapse .panel-body {
    border-top-color: #428bca
}

.panel-primary>.panel-footer+.panel-collapse .panel-body {
    border-bottom-color: #428bca
}

.panel-success {
    border-color: #48b151
}

.panel-success>.panel-heading {
    color: #fff;
    background-color: #55c65e;
    border-color: #48b151
}

.panel-success>.panel-heading+.panel-collapse .panel-body {
    border-top-color: #48b151
}

.panel-success>.panel-footer+.panel-collapse .panel-body {
    border-bottom-color: #48b151
}

.panel-warning {
    border-color: #e4752b
}

.panel-warning>.panel-heading {
    color: #fff;
    background-color: #fe9126;
    border-color: #e4752b
}

.panel-warning>.panel-heading+.panel-collapse .panel-body {
    border-top-color: #e4752b
}

.panel-warning>.panel-footer+.panel-collapse .panel-body {
    border-bottom-color: #e4752b
}

.panel-danger {
    border-color: #d4323d
}

.panel-danger>.panel-heading {
    color: #fff;
    background-color: #f3515c;
    border-color: #d4323d
}

.panel-danger>.panel-heading+.panel-collapse .panel-body {
    border-top-color: #d4323d
}

.panel-danger>.panel-footer+.panel-collapse .panel-body {
    border-bottom-color: #d4323d
}

.panel-info {
    border-color: #4b80c3
}

.panel-info>.panel-heading {
    color: #fff;
    background-color: #5192f3;
    border-color: #4b80c3
}

.panel-info>.panel-heading+.panel-collapse .panel-body {
    border-top-color: #4b80c3
}

.panel-info>.panel-footer+.panel-collapse .panel-body {
    border-bottom-color: #4b80c3
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05)
}

.well blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15)
}

.well-lg {
    padding: 24px;
    border-radius: 6px
}

.well-sm {
    padding: 9px;
    border-radius: 3px
}

.close {
    float: right;
    font-size: 19.5px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    filter: alpha(opacity=20)
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
    filter: alpha(opacity=50)
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table
}

.clearfix:after {
    clear: both
}

.pull-right {
    float: right !important
}

.pull-left {
    float: left !important
}

.hide {
    display: none !important
}

.show {
    display: block !important
}

.invisible {
    visibility: hidden
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.affix {
    position: fixed
}

@media screen and (max-width:400px) {
}

.hidden {
    display: none !important;
    visibility: hidden !important
}

.visible-xs {
    display: none !important
}

tr.visible-xs {
    display: none !important
}

th.visible-xs,
td.visible-xs {
    display: none !important
}

@media (max-width:479px) {
    .visible-xs {
        display: block !important
    }

    tr.visible-xs {
        display: table-row !important
    }

    th.visible-xs,
    td.visible-xs {
        display: table-cell !important
    }
}

@media (min-width:768px) and (max-width:999px) {
    .visible-xs.visible-sm {
        display: block !important
    }

    tr.visible-xs.visible-sm {
        display: table-row !important
    }

    th.visible-xs.visible-sm,
    td.visible-xs.visible-sm {
        display: table-cell !important
    }
}

@media (min-width:1000px) and (max-width:1319px) {
    .visible-xs.visible-md {
        display: block !important
    }

    tr.visible-xs.visible-md {
        display: table-row !important
    }

    th.visible-xs.visible-md,
    td.visible-xs.visible-md {
        display: table-cell !important
    }
}

@media (min-width:1320px) {
    .visible-xs.visible-lg {
        display: block !important
    }

    tr.visible-xs.visible-lg {
        display: table-row !important
    }

    th.visible-xs.visible-lg,
    td.visible-xs.visible-lg {
        display: table-cell !important
    }
}

.visible-sm {
    display: none !important
}

tr.visible-sm {
    display: none !important
}

th.visible-sm,
td.visible-sm {
    display: none !important
}

@media (max-width:479px) {
    .visible-sm.visible-xs {
        display: block !important
    }

    tr.visible-sm.visible-xs {
        display: table-row !important
    }

    th.visible-sm.visible-xs,
    td.visible-sm.visible-xs {
        display: table-cell !important
    }
}

@media (min-width:768px) and (max-width:999px) {
    .visible-sm {
        display: block !important
    }

    tr.visible-sm {
        display: table-row !important
    }

    th.visible-sm,
    td.visible-sm {
        display: table-cell !important
    }
}

@media (min-width:1000px) and (max-width:1319px) {
    .visible-sm.visible-md {
        display: block !important
    }

    tr.visible-sm.visible-md {
        display: table-row !important
    }

    th.visible-sm.visible-md,
    td.visible-sm.visible-md {
        display: table-cell !important
    }
}

@media (min-width:1320px) {
    .visible-sm.visible-lg {
        display: block !important
    }

    tr.visible-sm.visible-lg {
        display: table-row !important
    }

    th.visible-sm.visible-lg,
    td.visible-sm.visible-lg {
        display: table-cell !important
    }
}

.visible-md {
    display: none !important
}

tr.visible-md {
    display: none !important
}

th.visible-md,
td.visible-md {
    display: none !important
}

@media (max-width:479px) {
    .visible-md.visible-xs {
        display: block !important
    }

    tr.visible-md.visible-xs {
        display: table-row !important
    }

    th.visible-md.visible-xs,
    td.visible-md.visible-xs {
        display: table-cell !important
    }
}

@media (min-width:768px) and (max-width:999px) {
    .visible-md.visible-sm {
        display: block !important
    }

    tr.visible-md.visible-sm {
        display: table-row !important
    }

    th.visible-md.visible-sm,
    td.visible-md.visible-sm {
        display: table-cell !important
    }
}

@media (min-width:1000px) and (max-width:1319px) {
    .visible-md {
        display: block !important
    }

    tr.visible-md {
        display: table-row !important
    }

    th.visible-md,
    td.visible-md {
        display: table-cell !important
    }
}

@media (min-width:1320px) {
    .visible-md.visible-lg {
        display: block !important
    }

    tr.visible-md.visible-lg {
        display: table-row !important
    }

    th.visible-md.visible-lg,
    td.visible-md.visible-lg {
        display: table-cell !important
    }
}

.visible-lg {
    display: none !important
}

tr.visible-lg {
    display: none !important
}

th.visible-lg,
td.visible-lg {
    display: none !important
}

@media (max-width:479px) {
    .visible-lg.visible-xs {
        display: block !important
    }

    tr.visible-lg.visible-xs {
        display: table-row !important
    }

    th.visible-lg.visible-xs,
    td.visible-lg.visible-xs {
        display: table-cell !important
    }
}

@media (min-width:768px) and (max-width:999px) {
    .visible-lg.visible-sm {
        display: block !important
    }

    tr.visible-lg.visible-sm {
        display: table-row !important
    }

    th.visible-lg.visible-sm,
    td.visible-lg.visible-sm {
        display: table-cell !important
    }
}

@media (min-width:1000px) and (max-width:1319px) {
    .visible-lg.visible-md {
        display: block !important
    }

    tr.visible-lg.visible-md {
        display: table-row !important
    }

    th.visible-lg.visible-md,
    td.visible-lg.visible-md {
        display: table-cell !important
    }
}

@media (min-width:1320px) {
    .visible-lg {
        display: block !important
    }

    tr.visible-lg {
        display: table-row !important
    }

    th.visible-lg,
    td.visible-lg {
        display: table-cell !important
    }
}

.hidden-xs {
    display: block !important
}

tr.hidden-xs {
    display: table-row !important
}

th.hidden-xs,
td.hidden-xs {
    display: table-cell !important
}

@media (max-width:479px) {
    .hidden-xs {
        display: none !important
    }

    tr.hidden-xs {
        display: none !important
    }

    th.hidden-xs,
    td.hidden-xs {
        display: none !important
    }
}

@media (min-width:768px) and (max-width:999px) {
    .hidden-xs.hidden-sm {
        display: none !important
    }

    tr.hidden-xs.hidden-sm {
        display: none !important
    }

    th.hidden-xs.hidden-sm,
    td.hidden-xs.hidden-sm {
        display: none !important
    }
}

@media (min-width:1000px) and (max-width:1319px) {
    .hidden-xs.hidden-md {
        display: none !important
    }

    tr.hidden-xs.hidden-md {
        display: none !important
    }

    th.hidden-xs.hidden-md,
    td.hidden-xs.hidden-md {
        display: none !important
    }
}

@media (min-width:1320px) {
    .hidden-xs.hidden-lg {
        display: none !important
    }

    tr.hidden-xs.hidden-lg {
        display: none !important
    }

    th.hidden-xs.hidden-lg,
    td.hidden-xs.hidden-lg {
        display: none !important
    }
}

.hidden-sm {
    display: block !important
}

tr.hidden-sm {
    display: table-row !important
}

th.hidden-sm,
td.hidden-sm {
    display: table-cell !important
}

@media (max-width:479px) {
    .hidden-sm.hidden-xs {
        display: none !important
    }

    tr.hidden-sm.hidden-xs {
        display: none !important
    }

    th.hidden-sm.hidden-xs,
    td.hidden-sm.hidden-xs {
        display: none !important
    }
}

@media (min-width:768px) and (max-width:999px) {
    .hidden-sm {
        display: none !important
    }

    tr.hidden-sm {
        display: none !important
    }

    th.hidden-sm,
    td.hidden-sm {
        display: none !important
    }
}

@media (min-width:1000px) and (max-width:1319px) {
    .hidden-sm.hidden-md {
        display: none !important
    }

    tr.hidden-sm.hidden-md {
        display: none !important
    }

    th.hidden-sm.hidden-md,
    td.hidden-sm.hidden-md {
        display: none !important
    }
}

@media (min-width:1320px) {
    .hidden-sm.hidden-lg {
        display: none !important
    }

    tr.hidden-sm.hidden-lg {
        display: none !important
    }

    th.hidden-sm.hidden-lg,
    td.hidden-sm.hidden-lg {
        display: none !important
    }
}

.hidden-md {
    display: block !important
}

tr.hidden-md {
    display: table-row !important
}

th.hidden-md,
td.hidden-md {
    display: table-cell !important
}

@media (max-width:479px) {
    .hidden-md.hidden-xs {
        display: none !important
    }

    tr.hidden-md.hidden-xs {
        display: none !important
    }

    th.hidden-md.hidden-xs,
    td.hidden-md.hidden-xs {
        display: none !important
    }
}

@media (min-width:768px) and (max-width:999px) {
    .hidden-md.hidden-sm {
        display: none !important
    }

    tr.hidden-md.hidden-sm {
        display: none !important
    }

    th.hidden-md.hidden-sm,
    td.hidden-md.hidden-sm {
        display: none !important
    }
}

@media (min-width:1000px) and (max-width:1319px) {
    .hidden-md {
        display: none !important
    }

    tr.hidden-md {
        display: none !important
    }

    th.hidden-md,
    td.hidden-md {
        display: none !important
    }
}

@media (min-width:1320px) {
    .hidden-md.hidden-lg {
        display: none !important
    }

    tr.hidden-md.hidden-lg {
        display: none !important
    }

    th.hidden-md.hidden-lg,
    td.hidden-md.hidden-lg {
        display: none !important
    }
}

.hidden-lg {
    display: block !important
}

tr.hidden-lg {
    display: table-row !important
}

th.hidden-lg,
td.hidden-lg {
    display: table-cell !important
}

@media (max-width:479px) {
    .hidden-lg.hidden-xs {
        display: none !important
    }

    tr.hidden-lg.hidden-xs {
        display: none !important
    }

    th.hidden-lg.hidden-xs,
    td.hidden-lg.hidden-xs {
        display: none !important
    }
}

@media (min-width:768px) and (max-width:999px) {
    .hidden-lg.hidden-sm {
        display: none !important
    }

    tr.hidden-lg.hidden-sm {
        display: none !important
    }

    th.hidden-lg.hidden-sm,
    td.hidden-lg.hidden-sm {
        display: none !important
    }
}

@media (min-width:1000px) and (max-width:1319px) {
    .hidden-lg.hidden-md {
        display: none !important
    }

    tr.hidden-lg.hidden-md {
        display: none !important
    }

    th.hidden-lg.hidden-md,
    td.hidden-lg.hidden-md {
        display: none !important
    }
}

@media (min-width:1320px) {
    .hidden-lg {
        display: none !important
    }

    tr.hidden-lg {
        display: none !important
    }

    th.hidden-lg,
    td.hidden-lg {
        display: none !important
    }
}

.visible-print {
    display: none !important
}

tr.visible-print {
    display: none !important
}

th.visible-print,
td.visible-print {
    display: none !important
}

@media print {
    .visible-print {
        display: block !important
    }

    tr.visible-print {
        display: table-row !important
    }

    th.visible-print,
    td.visible-print {
        display: table-cell !important
    }

    .hidden-print {
        display: none !important
    }

    tr.hidden-print {
        display: none !important
    }

    th.hidden-print,
    td.hidden-print {
        display: none !important
    }
}

@font-face {
    font-family: 'FontAwesome';
    src: url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../fonts/fontawesome-webfont.eot?v=4.4.0");
    src: url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../fonts/fontawesome-webfont.eot?#iefix&v=4.4.0") format("embedded-opentype"), url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../fonts/fontawesome-webfont.woff2?v=4.4.0") format("woff2"), url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../fonts/fontawesome-webfont.woff?v=4.4.0") format("woff"), url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../fonts/fontawesome-webfont.ttf?v=4.4.0") format("truetype"), url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../fonts/fontawesome-webfont.svg?v=4.4.0#fontawesomeregular") format("svg");
    font-weight: normal;
    font-style: normal
}

.icon,
[class^="icon-"] {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0)
}

.icon-lg {
    font-size: 1.33333em;
    line-height: 0.75em;
    vertical-align: -15%
}

.icon-2x {
    font-size: 2em
}

.icon-3x {
    font-size: 3em
}

.icon-4x {
    font-size: 4em
}

.icon-5x {
    font-size: 5em
}

.icon-fw {
    width: 1.28571em;
    text-align: center
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14286em;
    list-style-type: none
}

.icon-ul>li {
    position: relative
}

.icon-li {
    position: absolute;
    left: -2.14286em;
    width: 2.14286em;
    top: 0.14286em;
    text-align: center
}

.icon-li.icon-lg {
    left: -1.85714em
}

.icon-border {
    padding: .2em .25em .15em;
    border: solid 0.08em #eee;
    border-radius: .1em
}

.pull-right {
    float: right
}

.pull-left {
    float: left
}

.icon.pull-left,
.pull-left[class^="icon-"] {
    margin-right: .3em
}

.icon.pull-right,
.pull-right[class^="icon-"] {
    margin-left: .3em
}

.icon-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear
}

.icon-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8)
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

.icon-rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.icon-rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.icon-rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
}

.icon-flip-horizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1)
}

.icon-flip-vertical {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1)
}

:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
    -webkit-filter: none;
            filter: none
}

.icon-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle
}

.icon-stack-1x,
.icon-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center
}

.icon-stack-1x {
    line-height: inherit
}

.icon-stack-2x {
    font-size: 2em
}

.icon-inverse {
    color: #fff
}

.icon-glass:before {
    content: "ï€€"
}

.icon-music:before {
    content: "ï€"
}

.icon-search:before {
    content: "ï€‚"
}

.icon-envelope-o:before,
.icon-envelope-alt:before {
    content: "ï€ƒ"
}

.icon-heart:before {
    content: "ï€„"
}

.icon-star:before {
    content: "ï€…"
}

.icon-star-o:before,
.icon-star-empty:before {
    content: "ï€†"
}

.icon-user:before {
    content: "ï€‡"
}

.icon-film:before {
    content: "ï€ˆ"
}

.icon-th-large:before {
    content: "ï€‰"
}

.icon-th:before {
    content: "ï€Š"
}

.icon-th-list:before {
    content: "ï€‹"
}

.icon-check:before {
    content: "ï€Œ"
}

.icon-remove:before,
.icon-close:before,
.icon-times:before,
.icon-remove:before {
    content: "ï€"
}

.icon-search-plus:before,
.icon-zoom-in:before {
    content: "ï€Ž"
}

.icon-search-minus:before,
.icon-zoom-out:before {
    content: "ï€"
}

.icon-power-off:before,
.icon-off:before {
    content: "ï€‘"
}

.icon-signal:before {
    content: "ï€’"
}

.icon-gear:before,
.icon-cog:before {
    content: "ï€“"
}

.icon-trash-o:before,
.icon-trash:before {
    content: "ï€”"
}

.icon-home:before {
    content: "ï€•"
}

.icon-file-o:before,
.icon-file-alt:before {
    content: "ï€–"
}

.icon-clock-o:before,
.icon-time:before {
    content: "ï€—"
}

.icon-road:before {
    content: "ï€˜"
}

.icon-download:before,
.icon-download-alt:before {
    content: "ï€™"
}

.icon-arrow-circle-o-down:before,
.icon-download:before,
.icon-download-alt:before {
    content: "ï€š"
}

.icon-arrow-circle-o-up:before,
.icon-upload:before,
.icon-upload-alt:before {
    content: "ï€›"
}

.icon-inbox:before {
    content: "ï€œ"
}

.icon-play-circle-o:before,
.icon-play-circle:before,
.icon-play-sign:before {
    content: "ï€"
}

.icon-rotate-right:before,
.icon-repeat:before {
    content: "ï€ž"
}

.icon-refresh:before {
    content: "ï€¡"
}

.icon-list-alt:before {
    content: "ï€¢"
}

.icon-lock:before {
    content: "ï€£"
}

.icon-flag:before {
    content: "ï€¤"
}

.icon-headphones:before {
    content: "ï€¥"
}

.icon-volume-off:before {
    content: "ï€¦"
}

.icon-volume-down:before {
    content: "ï€§"
}

.icon-volume-up:before {
    content: "ï€¨"
}

.icon-qrcode:before {
    content: "ï€©"
}

.icon-barcode:before {
    content: "ï€ª"
}

.icon-tag:before {
    content: "ï€«"
}

.icon-tags:before {
    content: "ï€¬"
}

.icon-book:before {
    content: "ï€­"
}

.icon-bookmark:before {
    content: "ï€®"
}

.icon-print:before {
    content: "ï€¯"
}

.icon-camera:before {
    content: "ï€°"
}

.icon-font:before {
    content: "ï€±"
}

.icon-bold:before {
    content: "ï€²"
}

.icon-italic:before {
    content: "ï€³"
}

.icon-text-height:before {
    content: "ï€´"
}

.icon-text-width:before {
    content: "ï€µ"
}

.icon-align-left:before {
    content: "ï€¶"
}

.icon-align-center:before {
    content: "ï€·"
}

.icon-align-right:before {
    content: "ï€¸"
}

.icon-align-justify:before {
    content: "ï€¹"
}

.icon-list:before {
    content: "ï€º"
}

.icon-dedent:before,
.icon-outdent:before,
.icon-indent-left:before {
    content: "ï€»"
}

.icon-indent:before,
.icon-indent-right:before {
    content: "ï€¼"
}

.icon-video-camera:before,
.icon-facetime-video:before {
    content: "ï€½"
}

.icon-photo:before,
.icon-image:before,
.icon-picture-o:before,
.icon-picture:before {
    content: "ï€¾"
}

.icon-pencil:before {
    content: "ï€"
}

.icon-map-marker:before {
    content: "ï"
}

.icon-adjust:before {
    content: "ï‚"
}

.icon-tint:before {
    content: "ïƒ"
}

.icon-edit:before,
.icon-pencil-square-o:before,
.icon-edit:before {
    content: "ï„"
}

.icon-share-square-o:before,
.icon-share:before,
.icon-share-alt:before {
    content: "ï…"
}

.icon-check-square-o:before {
    content: "ï†"
}

.icon-arrows:before,
.icon-move:before {
    content: "ï‡"
}

.icon-step-backward:before {
    content: "ïˆ"
}

.icon-fast-backward:before {
    content: "ï‰"
}

.icon-backward:before {
    content: "ïŠ"
}

.icon-play:before {
    content: "ï‹"
}

.icon-pause:before {
    content: "ïŒ"
}

.icon-stop:before {
    content: "ï"
}

.icon-forward:before {
    content: "ïŽ"
}

.icon-fast-forward:before {
    content: "ï"
}

.icon-step-forward:before {
    content: "ï‘"
}

.icon-eject:before {
    content: "ï’"
}

.icon-chevron-left:before {
    content: "ï“"
}

.icon-chevron-right:before {
    content: "ï”"
}

.icon-plus-circle:before,
.icon-plus-sign:before {
    content: "ï•"
}

.icon-minus-circle:before,
.icon-minus-sign:before {
    content: "ï–"
}

.icon-times-circle:before,
.icon-remove-sign:before {
    content: "ï—"
}

.icon-check-circle:before,
.icon-ok-sign:before {
    content: "ï˜"
}

.icon-question-circle:before,
.icon-question-sign:before {
    content: "ï™"
}

.icon-info-circle:before,
.icon-info-sign:before {
    content: "ïš"
}

.icon-crosshairs:before,
.icon-screenshot:before {
    content: "ï›"
}

.icon-times-circle-o:before,
.icon-remove-circle:before {
    content: "ïœ"
}

.icon-check-circle-o:before,
.icon-ok-circle:before {
    content: "ï"
}

.icon-ban:before {
    content: "ïž"
}

.icon-arrow-left:before {
    content: "ï "
}

.icon-arrow-right:before {
    content: "ï¡"
}

.icon-arrow-up:before {
    content: "ï¢"
}

.icon-arrow-down:before {
    content: "ï£"
}

.icon-mail-forward:before,
.icon-share:before,
.icon-share-alt:before {
    content: "ï¤"
}

.icon-expand:before,
.icon-resize-full:before {
    content: "ï¥"
}

.icon-compress:before,
.icon-resize-small:before {
    content: "ï¦"
}

.icon-plus:before {
    content: "ï§"
}

.icon-minus:before {
    content: "ï¨"
}

.icon-asterisk:before {
    content: "ï©"
}

.icon-exclamation-circle:before,
.icon-exclamation-sign:before {
    content: "ïª"
}

.icon-gift:before {
    content: "ï«"
}

.icon-leaf:before {
    content: "ï¬"
}

.icon-fire:before {
    content: "ï­"
}

.icon-eye:before,
.icon-eye-open:before {
    content: "ï®"
}

.icon-eye-slash:before,
.icon-eye-close:before {
    content: "ï°"
}

.icon-warning:before,
.icon-exclamation-triangle:before,
.icon-warning-sign:before {
    content: "ï±"
}

.icon-plane:before {
    content: "ï²"
}

.icon-calendar:before {
    content: "ï³"
}

.icon-random:before {
    content: "ï´"
}

.icon-comment:before {
    content: "ïµ"
}

.icon-magnet:before {
    content: "ï¶"
}

.icon-chevron-up:before {
    content: "ï·"
}

.icon-chevron-down:before {
    content: "ï¸"
}

.icon-retweet:before {
    content: "ï¹"
}

.icon-shopping-cart:before {
    content: "ïº"
}

.icon-folder:before,
.icon-folder-close:before {
    content: "ï»"
}

.icon-folder-open:before {
    content: "ï¼"
}

.icon-arrows-v:before,
.icon-resize-vertical:before {
    content: "ï½"
}

.icon-arrows-h:before,
.icon-resize-horizontal:before {
    content: "ï¾"
}

.icon-bar-chart-o:before,
.icon-bar-chart:before,
.icon-bar-chart:before {
    content: "ï‚€"
}

.icon-twitter-square:before,
.icon-twitter-sign:before {
    content: "ï‚"
}

.icon-facebook-square:before,
.icon-facebook-sign:before {
    content: "ï‚‚"
}

.icon-camera-retro:before {
    content: "ï‚ƒ"
}

.icon-key:before {
    content: "ï‚„"
}

.icon-gears:before,
.icon-cogs:before {
    content: "ï‚…"
}

.icon-comments:before {
    content: "ï‚†"
}

.icon-thumbs-o-up:before,
.icon-thumbs-up-alt:before {
    content: "ï‚‡"
}

.icon-thumbs-o-down:before,
.icon-thumbs-down-alt:before {
    content: "ï‚ˆ"
}

.icon-star-half:before {
    content: "ï‚‰"
}

.icon-heart-o:before,
.icon-heart-empty:before {
    content: "ï‚Š"
}

.icon-sign-out:before,
.icon-signout:before {
    content: "ï‚‹"
}

.icon-linkedin-square:before,
.icon-linkedin-sign:before {
    content: "ï‚Œ"
}

.icon-thumb-tack:before,
.icon-pushpin:before {
    content: "ï‚"
}

.icon-external-link:before {
    content: "ï‚Ž"
}

.icon-sign-in:before,
.icon-signin:before {
    content: "ï‚"
}

.icon-trophy:before {
    content: "ï‚‘"
}

.icon-github-square:before,
.icon-github-sign:before {
    content: "ï‚’"
}

.icon-upload:before,
.icon-upload-alt:before {
    content: "ï‚“"
}

.icon-lemon-o:before,
.icon-lemon:before {
    content: "ï‚”"
}

.icon-phone:before {
    content: "ï‚•"
}

.icon-square-o:before,
.icon-check-empty:before {
    content: "ï‚–"
}

.icon-bookmark-o:before,
.icon-bookmark-empty:before {
    content: "ï‚—"
}

.icon-phone-square:before,
.icon-phone-sign:before {
    content: "ï‚˜"
}

.icon-twitter:before {
    content: "ï‚™"
}

.icon-facebook-f:before,
.icon-facebook:before {
    content: "ï‚š"
}

.icon-github:before {
    content: "ï‚›"
}

.icon-unlock:before {
    content: "ï‚œ"
}

.icon-credit-card:before {
    content: "ï‚"
}

.icon-feed:before,
.icon-rss:before {
    content: "ï‚ž"
}

.icon-hdd-o:before,
.icon-hdd:before {
    content: "ï‚ "
}

.icon-bullhorn:before {
    content: "ï‚¡"
}

.icon-bell:before,
.icon-bell-alt:before {
    content: "ïƒ³"
}

.icon-certificate:before {
    content: "ï‚£"
}

.icon-hand-o-right:before,
.icon-hand-right:before {
    content: "ï‚¤"
}

.icon-hand-o-left:before,
.icon-hand-left:before {
    content: "ï‚¥"
}

.icon-hand-o-up:before,
.icon-hand-up:before {
    content: "ï‚¦"
}

.icon-hand-o-down:before,
.icon-hand-down:before {
    content: "ï‚§"
}

.icon-arrow-circle-left:before,
.icon-circle-arrow-left:before {
    content: "ï‚¨"
}

.icon-arrow-circle-right:before,
.icon-circle-arrow-right:before {
    content: "ï‚©"
}

.icon-arrow-circle-up:before,
.icon-circle-arrow-up:before {
    content: "ï‚ª"
}

.icon-arrow-circle-down:before,
.icon-circle-arrow-down:before {
    content: "ï‚«"
}

.icon-globe:before {
    content: "ï‚¬"
}

.icon-wrench:before {
    content: "ï‚­"
}

.icon-tasks:before {
    content: "ï‚®"
}

.icon-filter:before {
    content: "ï‚°"
}

.icon-briefcase:before {
    content: "ï‚±"
}

.icon-arrows-alt:before,
.icon-fullscreen:before {
    content: "ï‚²"
}

.icon-group:before,
.icon-users:before,
.icon-group:before {
    content: "ïƒ€"
}

.icon-chain:before,
.icon-link:before {
    content: "ïƒ"
}

.icon-cloud:before {
    content: "ïƒ‚"
}

.icon-flask:before,
.icon-beaker:before {
    content: "ïƒƒ"
}

.icon-cut:before,
.icon-scissors:before,
.icon-cut:before {
    content: "ïƒ„"
}

.icon-copy:before,
.icon-files-o:before,
.icon-copy:before {
    content: "ïƒ…"
}

.icon-paperclip:before,
.icon-paper-clip:before {
    content: "ïƒ†"
}

.icon-save:before,
.icon-floppy-o:before,
.icon-save:before {
    content: "ïƒ‡"
}

.icon-square:before,
.icon-sign-blank:before {
    content: "ïƒˆ"
}

.icon-navicon:before,
.icon-reorder:before,
.icon-bars:before,
.icon-reorder:before {
    content: "ïƒ‰"
}

.icon-list-ul:before {
    content: "ïƒŠ"
}

.icon-list-ol:before {
    content: "ïƒ‹"
}

.icon-strikethrough:before {
    content: "ïƒŒ"
}

.icon-underline:before {
    content: "ïƒ"
}

.icon-table:before {
    content: "ïƒŽ"
}

.icon-magic:before {
    content: "ïƒ"
}

.icon-truck:before {
    content: "ïƒ‘"
}

.icon-pinterest:before {
    content: "ïƒ’"
}

.icon-pinterest-square:before,
.icon-pinterest-sign:before {
    content: "ïƒ“"
}

.icon-google-plus-square:before,
.icon-google-plus-sign:before {
    content: "ïƒ”"
}

.icon-google-plus:before {
    content: "ïƒ•"
}

.icon-money:before {
    content: "ïƒ–"
}

.icon-caret-down:before {
    content: "ïƒ—"
}

.icon-caret-up:before {
    content: "ïƒ˜"
}

.icon-caret-left:before {
    content: "ïƒ™"
}

.icon-caret-right:before {
    content: "ïƒš"
}

.icon-columns:before {
    content: "ïƒ›"
}

.icon-unsorted:before,
.icon-sort:before {
    content: "ïƒœ"
}

.icon-sort-down:before,
.icon-sort-desc:before,
.icon-sort-up:before {
    content: "ïƒ"
}

.icon-sort-up:before,
.icon-sort-asc:before,
.icon-sort-down:before {
    content: "ïƒž"
}

.icon-envelope:before {
    content: "ïƒ "
}

.icon-linkedin:before {
    content: "ïƒ¡"
}

.icon-rotate-left:before,
.icon-undo:before {
    content: "ïƒ¢"
}

.icon-legal:before,
.icon-gavel:before,
.icon-legal:before {
    content: "ïƒ£"
}

.icon-dashboard:before,
.icon-tachometer:before,
.icon-dashboard:before {
    content: "ïƒ¤"
}

.icon-comment-o:before,
.icon-comment-alt:before {
    content: "ïƒ¥"
}

.icon-comments-o:before,
.icon-comments-alt:before {
    content: "ïƒ¦"
}

.icon-flash:before,
.icon-bolt:before {
    content: "ïƒ§"
}

.icon-sitemap:before {
    content: "ïƒ¨"
}

.icon-umbrella:before {
    content: "ïƒ©"
}

.icon-paste:before,
.icon-clipboard:before,
.icon-paste:before {
    content: "ïƒª"
}

.icon-lightbulb-o:before,
.icon-lightbulb:before {
    content: "ïƒ«"
}

.icon-exchange:before {
    content: "ïƒ¬"
}

.icon-cloud-download:before {
    content: "ïƒ­"
}

.icon-cloud-upload:before {
    content: "ïƒ®"
}

.icon-user-md:before {
    content: "ïƒ°"
}

.icon-stethoscope:before {
    content: "ïƒ±"
}

.icon-suitcase:before {
    content: "ïƒ²"
}

.icon-bell-o:before,
.icon-bell:before,
.icon-bell-alt:before {
    content: "ï‚¢"
}

.icon-coffee:before {
    content: "ïƒ´"
}

.icon-cutlery:before,
.icon-food:before {
    content: "ïƒµ"
}

.icon-file-text-o:before,
.icon-file-text-alt:before {
    content: "ïƒ¶"
}

.icon-building-o:before,
.icon-building:before {
    content: "ïƒ·"
}

.icon-hospital-o:before,
.icon-hospital:before {
    content: "ïƒ¸"
}

.icon-ambulance:before {
    content: "ïƒ¹"
}

.icon-medkit:before {
    content: "ïƒº"
}

.icon-fighter-jet:before {
    content: "ïƒ»"
}

.icon-beer:before {
    content: "ïƒ¼"
}

.icon-h-square:before,
.icon-h-sign:before {
    content: "ïƒ½"
}

.icon-plus-square:before,
.icon-plus-sign-alt:before {
    content: "ïƒ¾"
}

.icon-angle-double-left:before,
.icon-double-angle-left:before {
    content: "ï„€"
}

.icon-angle-double-right:before,
.icon-double-angle-right:before {
    content: "ï„"
}

.icon-angle-double-up:before,
.icon-double-angle-up:before {
    content: "ï„‚"
}

.icon-angle-double-down:before,
.icon-double-angle-down:before {
    content: "ï„ƒ"
}

.icon-angle-left:before {
    content: "ï„„"
}

.icon-angle-right:before {
    content: "ï„…"
}

.icon-angle-up:before {
    content: "ï„†"
}

.icon-angle-down:before {
    content: "ï„‡"
}

.icon-desktop:before {
    content: "ï„ˆ"
}

.icon-laptop:before {
    content: "ï„‰"
}

.icon-tablet:before {
    content: "ï„Š"
}

.icon-mobile-phone:before,
.icon-mobile:before,
.icon-mobile-phone:before {
    content: "ï„‹"
}

.icon-circle-o:before,
.icon-circle-blank:before {
    content: "ï„Œ"
}

.icon-quote-left:before {
    content: "ï„"
}

.icon-quote-right:before {
    content: "ï„Ž"
}

.icon-spinner:before {
    content: "ï„"
}

.icon-circle:before {
    content: "ï„‘"
}

.icon-mail-reply:before,
.icon-reply:before {
    content: "ï„’"
}

.icon-github-alt:before {
    content: "ï„“"
}

.icon-folder-o:before,
.icon-folder-close-alt:before {
    content: "ï„”"
}

.icon-folder-open-o:before,
.icon-folder-open-alt:before {
    content: "ï„•"
}

.icon-smile-o:before,
.icon-smile:before {
    content: "ï„˜"
}

.icon-frown-o:before,
.icon-frown:before {
    content: "ï„™"
}

.icon-meh-o:before,
.icon-meh:before {
    content: "ï„š"
}

.icon-gamepad:before {
    content: "ï„›"
}

.icon-keyboard-o:before,
.icon-keyboard:before {
    content: "ï„œ"
}

.icon-flag-o:before,
.icon-flag-alt:before {
    content: "ï„"
}

.icon-flag-checkered:before {
    content: "ï„ž"
}

.icon-terminal:before {
    content: "ï„ "
}

.icon-code:before {
    content: "ï„¡"
}

.icon-mail-reply-all:before,
.icon-reply-all:before {
    content: "ï„¢"
}

.icon-star-half-empty:before,
.icon-star-half-full:before,
.icon-star-half-o:before,
.icon-star-half-empty:before {
    content: "ï„£"
}

.icon-location-arrow:before {
    content: "ï„¤"
}

.icon-crop:before {
    content: "ï„¥"
}

.icon-code-fork:before {
    content: "ï„¦"
}

.icon-unlink:before,
.icon-chain-broken:before,
.icon-unlink:before {
    content: "ï„§"
}

.icon-question:before {
    content: "ï„¨"
}

.icon-info:before {
    content: "ï„©"
}

.icon-exclamation:before {
    content: "ï„ª"
}

.icon-superscript:before {
    content: "ï„«"
}

.icon-subscript:before {
    content: "ï„¬"
}

.icon-eraser:before {
    content: "ï„­"
}

.icon-puzzle-piece:before {
    content: "ï„®"
}

.icon-microphone:before {
    content: "ï„°"
}

.icon-microphone-slash:before,
.icon-microphone-off:before {
    content: "ï„±"
}

.icon-shield:before {
    content: "ï„²"
}

.icon-calendar-o:before,
.icon-calendar-empty:before {
    content: "ï„³"
}

.icon-fire-extinguisher:before {
    content: "ï„´"
}

.icon-rocket:before {
    content: "ï„µ"
}

.icon-maxcdn:before {
    content: "ï„¶"
}

.icon-chevron-circle-left:before,
.icon-chevron-sign-left:before {
    content: "ï„·"
}

.icon-chevron-circle-right:before,
.icon-chevron-sign-right:before {
    content: "ï„¸"
}

.icon-chevron-circle-up:before,
.icon-chevron-sign-up:before {
    content: "ï„¹"
}

.icon-chevron-circle-down:before,
.icon-chevron-sign-down:before {
    content: "ï„º"
}

.icon-html5:before {
    content: "ï„»"
}

.icon-css3:before {
    content: "ï„¼"
}

.icon-anchor:before {
    content: "ï„½"
}

.icon-unlock-alt:before {
    content: "ï„¾"
}

.icon-bullseye:before {
    content: "ï…€"
}

.icon-ellipsis-h:before,
.icon-ellipsis-horizontal:before {
    content: "ï…"
}

.icon-ellipsis-v:before,
.icon-ellipsis-vertical:before {
    content: "ï…‚"
}

.icon-rss-square:before,
.icon-rss-sign:before {
    content: "ï…ƒ"
}

.icon-play-circle:before,
.icon-play-sign:before {
    content: "ï…„"
}

.icon-ticket:before {
    content: "ï……"
}

.icon-minus-square:before,
.icon-minus-sign-alt:before {
    content: "ï…†"
}

.icon-minus-square-o:before,
.icon-check-minus:before,
.icon-collapse-alt:before {
    content: "ï…‡"
}

.icon-level-up:before {
    content: "ï…ˆ"
}

.icon-level-down:before {
    content: "ï…‰"
}

.icon-check-square:before,
.icon-check-sign:before {
    content: "ï…Š"
}

.icon-pencil-square:before,
.icon-edit-sign:before {
    content: "ï…‹"
}

.icon-external-link-square:before,
.icon-external-link-sign:before {
    content: "ï…Œ"
}

.icon-share-square:before,
.icon-share-sign:before {
    content: "ï…"
}

.icon-compass:before {
    content: "ï…Ž"
}

.icon-toggle-down:before,
.icon-caret-square-o-down:before,
.icon-collapse:before {
    content: "ï…"
}

.icon-toggle-up:before,
.icon-caret-square-o-up:before,
.icon-collapse-top:before {
    content: "ï…‘"
}

.icon-toggle-right:before,
.icon-caret-square-o-right:before {
    content: "ï…’"
}

.icon-euro:before,
.icon-eur:before {
    content: "ï…“"
}

.icon-gbp:before {
    content: "ï…”"
}

.icon-dollar:before,
.icon-usd:before {
    content: "ï…•"
}

.icon-rupee:before,
.icon-inr:before {
    content: "ï…–"
}

.icon-cny:before,
.icon-rmb:before,
.icon-yen:before,
.icon-jpy:before {
    content: "ï…—"
}

.icon-ruble:before,
.icon-rouble:before,
.icon-rub:before,
.icon-cny:before {
    content: "ï…˜"
}

.icon-won:before,
.icon-krw:before {
    content: "ï…™"
}

.icon-bitcoin:before,
.icon-btc:before {
    content: "ï…š"
}

.icon-file:before {
    content: "ï…›"
}

.icon-file-text:before {
    content: "ï…œ"
}

.icon-sort-alpha-asc:before,
.icon-sort-by-alphabet:before {
    content: "ï…"
}

.icon-sort-alpha-desc:before,
.icon-sort-by-alphabet-alt:before {
    content: "ï…ž"
}

.icon-sort-amount-asc:before,
.icon-sort-by-attributes:before {
    content: "ï… "
}

.icon-sort-amount-desc:before,
.icon-sort-by-attributes-alt:before {
    content: "ï…¡"
}

.icon-sort-numeric-asc:before,
.icon-sort-by-order:before {
    content: "ï…¢"
}

.icon-sort-numeric-desc:before,
.icon-sort-by-order-alt:before {
    content: "ï…£"
}

.icon-thumbs-up:before {
    content: "ï…¤"
}

.icon-thumbs-down:before {
    content: "ï…¥"
}

.icon-youtube-square:before,
.icon-youtube-sign:before {
    content: "ï…¦"
}

.icon-youtube:before {
    content: "ï…§"
}

.icon-xing:before {
    content: "ï…¨"
}

.icon-xing-square:before,
.icon-xing-sign:before {
    content: "ï…©"
}

.icon-youtube-play:before {
    content: "ï…ª"
}

.icon-dropbox:before {
    content: "ï…«"
}

.icon-stack-overflow:before,
.icon-stackexchange:before {
    content: "ï…¬"
}

.icon-instagram:before {
    content: "ï…­"
}

.icon-flickr:before {
    content: "ï…®"
}

.icon-adn:before {
    content: "ï…°"
}

.icon-bitbucket:before {
    content: "ï…±"
}

.icon-bitbucket-square:before,
.icon-bitbucket-sign:before {
    content: "ï…²"
}

.icon-tumblr:before {
    content: "ï…³"
}

.icon-tumblr-square:before,
.icon-tumblr-sign:before {
    content: "ï…´"
}

.icon-long-arrow-down:before {
    content: "ï…µ"
}

.icon-long-arrow-up:before {
    content: "ï…¶"
}

.icon-long-arrow-left:before {
    content: "ï…·"
}

.icon-long-arrow-right:before {
    content: "ï…¸"
}

.icon-apple:before {
    content: "ï…¹"
}

.icon-windows:before {
    content: "ï…º"
}

.icon-android:before {
    content: "ï…»"
}

.icon-linux:before {
    content: "ï…¼"
}

.icon-dribbble:before {
    content: "ï…½"
}

.icon-skype:before {
    content: "ï…¾"
}

.icon-foursquare:before {
    content: "ï†€"
}

.icon-trello:before {
    content: "ï†"
}

.icon-female:before {
    content: "ï†‚"
}

.icon-male:before {
    content: "ï†ƒ"
}

.icon-gittip:before,
.icon-gratipay:before {
    content: "ï†„"
}

.icon-sun-o:before,
.icon-sun:before {
    content: "ï†…"
}

.icon-moon-o:before,
.icon-moon:before {
    content: "ï††"
}

.icon-archive:before {
    content: "ï†‡"
}

.icon-bug:before {
    content: "ï†ˆ"
}

.icon-vk:before {
    content: "ï†‰"
}

.icon-weibo:before {
    content: "ï†Š"
}

.icon-renren:before {
    content: "ï†‹"
}

.icon-pagelines:before {
    content: "ï†Œ"
}

.icon-stack-exchange:before {
    content: "ï†"
}

.icon-arrow-circle-o-right:before {
    content: "ï†Ž"
}

.icon-arrow-circle-o-left:before {
    content: "ï†"
}

.icon-toggle-left:before,
.icon-caret-square-o-left:before {
    content: "ï†‘"
}

.icon-dot-circle-o:before {
    content: "ï†’"
}

.icon-wheelchair:before {
    content: "ï†“"
}

.icon-vimeo-square:before {
    content: "ï†”"
}

.icon-turkish-lira:before,
.icon-try:before {
    content: "ï†•"
}

.icon-plus-square-o:before,
.icon-expand-alt:before {
    content: "ï†–"
}

.icon-space-shuttle:before {
    content: "ï†—"
}

.icon-slack:before {
    content: "ï†˜"
}

.icon-envelope-square:before {
    content: "ï†™"
}

.icon-wordpress:before {
    content: "ï†š"
}

.icon-openid:before {
    content: "ï†›"
}

.icon-institution:before,
.icon-bank:before,
.icon-university:before {
    content: "ï†œ"
}

.icon-mortar-board:before,
.icon-graduation-cap:before {
    content: "ï†"
}

.icon-yahoo:before {
    content: "ï†ž"
}

.icon-google:before {
    content: "ï† "
}

.icon-reddit:before {
    content: "ï†¡"
}

.icon-reddit-square:before {
    content: "ï†¢"
}

.icon-stumbleupon-circle:before {
    content: "ï†£"
}

.icon-stumbleupon:before {
    content: "ï†¤"
}

.icon-delicious:before {
    content: "ï†¥"
}

.icon-digg:before {
    content: "ï†¦"
}

.icon-pied-piper:before {
    content: "ï†§"
}

.icon-pied-piper-alt:before {
    content: "ï†¨"
}

.icon-drupal:before {
    content: "ï†©"
}

.icon-joomla:before {
    content: "ï†ª"
}

.icon-language:before {
    content: "ï†«"
}

.icon-fax:before {
    content: "ï†¬"
}

.icon-building:before {
    content: "ï†­"
}

.icon-child:before {
    content: "ï†®"
}

.icon-paw:before {
    content: "ï†°"
}

.icon-spoon:before {
    content: "ï†±"
}

.icon-cube:before {
    content: "ï†²"
}

.icon-cubes:before {
    content: "ï†³"
}

.icon-behance:before {
    content: "ï†´"
}

.icon-behance-square:before {
    content: "ï†µ"
}

.icon-steam:before {
    content: "ï†¶"
}

.icon-steam-square:before {
    content: "ï†·"
}

.icon-recycle:before {
    content: "ï†¸"
}

.icon-automobile:before,
.icon-car:before {
    content: "ï†¹"
}

.icon-cab:before,
.icon-taxi:before {
    content: "ï†º"
}

.icon-tree:before {
    content: "ï†»"
}

.icon-spotify:before {
    content: "ï†¼"
}

.icon-deviantart:before {
    content: "ï†½"
}

.icon-soundcloud:before {
    content: "ï†¾"
}

.icon-database:before {
    content: "ï‡€"
}

.icon-file-pdf-o:before {
    content: "ï‡"
}

.icon-file-word-o:before {
    content: "ï‡‚"
}

.icon-file-excel-o:before {
    content: "ï‡ƒ"
}

.icon-file-powerpoint-o:before {
    content: "ï‡„"
}

.icon-file-photo-o:before,
.icon-file-picture-o:before,
.icon-file-image-o:before {
    content: "ï‡…"
}

.icon-file-zip-o:before,
.icon-file-archive-o:before {
    content: "ï‡†"
}

.icon-file-sound-o:before,
.icon-file-audio-o:before {
    content: "ï‡‡"
}

.icon-file-movie-o:before,
.icon-file-video-o:before {
    content: "ï‡ˆ"
}

.icon-file-code-o:before {
    content: "ï‡‰"
}

.icon-vine:before {
    content: "ï‡Š"
}

.icon-codepen:before {
    content: "ï‡‹"
}

.icon-jsfiddle:before {
    content: "ï‡Œ"
}

.icon-life-bouy:before,
.icon-life-buoy:before,
.icon-life-saver:before,
.icon-support:before,
.icon-life-ring:before {
    content: "ï‡"
}

.icon-circle-o-notch:before {
    content: "ï‡Ž"
}

.icon-ra:before,
.icon-rebel:before {
    content: "ï‡"
}

.icon-ge:before,
.icon-empire:before {
    content: "ï‡‘"
}

.icon-git-square:before {
    content: "ï‡’"
}

.icon-git:before {
    content: "ï‡“"
}

.icon-y-combinator-square:before,
.icon-yc-square:before,
.icon-hacker-news:before {
    content: "ï‡”"
}

.icon-tencent-weibo:before {
    content: "ï‡•"
}

.icon-qq:before {
    content: "ï‡–"
}

.icon-wechat:before,
.icon-weixin:before {
    content: "ï‡—"
}

.icon-send:before,
.icon-paper-plane:before {
    content: "ï‡˜"
}

.icon-send-o:before,
.icon-paper-plane-o:before {
    content: "ï‡™"
}

.icon-history:before {
    content: "ï‡š"
}

.icon-circle-thin:before {
    content: "ï‡›"
}

.icon-header:before {
    content: "ï‡œ"
}

.icon-paragraph:before {
    content: "ï‡"
}

.icon-sliders:before {
    content: "ï‡ž"
}

.icon-share-alt:before {
    content: "ï‡ "
}

.icon-share-alt-square:before {
    content: "ï‡¡"
}

.icon-bomb:before {
    content: "ï‡¢"
}

.icon-soccer-ball-o:before,
.icon-futbol-o:before {
    content: "ï‡£"
}

.icon-tty:before {
    content: "ï‡¤"
}

.icon-binoculars:before {
    content: "ï‡¥"
}

.icon-plug:before {
    content: "ï‡¦"
}

.icon-slideshare:before {
    content: "ï‡§"
}

.icon-twitch:before {
    content: "ï‡¨"
}

.icon-yelp:before {
    content: "ï‡©"
}

.icon-newspaper-o:before {
    content: "ï‡ª"
}

.icon-wifi:before {
    content: "ï‡«"
}

.icon-calculator:before {
    content: "ï‡¬"
}

.icon-paypal:before {
    content: "ï‡­"
}

.icon-google-wallet:before {
    content: "ï‡®"
}

.icon-cc-visa:before {
    content: "ï‡°"
}

.icon-cc-mastercard:before {
    content: "ï‡±"
}

.icon-cc-discover:before {
    content: "ï‡²"
}

.icon-cc-amex:before {
    content: "ï‡³"
}

.icon-cc-paypal:before {
    content: "ï‡´"
}

.icon-cc-stripe:before {
    content: "ï‡µ"
}

.icon-bell-slash:before {
    content: "ï‡¶"
}

.icon-bell-slash-o:before {
    content: "ï‡·"
}

.icon-trash:before {
    content: "ï‡¸"
}

.icon-copyright:before {
    content: "ï‡¹"
}

.icon-at:before {
    content: "ï‡º"
}

.icon-eyedropper:before {
    content: "ï‡»"
}

.icon-paint-brush:before {
    content: "ï‡¼"
}

.icon-birthday-cake:before {
    content: "ï‡½"
}

.icon-area-chart:before {
    content: "ï‡¾"
}

.icon-pie-chart:before {
    content: "ïˆ€"
}

.icon-line-chart:before {
    content: "ïˆ"
}

.icon-lastfm:before {
    content: "ïˆ‚"
}

.icon-lastfm-square:before {
    content: "ïˆƒ"
}

.icon-toggle-off:before {
    content: "ïˆ„"
}

.icon-toggle-on:before {
    content: "ïˆ…"
}

.icon-bicycle:before {
    content: "ïˆ†"
}

.icon-bus:before {
    content: "ïˆ‡"
}

.icon-ioxhost:before {
    content: "ïˆˆ"
}

.icon-angellist:before {
    content: "ïˆ‰"
}

.icon-cc:before {
    content: "ïˆŠ"
}

.icon-shekel:before,
.icon-sheqel:before,
.icon-ils:before {
    content: "ïˆ‹"
}

.icon-meanpath:before {
    content: "ïˆŒ"
}

.icon-buysellads:before {
    content: "ïˆ"
}

.icon-connectdevelop:before {
    content: "ïˆŽ"
}

.icon-dashcube:before {
    content: "ïˆ"
}

.icon-forumbee:before {
    content: "ïˆ‘"
}

.icon-leanpub:before {
    content: "ïˆ’"
}

.icon-sellsy:before {
    content: "ïˆ“"
}

.icon-shirtsinbulk:before {
    content: "ïˆ”"
}

.icon-simplybuilt:before {
    content: "ïˆ•"
}

.icon-skyatlas:before {
    content: "ïˆ–"
}

.icon-cart-plus:before {
    content: "ïˆ—"
}

.icon-cart-arrow-down:before {
    content: "ïˆ˜"
}

.icon-diamond:before {
    content: "ïˆ™"
}

.icon-ship:before {
    content: "ïˆš"
}

.icon-user-secret:before {
    content: "ïˆ›"
}

.icon-motorcycle:before {
    content: "ïˆœ"
}

.icon-street-view:before {
    content: "ïˆ"
}

.icon-heartbeat:before {
    content: "ïˆž"
}

.icon-venus:before {
    content: "ïˆ¡"
}

.icon-mars:before {
    content: "ïˆ¢"
}

.icon-mercury:before {
    content: "ïˆ£"
}

.icon-intersex:before,
.icon-transgender:before {
    content: "ïˆ¤"
}

.icon-transgender-alt:before {
    content: "ïˆ¥"
}

.icon-venus-double:before {
    content: "ïˆ¦"
}

.icon-mars-double:before {
    content: "ïˆ§"
}

.icon-venus-mars:before {
    content: "ïˆ¨"
}

.icon-mars-stroke:before {
    content: "ïˆ©"
}

.icon-mars-stroke-v:before {
    content: "ïˆª"
}

.icon-mars-stroke-h:before {
    content: "ïˆ«"
}

.icon-neuter:before {
    content: "ïˆ¬"
}

.icon-genderless:before {
    content: "ïˆ­"
}

.icon-facebook-official:before {
    content: "ïˆ°"
}

.icon-pinterest-p:before {
    content: "ïˆ±"
}

.icon-whatsapp:before {
    content: "ïˆ²"
}

.icon-server:before {
    content: "ïˆ³"
}

.icon-user-plus:before {
    content: "ïˆ´"
}

.icon-user-times:before {
    content: "ïˆµ"
}

.icon-hotel:before,
.icon-bed:before {
    content: "ïˆ¶"
}

.icon-viacoin:before {
    content: "ïˆ·"
}

.icon-train:before {
    content: "ïˆ¸"
}

.icon-subway:before {
    content: "ïˆ¹"
}

.icon-medium:before {
    content: "ïˆº"
}

.icon-yc:before,
.icon-y-combinator:before {
    content: "ïˆ»"
}

.icon-optin-monster:before {
    content: "ïˆ¼"
}

.icon-opencart:before {
    content: "ïˆ½"
}

.icon-expeditedssl:before {
    content: "ïˆ¾"
}

.icon-battery-4:before,
.icon-battery-full:before {
    content: "ï‰€"
}

.icon-battery-3:before,
.icon-battery-three-quarters:before {
    content: "ï‰"
}

.icon-battery-2:before,
.icon-battery-half:before {
    content: "ï‰‚"
}

.icon-battery-1:before,
.icon-battery-quarter:before {
    content: "ï‰ƒ"
}

.icon-battery-0:before,
.icon-battery-empty:before {
    content: "ï‰„"
}

.icon-mouse-pointer:before {
    content: "ï‰…"
}

.icon-i-cursor:before {
    content: "ï‰†"
}

.icon-object-group:before {
    content: "ï‰‡"
}

.icon-object-ungroup:before {
    content: "ï‰ˆ"
}

.icon-sticky-note:before {
    content: "ï‰‰"
}

.icon-sticky-note-o:before {
    content: "ï‰Š"
}

.icon-cc-jcb:before {
    content: "ï‰‹"
}

.icon-cc-diners-club:before {
    content: "ï‰Œ"
}

.icon-clone:before {
    content: "ï‰"
}

.icon-balance-scale:before {
    content: "ï‰Ž"
}

.icon-hourglass-o:before {
    content: "ï‰"
}

.icon-hourglass-1:before,
.icon-hourglass-start:before {
    content: "ï‰‘"
}

.icon-hourglass-2:before,
.icon-hourglass-half:before {
    content: "ï‰’"
}

.icon-hourglass-3:before,
.icon-hourglass-end:before {
    content: "ï‰“"
}

.icon-hourglass:before {
    content: "ï‰”"
}

.icon-hand-grab-o:before,
.icon-hand-rock-o:before {
    content: "ï‰•"
}

.icon-hand-stop-o:before,
.icon-hand-paper-o:before {
    content: "ï‰–"
}

.icon-hand-scissors-o:before {
    content: "ï‰—"
}

.icon-hand-lizard-o:before {
    content: "ï‰˜"
}

.icon-hand-spock-o:before {
    content: "ï‰™"
}

.icon-hand-pointer-o:before {
    content: "ï‰š"
}

.icon-hand-peace-o:before {
    content: "ï‰›"
}

.icon-trademark:before {
    content: "ï‰œ"
}

.icon-registered:before {
    content: "ï‰"
}

.icon-creative-commons:before {
    content: "ï‰ž"
}

.icon-gg:before {
    content: "ï‰ "
}

.icon-gg-circle:before {
    content: "ï‰¡"
}

.icon-tripadvisor:before {
    content: "ï‰¢"
}

.icon-odnoklassniki:before {
    content: "ï‰£"
}

.icon-odnoklassniki-square:before {
    content: "ï‰¤"
}

.icon-get-pocket:before {
    content: "ï‰¥"
}

.icon-wikipedia-w:before {
    content: "ï‰¦"
}

.icon-safari:before {
    content: "ï‰§"
}

.icon-chrome:before {
    content: "ï‰¨"
}

.icon-firefox:before {
    content: "ï‰©"
}

.icon-opera:before {
    content: "ï‰ª"
}

.icon-internet-explorer:before {
    content: "ï‰«"
}

.icon-tv:before,
.icon-television:before {
    content: "ï‰¬"
}

.icon-contao:before {
    content: "ï‰­"
}

.icon-500px:before {
    content: "ï‰®"
}

.icon-amazon:before {
    content: "ï‰°"
}

.icon-calendar-plus-o:before {
    content: "ï‰±"
}

.icon-calendar-minus-o:before {
    content: "ï‰²"
}

.icon-calendar-times-o:before {
    content: "ï‰³"
}

.icon-calendar-check-o:before {
    content: "ï‰´"
}

.icon-industry:before {
    content: "ï‰µ"
}

.icon-map-pin:before {
    content: "ï‰¶"
}

.icon-map-signs:before {
    content: "ï‰·"
}

.icon-map-o:before {
    content: "ï‰¸"
}

.icon-map:before {
    content: "ï‰¹"
}

.icon-commenting:before {
    content: "ï‰º"
}

.icon-commenting-o:before {
    content: "ï‰»"
}

.icon-houzz:before {
    content: "ï‰¼"
}

.icon-vimeo:before {
    content: "ï‰½"
}

.icon-black-tie:before {
    content: "ï‰¾"
}

.icon-fonticons:before {
    content: "ïŠ€"
}

@font-face {
    font-family: 'warehousefont';
    src: url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../fonts/warehousefont.eot");
    src: url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../fonts/warehousefont.eot?#iefix-l0rvkg") format("embedded-opentype"), url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../fonts/warehousefont.woff") format("woff"), url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../fonts/warehousefont.ttf") format("truetype"), url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../fonts/warehousefont.svg") format("svg");
    font-weight: normal;
    font-style: normal
}

[class^="wicon-"],
[class*=" wicon-"] {
    font-family: 'warehousefont';
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.wicon-vimeo-square:before {
    content: "\f194"
}

.wicon-arrow_top:before {
    content: "\e603"
}

.wicon-arrow_left:before {
    content: "\e600"
}

.wicon-arrow_right:before {
    content: "\e601"
}

.wicon-arrow_bottom:before {
    content: "\e604"
}

.wicon-shopping_bag:before {
    content: "\e605"
}

a:hover {
    text-decoration: none
}

body {
    min-width: 320px;
    height: 100%;
    line-height: 18px;
    font-size: 13px;
    color: #777
}

body.content_only {
    background: none
}

textarea {
    resize: none
}

#columns {
    position: relative;
    padding-bottom: 20px;
    padding-top: 20px
}

#index #columns {
    padding-bottom: 0
}

#index .tab-content {
    margin-top: 20px;
    margin-bottom: 0
}

#index .block .title_block:after,
#index .block h4:after {
    display: none
}

@media (max-width:767px) {
    .container-header {
        max-width: 100% !important;
        padding: 0 !important
    }
}

@media (max-width:767px) {
    .nav .container {
        max-width: 100% !important
    }
}

header {
    z-index: 1;
    position: relative
}

header .banner {
    background: black;
    max-height: 100%
}

header .banner .row {
    margin: 0
}

header .nav {
    background: #f8f8f8;
    min-height: 40px;
    padding-top: 5px
}

header .nav .row {
    margin: 0
}

header .nav nav {
    margin-top: 2px;
    line-height: 26px;
    width: 100%
}

header .nav nav>a,
header .nav nav .compare-form {
    padding: 0 10px
}

@media (max-width:767px) {

    header .nav nav>a,
    header .nav nav .compare-form {
        font-size: 0
    }
}

header .nav nav>a:hover,
header .nav nav .compare-form:hover {
    color: #515151
}

@media (max-width:767px) {

    header .nav nav>a i,
    header .nav nav .compare-form i {
        font-size: 13px;
        line-height: 28px
    }
}

header .nav nav .wishlist_top_link {
    display: none
}

header .nav nav .compare-form {
    float: right
}

header .nav nav .compare-form .bt_compare {
    border: none;
    background: none;
    color: inherit;
    padding: 0;
    outline: none !important
}

header .row {
    position: relative;
    clear: both
}

header .row>.container {
    position: relative
}

header .row #header_logo {
    padding-top: 20px;
    padding-bottom: 20px;
    height: 140px
}

@media (max-width:767px) {
    header .row #header_logo {
        height: auto
    }
}

header .row #header_logo img {
    display: inline-block;
    max-height: 100px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0
}

@media (min-width:768px) {
    header .row #header_logo img {
        width: auto !important
    }
}

header .row #header_logo.centered-logo img {
    left: 0;
    right: 0
}

@media (max-width:767px) {
    header .row #header_logo {
        padding-top: 20px;
        padding-bottom: 20px
    }

    header .row #header_logo img {
        display: block;
        position: static;
        margin: 0 auto;
        width: auto
    }
}

header .mobile-condensed-header {
    display: none
}

@media (max-width:999px) {
    header .mobile-condensed-header {
        display: block
    }

    header .mobile-condensed-header .mobile-main-wrapper {
        padding: 0 15px;
        position: relative
    }

    header .mobile-condensed-header .mh-button {
        text-align: center
    }

    header .mobile-condensed-header .mh-button>span,
    header .mobile-condensed-header .mh-button>a {
        display: inline-block;
        cursor: pointer;
        transition: background 0.2s ease, color 0.2s ease
    }

    header .mobile-condensed-header .mh-button .mh-icon {
        font-size: 24px
    }
}

@media (max-width:999px) and (max-width:479px) {
    header .mobile-condensed-header .mh-button .mh-icon {
        font-size: 18px
    }
}

@media (max-width:999px) {
    header .mobile-condensed-header .mh-drop {
        top: 100%;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        background: #fff;
        padding: 15px;
        left: 0;
        width: 100%;
        z-index: 2;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
        transition: opacity 0.2s ease, visibility 0.2s ease
    }

    header .mobile-condensed-header .mh-drop ul {
        margin: 0
    }

    header .mobile-condensed-header .active .mh-drop {
        opacity: 1;
        visibility: visible
    }

    header .mobile-condensed-header .active .mh-icon:before {
        font-family: FontAwesome;
        content: "\f00d"
    }

    header .mobile-condensed-header #mh-cart-wrapper .cart_block {
        display: block !important;
        top: 100%;
        border: none;
        width: 100%;
        text-align: left;
        opacity: 0 !important;
        visibility: hidden;
        transition: opacity 0.2s ease, visibility 0.2s ease
    }

    header .mobile-condensed-header #mh-cart-wrapper.active .ajax_cart_quantity {
        display: none
    }

    header .mobile-condensed-header #mh-cart-wrapper.active .cart_block {
        opacity: 1 !important;
        visibility: visible
    }

    header .mobile-condensed-header .mobile-main-bar {
        display: table;
        table-layout: fixed;
        width: 100%;
        min-height: 40px
    }

    header .mobile-condensed-header .mobile-main-bar .mh-button,
    header .mobile-condensed-header .mobile-main-bar .mobile-h-logo {
        display: table-cell;
        vertical-align: middle
    }

    header .mobile-condensed-header .mh-button {
        width: 40px
    }
}

@media (max-width:999px) and (max-width:479px) {
    header .mobile-condensed-header .mh-button {
        width: 30px
    }
}

@media (max-width:999px) {
    header .mobile-condensed-header .mobile-h-logo {
        padding: 10px 10px 10px 0
    }

    header .mobile-condensed-header .mobile-h-logo a {
        display: block
    }

    header .mobile-condensed-header .mobile-h-logo img {
        max-height: 100px
    }

    header .mobile-condensed-header.mobile-style1 .mobile-h-logo {
        text-align: center;
        padding: 10px
    }

    header .mobile-condensed-header.mobile-style1 .mobile-h-logo img {
        margin: 0 auto;
        width: auto
    }

    header .mobile-condensed-header.mobile-style2 .mobile-h-logo img {
        width: auto
    }

    header .mobile-condensed-header.mobile-style3 .mobile-h-logo {
        padding: 10px
    }

    header .mobile-condensed-header.mobile-style3 .mobile-h-logo img {
        margin: 0 auto
    }

    header .mobile-condensed-header.mobile-style3 .sticked .mobile-h-logo {
        visibility: hidden;
        opacity: 0;
        height: 0;
        padding: 0 !important
    }

    header .mobile-condensed-header.mobile-style3 .mobile-main-wrapper {
        padding: 0
    }

    header .mobile-condensed-header.mobile-style3 .mh-button>span {
        padding: 10px 0;
        display: block
    }

    header .mobile-condensed-header.mobile-style3 .mh-button:last-child {
        border-right: none
    }

    header .mobile-condensed-header.mobile-search-expanded .not-sticked .mh-search {
        opacity: 0;
        visibility: hidden
    }

    header .mobile-condensed-header.mobile-search-expanded .not-sticked .mh-search-drop {
        visibility: visible;
        opacity: 1;
        position: static
    }

    header .mobile-condensed-header.mobile-search-expanded.mobile-style3 .not-sticked .mh-search {
        display: none
    }

    header .mobile-condensed-header #mh-sticky {
        -webkit-transform: translate3d(0px, 0px, 0px);
        transform: translate3d(0px, 0px, 0px)
    }

    header .mobile-condensed-header #mh-sticky.sticked {
        left: 0;
        background: #fff;
        width: 100%;
        top: 0;
        position: fixed;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15)
    }

    header .mobile-condensed-header #mh-sticky.sticked .cart_block .cart_block_list dl.products {
        max-height: 120px
    }

    header .mobile-condensed-header #mh-sticky.sticked .mobile-h-logo {
        padding-top: 5px;
        padding-bottom: 5px
    }

    header .mobile-condensed-header #mh-sticky.sticked .mobile-h-logo img {
        max-height: 50px
    }

    header .ajax_cart_quantity {
        border-radius: 26px;
        width: 16px;
        height: 16px;
        text-align: center;
        padding: 0;
        line-height: 15px;
        font-size: 11px;
        position: absolute;
        top: -6px;
        right: -10px;
        background: red
    }

    header .desktop-header {
        display: none
    }
}

#center_column .page_product_box h3 {
    color: #555454;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    padding: 14px 20px 17px;
    margin: 0 0 20px;
    position: relative;
    border: 1px solid #dddddd;
    border-bottom: none;
    background: #f8f8f8
}

p.info-title {
    font-weight: bold;
    color: #777;
    margin-bottom: 25px
}

p.info-account {
    margin: -4px 0 24px 0
}

.main-page-indent {
    margin-bottom: 20px
}

.alert {
    border: none;
    font-weight: bold
}

.alert ul,
.alert ol {
    padding-left: 15px;
    margin-left: 27px
}

.alert ul:last-child,
.alert ol:last-child {
    margin-bottom: 0
}

.alert ul li,
.alert ol li {
    list-style-type: decimal;
    font-weight: normal
}

.alert.alert-success:before {
    font-family: "FontAwesome";
    content: "\f058";
    font-size: 20px;
    vertical-align: -2px;
    padding-right: 7px
}

.alert.alert-danger:before {
    font-family: "FontAwesome";
    content: "\f057";
    font-size: 20px;
    vertical-align: -2px;
    padding-right: 7px;
    float: left
}

.label {
    white-space: normal;
    display: inline-block;
    padding: 6px 10px;
    border: none;
    font-weight: normal
}

label.required:before {
    content: "*";
    color: red;
    font-size: 14px;
    position: relative;
    line-height: 12px
}

.unvisible {
    display: none
}

.checkbox {
    line-height: 16px
}

.close {
    opacity: 1
}

.close:hover {
    opacity: 1
}

input.button_mini,
input.button_small,
input.button,
input.button_large,
input.button_mini_disabled,
input.button_small_disabled,
input.button_disabled,
input.button_large_disabled,
input.exclusive_mini,
input.exclusive_small,
input.exclusive,
input.exclusive_large,
input.exclusive_mini_disabled,
input.exclusive_small_disabled,
input.exclusive_disabled,
input.exclusive_large_disabled,
a.button_mini,
a.button_small,
a.button,
a.button_large,
a.exclusive_mini,
a.exclusive_small,
a.exclusive,
a.exclusive_large,
span.button_mini,
span.button_small,
span.button,
span.button_large,
span.exclusive_mini,
span.exclusive_small,
span.exclusive,
span.exclusive_large,
span.exclusive_large_disabled {
    position: relative;
    display: inline-block;
    color: black;
    border: none;
    background: #f4b61b;
    cursor: pointer;
    white-space: normal;
    text-align: left;
    outline: none !important
}

*:first-child+html input.button_mini,
*:first-child+html input.button_small,
*:first-child+html input.button,
*:first-child+html input.button_large,
*:first-child+html input.button_mini_disabled,
*:first-child+html input.button_small_disabled,
*:first-child+html input.button_disabled,
*:first-child+html input.button_large_disabled,
*:first-child+html input.exclusive_mini,
*:first-child+html input.exclusive_small,
*:first-child+html input.exclusive,
*:first-child+html input.exclusive_large,
*:first-child+html input.exclusive_mini_disabled,
*:first-child+html input.exclusive_small_disabled,
*:first-child+html input.exclusive_disabled,
*:first-child+html input.exclusive_large_disabled {
    border: none
}

input.button_mini:hover,
input.button_small:hover,
input.button:hover,
input.button_large:hover,
input.exclusive_mini:hover,
input.exclusive_small:hover,
input.exclusive:hover,
input.exclusive_large:hover,
a.button_mini:hover,
a.button_small:hover,
a.button:hover,
a.button_large:hover,
a.exclusive_mini:hover,
a.exclusive_small:hover,
a.exclusive:hover,
a.exclusive_large:hover {
    text-decoration: none
}

input.button_disabled,
input.exclusive_disabled,
span.exclusive {
    color: #999999;
    background: #cccccc;
    cursor: default
}

.btn.disabled,
.btn[disabled]:hover {
    opacity: 0.2
}

.button.button-small {
    color: #fff;
    background: #6f6f6f;
    padding: 0
}

.button.button-small span {
    display: block;
    padding: 6px 12px
}

.button.button-small span i {
    vertical-align: 0;
    margin-right: 5px
}

.button.button-small span i.right {
    margin-right: 0;
    margin-left: 5px
}

.button.button-small:hover {
    background: #575757
}

.button.button-medium {
    color: #fff;
    background: #43b754;
    border: none
}

.button.button-medium span {
    display: block
}

.button.button-medium span i.left {
    font-size: 14px;
    vertical-align: 0;
    margin: 0 10px 0 0;
    display: inline-block
}

@media (max-width:480px) {
    .button.button-medium span i.left {
        margin-right: 5px
    }
}

.button.button-medium span i.right {
    margin-right: 0;
    margin-left: 9px
}

@media (max-width:480px) {
    .button.button-medium span i.right {
        margin-left: 5px
    }
}

.button.button-medium:hover {
    background: #3aa04c
}

.link-button {
    font-weight: bold
}

.link-button i {
    margin-right: 5px
}

.link-button i.large {
    font-size: 26px;
    line-height: 26px;
    vertical-align: -3px;
    color: silver
}

.btn.button-plus,
.btn.button-minus {
    font-size: 14px;
    line-height: 14px;
    padding: 0;
    color: #777;
    border-radius: 0
}

.btn.button-plus span,
.btn.button-minus span {
    display: block;
    border: 1px solid #fff;
    vertical-align: middle;
    width: 25px;
    height: 25px;
    text-align: center;
    vertical-align: middle;
    padding: 4px 0 0 0;
    background: white
}

.btn.button-plus:hover,
.btn.button-minus:hover {
    color: #777
}

.btn.button-plus:hover span,
.btn.button-minus:hover span {
    -webkit-filter: none;
            filter: none;
    background: #f6f6f6
}

.button.exclusive-medium {
    font-size: 17px;
    padding: 0;
    line-height: 21px;
    color: #777;
    font-weight: bold;
    border: 1px solid;
    border-color: #cacaca #b7b7b7 #9a9a9a #b7b7b7
}

.button.exclusive-medium span {
    border: 1px solid;
    border-color: #fff;
    display: block;
    padding: 9px 10px 11px 10px;
    background: #f7f7f7;
    background: linear-gradient(to bottom, #f7f7f7 0, #ededed 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f7f7', endColorstr='#ededed', GradientType=0)
}

@media (max-width:480px) {
    .button.exclusive-medium span {
        font-size: 15px;
        padding-right: 7px;
        padding-left: 7px
    }
}

.button.exclusive-medium span:hover {
    border-color: #9e9e9e #c2c2c2 #c8c8c8 #c2c2c2
}

.button.exclusive-medium span:hover span {
    background: #e7e7e7;
    background: linear-gradient(to bottom, #e7e7e7 0, #e7e7e7 0);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e7e7e7', endColorstr='#e7e7e7', GradientType=0)
}

.button.ajax_add_to_cart_button {
    color: #fff;
    padding: 0
}

.button.ajax_add_to_cart_button span {
    padding: 6px 14px;
    display: block
}

.button.ajax_add_to_cart_button:hover {
    background: #0084bf
}

.button.ajax_add_to_cart_button:hover span {
    -webkit-filter: none;
            filter: none
}

.button.lnk_view {
    color: #fff;
    padding: 0
}

.button.lnk_view span {
    padding: 6px 14px;
    display: block
}

.button.lnk_view:hover {
    background: #0084bf
}

.button.lnk_view:hover span {
    -webkit-filter: none;
            filter: none
}

.button.lnk_view i.left {
    padding-right: 8px;
    color: #777777
}


table.std,
table.table_block {
    margin-bottom: 20px;
    width: 100%;
    border: 1px solid #999999;
    border-bottom: none;
    background: white;
    border-collapse: inherit
}

table.std th,
table.table_block th {
    padding: 14px 12px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    background: #999999
}

table.std tr.alternate_item,
table.table_block tr.alternate_item {
    background-color: #f3f3f3
}

table.std td,
table.table_block td {
    padding: 12px;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    font-size: 12px;
    vertical-align: top
}

.table {
    margin-bottom: 30px
}

.table>thead>tr>th {
    background: #f8f8f8;
    border-bottom-width: 1px;
    vertical-align: middle
}

.table td a.color-myaccount {
    color: #777;
    text-decoration: underline
}

.table td a.color-myaccount:hover {
    text-decoration: none
}

.table tfoot tr {
    background: #f8f8f8
}

.product-name {
    line-height: 23px;
    margin-bottom: 0;
    font-weight: normal
}

.product-name a {
    line-height: 23px
}

@media (max-width:768px) {
    .product-name a {
        font-size: 14px
    }
}

.price {
    white-space: nowrap
}

.price.product-price {
    color: #f13340
}

.old-price {
    text-decoration: line-through
}

.old-price.product-price {
    font-size: 11px;
    font-size: 12px
}

.price-percent-reduction {
    background: #f13340;
    color: #fff;
    padding: 3px 4px;
    display: inline
}

.price-percent-reduction.small {
    padding: 1px 4px;
    font-size: 11px;
    margin-left: 4px
}

.new-label,
.sale-label,
.online-label {
    font-size: 9px;
    line-height: 12px;
    height: 12px;
    padding: 4px 5px 2px;
    text-transform: uppercase;
    text-align: center;
    display: block;
    position: absolute;
    z-index: 1
}

.new-label {
    color: #fff;
    background: #6ad4ff;
    left: 0;
    top: 10px
}

.sale-label {
    color: #fff;
    background: #f13340;
    right: 0;
    top: 10px
}

.online-label {
    color: #777777;
    background: #fff;
    left: 0;
    top: 10px
}

.online-label2 {
    left: 0;
    top: 33px
}

.box {
    background: #f8f8f8;
    border: 1px solid #dddddd;
    padding: 14px 18px 13px;
    margin: 0 0 30px 0;
    line-height: 23px
}

.box p {
    margin-bottom: 0
}

.box.box-small {
    padding: 9px 10px 9px 20px
}

.box.box-small .dark {
    padding-top: 10px;
    padding-right: 20px;
    margin-bottom: 0;
    width: auto
}

.page-product-box {
    padding-bottom: 10px
}

.product-box {
    width: 178px;
    float: left;
    box-sizing: border-box
}

.product-box .product-image {
    border: 1px solid #dddddd;
    background: white;
    padding: 5px;
    display: block;
    margin-bottom: 11px
}

.product-box .product-image img {
    max-width: 100%;
    width: 100%;
    height: auto
}

#home-page-tabs {
    border: none;
    background: #fff
}

@media (min-width:768px) {
    #home-page-tabs {
        padding: 10px 0;
        margin: 0 0 0 -18px
    }
}

#home-page-tabs>li {
    margin-bottom: 0
}

@media (min-width:768px) {
    #home-page-tabs>li {
        border-left: 1px solid #dddddd;
        margin: 0 0 0 9px;
        padding: 0 0 0 9px
    }
}

@media (max-width:479px) {
    #home-page-tabs>li {
        width: 100%
    }
}

#home-page-tabs>li a {
    font: 21px/24px "Open Sans", sans-serif;
    color: #555454;
    text-transform: uppercase;
    border: none;
    outline: none;
    margin: 0;
    padding: 10px
}

@media (min-width:768px) {
    #home-page-tabs>li a {
        padding: 1px 10px
    }
}

#home-page-tabs>li:first-child {
    border: none
}

#home-page-tabs>li.active a,
#home-page-tabs>li a:hover {
    background: #333;
    color: #fff;
    padding: 10px
}

@media (min-width:768px) {

    #home-page-tabs>li.active a,
    #home-page-tabs>li a:hover {
        margin: -9px 0
    }
}

.block {
    margin-bottom: 20px
}

@media (max-width:767px) {
    .block .block_content {
        margin-bottom: 20px
    }
}

.block .title_block,
.block h4 {
    font-size: 12px;
    line-height: 14px;
    border-bottom: 1px solid #dddddd;
    text-transform: uppercase;
    padding: 0 5px 10px 0;
    margin-bottom: 10px;
    margin-top: 0
}

@media (max-width:767px) {

    .block .title_block,
    .block h4 {
        position: relative
    }

    .block .title_block:after,
    .block h4:after {
        display: block;
        font-family: "FontAwesome";
        content: "\f107";
        position: absolute;
        right: 5px;
        top: 8px;
        font-size: 22px;
        font-weight: normal
    }

    .block .title_block.active:after,
    .block h4.active:after {
        content: "\f106"
    }
}

.block .title_block a,
.block h4 a {
    color: #555454
}

.block .title_block a:hover,
.block h4 a:hover {
    color: #777
}

.block .list-block li {
    padding: 5px 0 6px 1px;
    border-top: 1px dotted #dddddd;
    position: relative;
    transition: all 0.2s ease
}

.block .list-block li:hover>a:before {
    left: 3px
}

.block .list-block li a {
    padding-left: 10px;
    display: block
}

.block .list-block li a i {
    display: none
}

.block .list-block li a:before {
    content: "\f105";
    display: inline;
    position: absolute;
    transition: left 0.2s ease;
    left: 0;
    font-family: "FontAwesome";
    color: #777
}

.block .list-block li a:hover {
    color: #777
}

.block .list-block li:first-child {
    border-top: none;
    padding-top: 0
}

.block .list-block .form-group {
    padding-top: 20px;
    border-top: 1px solid #dddddd;
    margin-bottom: 0
}

.block .list-block .form-group select {
    max-width: 270px
}

@media (max-width:767px) {
    .block .list-block .form-group select {
        width: 270px
    }
}

.block .list-block .btn {
    margin-top: 12px
}

.block .products-block li {
    padding: 0 0 20px 0;
    margin-bottom: 20px;
    border-bottom: 1px dashed #dddddd
}

.block .products-block li .products-block-image {
    padding-left: 0
}

.block .products-block li .product-content {
    overflow: hidden;
    padding-right: 0
}

.block .products-block li .product-content h5 {
    margin: -3px 0 0 0
}

.block .products-block .product-name {
    line-height: 18px
}

.block .products-block .product-description {
    margin-bottom: 14px
}

.block .products-block .price-percent-reduction {
    padding: 1px 6px
}

.page-heading,
.advanced-checkout-wrapper h2 {
    font-size: 18px;
    text-transform: uppercase;
    padding: 0 0 8px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #dddddd;
    overflow: hidden;
    margin-top: 0
}

.page-heading span.heading-counter,
.advanced-checkout-wrapper h2 span.heading-counter {
    font-size: 13px;
    line-height: 22px;
    float: right;
    text-transform: none;
    font-weight: normal
}

@media (max-width:767px) {

    .page-heading span.heading-counter,
    .advanced-checkout-wrapper h2 span.heading-counter {
        float: none;
        display: block;
        padding-top: 5px;
        margin-bottom: 10px
    }
}

.page-heading.bottom-indent,
.advanced-checkout-wrapper h2.bottom-indent {
    margin-bottom: 16px
}

.page-heading .page-heading,
.advanced-checkout-wrapper h2 .page-heading {
    border: none;
    padding: 0;
    margin: 0;
    float: left
}

@media (max-width:767px) {

    .page-heading .page-heading,
    .advanced-checkout-wrapper h2 .page-heading {
        float: none
    }
}

.page-subheading {
    text-transform: uppercase;
    font-size: 16px;
    padding: 0 0 10px;
    line-height: normal;
    margin-bottom: 12px;
    border-bottom: 1px solid #dddddd;
    margin-top: 0
}

h3.page-product-heading {
    font-size: 12px;
    line-height: 14px;
    color: #555454;
    border-bottom: 1px solid #dddddd;
    text-transform: uppercase;
    padding: 6px 5px 10px 0;
    margin-bottom: 10px;
    margin-top: 0
}

ul.footer_links {
    padding: 20px 0 0 0;
    border-top: 1px solid #dddddd;
    height: 65px
}

ul.footer_links li {
    float: left
}

ul.footer_links li+li {
    margin-left: 10px
}

.content_sortPagiBar {
    margin-top: -9px
}

.content_sortPagiBar .sortPagiBar {
    border-bottom: 1px solid #dddddd;
    clear: both;
    margin-bottom: 20px
}

.content_sortPagiBar .sortPagiBar #productsSortForm {
    float: right;
    margin-left: 20px;
    margin-bottom: 10px
}

.content_sortPagiBar .sortPagiBar #productsSortForm select {
    max-width: 192px;
    float: left
}

@media (max-width:999px) {
    .content_sortPagiBar .sortPagiBar #productsSortForm select {
        max-width: 160px
    }
}

.content_sortPagiBar .sortPagiBar .selector {
    display: inline-block
}

.content_sortPagiBar .sortPagiBar .nbrItemPage {
    float: right;
    margin-bottom: 10px
}

.content_sortPagiBar .sortPagiBar .nbrItemPage select {
    max-width: 120px
}

.content_sortPagiBar .sortPagiBar .nbrItemPage .clearfix>span {
    padding: 3px 0 0 12px;
    display: inline-block;
    float: left
}

.content_sortPagiBar .sortPagiBar label,
.content_sortPagiBar .sortPagiBar select {
    display: inline-block
}

.content_sortPagiBar .sortPagiBar label {
    padding: 3px 6px 0 0
}

.content_sortPagiBar .sortPagiBar.instant_search #productsSortForm {
    display: none
}

.content_sortPagiBar .view-and-count {
    float: left
}

.content_sortPagiBar .product-count {
    display: inline-block;
    vertical-align: middle
}

@media (max-width:999px) {
    .content_sortPagiBar .product-count {
        display: none !important
    }
}

.content_sortPagiBar .display,
.content_sortPagiBar .display_m {
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px
}

.content_sortPagiBar .display li,
.content_sortPagiBar .display_m li {
    float: left;
    padding-left: 5px;
    text-align: center
}

.content_sortPagiBar .display li a,
.content_sortPagiBar .display_m li a {
    font-size: 11px;
    line-height: 14px;
    cursor: pointer;
    text-decoration: none
}

.content_sortPagiBar .display li a i,
.content_sortPagiBar .display_m li a i {
    display: block;
    font-size: 18px;
    line-height: 32px;
    opacity: 0.5
}

.content_sortPagiBar .display li a:hover i,
.content_sortPagiBar .display_m li a:hover i {
    opacity: 1
}

.content_sortPagiBar .display li.selected a,
.content_sortPagiBar .display_m li.selected a {
    cursor: default
}

.content_sortPagiBar .display li.selected i,
.content_sortPagiBar .display_m li.selected i {
    opacity: 1
}

.content_sortPagiBar .display li.display-title,
.content_sortPagiBar .display_m li.display-title {
    padding: 5px 4px 0 0
}

.top-pagination-content {
    border-top: 1px dotted #dddddd;
    clear: both;
    margin: 0;
    top: 1px;
    padding: 0
}

.top-pagination-content .showall {
    top: 13px
}

.top-pagination-content ul.pagination {
    margin: 12px 0 8px 0
}

.bottom-pagination-content {
    margin-top: 29px;
    padding: 12px 0 12px 0
}

.bottom-pagination-content .showall {
    top: 20px
}

.bottom-pagination-content ul.pagination {
    margin: 8px 0 8px 0
}

.top-pagination-content,
.bottom-pagination-content {
    text-align: center;
    position: relative
}

.top-pagination-content div.pagination,
.bottom-pagination-content div.pagination {
    margin: 0;
    display: block;
    text-align: center
}

.top-pagination-content div.pagination .showall,
.bottom-pagination-content div.pagination .showall {
    position: absolute;
    right: 0
}

@media (min-width:1000px) and (max-width:1319px) {

    .top-pagination-content div.pagination .showall,
    .bottom-pagination-content div.pagination .showall {
        margin-right: 11px
    }
}

@media (max-width:999px) {

    .top-pagination-content div.pagination .showall,
    .bottom-pagination-content div.pagination .showall {
        margin-right: 0
    }
}

.top-pagination-content div.pagination .showall .btn,
.bottom-pagination-content div.pagination .showall .btn {
    border: none;
    font-weight: normal;
    text-transform: none;
    padding: 0;
    background: none !important
}

.top-pagination-content div.pagination .showall .btn i,
.bottom-pagination-content div.pagination .showall .btn i {
    margin-right: 4px
}

.top-pagination-content div.pagination .showall .btn span,
.bottom-pagination-content div.pagination .showall .btn span {
    font-size: 13px;
    line-height: normal;
    color: #777
}

@media (max-width:999px) {

    .top-pagination-content ul.pagination,
    .bottom-pagination-content ul.pagination {
        float: left
    }
}

.top-pagination-content ul.pagination li,
.bottom-pagination-content ul.pagination li {
    display: inline-block;
    float: left
}

.top-pagination-content ul.pagination li>a,
.top-pagination-content ul.pagination li>span,
.bottom-pagination-content ul.pagination li>a,
.bottom-pagination-content ul.pagination li>span {
    padding: 2px 0;
    border: 1px solid;
    border-color: #dddddd;
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 1px 0 0;
    transition: all 0.2s ease
}

.top-pagination-content ul.pagination li>a span,
.top-pagination-content ul.pagination li>span span,
.bottom-pagination-content ul.pagination li>a span,
.bottom-pagination-content ul.pagination li>span span {
    display: block
}

.top-pagination-content ul.pagination li>a:hover span,
.bottom-pagination-content ul.pagination li>a:hover span {
    background: #f6f6f6
}

.top-pagination-content ul.pagination li.disabled,
.bottom-pagination-content ul.pagination li.disabled {
    display: none
}

.top-pagination-content ul.pagination li.active>span,
.bottom-pagination-content ul.pagination li.active>span {
    border-color: #dddddd;
    background: #f6f6f6;
    color: #777
}

.top-pagination-content .compare-form,
.bottom-pagination-content .compare-form {
    float: right
}

@media (max-width:479px) {

    .top-pagination-content .compare-form,
    .bottom-pagination-content .compare-form {
        float: left;
        width: 100%;
        text-align: left;
        padding-bottom: 10px;
        clear: both
    }
}

.top-pagination-content .product-count,
.bottom-pagination-content .product-count {
    padding: 11px 0 0 0;
    float: left
}

@media (max-width:999px) {

    .top-pagination-content .product-count,
    .bottom-pagination-content .product-count {
        clear: left
    }
}

.bottom-pagination-content {
    border-top: 1px solid #dddddd
}

ul.step {
    margin-bottom: 20px;
    overflow: hidden
}

@media (min-width:768px) {
    ul.step {
        border-radius: 4px
    }
}

ul.step li {
    float: left;
    width: 20%;
    text-align: left;
    border: 1px solid #dddddd;
    border-left-width: 0
}

@media (max-width:767px) {
    ul.step li {
        width: 100%;
        border-left-width: 1px
    }
}

ul.step li a,
ul.step li span,
ul.step li.step_current span,
ul.step li.step_current_end span {
    display: block;
    padding: 13px 10px 14px 13px;
    font-size: 17px;
    line-height: 21px;
    position: relative
}

@media (max-width:1000px) {

    ul.step li a,
    ul.step li span,
    ul.step li.step_current span,
    ul.step li.step_current_end span {
        font-size: 15px
    }
}

@media (min-width:1000px) {

    ul.step li a:after,
    ul.step li span:after,
    ul.step li.step_current span:after,
    ul.step li.step_current_end span:after {
        content: ".";
        position: absolute;
        top: 0;
        right: -31px;
        z-index: 0;
        text-indent: -5000px;
        display: block;
        width: 31px;
        height: 52px;
        margin-top: -2px
    }
}

ul.step li a:focus,
ul.step li span:focus,
ul.step li.step_current span:focus,
ul.step li.step_current_end span:focus {
    text-decoration: none;
    outline: none
}

ul.step li.first {
    border-left-width: 1px;
    border-left-color: #b7b7b7
}

@media (min-width:480px) {
    ul.step li.first {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px
    }
}



@media (min-width:480px) {

    ul.step li.first span,
    ul.step li.first a {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px
    }
}



@media (min-width:480px) {
    ul.step li.last {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px
    }

    ul.step li.last span {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px
    }
}

ul.step li.step_current {
    font-weight: bold;
    background: #575757
}

ul.step li.step_current span {
    color: #fff;
    position: relative;
    font-weight: bold
}

ul.step li.step_current_end {
    font-weight: bold
}

ul.step li.step_todo span {
    display: block;
    position: relative
}

ul.step li.step_done {
    background: #f8f8f8
}

ul.step li em {
    font-style: normal
}

@media (min-width:480px) and (max-width:767px) {
    ul.step li em {
        display: none
    }
}

.rating {
    clear: both;
    display: block;
    margin: 2em;
    cursor: pointer
}

.rating:after {
    content: ".";
    display: block;
    height: 0;
    width: 0;
    clear: both;
    visibility: hidden
}

.cancel,
.star {
    overflow: hidden;
    float: left;
    margin: 0 1px 0 0;
    width: 16px;
    height: 16px;
    cursor: pointer
}

.cancel,
.cancel a {
    background: url(https://warehouse.iqit-commerce.com/themes/warehouse/css/../../../modules/productcomments/img/delete.gif) no-repeat 0 -16px !important
}

.cancel a,
.star a {
    display: block;
    width: 100%;
    height: 100%;
    background-position: 0 0
}

div.star_on a {
    background-position: 0 -16px
}

div.star_hover a,
div.star a:hover {
    background-position: 0 -32px
}

.pack_content {
    margin: 10px 0 10px 0
}

.confirmation {
    margin: 0 0 10px;
    padding: 10px;
    border: 1px solid #e6db55;
    font-size: 13px;
    background: none repeat scroll 0 0 lightyellow
}

#page .rte {
    background: transparent none repeat scroll 0 0
}

#page .rte>h2,
#page .rte>h1,
#page .rte>h3 {
    margin-top: 0
}

.listcomment {
    list-style-type: none;
    margin: 0 0 20px 0 !important
}

.listcomment li {
    padding: 10px 0;
    border-bottom: 1px dotted #dddddd;
    color: #666666
}

.listcomment .titlecomment {
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: #777
}

.listcomment .txtcomment {
    display: block;
    padding: 5px 0;
    color: #777
}

.breadcrumb {
    display: block;
    padding: 0;
    padding-bottom: 2px;
    line-height: 24px;
    background: none;
    min-height: 6px;
    overflow: hidden;
    margin-bottom: 0;
    position: relative;
    top: -11px;
    z-index: 1
}

.breadcrumb.fullwidth-breadcrumb {
    display: block;
    padding: 6px 0;
    color: #8f8f8f;
    background: #f2f2f2;
    background: linear-gradient(to bottom, #f2f2f2 1%, #f7f7f7 50%, #f9f9f9 99%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f2f2f2', endColorstr='#f9f9f9', GradientType=0);
    position: static;
    margin: 0
}

.breadcrumb .container {
    display: block
}

.breadcrumb .navigation-pipe {
    width: 20px;
    display: inline-block;
    text-indent: -5000px
}

.breadcrumb div {
    display: inline
}

.breadcrumb a {
    display: inline-block;
    position: relative;
    z-index: 2;
    color: #777
}

.breadcrumb a.home {
    font-size: 14px;
    color: #777777;
    width: 12px;
    padding: 0;
    z-index: 99;
    line-height: 22px;
    display: inline-block;
    height: 25px
}

.breadcrumb a.home i {
    vertical-align: -1px
}

.breadcrumb a.home:after {
    right: -15px
}

.breadcrumb a:after {
    display: inline-block;
    content: ".";
    position: absolute;
    right: -11px;
    top: 8px;
    width: 8px;
    height: 8px;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    text-indent: -5000px;
    z-index: -1;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.breadcrumb a:hover {
    color: #777777
}

.ie8 .breadcrumb {
    min-height: 1px
}

.ie8 .breadcrumb a:after {
    display: none
}

.ie8 .breadcrumb .navigation-pipe {
    width: 20px
}

.footer-container .footer-container-inner {
    background-color: #f8f8f8;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd
}

.footer-container .footer-container-inner1 {
    background-color: #f8f8f8;
    border-top: 1px solid #dddddd
}

.footer-container .footer-container-inner-2 {
    padding: 0 20px
}

.footer-container .footer-container-inner1-2 {
    padding: 0 20px
}

.footer-container .container {
    padding-bottom: 0
}

.footer-container #footer .row,
.footer-container #footer1 .row {
    position: relative
}

.footer-container #footer .footer-block,
.footer-container #footer1 .footer-block {
    margin-top: 20px
}

.footer-container #footer a,
.footer-container #footer1 a {
    color: #777
}

.footer-container #footer a:hover,
.footer-container #footer1 a:hover {
    color: #777
}

.footer-container #footer h4,
.footer-container #footer1 h4 {
    font-size: 12px;
    line-height: 14px;
    color: #555454;
    border-bottom: 1px solid #dddddd;
    text-transform: uppercase;
    padding: 6px 5px 10px 0;
    margin-bottom: 10px;
    margin-top: 0
}

@media (max-width:767px) {

    .footer-container #footer h4,
    .footer-container #footer1 h4 {
        position: relative
    }



    .footer-container #footer h4.active:after,
    .footer-container #footer1 h4.active:after {
        content: "\f106"
    }
}

.footer-container #footer h4 a,
.footer-container #footer1 h4 a {
    color: #555454
}

.footer-container #footer h4 a:hover,
.footer-container #footer1 h4 a:hover {
    color: #777
}

.footer-container #footer .bullet li,
.footer-container #footer1 .bullet li {
    padding: 5px 0 6px 1px;
    position: relative;
    transition: all 0.2s ease
}

.footer-container #footer .bullet li:hover a:before,
.footer-container #footer1 .bullet li:hover a:before {
    left: 3px
}

.footer-container #footer .bullet li a,
.footer-container #footer1 .bullet li a {
    display: block;
    padding-left: 10px
}

.footer-container #footer .bullet li a i,
.footer-container #footer1 .bullet li a i {
    display: none
}

.footer-container #footer .bullet li a:before,
.footer-container #footer1 .bullet li a:before {
    content: "\f105";
    display: inline;
    position: absolute;
    transition: left 0.2s ease;
    left: 0;
    font-family: "FontAwesome";
    color: #777
}

.footer-container #footer .bullet li a:hover,
.footer-container #footer1 .bullet li a:hover {
    color: #777
}

.footer-container #footer .bullet li:first-child,
.footer-container #footer1 .bullet li:first-child {
    border-top: none
}

.footer-container #footer ul li,
.footer-container #footer1 ul li {
    padding-bottom: 8px
}

.footer-container #footer #block_contact_infos>div,
.footer-container #footer1 #block_contact_infos>div {
    padding: 0 0 0 5px
}

@media (max-width:767px) {

    .footer-container #footer #block_contact_infos>div,
    .footer-container #footer1 #block_contact_infos>div {
        padding-left: 0
    }
}

.footer-container #footer #block_contact_infos>div ul li,
.footer-container #footer1 #block_contact_infos>div ul li {
    padding: 0 0 7px 4px;
    overflow: hidden;
    line-height: 22px
}

.footer-container #footer #block_contact_infos>div ul li>span,
.footer-container #footer #block_contact_infos>div ul li>span a,
.footer-container #footer1 #block_contact_infos>div ul li>span,
.footer-container #footer1 #block_contact_infos>div ul li>span a {
    font-weight: normal
}

.footer-container #footer #block_contact_infos>div ul li i,
.footer-container #footer1 #block_contact_infos>div ul li i {
    font-size: 18px;
    width: 26px;
    text-align: center;
    padding-right: 12px;
    float: left;
    color: #908f8f;
    position: relative;
    top: 2px
}

.footer-container #footer .blockcategories_footer,
.footer-container #footer1 .blockcategories_footer {
    clear: left
}

.footer-container #footer #social_block,
.footer-container #footer1 #social_block {
    float: left;
    width: 50%;
    padding: 22px 15px 0 15px
}

@media (max-width:767px) {

    .footer-container #footer #social_block,
    .footer-container #footer1 #social_block {
        width: 100%;
        float: left;
        padding-top: 5px
    }
}

.footer-container #footer #social_block ul,
.footer-container #footer1 #social_block ul {
    float: right
}

@media (max-width:767px) {

    .footer-container #footer #social_block ul,
    .footer-container #footer1 #social_block ul {
        float: none
    }
}

.footer-container #footer #social_block ul li,
.footer-container #footer1 #social_block ul li {
    float: left;
    width: 40px;
    text-align: center
}

@media (min-width:768px) and (max-width:999px) {

    .footer-container #footer #social_block ul li,
    .footer-container #footer1 #social_block ul li {
        width: 30px
    }
}

.footer-container #footer #social_block ul li a,
.footer-container #footer1 #social_block ul li a {
    display: inline-block;
    color: #908f8f;
    font-size: 28px
}

@media (min-width:768px) and (max-width:999px) {

    .footer-container #footer #social_block ul li a,
    .footer-container #footer1 #social_block ul li a {
        font-size: 20px
    }
}

.footer-container #footer #social_block ul li a span,
.footer-container #footer1 #social_block ul li a span {
    display: none
}

.footer-container #footer #social_block ul li a:before,
.footer-container #footer1 #social_block ul li a:before {
    display: inline-block;
    font-family: "FontAwesome";
    vertical-align: -5px
}

.footer-container #footer #social_block ul li a:hover,
.footer-container #footer1 #social_block ul li a:hover {
    color: #fff
}

.footer-container #footer #social_block ul li.facebook a:before,
.footer-container #footer1 #social_block ul li.facebook a:before {
    content: "\f09a"
}

.footer-container #footer #social_block ul li.twitter a:before,
.footer-container #footer1 #social_block ul li.twitter a:before {
    content: "\f099"
}

.footer-container #footer #social_block ul li.rss a:before,
.footer-container #footer1 #social_block ul li.rss a:before {
    content: "\f09e"
}

.footer-container #footer #social_block ul li.youtube a:before,
.footer-container #footer1 #social_block ul li.youtube a:before {
    content: "\f167"
}

.footer-container #footer #social_block ul li.google-plus a:before,
.footer-container #footer1 #social_block ul li.google-plus a:before {
    content: "\f0d5"
}

.footer-container #footer #social_block ul li.pinterest a:before,
.footer-container #footer1 #social_block ul li.pinterest a:before {
    content: "\f0d2"
}

.footer-container #footer #social_block h4,
.footer-container #footer1 #social_block h4 {
    float: right;
    margin-bottom: 0;
    font-size: 21px;
    line-height: 25px;
    text-transform: none;
    padding: 0 10px 0 0
}

@media (max-width:767px) {

    .footer-container #footer #social_block h4,
    .footer-container #footer1 #social_block h4 {
        display: none;
        text-align: center
    }
}

.footer-container #footer .bottom-footer,
.footer-container #footer1 .bottom-footer {
    position: absolute;
    bottom: -55px;
    left: 0
}

.footer-container #footer .bottom-footer div,
.footer-container #footer1 .bottom-footer div {
    padding: 15px 0 0 0;
    border-top: 1px solid #515151;
    width: 100%
}

.footer-container .footer_copyrights {
    line-height: 31px;
    padding: 15px 0
}

.footer-container .footer_copyrights .col-sm-6 p:last-child {
    margin: 0
}

.footer-container .footer_copyrights .container {
    padding-bottom: 0
}

@media (max-width:768px) {
    .footer-container .footer_copyrights {
        text-align: center
    }
}

.footer-container .footer_copyrights .paymants_logos img {
    float: right
}

@media (max-width:768px) {
    .footer-container .footer_copyrights .paymants_logos img {
        float: none;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto
    }
}

#toTop {
    cursor: pointer;
    width: 56px;
    height: 30px;
    text-align: center;
    line-height: 32px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 42px;
    background: #fff;
    zoom: 1;
    opacity: 0.0;
    visibility: hidden;
    transition: visibility 200ms ease-in-out, opacity 200ms ease-in-out
}

#toTop.totop-showed {
    opacity: 0.6;
    visibility: visible
}

#toTop:before {
    content: "\e603";
    font-family: "warehousefont"
}

#toTop:hover {
    opacity: 1.0;
    color: #fff;
    background: #333
}

#reinsurance_block {
    clear: both !important
}

#short_description_content ul,
#short_description_content ol,
#short_description_content dl,
#tinymce ul,
#tinymce ol,
#tinymce dl {
    margin-left: 20px
}

.rte ul {
    list-style-type: disc;
    padding: 5px;
    padding-left: 30px
}

.rte ol {
    list-style-type: decimal;
    padding: 5px;
    padding-left: 30px
}

.rte em {
    font-style: italic
}

.rte a:link {
    text-decoration: underline
}

.rte p {
    margin: 0;
    padding: 0 0 9px
}

.block_hidden_only_for_screen {
    display: none
}

.cart_navigation {
    border-top: 1px solid #dddddd;
    padding-top: 20px;
    margin: 0 0 20px
}

.cart_navigation .button-medium {
    float: right;
    font-size: 16px
}

.cart_navigation .button-medium span {
    padding: 6px 7px 5px 7px
}

@media (max-width:1000px) {
    .cart_navigation .button-medium span {
        font-size: 14px
    }
}

.cart_navigation .button-medium i.right {
    font-size: 18px;
    line-height: 18px;
    margin-left: 6px
}

.cart_navigation .button-exclusive {
    border: none;
    background: none;
    padding: 0;
    margin: 9px 0 0 0
}

.cart_navigation .button-exclusive i {
    color: #777777;
    margin-right: 8px
}

.cart_navigation .button-exclusive:hover,
.cart_navigation .button-exclusive:focus,
.cart_navigation .button-exclusive:active {
    color: #515151;
    box-shadow: none
}

@media (max-width:767px) {
    .cart_navigation {
        text-align: center
    }

    .cart_navigation .button-medium {
        float: none;
        margin-bottom: 10px
    }

    .cart_navigation>span {
        display: block;
        width: 100%;
        padding-bottom: 15px
    }

    .cart_navigation .button-exclusive {
        display: block
    }
}

.cart_last_product {
    display: none
}

.cart_quantity .cart_quantity_input {
    height: 27px;
    line-height: 27px;
    padding: 0;
    text-align: center;
    width: 57px
}

.cart_gift_quantity .cart_quantity_input {
    height: 27px;
    line-height: 27px;
    padding: 0;
    text-align: center;
    width: 57px
}

.table tbody>tr>td {
    vertical-align: middle
}

.table tbody>tr>td.cart_quantity {
    padding: 41px 14px 25px;
    width: 88px
}

.table tbody>tr>td.cart_quantity .cart_quantity_button {
    margin-top: 3px
}

.table tbody>tr>td.cart_quantity .cart_quantity_button a {
    float: left;
    margin-right: 3px
}

.table tbody>tr>td.cart_quantity .cart_quantity_button a+a {
    margin-right: 0
}

.table tbody>tr>td.cart_delete,
.table tbody>tr>td.price_discount_del {
    padding: 5px
}

.table tfoot>tr>td {
    vertical-align: middle
}

.cart_delete a.cart_quantity_delete,
a.price_discount_delete {
    font-size: 18px
}

.cart_delete a.cart_quantity_delete .icon-trash:before,
a.price_discount_delete .icon-trash:before {
    content: "\F00D"
}

.cart_delete a.cart_quantity_delete:hover,
a.price_discount_delete:hover {
    color: silver
}

#cart_summary tbody td.cart_product {
    padding: 19px;
    width: 137px
}

#cart_summary tbody td.cart_unit .price span {
    display: block
}

#cart_summary tbody td.cart_unit .price span.price-percent-reduction {
    margin: 5px auto;
    display: inline-block
}

#cart_summary tbody td.cart_unit .price span.old-price {
    text-decoration: line-through
}

#cart_summary tbody td.cart_description {
    width: 480px
}

#cart_summary tbody td.cart_description small {
    display: block;
    padding: 5px 0 0 0
}

#cart_summary tfoot td.total_price_container span {
    font-weight: bold;
    font-size: 14px
}

#cart_summary tfoot td#total_price_container {
    font-size: 14px;
    font-weight: bold
}

@media (max-width:767px) {
    #order-detail-content {
        overflow-x: auto
    }

    #order-detail-content #cart_summary table,
    #order-detail-content #cart_summary thead,
    #order-detail-content #cart_summary tbody,
    #order-detail-content #cart_summary th,
    #order-detail-content #cart_summary td,
    #order-detail-content #cart_summary tr {
        display: block
    }

    #order-detail-content #cart_summary thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px
    }

    #order-detail-content #cart_summary tr {
        border-bottom: 1px solid #cccccc;
        overflow: hidden;
        position: relative
    }

    #order-detail-content #cart_summary td {
        border: none;
        position: relative;
        border-bottom: 1px dotted #dddddd;
        width: 100%;
        float: none;
        white-space: normal;
        text-align: center
    }

    #order-detail-content #cart_summary td.cart_avail {
        clear: both
    }

    #order-detail-content #cart_summary td.cart_unit>ul {
        margin: 0
    }

    #order-detail-content #cart_summary td.cart_quantity {
        clear: both;
        padding: 10px 12px 10px 12px
    }

    #order-detail-content #cart_summary td.cart_quantity .cart_quantity_input {
        display: block;
        margin: 0 auto
    }

    #order-detail-content #cart_summary td.cart_quantity .cart_quantity_button {
        display: inline-block;
        text-align: center
    }

    #order-detail-content #cart_summary td.cart_discount_price:before {
        content: attr(data-title);
        display: block
    }

    #order-detail-content #cart_summary td.cart_discount_price.text-right {
        display: none
    }

    #order-detail-content #cart_summary td.cart_discount_delete,
    #order-detail-content #cart_summary td.cart_discount_name {
        display: none
    }

    #order-detail-content #cart_summary td.cart_total {
        font-weight: bold
    }

    #order-detail-content #cart_summary td.cart_delete,
    #order-detail-content #cart_summary td.price_discount_del {
        position: absolute;
        top: 0;
        width: 30px;
        right: 5px;
        border: none
    }

    #order-detail-content #cart_summary td div {
        display: inline
    }

    #order-detail-content #cart_summary tfoot td {
        float: none;
        width: 100%;
        border-bottom: none;
        text-align: center
    }

    #order-detail-content #cart_summary tfoot td:before {
        display: inline
    }

    #order-detail-content #cart_summary tfoot td.cart_voucher {
        text-align: center
    }

    #order-detail-content #cart_summary tfoot tr .text-right,
    #order-detail-content #cart_summary tfoot tr .price {
        display: block;
        float: left;
        width: 50%
    }
}

.cart_voucher {
    vertical-align: top !important
}

.cart_voucher .title-offers {
    font-weight: bold;
    margin-bottom: 6px
}

.cart_voucher fieldset {
    margin-bottom: 10px
}

.cart_voucher fieldset #discount_name {
    float: left;
    width: 219px;
    margin-right: 11px
}

@media (max-width:767px) {
    .cart_voucher fieldset #discount_name {
        width: 170px;
        float: none;
        display: inline-block
    }
}

.cart_voucher #display_cart_vouchers span {
    font-weight: bold;
    cursor: pointer;
    color: #777
}

.cart_voucher #display_cart_vouchers span:hover {
    color: #515151
}

.enable-multishipping {
    margin: -13px 0 17px
}

.enable-multishipping label {
    font-weight: normal
}

#HOOK_SHOPPING_CART #loyalty i {
    font-size: 26px;
    line-height: 26px;
    color: #cfcccc
}

#order_carrier {
    clear: both;
    margin-top: 20px;
    border: 1px solid #999999;
    background: #fff
}

.order_carrier_logo {
    max-width: 65px;
    max-height: 65px
}

#order_carrier .title_block {
    padding: 0 15px;
    height: 29px;
    font-weight: bold;
    line-height: 29px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    background: url(https://warehouse.iqit-commerce.com/themes/warehouse/css/../img/bg_table_th.png) repeat-x 0 -10px #999999
}

#order_carrier span {
    display: block;
    padding: 15px;
    font-weight: bold
}

.multishipping_close_container {
    text-align: center
}

table#cart_summary .gift-icon {
    color: white;
    background: #0088cc;
    line-height: 20px;
    padding: 2px 5px;
    border-radius: 5px
}

#multishipping_mode_box {
    padding-top: 12px;
    padding-bottom: 19px
}

#multishipping_mode_box .title {
    font-weight: bold;
    color: #777;
    margin-bottom: 15px
}

#multishipping_mode_box .description_off {
    display: none
}

#multishipping_mode_box .description_off div {
    margin-bottom: 10px
}

#multishipping_mode_box .description_off a:hover,
#multishipping_mode_box .description a:hover {
    background: #f3f3f3;
    border: 1px solid #cccccc
}

#multishipping_mode_box.on .description_off {
    display: block
}

.multishipping-cart .cart_address_delivery.form-control {
    width: 198px
}

#order .address_add.submit {
    margin-bottom: 20px
}

#ordermsg {
    margin-bottom: 30px
}

#id_address_invoice.form-control,
#id_address_delivery.form-control {
    width: 269px
}

.addresses .select label,
.addresses .selector,
.addresses .addresses .address_select {
    clear: both;
    float: left
}

.addresses .waitimage {
    display: none;
    float: left;
    width: 24px;
    height: 24px;
    background-image: url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../../../img/ajax-loader.gif");
    background-repeat: no-repeat;
    position: relative;
    bottom: -2px;
    left: 10px
}

.addresses .checkbox.addressesAreEquals {
    clear: both;
    float: left;
    margin: 15px 0 25px 0
}

#order .delivery_option>div,
#order-opc .delivery_option>div {
    display: block;
    margin-bottom: 20px;
    font-weight: normal
}

#order .delivery_option>div>table,
#order-opc .delivery_option>div>table {
    margin-bottom: 0
}

#order .delivery_option>div>table.resume,
#order-opc .delivery_option>div>table.resume {
    height: 53px
}

#order .delivery_option>div>table.resume.delivery_option_carrier,
#order-opc .delivery_option>div>table.resume.delivery_option_carrier {
    margin-top: 10px;
    width: 100%;
    background: #5192f3;
    color: #fff;
    border: 1px solid #4b80c3;
    font-weight: bold
}

#order .delivery_option>div>table.resume.delivery_option_carrier td,
#order-opc .delivery_option>div>table.resume.delivery_option_carrier td {
    padding: 8px 11px 7px 11px
}

#order .delivery_option>div>table.resume.delivery_option_carrier td i,
#order-opc .delivery_option>div>table.resume.delivery_option_carrier td i {
    font-size: 20px;
    margin-right: 7px;
    vertical-align: -2px
}

#order .delivery_option>div>table.resume td.delivery_option_radio,
#order-opc .delivery_option>div>table.resume td.delivery_option_radio {
    width: 54px;
    padding-left: 0;
    padding-right: 0;
    text-align: center
}

#order .delivery_option>div>table.resume td.delivery_option_logo,
#order-opc .delivery_option>div>table.resume td.delivery_option_logo {
    width: 97px;
    padding-left: 21px
}

#order .delivery_option>div>table.resume td.delivery_option_price,
#order-opc .delivery_option>div>table.resume td.delivery_option_price {
    width: 162px
}

.order_carrier_content {
    line-height: normal;
    padding-bottom: 16px
}

.order_carrier_content .carrier_title {
    font-weight: bold
}

.order_carrier_content .checkbox input {
    margin-top: 2px
}

.order_carrier_content .delivery_options_address .carrier_title {
    margin-bottom: 17px
}

.order_carrier_content+div {
    padding-bottom: 15px
}

.order_carrier_content h2 {
    font-size: inherit;
    font-weight: bold
}

.order_carrier_content .box {
    margin-bottom: 10px
}

#carrierTable {
    border: 1px solid #999999;
    border-bottom: none;
    background: #fff
}

#carrierTable tbody {
    border-bottom: 1px solid #999999
}

#carrierTable th {
    padding: 0 15px;
    height: 29px;
    font-weight: bold;
    line-height: 29px;
    color: #fff;
    text-transform: uppercase;
    background: url(https://warehouse.iqit-commerce.com/themes/warehouse/css/../img/bg_table_th.png) repeat-x 0 -10px #999999
}

#carrierTable td {
    padding: 15px;
    font-weight: bold;
    border-right: 1px solid #e9e9e9
}

#carrierTable td.carrier_price {
    border: none
}

p.checkbox.gift {
    margin-bottom: 10px
}

#gift_div {
    display: none;
    margin-top: 5px;
    margin-bottom: 10px
}

a.iframe {
    color: #777;
    text-decoration: underline
}

a.iframe:hover {
    text-decoration: none
}

#HOOK_PAYMENT .row>div {
    width: 100%;
    float: none
}

p.payment_module {
    margin-bottom: 10px
}

p.payment_module a {
    display: block;
    border: 1px solid #d6d4d4;
    border-radius: 4px;
    font-size: 17px;
    line-height: 23px;
    color: #777;
    font-weight: bold;
    padding: 33px 40px 34px 99px;
    letter-spacing: -1px;
    position: relative
}

p.payment_module a.bankwire {
    background: url(https://warehouse.iqit-commerce.com/themes/warehouse/css/../img/bankwire.png) 15px 12px no-repeat #f8f8f8
}

p.payment_module a.cheque {
    background: url(https://warehouse.iqit-commerce.com/themes/warehouse/css/../img/cheque.png) 15px 15px no-repeat #f8f8f8
}

p.payment_module a.cash {
    background: url(https://warehouse.iqit-commerce.com/themes/warehouse/css/../img/cash.png) 15px 15px no-repeat #f8f8f8
}

p.payment_module a.cheque:after,
p.payment_module a.bankwire:after,
p.payment_module a.cash:after {
    display: block;
    content: "\f054";
    position: absolute;
    right: 15px;
    margin-top: -11px;
    top: 50%;
    font-family: "FontAwesome";
    font-size: 25px;
    height: 22px;
    width: 14px;
    color: #777
}

p.payment_module a:hover {
    background-color: #f6f6f6
}

p.payment_module a span {
    font-weight: bold;
    color: #777
}

.payment_module.box {
    margin-top: 20px
}

p.cheque-indent {
    margin-bottom: 9px
}

.cheque-box .form-group {
    margin-bottom: 0
}

.cheque-box .form-group .form-control {
    width: 269px
}

.order-confirmation.box {
    line-height: 29px
}

.order-confirmation.box a {
    text-decoration: underline
}

.order-confirmation.box a:hover {
    text-decoration: none
}

.delivery_option_carrier td {
    width: 200px
}

.delivery_option_carrier td+td {
    width: 280px
}

.delivery_option_carrier td+td+td {
    width: 200px
}

.delivery_option_carrier tr td {
    padding: 5px
}

.delivery_option_carrier.selected {
    display: table
}

.delivery_option_carrier.not-displayable {
    display: none
}

#address,
#identity,
#account-creation_form,
#new_account_form,
#opc_account_form,
#authentication {
    line-height: 20px
}

#address p.required,
#identity p.required,
#account-creation_form p.required,
#new_account_form p.required,
#opc_account_form p.required,
#authentication p.required {
    color: #f13340;
    margin: 9px 0 16px 0;
    margin-bottom: 0
}

#address p.inline-infos,
#identity p.inline-infos,
#account-creation_form p.inline-infos,
#new_account_form p.inline-infos,
#opc_account_form p.inline-infos,
#authentication p.inline-infos {
    color: red
}

#address .form-group,
#identity .form-group,
#account-creation_form .form-group,
#new_account_form .form-group,
#opc_account_form .form-group,
#authentication .form-group {
    margin-bottom: 4px
}

#address .form-group .form-control,
#identity .form-group .form-control,
#account-creation_form .form-group .form-control,
#new_account_form .form-group .form-control,
#opc_account_form .form-group .form-control,
#authentication .form-group .form-control {
    max-width: 271px
}

#address .form-group .form-control#adress_alias,
#identity .form-group .form-control#adress_alias,
#account-creation_form .form-group .form-control#adress_alias,
#new_account_form .form-group .form-control#adress_alias,
#opc_account_form .form-group .form-control#adress_alias,
#authentication .form-group .form-control#adress_alias {
    margin-bottom: 20px
}

@media (min-width:1320px) {

    #address .form-group.phone-number,
    #identity .form-group.phone-number,
    #account-creation_form .form-group.phone-number,
    #new_account_form .form-group.phone-number,
    #opc_account_form .form-group.phone-number,
    #authentication .form-group.phone-number {
        float: left;
        width: 270px;
        margin-right: 13px
    }
}

@media (min-width:1320px) {

    #address .form-group.phone-number+p,
    #identity .form-group.phone-number+p,
    #account-creation_form .form-group.phone-number+p,
    #new_account_form .form-group.phone-number+p,
    #opc_account_form .form-group.phone-number+p,
    #authentication .form-group.phone-number+p {
        padding: 23px 0 0 0;
        margin-bottom: 0
    }
}

#address .gender-line,
#identity .gender-line,
#account-creation_form .gender-line,
#new_account_form .gender-line,
#opc_account_form .gender-line,
#authentication .gender-line {
    margin-bottom: 4px;
    padding-top: 4px
}

#address .gender-line>label,
#identity .gender-line>label,
#account-creation_form .gender-line>label,
#new_account_form .gender-line>label,
#opc_account_form .gender-line>label,
#authentication .gender-line>label {
    margin-right: 10px;
    margin-bottom: 0
}

#address .gender-line .radio-inline label,
#identity .gender-line .radio-inline label,
#account-creation_form .gender-line .radio-inline label,
#new_account_form .gender-line .radio-inline label,
#opc_account_form .gender-line .radio-inline label,
#authentication .gender-line .radio-inline label {
    font-weight: normal;
    color: #777777
}

#address .submit2,
#identity .submit2,
#account-creation_form .submit2,
#new_account_form .submit2,
#opc_account_form .submit2,
#authentication .submit2 {
    margin-top: 10px
}

#identity #center_column form.std .row,
#authentication #center_column form.std .row,
#order-opc #center_column form.std .row {
    margin-left: -5px;
    margin-right: -5px
}

#identity #center_column form.std .row .col-xs-4,
#authentication #center_column form.std .row .col-xs-4,
#order-opc #center_column form.std .row .col-xs-4 {
    padding-left: 5px;
    padding-right: 5px;
    max-width: 94px
}

#identity #center_column form.std .row .col-xs-4 .form-control,
#authentication #center_column form.std .row .col-xs-4 .form-control,
#order-opc #center_column form.std .row .col-xs-4 .form-control {
    max-width: 84px
}

#identity #center_column .footer_links,
#authentication #center_column .footer_links,
#order-opc #center_column .footer_links {
    border: none;
    padding-top: 0
}

#new_account_form .box {
    line-height: 20px
}

#new_account_form .box .date-select {
    padding-bottom: 10px
}

#new_account_form .box .top-indent {
    padding-top: 10px
}

#new_account_form .box .customerprivacy {
    padding-top: 15px
}

#password {
    padding-top: 0;
    border: none
}

#password .box p {
    margin-bottom: 9px
}

#password .box .form-group {
    margin-bottom: 20px
}

#password .box .form-group .form-control {
    width: 263px
}

#instant_search_results a.close {
    font-size: 13px;
    opacity: 1;
    line-height: inherit;
    color: #777;
    text-transform: none
}

#instant_search_results a.close:hover {
    color: #515151
}

#manufacturers_list h3,
#suppliers_list h3 {
    font-size: 17px;
    line-height: 23px
}

#manufacturers_list .description,
#suppliers_list .description {
    line-height: 18px
}

#manufacturers_list .product-counter a:hover,
#suppliers_list .product-counter a:hover {
    color: #515151
}

#manufacturers_list.list li .mansup-container,
#suppliers_list.list li .mansup-container {
    border-top: 1px solid #dddddd;
    padding: 20px 0
}

@media (max-width:767px) {

    #manufacturers_list.list li .mansup-container,
    #suppliers_list.list li .mansup-container {
        text-align: center
    }
}

#manufacturers_list.list li:first-child .mansup-container,
#suppliers_list.list li:first-child .mansup-container {
    border-top: 0;
    padding-top: 0
}

#manufacturers_list.list li:last-child .mansup-container,
#suppliers_list.list li:last-child .mansup-container {
    padding-bottom: 0
}

#manufacturers_list.list li .left-side,
#suppliers_list.list li .left-side {
    text-align: center
}

#manufacturers_list.list li h3,
#suppliers_list.list li h3 {
    padding-bottom: 8px
}

#manufacturers_list.list li .middle-side,
#suppliers_list.list li .middle-side {
    padding-left: 0
}

@media (max-width:767px) {

    #manufacturers_list.list li .middle-side,
    #suppliers_list.list li .middle-side {
        padding-right: 15px;
        padding-left: 15px
    }
}

#manufacturers_list.list li .right-side-content,
#suppliers_list.list li .right-side-content {
    border-left: 1px solid #dddddd;
    min-height: 108px;
    padding-left: 15px
}

@media (max-width:767px) {

    #manufacturers_list.list li .right-side-content,
    #suppliers_list.list li .right-side-content {
        min-height: 1px;
        padding: 0;
        border: none
    }
}

#manufacturers_list.list li .right-side-content .product-counter,
#suppliers_list.list li .right-side-content .product-counter {
    position: relative;
    margin-bottom: 12px
}

@media (max-width:767px) {

    #manufacturers_list.list li .right-side-content .product-counter,
    #suppliers_list.list li .right-side-content .product-counter {
        top: 0
    }
}

#manufacturers_list.grid li,
#suppliers_list.grid li {
    text-align: center
}

#manufacturers_list.grid li .product-container,
#suppliers_list.grid li .product-container {
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dddddd
}

#manufacturers_list.grid li .product-container .left-side,
#suppliers_list.grid li .product-container .left-side {
    padding-bottom: 42px
}

#manufacturers_list.grid li .product-container h3,
#suppliers_list.grid li .product-container h3 {
    padding-bottom: 10px
}

#manufacturers_list.grid li .product-container .product-counter,
#suppliers_list.grid li .product-container .product-counter {
    margin-bottom: 12px
}

@media (min-width:1320px) {

    #manufacturers_list.grid li.first-in-line,
    #suppliers_list.grid li.first-in-line {
        clear: left
    }

    #manufacturers_list.grid li.last-line .product-container,
    #suppliers_list.grid li.last-line .product-container {
        border-bottom: none
    }
}

@media (min-width:768px) and (max-width:1319px) {

    #manufacturers_list.grid li.first-item-of-tablet-line,
    #suppliers_list.grid li.first-item-of-tablet-line {
        clear: left
    }

    #manufacturers_list.grid li.last-tablet-line .product-container,
    #suppliers_list.grid li.last-tablet-line .product-container {
        border-bottom: none
    }
}

@media (max-width:767px) {

    #manufacturers_list.grid .product-container,
    #suppliers_list.grid .product-container {
        border-bottom: none
    }
}

#manufacturer .description_box,
#supplier .description_box {
    border: 1px solid #dddddd;
    padding: 15px;
    margin-bottom: 20px
}

@media (max-width:999px) {

    #manufacturer .description_box,
    #supplier .description_box {
        padding: 10px
    }
}

#manufacturer .description_box .hide_desc,
#supplier .description_box .hide_desc {
    display: none
}

table.discount i {
    font-size: 20px;
    line-height: 20px;
    vertical-align: -2px
}

table.discount i.icon-ok {
    color: #46a74e
}

table.discount i.icon-remove {
    color: #f13340
}

#guestTracking .form-control {
    max-width: 271px
}

@media (min-width:1320px) {
    #guestTracking .form-group {
        overflow: hidden
    }

    #guestTracking .form-group i {
        padding-left: 10px
    }

    #guestTracking .form-control {
        float: left
    }

    #guestTracking label {
        float: left;
        clear: both;
        width: 100%
    }
}

#pagenotfound .pagenotfound {
    max-width: 824px;
    margin: 0 auto;
    text-align: center
}

#pagenotfound .pagenotfound .img-404 {
    font-size: 210px;
    line-height: 210px
}

@media (max-width:767px) {
    #pagenotfound .pagenotfound .img-404 {
        font-size: 140px;
        line-height: 140px
    }
}

#pagenotfound .pagenotfound .img-404 img {
    max-width: 100%
}

#pagenotfound .pagenotfound h1 {
    font-weight: bold;
    font-size: 18px;
    line-height: 34px;
    color: #777;
    text-transform: uppercase;
    margin-bottom: 7px
}

#pagenotfound .pagenotfound p {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: #555454;
    text-transform: uppercase;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 19px;
    margin-bottom: 20px
}

#pagenotfound .pagenotfound h3 {
    font-weight: bold;
    color: #777;
    font-size: 13px;
    line-height: normal;
    margin-bottom: 18px
}

#pagenotfound .pagenotfound label {
    font-weight: normal
}

@media (max-width:767px) {
    #pagenotfound .pagenotfound label {
        display: block
    }
}

#pagenotfound .pagenotfound .form-control {
    max-width: 293px;
    display: inline-block;
    margin-right: 5px
}

@media (max-width:767px) {
    #pagenotfound .pagenotfound .form-control {
        margin: 0 auto 15px auto;
        display: block
    }
}

#pagenotfound .pagenotfound .buttons {
    padding: 48px 0 20px 0
}

#pagenotfound .pagenotfound .buttons .button-medium i.left {
    font-size: 17px
}

#account-creation_form fieldset.reversoform {
    padding: 10px !important
}

#account-creation_form fieldset.reversoform .text label {
    display: block;
    padding: 0 0 10px 0;
    width: 100%;
    text-align: left
}

#account-creation_form fieldset.reversoform .infos-sup {
    padding: 0 !important;
    font-size: 10px;
    font-style: italic;
    text-align: right
}

#account-creation_form fieldset.customerprivacy label,
fieldset.customerprivacy label {
    cursor: pointer
}

.tab-content {
    margin-top: 20px
}

form#sendComment fieldset {
    padding: 10px;
    border: 1px solid #cccccc;
    background: #eeeeee
}

form#sendComment h3 {
    font-size: 14px
}

#new_comment_form p.text {
    margin-bottom: 0;
    padding-bottom: 0
}

#sendComment p.text label,
#sendComment p.textarea label {
    display: block;
    margin: 12px 0 4px;
    font-weight: bold;
    font-size: 12px
}

#sendComment p.text input {
    padding: 0 5px;
    height: 28px;
    width: 498px;
    border: 1px solid #cccccc;
    background: url(https://warehouse.iqit-commerce.com/themes/warehouse/css/../img/bg_input.png) repeat-x 0 0 #fff
}

#sendComment p.textarea textarea {
    height: 80px;
    width: 508px;
    border: 1px solid #cccccc
}

#sendComment p.submit {
    padding: 0;
    text-align: right
}

#sendComment p.closeform {
    float: right;
    padding: 0;
    height: 12px;
    width: 12px;
    text-indent: -5000px;
    background: url(https://warehouse.iqit-commerce.com/themes/warehouse/css/../img/icon/delete.gif) no-repeat 0 0
}

.star {
    position: relative;
    top: -1px;
    float: left;
    width: 12px;
    overflow: hidden;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal
}

.star {
    display: block
}

.star a {
    display: block;
    position: absolute;
    text-indent: -5000px
}

div.star {
    opacity: 0.35
}

div.star:after {
    content: "\f006";
    font-family: "FontAwesome";
    display: inline-block;
    color: #dddddd
}

div.star.star_on {
    display: block;
    opacity: 1
}

div.star.star_on:after {
    content: "\f005";
    font-family: "FontAwesome";
    display: inline-block;
    color: #f13340
}

div.star.star_hover {
    opacity: 1
}

div.star.star_hover:after {
    content: "\f005";
    font-family: "FontAwesome";
    display: inline-block;
    color: #f13340
}

.advertising_block {
    width: 191px;
    margin-bottom: 1em;
    text-align: center
}

.blockcategories_footer ul ul {
    display: none !important
}

.form-group.form-error input,
.form-group.form-error textarea {
    border: 1px solid #f13340;
    color: #f13340;
    background: url(https://warehouse.iqit-commerce.com/themes/warehouse/css/../img/icon/form-error.png) 98% 5px no-repeat #fff1f2
}

.form-group.form-ok input,
.form-group.form-ok textarea {
    border: 1px solid #46a74e;
    color: #35b33f;
    background: url(https://warehouse.iqit-commerce.com/themes/warehouse/css/../img/icon/form-ok.png) 98% 5px no-repeat #ddf9e1
}

.form-group.form-error .form-control,
.form-group.form-ok .form-control {
    padding-right: 30px
}

.radio-inline,
.checkbox {
    padding-left: 0
}

.radio-inline .checker,
.checkbox .checker {
    float: left
}

.radio-inline .checker span,
.checkbox .checker span {
    top: 0
}

.radio-inline div.radio,
.checkbox div.radio {
    display: inline-block
}

.radio-inline div.radio span,
.checkbox div.radio span {
    float: left;
    top: 0
}

.fancybox-skin {
    background: #fff !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15) !important
}

.fancybox-overlay {
    background: url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../img/fancybox_overlay.png") !important
}

body .fancybox-skin {
    border-radius: 0
}

body .fancybox-skin .fancybox-close {
    font-size: 14px;
    background: black;
    right: 0;
    top: -24px;
    line-height: 22px;
    color: #fff;
    width: 24px;
    height: 24px;
    text-align: center
}

body .fancybox-skin .fancybox-close:hover {
    color: #515151
}

body .fancybox-skin .fancybox-close:after {
    content: "\f00d";
    font-family: "FontAwesome"
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}


#facebook_block,
#cmsinfo_block {
    overflow: hidden;
    background: #f2f2f2;
    min-height: 344px;
    padding-right: 29px;
    padding-left: 29px
}

@media (max-width:999px) {

    #facebook_block,
    #cmsinfo_block {
        min-height: 348px;
        padding-left: 13px;
        padding-right: 13px
    }
}

@media (max-width:767px) {

    #facebook_block,
    #cmsinfo_block {
        width: 100%;
        min-height: 1px
    }
}

#facebook_block h4 {
    padding: 35px 0 0 0;
    margin: 0 0 12px 0;
    font-size: 21px;
    line-height: 35px;
    color: #6f6d6d
}

@media (max-width:999px) {
    #facebook_block h4 {
        font-size: 18px
    }
}

@media (max-width:767px) {
    #facebook_block h4 {
        padding-top: 20px !important
    }
}

@media (max-width:768px) {
    #facebook_block h4 {
        font-size: 16px
    }
}

#facebook_block .facebook-fanbox {
    background: #fff;
    border: 1px solid #aaaaaa;
    padding-bottom: 10px
}

@media (max-width:767px) {
    #cmsinfo_block {
        border: none;
        margin-top: 10px
    }
}

#cmsinfo_block>div {
    padding: 35px 10px 0 0
}

@media (max-width:767px) {
    #cmsinfo_block>div {
        padding-top: 20px
    }
}

@media (max-width:479px) {
    #cmsinfo_block>div {
        width: 100%;
        border-top: 1px solid #d9d9d9
    }
}

#cmsinfo_block>div+div {
    border-left: 1px solid #d9d9d9;
    min-height: 344px;
    padding-left: 29px
}

@media (max-width:479px) {
    #cmsinfo_block>div+div {
        border-left: none;
        padding-left: 10px;
        min-height: 1px;
        padding-bottom: 15px
    }
}

#cmsinfo_block em {
    float: left;
    width: 60px;
    height: 60px;
    margin: 3px 10px 0 0;
    font-size: 30px;
    color: #fff;
    line-height: 60px;
    text-align: center;
    background: #6f6d6d;
    border-radius: 100px
}

@media (max-width:999px) {
    #cmsinfo_block em {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 20px
    }
}

#cmsinfo_block .type-text {
    overflow: hidden
}

#cmsinfo_block h3 {
    margin: 0 0 5px 0;
    font-size: 21px;
    line-height: 25px;
    color: #6f6d6d
}

@media (max-width:1319px) {
    #cmsinfo_block h3 {
        font-size: 18px
    }
}

#cmsinfo_block ul li {
    padding-bottom: 22px
}

@media (max-width:1319px) {
    #cmsinfo_block ul li {
        padding-bottom: 10px
    }
}

@media (max-width:999px) {
    #cmsinfo_block ul li {
        padding-bottom: 0
    }
}

#cmsinfo_block p em {
    background: none;
    border-radius: 0;
    margin: 0;
    font-size: 13px;
    color: #777777;
    float: none;
    height: inherit;
    line-height: inherit;
    text-align: left;
    font-style: italic
}

.ie8 #facebook_block,
.ie8 #cmsinfo_block {
    height: 344px
}

@media (max-width:1319px) {
    .zoomdiv {
        display: none !important
    }
}

#product.content_only div.pb-left-column p.resetimg {
    margin-bottom: 0;
    padding-top: 0
}

#product.content_only div.pb-left-column #thumbs_list_frame {
    margin-bottom: 0
}

#product_comparison #social-share-compare {
    margin-bottom: 50px
}

.btn-facebook:hover,
.btn-twitter:hover,
.btn-google-plus:hover,
.btn-pinterest:hover {
    border-color: white
}

.btn-facebook i {
    color: #435f9f
}

.btn-facebook:hover {
    color: white;
    background-color: #435f9f
}

.btn-facebook:hover i {
    color: white
}

.btn-twitter i {
    color: #00aaf0
}

.btn-twitter:hover {
    color: white;
    background-color: #00aaf0
}

.btn-twitter:hover i {
    color: white
}

.btn-google-plus i {
    color: #e04b34
}

.btn-google-plus:hover {
    color: white;
    background-color: #e04b34
}

.btn-google-plus:hover i {
    color: white
}

.btn-pinterest i {
    color: #ce1f21
}

.btn-pinterest:hover {
    color: white;
    background-color: #ce1f21
}

.btn-pinterest:hover i {
    color: white
}

html {
    -ms-overflow-style: scrollbar
}

.flexslider_carousel_block,
.iqitcontent-column-inner {
    margin-bottom: 0;
    padding-bottom: 0
}

.flexslider_carousel_block .tab-content,
.iqitcontent-column-inner .tab-content {
    margin-top: 0 !important
}

.flexslider_carousel_block .tab-content .tab-pane,
.iqitcontent-column-inner .tab-content .tab-pane {
    display: block;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden
}

.flexslider_carousel_block .tab-content .tab-pane .iqitcontent-column,
.iqitcontent-column-inner .tab-content .tab-pane .iqitcontent-column {
    height: 0
}

.flexslider_carousel_block .tab-content .tab-pane.active,
.iqitcontent-column-inner .tab-content .tab-pane.active {
    height: auto;
    opacity: 1;
    visibility: visible;
    position: static;
    overflow: visible
}

.flexslider_carousel_block .tab-content .tab-pane.active .iqitcontent-column,
.iqitcontent-column-inner .tab-content .tab-pane.active .iqitcontent-column {
    height: auto
}

.slick_carousel {
    padding: 0;
    position: relative;
    margin-top: -10px
}

.slick_carousel .ajax_block_product {
    text-align: center;
    padding: 0 9px;
    position: relative;
    margin: 0;
    padding-top: 9px;
    padding-bottom: 9px
}

@media (max-width:479px) {
    .slick_carousel .ajax_block_product {
        padding-left: 10px;
        padding-right: 10px
    }
}

.slick_carousel .loading {
    min-height: 300px;
    background: url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../img/ajax-loader.gif") no-repeat center center;
    text-indent: -9999px;
    z-index: 0;
    position: relative;
    left: 0;
    top: 0;
    width: 100%
}

.slick_carousel .viewport {
    padding: 11px 5px
}

.pl-quantity-input-wrapper {
    display: inline-block;
    width: 40px;
    top: 1px;
    margin-right: 5px;
    position: relative;
    vertical-align: middle
}

@media (max-width:999px) {
    .pl-quantity-input-wrapper {
        display: none
    }
}

.product_list.grid .button-container {
    white-space: nowrap;
    overflow: hidden
}

.quantity-input-b-wrapper .pl_product_quantity_up {
    position: absolute;
    top: 0;
    right: 5px
}

.quantity-input-b-wrapper .pl_product_quantity_down {
    position: absolute;
    top: 10px;
    right: 5px
}

.product-image-container {
    margin-bottom: 5px;
    position: relative
}

.product-image-container .img_1,
.product-image-container .img_0 {
    margin: 0 auto;
    transition: visibility 200ms ease-in-out, opacity 200ms ease-in-out
}

.product-image-container .img_1 {
    visibility: hidden;
    opacity: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%
}

.product-name-container {
    overflow: hidden;
    margin: 0;
    height: 22px
}

.star_content {
    width: 65px;
    margin: 0 auto;
    padding-bottom: 4px
}

.content_price {
    padding: 4px 0
}

ul.product_list.grid .content_price {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.slick_carousel .ajax_block_product,
.slick_carousel .iqitmanufacuter-logo {
    opacity: 0;
    visibility: hidden
}

.slick-initialized .ajax_block_product,
.slick-initialized .iqitmanufacuter-logo {
    opacity: 1;
    visibility: visible
}

ul.product_list.grid .ajax_block_product,
.slick_carousel .ajax_block_product {
    transition: box-shadow 0.2s ease
}

ul.product_list.grid .ajax_block_product .color-list-container,
.slick_carousel .ajax_block_product .color-list-container {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    margin: 0;
    bottom: 30px;
    max-height: 100%;
    height: auto !important;
    height: 100%;
    overflow: hidden;
    display: none
}

ul.product_list.grid .ajax_block_product .color-list-container ul,
.slick_carousel .ajax_block_product .color-list-container ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    direction: rtl;
    padding-top: 32px
}

ul.product_list.grid .ajax_block_product .color-list-container ul li,
.slick_carousel .ajax_block_product .color-list-container ul li {
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-bottom: 1px;
    border: 1px solid #dddddd
}

ul.product_list.grid .ajax_block_product .color-list-container ul li a,
.slick_carousel .ajax_block_product .color-list-container ul li a {
    width: 12px;
    display: block;
    padding: 0;
    margin: 0;
    height: 12px
}

ul.product_list .availability span,
.slick_carousel .availability span {
    display: inline-block;
    color: #fff;
    font-size: 9px;
    line-height: 12px;
    padding: 4px 5px 2px;
    text-transform: uppercase;
    text-align: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    margin-bottom: 10px
}

ul.product_list .availability span.available-now,
.slick_carousel .availability span.available-now {
    background: #46a74e
}

ul.product_list .availability span.out-of-stock,
.slick_carousel .availability span.out-of-stock {
    background: #ff7430
}

.sale-label,
.price-percent-reduction,
#reduction_percent,
#reduction_amount,
#availability_value,
#last_quantities {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15)
}

@media (max-width:767px) {

    .slick_carousel .available-sizes,
    ul.product_list.grid .available-sizes {
        display: none
    }
}

.slick_carousel .functional-buttons,
ul.product_list.grid .functional-buttons {
    line-height: 10px;
    text-align: center;
    box-sizing: content-box
}

@media (max-width:767px) {

    .slick_carousel .functional-buttons,
    ul.product_list.grid .functional-buttons {
        display: none
    }
}

.slick_carousel .functional-buttons *,
ul.product_list.grid .functional-buttons * {
    box-sizing: content-box
}

.availability-slidein {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease
}

.availability-slidein .availabile_product {
    display: none !important
}

.functional-buttons {
    z-index: 2;
    font-size: 0;
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-bottom: -15px;
    transition: all 0.2s ease
}

.functional-buttons>div {
    width: auto;
    padding: 0 10px;
    display: inline-block;
    float: none
}

.functional-buttons div {
    height: 23px;
    line-height: 19px;
    padding-top: 4px
}

.functional-buttons div a,
.functional-buttons div label {
    color: #777676;
    cursor: pointer;
    font-size: 0
}

.functional-buttons div a:hover,
.functional-buttons div label:hover {
    color: #000
}

.functional-buttons div a:before,
.functional-buttons div label:before {
    font-size: 15px
}

.functional-buttons div.quickview {
    border-right: 1px solid #dddddd
}

.functional-buttons div.quickview a:before {
    display: inline-block;
    font-family: "FontAwesome";
    content: "\f00e";
    font-size: 15px;
    margin-right: 4px
}

.functional-buttons div.wishlist {
    border-right: 1px solid #dddddd
}

.functional-buttons div.wishlist a:before {
    display: inline-block;
    font-family: "FontAwesome";
    content: "\f08a"
}

.functional-buttons div.wishlist a.checked:before {
    content: "\f004"
}

.functional-buttons div.compare a:before {
    content: "\f074";
    display: inline-block;
    font-family: "FontAwesome";
    margin-right: 3px
}

.functional-buttons div.compare a.checked:before {
    content: "\f068"
}

.functional-buttons div:last-child {
    border-right: none !important
}

.ajax_block_product .hovered .product-image-container .img_1,
.ajax_block_product:hover .product-image-container .img_1 {
    visibility: visible;
    opacity: 1 !important
}

.ajax_block_product .hovered .availability-slidein,
.ajax_block_product:hover .availability-slidein {
    bottom: -10px;
    visibility: hidden;
    opacity: 0
}

.ajax_block_product .hovered .functional-buttons-grid,
.ajax_block_product:hover .functional-buttons-grid {
    visibility: visible;
    opacity: 0.9;
    margin-bottom: 0
}

.ajax_block_product .hovered .functional-buttons-grid:hover,
.ajax_block_product:hover .functional-buttons-grid:hover {
    opacity: 1.0
}

.ajax_block_product:hover .functional-buttons-grid {
    visibility: visible;
    opacity: 0.9;
    margin-bottom: 0
}

.ajax_block_product:hover .functional-buttons-grid:hover {
    opacity: 1.0
}

.online-label,
.new-label,
.sale-label {
    box-sizing: content-box;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15)
}

.transition-300 {
    transition: all 0.2s ease
}

.transition-opacity-300 {
    transition: opacity 0.2s ease
}

.nb-comments {
    display: none
}

.productlistRating .star {
    cursor: default;
    vertical-align: middle
}

.productlistRating .nb-comments {
    display: inline;
    vertical-align: middle
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 400;
    line-height: 1.1;
    font-family: inherit;
    text-transform: uppercase
}

@media (min-width:1320px) {

    #left_column,
    #right_column {
        width: 20%
    }

    .show-left-column #left_column.col-sm-pull-9 {
        right: 80%
    }

    .show-left-column #left_column.col-sm-pull-6 {
        right: 60%
    }

    .show-left-column #center_column {
        width: 80%
    }

    .show-left-column #center_column.col-sm-push-3 {
        left: 20%
    }

    .show-right-column #center_column {
        width: 80%
    }

    .show-left-column.show-right-column #center_column {
        width: 60%
    }

    .is_rtl.show-left-column #left_column.col-sm-pull-9 {
        left: 80%;
        right: auto
    }

    .is_rtl.show-left-column #left_column.col-sm-pull-6 {
        left: 60%;
        right: auto
    }

    .is_rtl.show-left-column #center_column.col-sm-push-3 {
        right: 20%;
        left: auto
    }
}

input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="text"],
input[type="password"] {
    -webkit-appearance: none;
    border-radius: 0
}

.ui-slider .ui-slider-handle {
    width: 11px !important;
    height: 11px !important
}

.ui-slider .ui-slider-range {
    background: #dddddd !important
}

.ui-slider {
    height: 5px !important;
    border-color: #dddddd !important
}

.table-bordered>thead>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tbody>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>tfoot>tr>td {
    border-color: #dddddd
}

.product-container .bottomLine {
    background-image: none !important;
    background-repeat: no-repeat !important;
    visibility: visible !important;
    display: block !important;
    width: 75px !important;
    height: 22px;
    margin: 0 auto !important
}

.product-container .bottomLine .number_results,
.product-container .bottomLine .text,
.product-container .bottomLine .text-m {
    display: none !important
}

.product-container .bottomLine .yotpo-bottomline {
    float: none !important;
    text-align: center !important
}

.yotpo .yotpo-bottomline .icon-star,
.yotpo .yotpo-bottomline .icon-half-star,
.yotpo .yotpo-bottomline .icon-empty-star,
.yotpo-stars .icon-star,
.yotpo-stars .icon-empty-star,
.yotpo-stars .yotpo-icon-star,
.yotpo-stars .yotpo-icon-empty-star,
.yotpo-stars .yotpo-icon-half-star,
.yotpo-stars .icon-half-star {
    font-size: 15px !important;
    margin: 0 !important
}

.v-center-parent {
    display: table;
    table-layout: fixed
}

.v-center-child {
    display: table-cell;
    vertical-align: middle;
    text-align: center
}

.eqcol {
    display: table;
    table-layout: fixed
}

.eqcol [class*="col-"] {
    float: none;
    display: table-cell;
    vertical-align: top
}

a:focus {
    outline: none !important
}

label {
    font-weight: normal
}

@media (max-width:1319px) {
    .videoWrapper {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden
    }

    .videoWrapper iframe,
    .videoWrapper object,
    .videoWrapper embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }
}

.circle-icon {
    display: inline-block;
    font-family: "FontAwesome";
    width: 32px;
    height: 32px;
    font-size: 16px;
    color: #fff !important;
    line-height: 33px;
    text-align: center;
    background: #99999b;
    margin-right: 5px;
    border-radius: 50px;
    outline: none !important;
    transition: all 0.2s ease
}

.circle-icon:hover {
    text-decoration: none;
    background: #515151
}

.info-paragraph-icon:hover .circle-icon {
    background: #515151
}



@media (max-width:479px) {
    .nav-tabs>li {
        float: none
    }
}

.nav-tabs>li>a {
    font-size: 12px;
    line-height: 16px;
    transition: background-color 0.2s ease;
    text-transform: uppercase
}

.nav-tabs>li>a:hover {
    background-color: #FAFAFA
}

.tab-content {
    margin-top: 0
}

.tab-content>.page-product-box.tab-pane {
    border: 1px solid #dddddd;
    border-top: none
}

#page .tab-pane .rte {
    padding: 0 0 20px 0
}

a,
.bt_compare,
#slidetopcontentShower,
.bt_compare,
#languages-block-top div.current,
#currencies-block-top div.current {
    transition: color 0.2s ease
}

div.selector {
    font-size: inherit !important
}

.btn-default {
    background-color: transparent
}

.container {
    padding-left: 15px;
    padding-right: 15px
}

.circle {
    width: 85px;
    height: 85px;
    display: block;
    text-align: center;
    border-radius: 50%;
    background-color: #f2f2f2;
    text-align: center;
    transition: all 0.2s ease;
    z-index: 5;
    position: relative
}

.circle i {
    font-size: 26px;
    line-height: 85px
}

.circle:hover {
    background-color: #777777;
    color: #f2f2f2
}

.gm-style .gm-style-iw {
    color: #777777 !important
}


.is_rtl .slick-next:before {
    content: "\e600"
}


.product_list_small {
    margin-left: 0;
    margin-right: 0
}

.product_list_small .left-block {
    width: 35%;
    float: left;
    text-align: left
}

.product_list_small .right-block {
    width: 65%;
    float: left;
    text-align: left;
    padding: 5px 0 5px 10px
}

.product_list_small .functional-buttons {
    border: none;
    background: none;
    text-align: right
}

.product_list_small .functional-buttons div {
    padding: 0 4px
}

.product_list_small .product-image-container {
    margin-bottom: 0
}

.product_list_small .ajax_block_product {
    padding: 9px
}

.product_list_small .star_content {
    margin: 0;
    padding-top: 4px;
    padding-bottom: 0
}

.ajax_block_product {
    transition: color 0.2s ease, background 0.2s ease
}

.ajax_block_product .product-container {
    transition: color 0.2s ease, background 0.2s ease
}

.ajax_block_product .star {
    cursor: default
}

.alternative-slick-arrows .slick-prev,
.alternative-slick-arrows .slick-next {
    width: 25px;
    height: 25px;
    top: 4px;
    text-indent: 0;
    font-size: 0;
    line-height: 0;
    text-align: center
}

.alternative-slick-arrows .slick-prev:before,
.alternative-slick-arrows .slick-next:before {
    font-size: 20px;
    line-height: 25px
}

.alternative-slick-arrows .slick-prev {
    left: auto;
    right: 30px
}

.alternative-slick-arrows .slick-prev:before {
    content: "\F104";
    font-family: "FontAwesome"
}

.alternative-slick-arrows .slick-next {
    right: 0
}

.alternative-slick-arrows .slick-next:before {
    content: "\F105";
    font-family: "FontAwesome"
}

.is_rtl .alternative-slick-arrows .slick-prev {
    right: auto;
    left: 30px
}

.is_rtl .alternative-slick-arrows .slick-prev:before {
    content: "\F105"
}

.is_rtl .alternative-slick-arrows .slick-next {
    right: auto;
    left: 0
}

.is_rtl .alternative-slick-arrows .slick-next:before {
    content: "\F104"
}

.quickview-full-btn {
    right: 0;
    z-index: 4;
    bottom: 0;
    position: absolute !important;
    border-radius: 0
}

.lazy {
    background-image: url("https://warehouse.iqit-commerce.com/themes/warehouse/css/../img/ajax-loader.gif");
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 60px;
    text-indent: -999px
}

@media (min-width:1000px) {
    #header.sticky-header {
        position: fixed !important;
        top: 0 !important;
        width: 100% !important;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        left: 0;
        -webkit-backface-visibility: hidden;
        padding: 5px 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        -webkit-animation: slidedown 0.3s forwards;
        animation: slidedown 0.3s forwards
    }

    #header.sticky-header .container-header {
        background: none !important
    }

    #header.sticky-header .nav {
        opacity: 0;
        padding: 0;
        visibility: hidden;
        height: 0 !important;
        min-height: 0 !important;
        overflow: hidden
    }

    #header.sticky-header .row #header_logo {
        max-height: 80px;
        padding-top: 5px;
        padding-bottom: 5px
    }

    #header.sticky-header .row #header_logo img {
        max-height: 60px
    }
}

.payment_option_form {
    display: none
}

.payment_option_logo {
    height: 50px;
    max-height: 50px
}

.payment_module_adv {
    height: 120px;
    max-height: 120px
}

.payment_option_selected {
    display: none;
    margin-top: 15px
}

.payment_option_selected i {
    color: green
}

.payment_option_cta {
    margin-left: 10px;
    color: #333 !important
}

.tnc_box {
    background: #fbfbfb;
    border: 1px solid #d6d4d4;
    padding: 14px 18px 13px;
    margin: 0 0 30px 0;
    line-height: 23px
}

.pointer-box {
    cursor: pointer
}

#HOOK_ADVANCED_PAYMENT {
    margin-bottom: 10px
}

#HOOK_ADVANCED_PAYMENT p.payment_module {
    margin-bottom: 20px
}

span.aeuc_from_label,
span.aeuc_before_label,
span.aeuc_tax_label,
div.aeuc_shipping_label,
div.aeuc_weight_label {
    font-size: 80%
}

div.aeuc_delivery_label {
    border: 1px solid #cecece;
    padding: 4px;
    text-align: center;
    font-size: 80%;
    clear: both;
    margin-top: 10px
}

ul.product_list.grid .ajax_block_product .button-container div.aeuc_delivery_label,
.slick_carousel .ajax_block_product .button-container div.aeuc_delivery_label {
    border-left: none;
    border-bottom: none;
    border-right: none;
    padding-bottom: 0
}

.product_list_small .ajax_block_product .button-container div.aeuc_delivery_label {
    text-align: left;
    padding-left: 0;
    border: none;
    margin-top: 8px;
    padding: 0
}

ul.product_list.grid>li .product-container .old-price,
ul.product_list.grid>li .product-container .price,
ul.product_list.grid>li .product-container .price-percent-reduction {
    vertical-align: bottom
}

::-ms-clear {
    width: 0;
    height: 0
}

@-webkit-keyframes tada {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    10%,
    20% {
        -webkit-transform: scale(0.9) rotate(-8deg);
        transform: scale(0.9) rotate(-8deg)
    }

    30%,
    50%,
    70% {
        -webkit-transform: scale(1.3) rotate(8deg);
        transform: scale(1.3) rotate(8deg)
    }

    40%,
    60% {
        -webkit-transform: scale(1.3) rotate(-8deg);
        transform: scale(1.3) rotate(-8deg)
    }

    80%,
    100% {
        -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0)
    }
}

@keyframes tada {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    10%,
    20% {
        -webkit-transform: scale(0.9) rotate(-8deg);
        transform: scale(0.9) rotate(-8deg)
    }

    30%,
    50%,
    70% {
        -webkit-transform: scale(1.3) rotate(8deg);
        transform: scale(1.3) rotate(8deg)
    }

    40%,
    60% {
        -webkit-transform: scale(1.3) rotate(-8deg);
        transform: scale(1.3) rotate(-8deg)
    }

    80%,
    100% {
        -webkit-transform: scale(1) rotate(0);
        transform: scale(1) rotate(0)
    }
}

.faa-tada.animated,
.faa-tada.animated-hover:hover,
.faa-parent.animated-hover:hover>.faa-tada {
    -webkit-animation: tada 2s linear infinite;
    animation: tada 2s linear infinite
}

.faa-tada.animated.faa-fast,
.faa-tada.animated-hover.faa-fast:hover,
.faa-parent.animated-hover:hover>.faa-tada.faa-fast {
    -webkit-animation: tada 1s linear infinite;
    animation: tada 1s linear infinite
}

.faa-tada.animated.faa-slow,
.faa-tada.animated-hover.faa-slow:hover,
.faa-parent.animated-hover:hover>.faa-tada.faa-slow {
    -webkit-animation: tada 3s linear infinite;
    animation: tada 3s linear infinite
}

.cbp-spmenu-push-toright #mh-sticky.sticked {
    left: 240px !important;
    width: 100%
}

@-webkit-keyframes slidedown {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes slidedown {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

select::-ms-expand {
    display: none
}

@media only screen and (-webkit-min-device-pixel-ratio:2),
only screen and (min-device-pixel-ratio:2) {
    .replace-2x {
        font-size: 1px
    }

    .replace-2xlogo {
        font-size: 1px
    }

    .example {
        background-image: url(https://warehouse.iqit-commerce.com/themes/warehouse/css/autoload/../images/example2x.png);
        background-size: 13px 13px
    }
}

table th {
    font-weight: bold
}

table td,
table th {
    padding: 9px 10px;
    text-align: left
}

@media only screen and (max-width:767px) {
    table.responsive {
        margin-bottom: 0
    }

    .pinned {
        position: absolute;
        left: 0;
        top: 0;
        background: #fff;
        width: 35%;
        overflow: hidden;
        overflow-x: scroll;
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc
    }

    .pinned table {
        border-right: none;
        border-left: none;
        width: 100%
    }

    .pinned table th,
    .pinned table td {
        white-space: nowrap
    }

    .pinned td:last-child {
        border-bottom: 0
    }

    div.table-wrapper {
        position: relative;
        margin-bottom: 20px;
        overflow: hidden;
        border-right: 1px solid #ccc
    }

    div.table-wrapper div.scrollable {
        margin-left: 35%
    }

    div.table-wrapper div.scrollable {
        overflow: scroll;
        overflow-y: hidden
    }

    table.responsive td,
    table.responsive th {
        position: relative;
        white-space: nowrap;
        overflow: hidden
    }

    table.responsive th:first-child,
    table.responsive td:first-child,
    table.responsive td:first-child,
    table.responsive.pinned td {
        display: none
    }
}

div.selector,
div.selector span,
div.checker span,
div.radio span,
div.uploader,
div.uploader span.action,
div.button,
div.button span {
    border: 1px solid #cecece;
    background-repeat: no-repeat;
    -webkit-font-smoothing: antialiased
}

div.selector,
div.checker,
div.button,
div.radio,
div.uploader {
    vertical-align: middle
}

div.selector:focus,
div.checker:focus,
div.button:focus,
div.radio:focus,
div.uploader:focus {
    outline: 0
}

div.selector,
div.selector *,
div.radio,
div.radio *,
div.checker,
div.checker *,
div.uploader,
div.uploader *,
div.button,
div.button * {
    margin: 0;
    padding: 0
}

.highContrastDetect {
    width: 0;
    height: 0
}

input.uniform-input,
select.uniform-multiselect,
textarea.uniform {
    padding: 3px;
    background: white;
    outline: 0
}

div.checker input,
input[type="search"],
input[type="search"]:active {
    -moz-appearance: none;
    -webkit-appearance: none
}

div.selector {
    background-position: 0 -54px;
    line-height: 27px;
    height: 27px;
    padding: 0 0 0 10px;
    position: relative;
    overflow: hidden
}

div.selector span {
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    background-position: right 0;
    height: 27px;
    line-height: 27px;
    padding-right: 30px;
    cursor: pointer;
    width: 100%;
    display: block
}

div.selector.fixedWidth {
    width: 190px
}

div.selector.fixedWidth span {
    width: 150px
}

div.selector select {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    border: none;
    background: none;
    position: absolute;
    height: 27px;
    top: 0;
    left: 0;
    width: 100%
}

div.selector.active span {
    background-position: right -27px
}

div.selector.hover span,
div.selector.focus span {
    background-position: right -27px
}

div.selector.hover.active span,
div.selector.focus.active span {
    background-position: right -27px
}

div.selector.disabled span,
div.selector.disabled.active span {
    background-position: right 0
}

div.selector1>div {
    background-position: 0 -135px
}

div.selector1>div span {
    background-position: right -81px
}

div.selector1>div.active span {
    background-position: right -108px
}

div.selector1>div.hover span,
div.selector1>div.focus span {
    background-position: right -108px
}

div.selector1>div.hover.active span,
div.selector1>div.focus.active span {
    background-position: right -108px
}

div.selector1>div.disabled,
div.selector1>div.disabled.active {
    background-position: 0 -135px
}

div.selector1>div.disabled span,
div.selector1>div.disabled.active span {
    background-position: right -81px
}

div.selector2>div {
    background-position: 0 -216px
}

div.selector2>div span {
    background-position: right -162px
}

div.selector2>div.active span {
    background-position: right -189px
}

div.selector2>div.hover span,
div.selector2>div.focus span {
    background-position: right -189px
}

div.selector2>div.hover.active span,
div.selector2>div.focus.active span {
    background-position: right -189px
}

div.selector2>div.disabled,
div.selector2>div.disabled.active {
    background-position: 0 -216px
}

div.selector2>div.disabled span,
div.selector2>div.disabled.active span {
    background-position: right -162px
}

div.selector3>div {
    background-position: 0 -351px
}

div.selector3>div span {
    background-position: right -297px
}

div.selector3>div.active span {
    background-position: right -324px
}

div.selector3>div.hover span,
div.selector3>div.focus span {
    background-position: right -324px
}

div.selector3>div.hover.active span,
div.selector3>div.focus.active span {
    background-position: right -324px
}

div.selector3>div.disabled,
div.selector3>div.disabled.active {
    background-position: 0 -351px
}

div.selector3>div.disabled span,
div.selector3>div.disabled.active span {
    background-position: right -297px
}

div.checker {
    position: relative;
    cursor: pointer
}

div.checker,
div.checker span,
div.checker input {
    width: 15px;
    height: 15px;
    cursor: pointer
}

div.checker span {
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
    text-align: center
}

div.checker span.checked:before {
    content: "\f00c";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    font-size: 10px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 2px;
    bottom: 0
}

div.checker input {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    border: none;
    background: none;
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1
}

div.checker.active span {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2)
}

div.checker.hover span,
div.checker.focus span {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2)
}

div.checker.disabled,
div.checker.disabled.active {
    opacity: 0.6
}

div.radio {
    position: relative;
    display: inline;
    cursor: pointer
}

div.radio,
div.radio span,
div.radio input {
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-radius: 50%
}

div.radio span {
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
    text-align: center
}

div.radio span.checked:before {
    content: "\f111";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    font-size: 7px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 3px;
    bottom: 0
}

div.radio input {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    border: none;
    background: none;
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
    text-align: center
}

div.radio.active span {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2)
}

div.radio.hover span,
div.radio.focus span {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2)
}

div.radio.disabled span,
div.radio.disabled.active span {
    opacity: 0.6
}

div.uploader {
    background: none;
    height: 27px;
    width: 100%;
    cursor: pointer;
    position: relative;
    border: none !important;
    overflow: hidden
}

div.uploader span.action {
    height: 27px;
    line-height: 27px;
    width: 94px;
    text-align: center;
    float: left;
    display: inline;
    overflow: hidden;
    cursor: pointer
}

div.uploader span.filename {
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    cursor: default;
    height: 27px;
    margin: 0 0 0 0;
    line-height: 27px;
    width: 172px;
    padding: 0 10px
}

div.uploader input {
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    border: none;
    background: none;
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    cursor: default;
    width: 100%;
    height: 100%
}

div.button {
    background-position: 0 -636px;
    height: 30px;
    cursor: pointer;
    position: relative
}

div.button a,
div.button button,
div.button input {
    opacity: 0.01;
    filter: alpha(opacity=1);
    -moz-opacity: 0.01;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute
}

div.button span {
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
    line-height: 30px;
    text-align: center;
    background-position: right -516px;
    height: 30px;
    margin-left: 13px;
    padding: 0
}

div.button.active {
    background-position: 0 -666px
}

div.button.active span {
    background-position: right -546px;
    cursor: default
}

div.button.hover,
div.button.focus {
    background-position: 0 -696px
}

div.button.hover span,
div.button.focus span {
    background-position: right -576px
}

div.button.disabled,
div.button.disabled.active {
    background-position: 0 -726px
}

div.button.disabled span,
div.button.disabled.active span {
    background-position: right -606px;
    cursor: default
}

input.uniform-input,
select.uniform-multiselect,
textarea.uniform {
    font-size: 13px;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: normal;
    line-height: 18px;
    color: #777;
    border: solid 1px #d6d4d4
}

input.uniform-input.hover,
input.uniform-input.focus,
select.uniform-multiselect.hover,
select.uniform-multiselect.focus,
textarea.uniform.hover,
textarea.uniform.focus {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-color: #cbcaca
}

div.button span {
    font-weight: bold;
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase
}

div.button.hover span,
div.button.focus span {
    color: #555
}

div.button.disabled span,
div.button.disabled.active span {
    color: #bbb
}

#compare_shipping .form-group label {
    display: block
}

div.selector {
    font-size: 12px
}

div.selector span {
    color: #666
}

div.selector select {
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 12px
}

div.selector.disabled span,
div.selector.disabled.active span {
    color: #bbb
}

.checker span input {
    margin: 0 !important
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    margin: 0 !important
}

.radio-inline,
.checkbox-inline {
    cursor: default
}

div.checker {
    cursor: pointer;
    margin-right: 5px
}

div.checker span {
    position: relative
}

#layered_form div.checker {
    display: inline;
    position: absolute;
    left: 0
}

div.radio {
    margin-right: 3px
}

div.radio span {
    position: relative
}

div.uploader span.action {
    font-size: 13px;
    font-weight: bold
}

div.uploader span.filename {
    border: solid 1px #d6d4d4;
    font-size: 13px;
    background: #fbfbfb;
    margin-right: 2px
}

div.uploader.disabled span.action,
div.uploader.disabled.active span.action {
    color: #aaa
}

div.uploader.disabled span.filename,
div.uploader.disabled.active span.filename {
    border-color: #ddd;
    color: #aaa
}

input.uniform-input,
input.uniform-input:focus {
    background-color: #fff
}

.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    vertical-align: top
}

.fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8020
}

.fancybox-skin {
    position: relative;
    background: #f9f9f9;
    color: #444;
    text-shadow: none;
    border-radius: 4px
}

.fancybox-opened {
    z-index: 8030
}

.fancybox-opened .fancybox-skin {
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5)
}

.fancybox-outer,
.fancybox-inner {
    position: relative
}

.fancybox-inner {
    overflow: hidden
}

.fancybox-type-iframe .fancybox-inner {
    -webkit-overflow-scrolling: touch
}

.fancybox-error {
    color: #444;
    font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
    padding: 15px;
    white-space: nowrap
}

.fancybox-image,
.fancybox-iframe {
    display: block;
    width: 100%;
    height: 100%
}

.fancybox-image {
    max-width: 100%;
    max-height: 100%
}

#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
    background-image: url('https://warehouse.iqit-commerce.com/js/jquery/plugins/fancybox/fancybox_sprite.png')
}

#fancybox-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px;
    background-position: 0 -108px;
    opacity: 0.8;
    cursor: pointer;
    z-index: 8060
}

#fancybox-loading div {
    width: 44px;
    height: 44px;
    background: url('https://warehouse.iqit-commerce.com/js/jquery/plugins/fancybox/fancybox_loading.gif') center center no-repeat
}

.fancybox-close {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 8040
}

.fancybox-nav {
    position: absolute;
    top: 0;
    width: 40%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    background: transparent url('https://warehouse.iqit-commerce.com/js/jquery/plugins/fancybox/blank.gif');
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 8040
}

.fancybox-prev {
    left: 0
}

.fancybox-next {
    right: 0
}

.fancybox-nav span {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 34px;
    margin-top: -18px;
    cursor: pointer;
    z-index: 8040;
    visibility: hidden
}

.fancybox-prev span {
    left: 10px;
    background-position: 0 -36px
}

.fancybox-next span {
    right: 10px;
    background-position: 0 -72px
}

.fancybox-nav:hover span {
    visibility: visible
}

.fancybox-tmp {
    position: absolute;
    top: -99999px;
    left: -99999px;
    visibility: hidden;
    max-width: 99999px;
    max-height: 99999px;
    overflow: visible !important
}

.fancybox-lock {
    overflow: hidden !important;
    width: auto
}

.fancybox-lock body {
    overflow: hidden !important
}

.fancybox-lock-test {
    overflow-y: hidden !important
}

.fancybox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    display: none;
    z-index: 8010;
    background: url('https://warehouse.iqit-commerce.com/js/jquery/plugins/fancybox/fancybox_overlay.png')
}

.fancybox-overlay-fixed {
    position: fixed;
    bottom: 0;
    right: 0
}

.fancybox-lock .fancybox-overlay {
    overflow: auto;
    overflow-y: scroll
}

.fancybox-title {
    visibility: hidden;
    font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    text-shadow: none;
    z-index: 8050
}

.fancybox-opened .fancybox-title {
    visibility: visible
}

.fancybox-title-float-wrap {
    position: absolute;
    bottom: 0;
    right: 50%;
    margin-bottom: -35px;
    z-index: 8050;
    text-align: center
}

.fancybox-title-float-wrap .child {
    display: inline-block;
    margin-right: -100%;
    padding: 2px 20px;
    background: transparent;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    text-shadow: 0 1px 2px #222;
    color: #FFF;
    font-weight: bold;
    line-height: 24px;
    white-space: nowrap
}

.fancybox-title-outside-wrap {
    position: relative;
    margin-top: 10px;
    color: #fff
}

.fancybox-title-inside-wrap {
    padding-top: 10px
}

.fancybox-title-over-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 10px;
    background: #000;
    background: rgba(0, 0, 0, .8)
}

@media only screen and (-webkit-min-device-pixel-ratio:1.5),
only screen and (min--moz-device-pixel-ratio:1.5),
only screen and (min-device-pixel-ratio:1.5) {

    #fancybox-loading,
    .fancybox-close,
    .fancybox-prev span,
    .fancybox-next span {
        background-image: url('https://warehouse.iqit-commerce.com/js/jquery/plugins/fancybox/fancybox_sprite@2x.png');
        background-size: 44px 152px
    }

    #fancybox-loading div {
        background-image: url('https://warehouse.iqit-commerce.com/js/jquery/plugins/fancybox/fancybox_loading@2x.gif');
        background-size: 24px 24px
    }
}

#currencies-block-top {
    float: right;
    position: relative
}

@media (max-width:999px) {
    #currencies-block-top {
        float: left
    }
}

#currencies-block-top span.firstWord {
    display: none
}

#currencies-block-top div.current {
    cursor: pointer;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent
}

#currencies-block-top div.current:hover {
    color: #515151
}

#currencies-block-top div.current.active {
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    border: 1px solid #dddddd;
    border-bottom-color: #fff;
    line-height: 30px;
    position: relative;
    top: -3px
}

#currencies-block-top div.current.active div {
    background: #fff
}

#currencies-block-top div.current div {
    position: relative;
    z-index: 5;
    padding: 0 10px
}

#currencies-block-top div.current div:after {
    content: "\F107";
    font-family: "FontAwesome";
    padding-left: 2px
}

@media (max-width:767px) {
    #currencies-block-top div.current .cur-label {
        display: none
    }
}

#currencies-block-top ul {
    display: none;
    position: absolute;
    top: 26px;
    right: 0;
    min-width: 120%;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    border: 1px solid #dddddd;
    background: #fff;
    z-index: 2
}

@media (max-width:479px) {
    #currencies-block-top ul {
        left: 0;
        right: auto;
        min-width: 250%
    }
}

#currencies-block-top ul li {
    line-height: 16px
}

#languages-block-top {
    float: right;
    position: relative
}

@media (max-width:999px) {
    #languages-block-top {
        float: left
    }
}

#languages-block-top img {
    vertical-align: baseline
}

#languages-block-top span.firstWord {
    display: none
}

#languages-block-top div.current {
    cursor: pointer;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent
}

#languages-block-top div.current:hover {
    color: #515151
}

#languages-block-top div.current.active {
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    border: 1px solid #dddddd;
    border-bottom-color: #fff;
    line-height: 30px;
    position: relative;
    top: -3px
}

#languages-block-top div.current.active div {
    background: #fff
}

#languages-block-top div.current div {
    position: relative;
    z-index: 5;
    padding: 0 10px
}

#languages-block-top div.current div:after {
    content: "\F107";
    font-family: "FontAwesome";
    padding-left: 2px
}

@media (max-width:767px) {
    #languages-block-top div.current .cur-label {
        display: none
    }
}

#languages-block-top ul {
    display: none;
    position: absolute;
    top: 26px;
    right: 0;
    min-width: 120%;
    padding: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    border: 1px solid #dddddd;
    background: #fff;
    z-index: 2
}

@media (max-width:479px) {
    #languages-block-top ul {
        left: 0;
        right: auto;
        min-width: 250%
    }
}

#languages-block-top ul li {
    line-height: 16px
}

#columnadverts_slider img {
    margin: 0 auto
}

#columnadverts_list img {
    margin-bottom: 10px;
    width: 100%;
    opacity: 1.0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    transition: opacity 300ms ease-in-out;
    -moz-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    display: block
}

#columnadverts_list img:hover {
    margin-bottom: 10px;
    opacity: .8
}

header .nav .social_block_mod {
    float: left
}

header .nav .social_block_mod li {
    height: 24px;
    margin-right: 3px
}

header .nav .social_block_mod li a {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 12px
}

.social_block_mod ul {
    margin-bottom: 0;
    margin-top: -6px
}

.social_block_mod li {
    border: none;
    float: left;
    margin-right: 6px;
    margin-bottom: 0;
    margin-top: 6px;
    list-style-type: none;
    padding: 0;
    height: 32px
}

.social_block_mod li a {
    display: block;
    font-family: "FontAwesome";
    width: 32px;
    height: 32px;
    font-size: 16px;
    color: #fff !important;
    line-height: 33px;
    display: block;
    text-align: center;
    background: #99999b;
    border-radius: 50px;
    outline: none !important
}

.social_block_mod li a:hover {
    text-decoration: none
}

.social_block_mod li.facebook a:before {
    content: "\F09A"
}

.social_block_mod li.facebook a:hover {
    background: #3b5998 !important
}

.social_block_mod li.twitter a:before {
    content: "\F099"
}

.social_block_mod li.twitter a:hover {
    background: #23c3fd !important
}

.social_block_mod li.rss a:before {
    content: "\F09E"
}

.social_block_mod li.rss a:hover {
    background: #f69838 !important
}

.social_block_mod li.youtube a:before {
    content: "\F167"
}

.social_block_mod li.youtube a:hover {
    background: #e82a34 !important
}

.social_block_mod li.vimeo a {
    font-family: "warehousefont";
    position: relative;
    top: -1px
}

.social_block_mod li.vimeo a:before {
    content: "\f194";
    position: relative;
    left: 1px
}

.social_block_mod li.vimeo a:hover {
    background: #85c6fc !important
}

.social_block_mod li.pinterest a:before {
    content: "\F0D2"
}

.social_block_mod li.pinterest a:hover {
    background: #cb2027 !important
}

.social_block_mod li.tumblr a:before {
    content: "\F173"
}

.social_block_mod li.tumblr a:hover {
    background: #425e75 !important
}

.social_block_mod li.google a:before {
    content: "\F0D5"
}

.social_block_mod li.google a:hover {
    background: #c73e2e !important
}

.social_block_mod li.instagram a:before {
    content: "\F16D"
}

.social_block_mod li.instagram a:hover {
    background: #517fa3 !important
}

.social_block_mod li.flickr a:before {
    content: "\F16E"
}

.social_block_mod li.flickr a:hover {
    background: #517fa3 !important
}

.social_block_mod li:last-child {
    margin-right: 0
}

#left_column .social_block_mod {
    width: auto
}

#left_column .social_block_mod ul {
    list-style-type: none
}

#columns #newsletter_block_left .form-group {
    margin-bottom: 0
}

#columns #newsletter_block_left .form-group .form-control {
    max-width: 222px;
    display: inline-block;
    margin-right: 6px
}

@media (min-width:768px) and (max-width:1319px) {
    #columns #newsletter_block_left .form-group .form-control {
        margin-bottom: 10px;
        margin-right: 0
    }
}

#columns #newsletter_block_left .success_inline,
#columns #newsletter_block_left .warning_inline {
    text-align: left;
    padding: 1px 0 0 0;
    margin-bottom: -19px
}

#columns #newsletter_block_left .success_inline {
    color: #418B19
}

#columns #newsletter_block_left .warning_inline {
    color: #f13340
}

#footer #newsletter_block_left {
    float: right
}

@media (max-width:767px) {
    #footer #newsletter_block_left {
        margin-top: 20px;
        float: none
    }
}

#footer #newsletter_block_left .block_content {
    overflow: hidden
}

#footer #newsletter_block_left .promo-text {
    margin-top: 5px;
    display: block
}

#footer #newsletter_block_left .form-group {
    margin-bottom: 0
}

#footer #newsletter_block_left .form-group .form-control {
    max-width: 267px;
    padding: 5px 43px 5px 12px;
    display: inline-block;
    float: left
}

#footer #newsletter_block_left .form-group .form-control:focus {
    box-shadow: black 0 0 0
}

#footer #newsletter_block_left .form-group .button-small {
    margin-left: -27px;
    border: none;
    background: none !important;
    text-align: center;
    color: #908f8f !important;
    border: none !important;
    padding: 8px;
    text-shadow: none;
    line-height: 14px;
    outline: none !important
}

#footer #newsletter_block_left .form-group .button-small:before {
    content: "\F054";
    font-family: "FontAwesome";
    font-size: 14px;
    line-height: 14px
}

#footer #newsletter_block_left .form-group .button-small:hover {
    color: #777 !important
}

#footer #newsletter_block_left .form-group .button-small span {
    display: none
}

#footer #newsletter_block_left .warning_inline {
    display: block;
    color: #f13340;
    font-size: 13px;
    line-height: 26px;
    clear: both
}

@media (min-width:1320px) {
    #footer #newsletter_block_left .warning_inline {
        display: inline-block;
        position: relative;
        top: -35px;
        margin-bottom: -35px;
        left: 15px;
        clear: none
    }
}

#footer #newsletter_block_left .newsletter-input {
    max-width: 300px !important
}

.newsletter-close {
    cursor: pointer;
    float: right;
    font-size: 18px
}

.tags_block .block_content {
    overflow: hidden
}

.tags_block .block_content a {
    display: inline-block;
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
    padding: 4px 9px 5px 9px;
    border: 1px solid #dddddd;
    float: left;
    margin: 0 3px 3px 0
}

.tags_block .block_content a:hover {
    color: #777;
    background: #f6f6f6
}

.header_user_info {
    text-align: right;
    margin-top: 10px
}

@media (max-width:767px) {
    .header_user_info {
        text-align: center;
        margin: 10px 0
    }
}

.header_user_info a {
    cursor: pointer
}

.header_user_info_nav {
    float: right
}

#viewed-products_block_left li.last_item {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none
}

#wishlist_button:before,
#wishlist_button_nopop:before {
    content: "\f08a";
    font: 400 15px/31px "FontAwesome";
    margin-right: 5px;
    display: inline-block
}

#wishlist_block #wishlist_block_list {
    margin: 0 0 20px 0
}

#wishlist_block #wishlist_block_list dl {
    font-weight: 400
}

#wishlist_block #wishlist_block_list dl.products.no-products {
    font-weight: 700;
    padding: 0 0 15px 0;
    border-bottom: 1px solid #dddddd
}

#wishlist_block #wishlist_block_list dl.products.no-products dt {
    padding: 0;
    border-top: none
}

#wishlist_block #wishlist_block_list dl.products.no-products dd {
    display: none
}

#wishlist_block #wishlist_block_list dl.products {
    border-bottom: 1px solid #dddddd
}

#wishlist_block #wishlist_block_list dl.products dt {
    padding: 20px 0 0;
    border-top: 1px solid #dddddd
}

#wishlist_block #wishlist_block_list dl.products dd {
    padding: 0 0 20px 0
}

#wishlist_block #wishlist_block_list dl.products dt.first_item {
    padding: 0;
    border-top: none
}

#wishlist_block #wishlist_block_list dl dt {
    position: relative;
    padding-right: 0
}

#wishlist_block #wishlist_block_list dl dd {
    margin: 0 0 0 24px
}

#wishlist_block .lnk .form-group {
    margin: 0 0 20px 0
}

#wishlist_block .lnk .form-group select {
    max-width: 192px
}

@media (max-width:479px) {
    #wishlist_block .lnk .form-group select {
        width: 192px
    }
}

#wishlist_block .ajax_cart_block_remove_link {
    font-size: 14px;
    line-height: 14px;
    color: #d3d2d2;
    width: 14px;
    height: 14px;
    float: right
}

#wishlist_block .ajax_cart_block_remove_link:hover {
    color: #515151
}

#wishlist_block .cart_block_product_name {
    width: 85%;
    display: inline-block;
    font-weight: bold
}

#wishlist_block .quantity-formated {
    float: left;
    margin: 0 5px 0 0;
    width: 15px
}

#wishlist_block_list .price {
    float: right
}

#mywishlist td.wishlist_delete a {
    font-size: 15px
}

#module-blockwishlist-mywishlist #block-order-detail #hideSendWishlist {
    display: inline-block
}

#module-blockwishlist-mywishlist .wishlistLinkTop ul.display_list {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 20px
}

#module-blockwishlist-mywishlist .wishlistLinkTop ul.display_list a {
    display: inline-block;
    color: #777;
    margin: 10px 10px 10px 0;
    font-weight: bold
}

#module-blockwishlist-mywishlist .wishlistLinkTop ul.display_list a:hover {
    color: #515151
}

#module-blockwishlist-mywishlist .wishlistLinkTop li {
    float: left
}

#module-blockwishlist-mywishlist .wishlistLinkTop #hideSendWishlist {
    float: right
}

#module-blockwishlist-mywishlist .wishlistLinkTop #showBoughtProducts,
#module-blockwishlist-mywishlist .wishlistLinkTop #hideBoughtProductsInfos {
    display: none
}

ul.wlp_bought_list li {
    margin: 0 0 30px 0
}

ul.wlp_bought_list li .product_image {
    padding: 9px;
    border: 1px solid #dddddd;
    margin: 0 0 20px 0;
    max-width: 270px
}

ul.wlp_bought_list li .product-name {
    margin: 0 0 10px 0;
    padding: 0 20px 0 0
}

ul.wlp_bought_list li .product-name small {
    display: block;
    font-size: 11px
}

ul.wlp_bought_list li .product-name small a {
    font-size: 11px
}

ul.wlp_bought_list li .product_infos {
    position: relative
}

ul.wlp_bought_list li .btn_action .btn {
    margin: 0 0 10px 0
}

@media (min-width:1000px) {
    ul.wlp_bought_list li.first-in-line {
        clear: left
    }
}

@media (min-width:768px) and (max-width:999px) {
    ul.wlp_bought_list li.first-item-of-tablet-line {
        clear: left
    }
}

.wishlistLinkTop .submit {
    margin: 0 0 30px 0
}

ul.wlp_bought_list li .lnkdel {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    font-size: 14px
}

#wishlist_button>.btn-group {
    width: 80%
}

.product-container .functional-buttons .wishlist .popover {
    padding: 0;
    position: absolute;
    min-width: 160px;
    height: auto;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15)
}

.product-container .functional-buttons .wishlist .popover .popover-title {
    display: none
}

.product-container .functional-buttons .wishlist .popover .arrow {
    display: none
}

.product-container .functional-buttons .wishlist .popover .popover-content {
    padding: 0;
    height: auto;
    font-size: 11px;
    min-width: 158px
}

.product-container .functional-buttons .wishlist .popover table {
    margin-bottom: 2px
}

.product-container .functional-buttons .wishlist .popover table td:hover {
    background-color: #cccccc;
    cursor: pointer
}

.buttons_bottom_block .popover {
    position: absolute;
    background: #fff;
    padding-bottom: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15)
}

[id=wishlist_button]+.popover,
.wishlist_change_button+.popover {
    padding: 0;
    min-width: 150px
}

[id=wishlist_button]+.popover .popover-content,
.wishlist_change_button+.popover .popover-content {
    padding: 0
}

[id=wishlist_button]+.popover table,
.wishlist_change_button+.popover table {
    margin-bottom: 2px
}

[id=wishlist_button]+.popover table td:hover,
.wishlist_change_button+.popover table td:hover {
    background-color: #cccccc;
    cursor: pointer
}

#product_comments_block_extra {
    font-weight: bold;
    line-height: 18px;
    border-top: 1px solid #dddddd
}

#product_comments_block_extra .comments_note {
    margin-bottom: 5px
}

#product_comments_block_extra .comments_note span,
#product_comments_block_extra .star_content {
    float: left;
    color: #777
}

#product_comments_block_extra .star_content {
    margin-top: 1px
}

#product_comments_block_extra .comments_advices {
    clear: both
}

.comments_advices {
    padding-top: 15px
}

.comments_advices li {
    display: inline-block;
    line-height: 30px
}

@media (min-width:768px) and (max-width:999px) {
    .comments_advices li {
        display: block;
        line-height: 20px
    }
}

.comments_advices a {
    text-decoration: none;
    color: #777;
    position: relative;
    margin-right: 8px;
    line-height: 30px;
    padding: 0;
    cursor: pointer;
    display: inline-block
}

@media (min-width:768px) and (max-width:1319px) {
    .comments_advices a {
        float: none;
        line-height: 20px
    }
}

.comments_advices a:before {
    background: #929292;
    border-radius: 50px;
    color: white;
    display: inline-block;
    font-family: "FontAwesome";
    font-size: 15px;
    font-weight: normal;
    height: 30px;
    line-height: 30px;
    margin-right: 6px;
    text-align: center;
    width: 30px
}

@media (max-width:1319px) {
    .comments_advices a:before {
        display: none
    }
}

.comments_advices a.reviews:before {
    content: "\f0e5"
}

.comments_advices a.open-comment-form:before {
    content: "\f040"
}

.comments_advices a:hover {
    color: #777
}

.comments_advices a.reviews {
    margin-right: 10px;
    margin-bottom: 10px
}

#fancybox-wrap {
    width: 585px
}

#fancybox-content {
    width: 585px;
    border-width: 0
}

#new_comment_form {
    overflow: hidden;
    color: #777;
    text-align: left
}

#new_comment_form h2 {
    margin-bottom: 10px
}

#new_comment_form .title {
    padding: 10px;
    font-size: 13px;
    color: #fff;
    text-transform: uppercase;
    background: #333
}

#new_comment_form ul.grade_content {
    list-style-type: none;
    margin: 0 0 20px 0
}

#new_comment_form ul.grade_content li {
    width: 50%
}

#new_comment_form ul.grade_content span {
    display: inline-block;
    padding: 0 10px;
    width: 150px;
    font-weight: bold
}

#new_comment_form ul.grade_content .cancel {
    margin-right: 5px
}

#new_comment_form .product {
    padding: 15px
}

#new_comment_form .product img {
    border: 1px solid #dddddd
}

#new_comment_form .product .product_desc {
    line-height: 18px;
    color: #666
}

#new_comment_form .product .product_desc .product_name {
    padding: 10px 0 5px;
    font-size: 13px;
    color: #000
}

#new_comment_form .new_comment_form_content {
    padding: 15px 25px 15px 15px;
    background: #f8f8f8
}

@media (max-width:479px) {
    #new_comment_form .new_comment_form_content {
        padding-left: 25px
    }
}

#new_comment_form .new_comment_form_content .intro_form {
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 12px
}

#new_comment_form label {
    display: block;
    margin: 12px 0 4px 0;
    font-weight: bold;
    font-size: 12px
}

#new_comment_form input,
#new_comment_form textarea {
    padding: 0 5px;
    height: 28px;
    width: 100%;
    border: 1px solid #ccc;
    background: #fff
}

#new_comment_form textarea {
    height: 80px
}

#new_comment_form .submit {
    margin-top: 20px;
    padding: 0;
    font-size: 13px;
    text-align: right
}

#new_comment_form #criterions_list {
    border-bottom: 1px solid #CCC;
    padding-bottom: 15px;
    list-style-type: none
}

#new_comment_form #criterions_list li {
    margin-bottom: 10px
}

#new_comment_form #criterions_list label {
    display: inline;
    float: left;
    margin: 0 10px 0 0
}

#new_comment_form #criterions_list .star_content {
    float: left;
    width: auto
}

#new_comment_form #new_comment_form_footer {
    margin-top: 20px;
    font-size: 12px
}

#product_comments_block_tab {
    margin: 0 0 20px 0
}

#product_comments_block_tab div.comment {
    margin: 0 0 14px;
    padding-top: 14px;
    border-top: 1px solid
}

#product_comments_block_tab div.comment:first-child {
    padding: 0;
    border: none
}

#product_comments_block_tab div.comment .comment_author {
    padding: 0;
    padding-right: 10px;
    line-height: 18px
}

#product_comments_block_tab div.comment .comment_author span {
    font-weight: bold;
    float: left
}

#product_comments_block_tab div.comment .comment_author .star_content {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 5px
}

#product_comments_block_tab div.comment .comment_details {
    overflow: hidden;
    border-left: 1px solid
}

@media (max-width:767px) {
    #product_comments_block_tab div.comment .comment_details {
        border-left: none;
        padding-left: 0;
        padding-top: 5px
    }

    #product_comments_block_tab div.comment .comment_details ul {
        list-style-type: none;
        margin: 0
    }

    #product_comments_block_tab div.comment .comment_details ul li {
        padding: 2px 0 2px
    }

    #product_comments_block_tab div.comment .comment_details ul li:before {
        font-family: "FontAwesome";
        content: "\f0da";
        color: #777;
        margin-right: 8px
    }
}

#product_comments_block_tab .comment_author_infos {
    clear: both
}

#product_comments_block_tab .comment_author_infos strong {
    display: block;
    color: #777
}

#product_comments_block_tab .comment_author_infos em {
    color: #adadad
}

#product_comments_block_tab a {
    text-decoration: none;
    font-weight: bold
}

#product_comments_block_tab span.report_btn {
    cursor: pointer
}

#product_comments_block_tab span.report_btn:hover {
    text-decoration: underline
}

.fl {
    float: left
}

.fr {
    float: right
}

.ac_results {
    text-align: left;
    padding: 0;
    border: 1px solid black;
    background-color: white;
    overflow: hidden;
    z-index: 99999
}

.ac_results ul {
    width: 100%;
    list-style-position: outside;
    list-style: none;
    padding: 0;
    margin: 0
}

.ac_results li {
    margin: 0;
    padding: 2px 5px;
    cursor: default;
    display: block;
    font: menu;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden
}

.ac_odd {
    background-color: #eee
}

.ac_over {
    background-color: #0A246A;
    color: white
}

ul.product_list {
    margin-left: 0;
    margin-right: 0;
    position: relative
}

ul.product_list .color-list-container {
    margin-bottom: 12px
}

ul.product_list .color-list-container ul li {
    display: inline-block;
    margin: 1px;
    border: 1px solid #dddddd
}

ul.product_list .color-list-container ul li a {
    display: block;
    width: 23px;
    height: 23px
}

ul.product_list.grid>li {
    margin-bottom: 10px;
    padding: 9px;
    text-align: center
}

ul.product_list.grid>li .product-container {
    position: relative
}

ul.product_list.grid>li .product-container .product-desc {
    display: none
}

ul.product_list.grid>li .product-container .old-price,
ul.product_list.grid>li .product-container .price {
    display: inline
}

ul.product_list.grid>li .product-container .price-percent-reduction {
    display: none
}

ul.product_list.grid>li .product-container .old-price {
    margin-right: 5px
}

@media (min-width:1320px) {
    ul.product_list.grid>li.hovered .product-container {
        box-shadow: rgba(0, 0, 0, 0.17) 0 0 13px;
        position: relative;
        z-index: 10
    }

    ul.product_list.grid>li.hovered .product-container .content_price {
        display: none
    }
}

ul.product_list.grid li.hovered h5 {
    min-height: 30px
}

#blockpack .button-container {
    display: none
}

ul.product_list.list {
    margin-top: -20px;
    margin-bottom: -29px
}

@media (max-width:319px) {
    ul.product_list.list>li .left-block {
        width: 100%
    }
}

ul.product_list.list>li .product-container {
    border-top: 1px solid #dddddd;
    padding: 20px 0 20px
}

ul.product_list.list>li:first-child .product-container {
    border-top: none
}

ul.product_list.list>li h5 {
    padding: 0;
    margin: 0;
    padding-bottom: 5px
}

ul.product_list.list>li .product-desc {
    clear: both;
    margin-bottom: 15px
}

ul.product_list.list>li .product-reference {
    clear: both;
    display: block
}

@media (max-width:999px) {
    ul.product_list.list>li .right-block {
        clear: both
    }
}

ul.product_list.list>li .product-name {
    font-size: 14px
}

ul.product_list.list>li .comments_note {
    margin-bottom: 5px;
    float: left
}

ul.product_list.list>li .comments_note .star_content {
    min-height: 0;
    margin-top: 2px;
    margin-right: 5px
}

ul.product_list.list>li .comments_note .empty_comments {
    display: none
}

ul.product_list.list>li .comments_note .nb-comments {
    overflow: hidden;
    font-style: italic
}

ul.product_list.list>li .functional-buttons {
    visibility: visible;
    opacity: 1;
    padding: 0;
    border-bottom: none;
    border-top: none;
    position: static;
    clear: both;
    margin-bottom: 0;
    background: none
}

ul.product_list.list>li .functional-buttons div {
    float: none;
    border: none !important;
    width: 100%;
    height: 20px
}

@media (max-width:999px) {
    ul.product_list.list>li .functional-buttons div {
        width: 33.33333%;
        float: left;
        padding-left: 5px;
        padding-right: 5px;
        text-align: center
    }
}

ul.product_list.list>li .functional-buttons div a,
ul.product_list.list>li .functional-buttons div label {
    font-size: 11px
}

ul.product_list.list>li .functional-buttons div a:before,
ul.product_list.list>li .functional-buttons div label:before {
    margin-right: 4px
}

@media (max-width:479px) {

    ul.product_list.list>li .functional-buttons div a,
    ul.product_list.list>li .functional-buttons div label {
        font-size: 0
    }
}

ul.product_list.list>li .functional-buttons div.compare a:before {
    margin-right: 7px
}

ul.product_list.list>li .new-label,
ul.product_list.list>li .sale-label,
ul.product_list.list>li .online-label {
    position: static;
    display: inline-block;
    white-space: nowrap;
    margin-bottom: 5px
}

ul.product_list.list>li .online-label {
    background: #f8f8f8
}

@media (min-width:1000px) {
    ul.product_list.list>li .right-block .right-block-content {
        margin: 0;
        border-left: 1px solid #dddddd;
        padding-left: 15px;
        padding-bottom: 16px
    }

    ul.product_list.list>li .right-block .right-block-content>div {
        padding-left: 0;
        padding-right: 0
    }
}

@media (max-width:999px) {
    ul.product_list.list>li .right-block .right-block-content {
        padding-top: 10px;
        clear: both
    }
}

@media (max-width:319px) {
    ul.product_list.list>li .right-block .right-block-content {
        padding-top: 5px
    }
}

ul.product_list.list>li .right-block .right-block-content .content_price {
    padding: 0 10px 10px 10px
}

@media (max-width:999px) {
    ul.product_list.list>li .right-block .right-block-content .content_price {
        text-align: right;
        padding-top: 5px;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 5px
    }
}

@media (max-width:319px) {
    ul.product_list.list>li .right-block .right-block-content .content_price {
        padding-top: 0;
        width: 100%
    }
}

ul.product_list.list>li .right-block .right-block-content .content_price span {
    display: inline-block;
    margin-top: -4px
}

ul.product_list.list>li .right-block .right-block-content .content_price span.old-price {
    margin-right: 8px
}

ul.product_list.list>li .right-block .right-block-content .button-container {
    overflow: hidden;
    padding-bottom: 10px
}

@media (max-width:999px) {
    ul.product_list.list>li .right-block .right-block-content .button-container {
        padding-left: 5px;
        padding-right: 0
    }
}

@media (max-width:319px) {
    ul.product_list.list>li {
        text-align: center
    }
}

ul.product_list.table {
    margin-top: -20px;
    margin-bottom: -29px
}

@media (max-width:319px) {
    ul.product_list.table>li .left-block {
        width: 100%
    }
}

ul.product_list.table>li .product-container {
    border-top: 1px solid #dddddd;
    padding: 10px 0;
    display: table;
    width: 100%
}

ul.product_list.table>li .product-container>.row {
    display: table-row
}

ul.product_list.table>li .product-image-container {
    min-width: 60px
}

ul.product_list.table>li:first-child .product-container {
    border-top: none
}

ul.product_list.table>li h5 {
    padding: 0;
    margin: 0;
    padding-bottom: 5px
}

ul.product_list.table>li .product-desc {
    display: none
}

@media (max-width:479px) {
    ul.product_list.table>li .product-flags {
        display: none
    }
}

ul.product_list.table>li .product-reference {
    clear: both;
    display: block
}

@media (max-width:999px) {
    ul.product_list.table>li .right-block {
        clear: both
    }
}

ul.product_list.table>li .product-name {
    font-size: 14px
}

ul.product_list.table>li .comments_note {
    margin-bottom: 5px
}

ul.product_list.table>li .comments_note .star_content {
    min-height: 0;
    margin: 3px 0;
    margin-bottom: 0
}

ul.product_list.table>li .comments_note .empty_comments {
    display: none
}

ul.product_list.table>li .comments_note .nb-comments {
    overflow: hidden;
    font-style: italic
}

ul.product_list.table>li .color-list-container ul li a {
    width: 18px;
    height: 18px
}

ul.product_list.table>li .functional-buttons {
    visibility: visible;
    opacity: 1;
    padding: 0;
    border-bottom: none;
    border-top: none;
    position: static;
    clear: both;
    margin-bottom: 0;
    background: none
}

ul.product_list.table>li .functional-buttons div {
    border: none !important;
    padding: 0 2px
}

ul.product_list.table>li .functional-buttons div.quickview a {
    font-size: 0
}

ul.product_list.table>li .functional-buttons div.compare {
    padding-left: 5px
}

ul.product_list.table>li .new-label,
ul.product_list.table>li .sale-label,
ul.product_list.table>li .online-label {
    position: static;
    display: inline-block;
    white-space: nowrap;
    margin-bottom: 5px
}

ul.product_list.table>li .online-label {
    background: #f8f8f8
}

ul.product_list.table>li .left-block,
ul.product_list.table>li .center-block,
ul.product_list.table>li .right-block {
    display: table-cell;
    vertical-align: middle;
    float: none
}

ul.product_list.table>li .price-percent-reduction {
    white-space: nowrap
}

ul.product_list.table>li .right-block-content-inner {
    display: -webkit-flex;
    -webkit-align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
}

ul.product_list.table>li .right-block {
    margin-left: -10px;
    margin-right: -10px
}

ul.product_list.table>li .right-block .right-block-content {
    text-align: center;
    width: 50%;
    padding: 0 10px;
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    border-left: 1px solid #dddddd
}

@media (max-width:767px) {
    ul.product_list.table>li .right-block .right-block-content {
        width: 100%;
        height: auto;
        display: block
    }
}

ul.product_list.table>li .right-block .right-block-content .button-container {
    padding-bottom: 10px
}

@media (min-width:1000px) and (max-width:1319px) {

    .show-left-column.show-right-column ul.product_list.list>li .functional-buttons div a,
    .show-left-column.show-right-column ul.product_list.list>li .functional-buttons div label {
        font-size: 0
    }

    .show-left-column.show-right-column ul.product_list.list>li .functional-buttons div {
        display: inline-block;
        width: auto
    }
}

#search_block_top_content {
    height: 140px;
    position: relative
}

@media (max-width:767px) {
    #search_block_top_content {
        height: auto
    }
}

#search_block_top_content .iqitsearch-inner p {
    margin: 0
}

@media (max-width:767px) {
    #search_block_top_content .iqitsearch-inner {
        margin-top: 10px
    }
}

@media (min-width:768px) {
    #search_block_top_content .iqitsearch-inner {
        width: 100%;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }
}

#search_block_top {
    margin: 0 auto;
    position: relative;
    height: 40px;
    width: 85%
}

@media (max-width:767px) {
    #search_block_top {
        top: 0
    }
}

@media (min-width:768px) {
    .disable_center #search_block_top {
        margin-left: 0;
        margin-right: 0
    }
}

#search_block_top .search_query:focus {
    outline: 0
}

.iqit-search-shower {
    position: relative;
    max-width: 80px;
    width: 6%;
    margin-top: 40px;
    float: right;
    line-height: 12px;
    text-align: center;
    vertical-align: top
}

.iqit-search-shower #search_block_top.showedSearch {
    visibility: visible;
    opacity: 1
}

.iqit-search-shower-i {
    cursor: pointer
}

.iqit-search-shower-i .icon-search {
    font-size: 42px;
    line-height: 40px;
    display: block;
    text-align: center;
    top: -1px;
    position: relative;
    margin-bottom: 8px
}

.iqit-search-shower-i.showedSearch .icon-search:before {
    content: '\f00d'
}

#search_block_top .search_query_container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%
}

#search_block_top.iqit-search-c {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 300px;
    z-index: 5;
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out
}

@media (max-width:767px) {
    #search_block_top.iqit-search-c {
        right: auto
    }
}

.search_block_top .search-cat-select {
    height: 40px
}

.search_block_top .search-cat-selector {
    position: absolute;
    left: 0;
    right: 0;
    width: 130px
}

.search_block_top .search-cat-selector .selector {
    height: 40px;
    width: 130px !important;
    line-height: 40px;
    border: none !important;
    background: none
}

.search_block_top .search-cat-selector .selector span {
    background: none;
    height: 40px;
    line-height: 40px;
    text-shadow: none
}

.search_block_top .search-cat-selector .selector span:after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    line-height: 42px;
    padding-right: 11px;
    content: "\f107";
    font-family: "FontAwesome"
}

.search_block_top .search_query {
    height: 40px
}

.search_block_top .search-w-selector .search_query {
    padding-left: 135px
}

.search_block_top .button-search {
    background: none;
    border: none;
    position: absolute;
    right: 4px;
    top: 11px;
    font-size: 0
}

.search_block_top .button-search:focus {
    outline: 0
}

.search_block_top .button-search span {
    display: none
}

.search_block_top .button-search::before {
    content: "\f002";
    display: block;
    font-family: "FontAwesome";
    font-size: 16px;
    color: #666;
    width: 100%;
    text-align: center
}

.search_block_top .button-search :hover {
    color: #6f6f6f
}

.mh-search-module>form {
    position: relative
}

.ac_results {
    border: 1px solid #CECECE;
    margin-top: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    color: 3C3C3C;
    z-index: 999999;
    background-color: white;
    overflow: hidden;
    z-index: 99999;
    text-align: left;
    padding: 0
}

.ac_odd {
    background: #fff
}

.ac_over {
    background: #f3f3f3;
    color: #3C3C3C
}

.ac_results li {
    border-bottom: 1px dashed #D8D8D8;
    cursor: pointer !important
}

.ac_results li:last-child {
    border: none
}

.ac_results li img {
    display: inline;
    vertical-align: middle;
    border: 1px solid #D8D8D8
}

.ac_results .prname {
    display: inline-block;
    vertical-align: middle;
    margin-left: -10px
}

.ac_results .prprice {
    color: #f13340
}

#ac_search_more {
    padding: 5px 0;
    cursor: pointer !important
}

#cookielaw {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 100%;
    padding: 13px 0;
    border-top: 1px solid #cecece;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
    zoom: 1;
    display: none;
    filter: alpha(opacity=70);
    opacity: 0.7;
    text-align: left !important;
    transition: all 1s ease;
    z-index: 5000
}

#cookielaw:hover {
    filter: alpha(opacity=90);
    opacity: 0.9
}

#cookielaw .container {
    padding-bottom: 0
}

#cookielaw p {
    padding: 6px 0;
    margin-right: 15px
}

#cookie_close {
    float: right
}

#footer1 #manufacturers_slider {
    border-bottom: 1px solid #cecece;
    margin: 0 10px
}

#manufacturers_slider {
    padding: 20px 0;
    border-top: 1px solid #dddddd
}


#manufacturers_slider li {
    list-style-type: none;
    padding-bottom: 0 !important
}

#manufacturers_slider li a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

#manufacturers_slider li a img {
    margin: 0 auto;
    opacity: 0.6
}

#manufacturers_slider li a:hover img {
    opacity: 1.0
}

#header .shopping_cart {
    position: relative;
    float: right;
    padding-top: 44px
}

#header .shopping_cart>a {
    text-align: right
}

@media (max-width:767px) {
    #header .shopping_cart {
        float: none;
        margin: 0 auto;
        padding-top: 10px
    }

    #header .shopping_cart>a {
        text-align: center
    }
}

#shopping_cart_container {
    float: right
}

@media (min-width:1470px) {
    #shopping_cart_container.stickCart {
        position: fixed;
        right: 0;
        top: 4px;
        width: auto;
        z-index: 5002
    }

    #shopping_cart_container.stickCart .cart_block {
        top: 30px !important
    }

    #shopping_cart_container.stickCart .shopping_cart {
        padding-top: 0
    }

    #shopping_cart_container.stickCart .more_info {
        display: none
    }
}

@media (max-width:767px) {
    #shopping_cart_container {
        float: none
    }
}

@media (max-width:320px) {
    .shopping_cart {
        padding-top: 20px
    }
}

.shopping_cart>a:first-child {
    display: block;
    color: #777777;
    float: right;
    line-height: 16px;
    overflow: hidden
}

@media (max-width:767px) {
    .shopping_cart>a:first-child {
        float: none
    }
}

.shopping_cart>a:first-child span.cart_name {
    color: #fff;
    padding-right: 5px;
    padding: 5px 10px 6px 10px;
    display: inline-block;
    background: #333;
    text-transform: uppercase
}

.shopping_cart>a:first-child span.cart_name:before {
    content: "\f07a";
    font-family: "FontAwesome";
    display: inline-block;
    font-size: 16px;
    color: #fff;
    padding-right: 10px;
    float: left
}

.shopping_cart .more_info {
    background: #eeeeee;
    padding: 5px 10px 6px 10px;
    display: inline-block
}

@media (max-width:767px) {
    .shopping_cart .more_info {
        text-align: center
    }
}

.cart_block .cart_block_list .remove_link {
    position: absolute;
    right: 10px;
    top: 14px
}

.cart_block .cart_block_list .remove_link a,
.cart_block .cart_block_list .ajax_cart_block_remove_link,
.cart_block .cart_block_list .delete_voucher {
    display: block;
    width: 100%;
    height: 100%
}

.cart_block .cart_block_list .remove_link a:before,
.cart_block .cart_block_list .ajax_cart_block_remove_link:before,
.cart_block .cart_block_list .delete_voucher:before {
    display: inline-block;
    content: "\f00d";
    font-family: "FontAwesome";
    font-size: 10px;
    line-height: 18px
}

.cart_block .cart-images {
    float: left;
    margin-right: 20px
}

.cart_block .cart-info {
    overflow: hidden;
    position: relative;
    padding-right: 20px
}

.cart_block .cart-info .product-name {
    padding-bottom: 0;
    font-size: 12px;
    line-height: 14px
}

.cart_block .cart-info .product-name a {
    font-size: 12px;
    line-height: 14px;
    display: inline-block
}

.cart_block .cart-info .quantity-formated {
    display: inline-block;
    padding-right: 5px
}

.cart_block dt {
    font-weight: normal;
    overflow: hidden;
    padding: 20px 10px 16px 20px;
    position: relative;
    border-top: 1px dashed #dddddd
}

.cart_block dt:first-child {
    border: none
}

.cart_block dd {
    position: relative
}

.cart_block dd .cart_block_customizations {
    border-top: 1px dashed #333
}

.cart_block dd .cart_block_customizations li {
    padding: 10px 20px
}

.cart_block dd .cart_block_customizations li .deleteCustomizableProduct {
    position: absolute;
    right: 10px
}

.cart_block .cart_block_no_products {
    margin: 0;
    padding: 10px 20px
}

.cart_block .cart-prices {
    border-top: 1px solid #dddddd;
    padding: 10px 20px 10px 20px
}

.cart_block .cart-prices .cart-prices-line {
    overflow: hidden;
    border-bottom: 1px dashed #dddddd;
    padding: 7px 0
}

.cart_block .cart-prices .cart-prices-line.last-line {
    font-weight: bold;
    border: none
}

.cart_block .cart-buttons {
    overflow: hidden;
    padding: 20px 20px 10px 20px;
    margin: 0;
    background: #f6f6f6
}

.cart_block .cart-buttons a {
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 10px;
    margin-right: 10px
}

.cart_block .cart-buttons a#button_order_cart {
    margin-right: 0
}

.cart_block .cart-buttons a#button_order_cart span {
    padding: 7px 0;
    font-size: 1.1em
}

#header .cart-buttons {
    border-top: 1px solid #dddddd
}

#header .cart_block {
    position: absolute;
    top: 75px;
    right: 0;
    z-index: 100;
    display: none;
    height: auto;
    background: #fff;
    width: 320px;
    border: 1px solid #D8D8D8;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15)
}

@media (max-width:767px) {
    #header .cart_block {
        top: 45px;
        width: 100%
    }
}

#header .cart_block .cart_block_list .remove_link a,
#header .cart_block .cart_block_list .ajax_cart_block_remove_link {
    outline: none;
    text-decoration: none
}

#header .cart_block .cart_block_list dl.products {
    margin-bottom: 0;
    max-height: 360px;
    overflow-x: hidden;
    overflow-y: auto
}

#header .cart_block .cart_block_list dl.products::-webkit-scrollbar {
    width: 8px
}

#header .cart_block .cart_block_list dl.products::-webkit-scrollbar-track {
    background-color: #eaeaea;
    border-left: 1px solid #ccc
}

#header .cart_block .cart_block_list dl.products::-webkit-scrollbar-thumb {
    background-color: #ccc
}

#header .cart_block .cart_block_list dl.products::-webkit-scrollbar-thumb:hover {
    background-color: #aaa
}

#header .cart_block .cart-images img {
    border: 1px solid #ddd
}

#header .cart_block .product-atributes {
    font-size: 11px
}

#header .block_content {
    margin-bottom: 0
}

.cart_block #cart_block_summary,
.cart_block .title_block span,
#header .cart_block h4,
#header .cart_block .title_block,
#header .cart_block h4 {
    display: none
}

.cart_block .cart_block_shipping_cost,
.cart_block .cart_block_tax_cost,
.cart_block .cart_block_total,
.cart_block .cart_block_wrapping_cost {
    float: right
}

.cart_block table.vouchers {
    clear: both;
    width: 78%;
    margin: 0 auto;
    margin-bottom: 10px;
    border: 1px dashed #dddddd
}

.cart_block table.vouchers tr td {
    padding: 8px
}

.cart_block table.vouchers tr td.quantity {
    margin-right: 5px;
    min-width: 18px
}

.cart_block table.vouchers tr td.delete {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    width: 16px
}

.layer_cart_overlay {
    background-color: #000;
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5005;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
    opacity: 0.2
}

* html .layer_cart_overlay {
    position: absolute;
    left: 0;
    margin-left: -160px;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
    opacity: 0.2
}

#layer_cart {
    background-color: #fff;
    position: absolute;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    display: none;
    z-index: 5007;
    width: 84%;
    right: 0;
    left: 0;
    max-width: 960px;
    margin: 0 auto;
    border-radius: 4px
}

#layer_cart .layer_cart_title {
    border-bottom: 1px solid #dddddd;
    text-transform: uppercase;
    padding: 10px 20px;
    padding-right: 40px
}

#layer_cart .layer_cart_product {
    padding: 20px;
    overflow: hidden;
    position: static
}

#layer_cart .layer_cart_product h2 {
    font: 400 23px/29px Arial, Helvetica, sans-serif;
    margin-bottom: 22px;
    padding-right: 100px
}

@media (max-width:479px) {
    #layer_cart .layer_cart_product h2 {
        font-size: 18px;
        padding-right: 0;
        line-height: normal
    }
}

#layer_cart .layer_cart_product h2 i {
    font-size: 30px;
    line-height: 30px;
    float: left;
    padding-right: 8px
}

@media (max-width:479px) {
    #layer_cart .layer_cart_product h2 i {
        font-size: 22px;
        line-height: 22px
    }
}

#layer_cart .layer_cart_product .product-image-container {
    max-width: 178px;
    border: 1px solid #dddddd;
    padding: 5px;
    float: left;
    max-width: 25%
}

#layer_cart .layer_cart_product .layer_cart_product_info {
    float: left;
    max-width: 75%;
    padding-left: 20px
}

#layer_cart .layer_cart_product .layer_cart_product_info #layer_cart_product_title {
    display: block;
    padding-left: 0;
    border-bottom: 1px dotted #dddddd;
    margin-bottom: 9px
}

#layer_cart .layer_cart_product .layer_cart_product_info>div strong {
    padding-right: 3px
}

#layer_cart .layer_cart_cart {
    background: #fafafa;
    border-left: 1px solid #dddddd;
    padding: 20px;
    position: relative
}

@media (min-width:1000px) {
    #layer_cart .layer_cart_cart {
        min-height: 160px
    }
}

@media (max-width:767px) {
    #layer_cart .layer_cart_cart {
        border-left: none;
        border-top: 1px solid #dddddd
    }
}

#layer_cart .layer_cart_cart .overall_cart_title {
    border-bottom: 1px dotted #dddddd;
    line-height: 23px;
    margin-top: 0
}

#layer_cart .button-container {
    border-top: 1px solid #dddddd;
    padding: 20px;
    padding-bottom: 10px
}

#layer_cart .button-container .btn {
    margin-bottom: 10px
}

#layer_cart .button-container span.exclusive-medium {
    margin-right: 5px
}

#layer_cart .button-container span.exclusive-medium i {
    padding-right: 5px
}

#layer_cart .cross {
    position: absolute;
    right: 13px;
    top: 17px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    z-index: 2
}

#layer_cart .cross:before {
    content: "\f00d";
    display: block;
    font-family: "FontAwesome";
    font-size: 16px;
    line-height: 16px
}

#layer_cart .continue {
    cursor: pointer
}

#layer_cart p {
    padding: 0
}

#layer_cart .crossseling .crossseling-content {
    border-top: 1px solid #dddddd;
    position: relative;
    padding: 20px
}

#layer_cart .crossseling h5.crossseling_pop_title {
    text-transform: uppercase;
    padding: 0
}

#layer_cart .crossseling #blockcart_list {
    max-width: 773px;
    width: 84%;
    margin: 0 auto;
    overflow: hidden
}

#layer_cart .crossseling #blockcart_list ul {
    display: block
}

#layer_cart .crossseling #blockcart_list ul li {
    float: left;
    width: 178px;
    margin: 0 0 0 0
}

#layer_cart .crossseling #blockcart_list ul li .product-image-container {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 5px;
    margin-bottom: 15px
}

#layer_cart .crossseling #blockcart_list ul li .product-image-container img {
    width: 100%
}

#layer_cart .crossseling #blockcart_list ul li .product-name {
    padding-bottom: 5px
}

#layer_cart .crossseling #blockcart_list ul li .product-name a {
    font-size: 15px;
    line-height: 20px
}

#layer_cart .crossseling #blockcart_list a.bx-prev,
#layer_cart .crossseling #blockcart_list a.bx-next {
    display: block;
    font-family: "FontAwesome";
    font-size: 20px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    color: #777;
    text-indent: -5000px;
    position: absolute;
    top: 45%;
    margin-top: -20px
}

#layer_cart .crossseling #blockcart_list a.bx-prev:after,
#layer_cart .crossseling #blockcart_list a.bx-next:after {
    display: block;
    text-indent: 0
}

#layer_cart .crossseling #blockcart_list a.bx-prev:hover,
#layer_cart .crossseling #blockcart_list a.bx-next:hover {
    color: #c0c0c0
}

#layer_cart .crossseling #blockcart_list a.bx-prev.disabled,
#layer_cart .crossseling #blockcart_list a.bx-next.disabled {
    display: none
}

#layer_cart .crossseling #blockcart_list a.bx-prev {
    left: 5%
}

#layer_cart .crossseling #blockcart_list a.bx-prev:after {
    content: "\f137"
}

#layer_cart .crossseling #blockcart_list a.bx-next {
    right: 5%
}

#layer_cart .crossseling #blockcart_list a.bx-next:after {
    content: "\f138"
}

#layer_cart .crossseling #blockcart_list .bx-controls {
    overflow: hidden
}

.bx-wrapper {
    position: relative;
    padding: 0;
    zoom: 1;
    margin: 0 !important
}

.bx-wrapper img {
    max-width: 100%;
    display: block
}

.bx-viewport {
    direction: ltr !important
}

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
    position: absolute;
    bottom: -30px;
    width: 100%
}

.bx-wrapper .bx-loading {
    min-height: 50px;
    background: url(https://warehouse.iqit-commerce.com/js/jquery/plugins/bxslider/images/bx_loader.gif) center center no-repeat;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
    display: none
}

.bx-wrapper .bx-pager {
    text-align: center;
    font-size: .85em;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #666;
    padding-top: 20px
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
    display: inline-block;
    zoom: 1
}

.bx-wrapper .bx-pager.bx-default-pager a {
    text-indent: -9999px;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background: #000;
    outline: 0;
    border-radius: 5px
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #000
}

.bx-wrapper .bx-controls-direction a {
    margin-top: 38px;
    height: 20px;
    width: 20px;
    line-height: 0;
    position: absolute;
    top: 40%;
    margin-top: -10px;
    font-size: 0;
    overflow: hidden;
    outline: none;
    text-decoration: none
}

.bx-wrapper .bx-controls-direction a:before {
    padding-left: 2px;
    color: #c0c0c0;
    font-family: "FontAwesome";
    font-size: 20px;
    line-height: 22px
}

.bx-wrapper .bx-controls-direction a:hover:before {
    color: #333
}

.bx-next {
    right: 10px
}

.bx-next:before {
    content: "\f138"
}

.bx-prev {
    left: 10px
}

.bx-prev:before {
    content: "\f137"
}

.bx-wrapper .bx-controls-direction a.disabled {
    display: none
}

.bx-wrapper .bx-controls-auto {
    text-align: center
}

.bx-wrapper .bx-controls-auto .bx-start {
    display: block;
    text-indent: -9999px;
    width: 10px;
    height: 11px;
    outline: 0;
    background: url(https://warehouse.iqit-commerce.com/js/jquery/plugins/bxslider/images/controls.png) -86px -11px no-repeat;
    margin: 0 3px
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
    background-position: -86px 0
}

.bx-wrapper .bx-controls-auto .bx-stop {
    display: block;
    text-indent: -9999px;
    width: 9px;
    height: 11px;
    outline: 0;
    background: url(https://warehouse.iqit-commerce.com/js/jquery/plugins/bxslider/images/controls.png) -86px -44px no-repeat;
    margin: 0 3px
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
    background-position: -86px -33px
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
    text-align: left;
    width: 80%
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
    right: 0;
    width: 35px
}

.bx-wrapper .bx-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #666;
    background: rgba(80, 80, 80, 0.75);
    width: 100%
}

.bx-wrapper .bx-caption span {
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    display: block;
    font-size: .85em;
    padding: 10px
}

.ph_simpleblog {
    width: 100%;
    height: 100%
}

.ph_simpleblog .ph_row,
.ph_simpleblog:after,
.ph_simpleblog:before {
    content: "";
    display: table
}

.ph_simpleblog .ph_col {
    float: left;
    padding-left: 15px;
    padding-right: 15px
}

.ph_simpleblog .ph_row {
    margin-left: -15px;
    margin-right: -15px;
    width: 100%
}

.ph_simpleblog .ph_row:after,
.ph_simpleblog .ph_row:before {
    content: "";
    display: table
}

.ph_simpleblog .ph_row:after {
    clear: both
}

.ph_simpleblog .ph_col_2 {
    width: 50%
}

.ph_simpleblog .ph_col_3 {
    width: 33%
}

.ph_simpleblog .ph_col_4 {
    width: 25%
}

.ph_simpleblog .ph_cat_description {
    margin: 20px 0;
    padding: 15px;
    border: 1px solid #dddddd
}

.ph_simpleblog .ph_cat_description p:last-child {
    margin-bottom: 0
}

.ph_simpleblog figure {
    margin: 0
}

.ph_simpleblog .simpleblog-category-image {
    margin-bottom: 20px
}

.ph_simpleblog.simpleblog-related-posts h2,
.ph_simpleblog.simpleblog-home h2,
.ph_simpleblog.simpleblog-recent h2,
.ph_simpleblog.simpleblog-category h2 {
    margin: 10px 0;
    padding: 0
}

.ph_simpleblog.simpleblog-related-posts .post-additional-info span,
.ph_simpleblog.simpleblog-home .post-additional-info span,
.ph_simpleblog.simpleblog-recent .post-additional-info span,
.ph_simpleblog.simpleblog-category .post-additional-info span {
    display: block
}

.ph_simpleblog.simpleblog-related-posts p.title_block,
.ph_simpleblog.simpleblog-home p.title_block,
.ph_simpleblog.simpleblog-recent p.title_block,
.ph_simpleblog.simpleblog-category p.title_block {
    padding-bottom: 6px;
    margin-bottom: 10px
}

.ph_simpleblog.simpleblog-related-posts .post-item,
.ph_simpleblog.simpleblog-home .post-item,
.ph_simpleblog.simpleblog-recent .post-item,
.ph_simpleblog.simpleblog-category .post-item {
    margin-bottom: 10px;
    float: left;
    width: 100%
}

.ph_simpleblog.simpleblog-related-posts .post-thumbnail img,
.ph_simpleblog.simpleblog-home .post-thumbnail img,
.ph_simpleblog.simpleblog-recent .post-thumbnail img,
.ph_simpleblog.simpleblog-category .post-thumbnail img {
    margin: 0 auto
}

.ph_simpleblog.simpleblog-single .post-meta-info {
    border-bottom: 1px solid #CECECE;
    margin-top: -10px;
    padding-bottom: 5px;
    margin-bottom: 20px
}

.ph_simpleblog.simpleblog-single .post-content img {
    display: block;
    max-width: 100%;
    height: auto
}

.ph_simpleblog.simpleblog-single .post-block {
    margin-top: 20px
}

.ph_simpleblog.simpleblog-single h1 {
    position: relative
}

.ph_simpleblog.simpleblog-single h1 .blog-post-likes {
    position: absolute;
    right: 0;
    top: 0;
    padding: 2px 6px;
    text-align: center;
    cursor: pointer;
    font-size: 11px
}

.ph_simpleblog.simpleblog-single h1 .blog-post-likes:hover {
    background: #333;
    color: #f0f0f0
}

.ph_simpleblog.simpleblog-single h1 .blog-post-likes .txt {
    font-size: 11px
}

.ph_simpleblog.simpleblog-single .post-additional-info {
    margin-bottom: 10px
}

.ph_simpleblog .post-additional-info {
    padding: 5px 0 0 0;
    background: #efefef;
    width: 100%;
    text-align: center
}

.ph_simpleblog .post-additional-info span {
    display: block
}

.ph_simpleblog .simpleblog-posts .post-item {
    width: 100%;
    position: relative;
    text-align: center;
    transition: all 0.3s ease
}

.ph_simpleblog .simpleblog-posts .post-item:hover .post-content {
    background: none
}

.ph_simpleblog .simpleblog-posts .post-item:hover .post-thumbnail {
    filter: alpha(opacity=85);
    opacity: 0.85
}

.ph_simpleblog .simpleblog-posts h2 {
    font-size: 14px;
    text-align: center;
    margin: 0;
    padding: 10px 0;
    text-transform: uppercase
}

.ph_simpleblog .simpleblog-posts .post-thumbnail {
    position: relative;
    transition: all 0.3s ease
}

.ph_simpleblog .simpleblog-posts .post-thumbnail h2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.6)
}

.ph_simpleblog .simpleblog-posts .post-thumbnail h2 a {
    color: #fff !important
}

.ph_simpleblog .simpleblog-posts .post-title h2 {
    border: 1px solid #dddddd;
    border-left: none;
    border-right: none
}

.ph_simpleblog .simpleblog-posts .post-content {
    background: #f8f8f8;
    padding: 10px;
    transition: all 0.3s ease
}

.ph_simpleblog .simpleblog-posts .post-content p:last-child {
    margin-bottom: 0
}

.ph_simpleblog .simpleblog-posts .post-additional-info {
    padding: 5px;
    background: none;
    border-top: 1px solid #dddddd;
    font-size: 10px;
    text-align: center
}

.ph_simpleblog .simpleblog-posts .post-additional-info span {
    display: inline-block;
    border-right: 1px dotted #dddddd;
    padding-right: 8px;
    margin-right: 8px
}

.ph_simpleblog .simpleblog-posts .post-additional-info span:last-child {
    border: none;
    margin: 0;
    padding: 0
}

.ph_simpleblog .simpleblog-posts .post-additional-info span.post-author {
    clear: both
}

.ph_simpleblog .simpleblog-posts p {
    font-size: 12px
}

.ph_simpleblog .simpleblog-posts a.more {
    font-size: 12px;
    clear: both;
    float: right;
    margin: 10px 0
}

.ph_simpleblog .simpleblog-posts.related h2 {
    font-size: 22px;
    line-height: 24px
}

.ph_simpleblog .simpleblog-posts.related .post-additional-info span {
    float: none;
    border-left: 0;
    margin-left: 0;
    padding-left: 0
}

.ph_simpleblog .post_cover {
    display: block;
    margin: 0 -15px
}

.simpleblog-single.with-cover .cover {
    position: relative;
    margin: 0 0 30px 0
}

.simpleblog-single.with-cover .cover .info h1 {
    margin-top: 10px
}

.simpleblog-single.with-cover .cover .info h1 .blog-post-likes {
    padding: 2px 25px;
    color: #000;
    background: none
}

.simpleblog-single.with-cover .cover .info h1 .blog-post-likes .txt {
    line-height: 16px
}

.simpleblog-single.with-cover .cover .info h1 .blog-post-likes:hover {
    background: #222;
    color: #fff
}

.simpleblog-single .post-additional-info {
    background: #fff;
    font-size: 12px;
    color: #a1a1a1;
    overflow: hidden;
    clear: both;
    margin: -7px 0 0 10px;
    padding: 5px 10px;
    width: auto;
    float: left
}

.simpleblog-single .post-additional-info a {
    color: #a1a1a1
}

.simpleblog-single .post-additional-info span {
    float: left
}

.simpleblog-single .post-additional-info span.post-author,
.simpleblog-single .post-additional-info span.post-category,
.simpleblog-single .post-additional-info span.post-tags {
    border-left: 1px solid #a1a1a1;
    margin-left: 10px;
    padding-left: 10px
}

.simpleblog-single div.post-content {
    margin-bottom: 30px
}

.simpleblog-single div.post-content p {
    margin-bottom: 10px
}

.simpleblog-single div.col-md-10 div.post-additional-info {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    margin: 0 0 10px 0;
    padding: 5px 0;
    width: 100%
}

.simpleblog-single div.post-share-buttons div,
.simpleblog-single div.post-share-buttons iframe {
    margin-bottom: 10px
}

#blog_for_prestashop_column .products-block-image {
    margin-bottom: 10px;
    display: block
}

@font-face {
    font-family: 'FontAwesome';
    src: url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/fonts/font-awesome/css/../../../../../fonts/font-awesome/fontawesome-webfont.eot?v=4.2.0');
    src: url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/fonts/font-awesome/css/../../../../../fonts/font-awesome/fontawesome-webfont.eot?#iefix&v=4.2.0') format('embedded-opentype'), url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/fonts/font-awesome/css/../../../../../fonts/font-awesome/fontawesome-webfont.woff?v=4.2.0') format('woff'), url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/fonts/font-awesome/css/../../../../../fonts/font-awesome/fontawesome-webfont.ttf?v=4.2.0') format('truetype'), url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/fonts/font-awesome/css/../../../../../fonts/font-awesome/fontawesome-webfont.svg?v=4.2.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal
}

[class^="fa-icon-"],
[class*=" fa-icon-"] {
    display: inline-block;
    font-family: 'FontAwesome';
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block
}

.fa-icon-spin {
    -webkit-animation: fa-icon-spin 2s infinite linear;
    animation: fa-icon-spin 2s infinite linear
}

.fa-icon-rotate-90 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg)
}

.fa-icon-rotate-180 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
}

.fa-icon-rotate-270 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg)
}

.fa-icon-flip-horizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1)
}

.fa-icon-flip-vertical {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
    -webkit-transform: scale(1, -1);
    transform: scale(1, -1)
}

:root .fa-icon-rotate-90,
:root .fa-icon-rotate-180,
:root .fa-icon-rotate-270,
:root .fa-icon-flip-horizontal,
:root .fa-icon-flip-vertical {
    -webkit-filter: none;
            filter: none
}

.fa-icon-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle
}

.fa-icon-stack-1x,
.fa-icon-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center
}

.fa-icon-stack-1x {
    line-height: inherit
}

.fa-icon-stack-2x {
    font-size: 2em
}

.fa-icon-inverse {
    color: #ffffff
}

.fa-icon-glass:before {
    content: "\f000"
}

.fa-icon-music:before {
    content: "\f001"
}

.fa-icon-search:before {
    content: "\f002"
}

.fa-icon-envelope-o:before {
    content: "\f003"
}

.fa-icon-heart:before {
    content: "\f004"
}

.fa-icon-star:before {
    content: "\f005"
}

.fa-icon-star-o:before {
    content: "\f006"
}

.fa-icon-user:before {
    content: "\f007"
}

.fa-icon-film:before {
    content: "\f008"
}

.fa-icon-th-large:before {
    content: "\f009"
}

.fa-icon-th:before {
    content: "\f00a"
}

.fa-icon-th-list:before {
    content: "\f00b"
}

.fa-icon-check:before {
    content: "\f00c"
}

.fa-icon-remove:before,
.fa-icon-close:before,
.fa-icon-times:before {
    content: "\f00d"
}

.fa-icon-search-plus:before {
    content: "\f00e"
}

.fa-icon-search-minus:before {
    content: "\f010"
}

.fa-icon-power-off:before {
    content: "\f011"
}

.fa-icon-signal:before {
    content: "\f012"
}

.fa-icon-gear:before,
.fa-icon-cog:before {
    content: "\f013"
}

.fa-icon-trash-o:before {
    content: "\f014"
}

.fa-icon-home:before {
    content: "\f015"
}

.fa-icon-file-o:before {
    content: "\f016"
}

.fa-icon-clock-o:before {
    content: "\f017"
}

.fa-icon-road:before {
    content: "\f018"
}

.fa-icon-download:before {
    content: "\f019"
}

.fa-icon-arrow-circle-o-down:before {
    content: "\f01a"
}

.fa-icon-arrow-circle-o-up:before {
    content: "\f01b"
}

.fa-icon-inbox:before {
    content: "\f01c"
}

.fa-icon-play-circle-o:before {
    content: "\f01d"
}

.fa-icon-rotate-right:before,
.fa-icon-repeat:before {
    content: "\f01e"
}

.fa-icon-refresh:before {
    content: "\f021"
}

.fa-icon-list-alt:before {
    content: "\f022"
}

.fa-icon-lock:before {
    content: "\f023"
}

.fa-icon-flag:before {
    content: "\f024"
}

.fa-icon-headphones:before {
    content: "\f025"
}

.fa-icon-volume-off:before {
    content: "\f026"
}

.fa-icon-volume-down:before {
    content: "\f027"
}

.fa-icon-volume-up:before {
    content: "\f028"
}

.fa-icon-qrcode:before {
    content: "\f029"
}

.fa-icon-barcode:before {
    content: "\f02a"
}

.fa-icon-tag:before {
    content: "\f02b"
}

.fa-icon-tags:before {
    content: "\f02c"
}

.fa-icon-book:before {
    content: "\f02d"
}

.fa-icon-bookmark:before {
    content: "\f02e"
}

.fa-icon-print:before {
    content: "\f02f"
}

.fa-icon-camera:before {
    content: "\f030"
}

.fa-icon-font:before {
    content: "\f031"
}

.fa-icon-bold:before {
    content: "\f032"
}

.fa-icon-italic:before {
    content: "\f033"
}

.fa-icon-text-height:before {
    content: "\f034"
}

.fa-icon-text-width:before {
    content: "\f035"
}

.fa-icon-align-left:before {
    content: "\f036"
}

.fa-icon-align-center:before {
    content: "\f037"
}

.fa-icon-align-right:before {
    content: "\f038"
}

.fa-icon-align-justify:before {
    content: "\f039"
}

.fa-icon-list:before {
    content: "\f03a"
}

.fa-icon-dedent:before,
.fa-icon-outdent:before {
    content: "\f03b"
}

.fa-icon-indent:before {
    content: "\f03c"
}

.fa-icon-video-camera:before {
    content: "\f03d"
}

.fa-icon-photo:before,
.fa-icon-image:before,
.fa-icon-picture-o:before {
    content: "\f03e"
}

.fa-icon-pencil:before {
    content: "\f040"
}

.fa-icon-map-marker:before {
    content: "\f041"
}

.fa-icon-adjust:before {
    content: "\f042"
}

.fa-icon-tint:before {
    content: "\f043"
}

.fa-icon-edit:before,
.fa-icon-pencil-square-o:before {
    content: "\f044"
}

.fa-icon-share-square-o:before {
    content: "\f045"
}

.fa-icon-check-square-o:before {
    content: "\f046"
}

.fa-icon-arrows:before {
    content: "\f047"
}

.fa-icon-step-backward:before {
    content: "\f048"
}

.fa-icon-fast-backward:before {
    content: "\f049"
}

.fa-icon-backward:before {
    content: "\f04a"
}

.fa-icon-play:before {
    content: "\f04b"
}

.fa-icon-pause:before {
    content: "\f04c"
}

.fa-icon-stop:before {
    content: "\f04d"
}

.fa-icon-forward:before {
    content: "\f04e"
}

.fa-icon-fast-forward:before {
    content: "\f050"
}

.fa-icon-step-forward:before {
    content: "\f051"
}

.fa-icon-eject:before {
    content: "\f052"
}

.fa-icon-chevron-left:before {
    content: "\f053"
}

.fa-icon-chevron-right:before {
    content: "\f054"
}

.fa-icon-plus-circle:before {
    content: "\f055"
}

.fa-icon-minus-circle:before {
    content: "\f056"
}

.fa-icon-times-circle:before {
    content: "\f057"
}

.fa-icon-check-circle:before {
    content: "\f058"
}

.fa-icon-question-circle:before {
    content: "\f059"
}

.fa-icon-info-circle:before {
    content: "\f05a"
}

.fa-icon-crosshairs:before {
    content: "\f05b"
}

.fa-icon-times-circle-o:before {
    content: "\f05c"
}

.fa-icon-check-circle-o:before {
    content: "\f05d"
}

.fa-icon-ban:before {
    content: "\f05e"
}

.fa-icon-arrow-left:before {
    content: "\f060"
}

.fa-icon-arrow-right:before {
    content: "\f061"
}

.fa-icon-arrow-up:before {
    content: "\f062"
}

.fa-icon-arrow-down:before {
    content: "\f063"
}

.fa-icon-mail-forward:before,
.fa-icon-share:before {
    content: "\f064"
}

.fa-icon-expand:before {
    content: "\f065"
}

.fa-icon-compress:before {
    content: "\f066"
}

.fa-icon-plus:before {
    content: "\f067"
}

.fa-icon-minus:before {
    content: "\f068"
}

.fa-icon-asterisk:before {
    content: "\f069"
}

.fa-icon-exclamation-circle:before {
    content: "\f06a"
}

.fa-icon-gift:before {
    content: "\f06b"
}

.fa-icon-leaf:before {
    content: "\f06c"
}

.fa-icon-fire:before {
    content: "\f06d"
}

.fa-icon-eye:before {
    content: "\f06e"
}

.fa-icon-eye-slash:before {
    content: "\f070"
}

.fa-icon-warning:before,
.fa-icon-exclamation-triangle:before {
    content: "\f071"
}

.fa-icon-plane:before {
    content: "\f072"
}

.fa-icon-calendar:before {
    content: "\f073"
}

.fa-icon-random:before {
    content: "\f074"
}

.fa-icon-comment:before {
    content: "\f075"
}

.fa-icon-magnet:before {
    content: "\f076"
}

.fa-icon-chevron-up:before {
    content: "\f077"
}

.fa-icon-chevron-down:before {
    content: "\f078"
}

.fa-icon-retweet:before {
    content: "\f079"
}

.fa-icon-shopping-cart:before {
    content: "\f07a"
}

.fa-icon-folder:before {
    content: "\f07b"
}

.fa-icon-folder-open:before {
    content: "\f07c"
}

.fa-icon-arrows-v:before {
    content: "\f07d"
}

.fa-icon-arrows-h:before {
    content: "\f07e"
}

.fa-icon-bar-chart-o:before,
.fa-icon-bar-chart:before {
    content: "\f080"
}

.fa-icon-twitter-square:before {
    content: "\f081"
}

.fa-icon-facebook-square:before {
    content: "\f082"
}

.fa-icon-camera-retro:before {
    content: "\f083"
}

.fa-icon-key:before {
    content: "\f084"
}

.fa-icon-gears:before,
.fa-icon-cogs:before {
    content: "\f085"
}

.fa-icon-comments:before {
    content: "\f086"
}

.fa-icon-thumbs-o-up:before {
    content: "\f087"
}

.fa-icon-thumbs-o-down:before {
    content: "\f088"
}

.fa-icon-star-half:before {
    content: "\f089"
}

.fa-icon-heart-o:before {
    content: "\f08a"
}

.fa-icon-sign-out:before {
    content: "\f08b"
}

.fa-icon-linkedin-square:before {
    content: "\f08c"
}

.fa-icon-thumb-tack:before {
    content: "\f08d"
}

.fa-icon-external-link:before {
    content: "\f08e"
}

.fa-icon-sign-in:before {
    content: "\f090"
}

.fa-icon-trophy:before {
    content: "\f091"
}

.fa-icon-github-square:before {
    content: "\f092"
}

.fa-icon-upload:before {
    content: "\f093"
}

.fa-icon-lemon-o:before {
    content: "\f094"
}

.fa-icon-phone:before {
    content: "\f095"
}

.fa-icon-square-o:before {
    content: "\f096"
}

.fa-icon-bookmark-o:before {
    content: "\f097"
}

.fa-icon-phone-square:before {
    content: "\f098"
}

.fa-icon-twitter:before {
    content: "\f099"
}

.fa-icon-facebook:before {
    content: "\f09a"
}

.fa-icon-github:before {
    content: "\f09b"
}

.fa-icon-unlock:before {
    content: "\f09c"
}

.fa-icon-credit-card:before {
    content: "\f09d"
}

.fa-icon-rss:before {
    content: "\f09e"
}

.fa-icon-hdd-o:before {
    content: "\f0a0"
}

.fa-icon-bullhorn:before {
    content: "\f0a1"
}

.fa-icon-bell:before {
    content: "\f0f3"
}

.fa-icon-certificate:before {
    content: "\f0a3"
}

.fa-icon-hand-o-right:before {
    content: "\f0a4"
}

.fa-icon-hand-o-left:before {
    content: "\f0a5"
}

.fa-icon-hand-o-up:before {
    content: "\f0a6"
}

.fa-icon-hand-o-down:before {
    content: "\f0a7"
}

.fa-icon-arrow-circle-left:before {
    content: "\f0a8"
}

.fa-icon-arrow-circle-right:before {
    content: "\f0a9"
}

.fa-icon-arrow-circle-up:before {
    content: "\f0aa"
}

.fa-icon-arrow-circle-down:before {
    content: "\f0ab"
}

.fa-icon-globe:before {
    content: "\f0ac"
}

.fa-icon-wrench:before {
    content: "\f0ad"
}

.fa-icon-tasks:before {
    content: "\f0ae"
}

.fa-icon-filter:before {
    content: "\f0b0"
}

.fa-icon-briefcase:before {
    content: "\f0b1"
}

.fa-icon-arrows-alt:before {
    content: "\f0b2"
}

.fa-icon-group:before,
.fa-icon-users:before {
    content: "\f0c0"
}

.fa-icon-chain:before,
.fa-icon-link:before {
    content: "\f0c1"
}

.fa-icon-cloud:before {
    content: "\f0c2"
}

.fa-icon-flask:before {
    content: "\f0c3"
}

.fa-icon-cut:before,
.fa-icon-scissors:before {
    content: "\f0c4"
}

.fa-icon-copy:before,
.fa-icon-files-o:before {
    content: "\f0c5"
}

.fa-icon-paperclip:before {
    content: "\f0c6"
}

.fa-icon-save:before,
.fa-icon-floppy-o:before {
    content: "\f0c7"
}

.fa-icon-square:before {
    content: "\f0c8"
}

.fa-icon-navicon:before,
.fa-icon-reorder:before,
.fa-icon-bars:before {
    content: "\f0c9"
}

.fa-icon-list-ul:before {
    content: "\f0ca"
}

.fa-icon-list-ol:before {
    content: "\f0cb"
}

.fa-icon-strikethrough:before {
    content: "\f0cc"
}

.fa-icon-underline:before {
    content: "\f0cd"
}

.fa-icon-table:before {
    content: "\f0ce"
}

.fa-icon-magic:before {
    content: "\f0d0"
}

.fa-icon-truck:before {
    content: "\f0d1"
}

.fa-icon-pinterest:before {
    content: "\f0d2"
}

.fa-icon-pinterest-square:before {
    content: "\f0d3"
}

.fa-icon-google-plus-square:before {
    content: "\f0d4"
}

.fa-icon-google-plus:before {
    content: "\f0d5"
}

.fa-icon-money:before {
    content: "\f0d6"
}

.fa-icon-caret-down:before {
    content: "\f0d7"
}

.fa-icon-caret-up:before {
    content: "\f0d8"
}

.fa-icon-caret-left:before {
    content: "\f0d9"
}

.fa-icon-caret-right:before {
    content: "\f0da"
}

.fa-icon-columns:before {
    content: "\f0db"
}

.fa-icon-unsorted:before,
.fa-icon-sort:before {
    content: "\f0dc"
}

.fa-icon-sort-down:before,
.fa-icon-sort-desc:before {
    content: "\f0dd"
}

.fa-icon-sort-up:before,
.fa-icon-sort-asc:before {
    content: "\f0de"
}

.fa-icon-envelope:before {
    content: "\f0e0"
}

.fa-icon-linkedin:before {
    content: "\f0e1"
}

.fa-icon-rotate-left:before,
.fa-icon-undo:before {
    content: "\f0e2"
}

.fa-icon-legal:before,
.fa-icon-gavel:before {
    content: "\f0e3"
}

.fa-icon-dashboard:before,
.fa-icon-tachometer:before {
    content: "\f0e4"
}

.fa-icon-comment-o:before {
    content: "\f0e5"
}

.fa-icon-comments-o:before {
    content: "\f0e6"
}

.fa-icon-flash:before,
.fa-icon-bolt:before {
    content: "\f0e7"
}

.fa-icon-sitemap:before {
    content: "\f0e8"
}

.fa-icon-umbrella:before {
    content: "\f0e9"
}

.fa-icon-paste:before,
.fa-icon-clipboard:before {
    content: "\f0ea"
}

.fa-icon-lightbulb-o:before {
    content: "\f0eb"
}

.fa-icon-exchange:before {
    content: "\f0ec"
}

.fa-icon-cloud-download:before {
    content: "\f0ed"
}

.fa-icon-cloud-upload:before {
    content: "\f0ee"
}

.fa-icon-user-md:before {
    content: "\f0f0"
}

.fa-icon-stethoscope:before {
    content: "\f0f1"
}

.fa-icon-suitcase:before {
    content: "\f0f2"
}

.fa-icon-bell-o:before {
    content: "\f0a2"
}

.fa-icon-coffee:before {
    content: "\f0f4"
}

.fa-icon-cutlery:before {
    content: "\f0f5"
}

.fa-icon-file-text-o:before {
    content: "\f0f6"
}

.fa-icon-building-o:before {
    content: "\f0f7"
}

.fa-icon-hospital-o:before {
    content: "\f0f8"
}

.fa-icon-ambulance:before {
    content: "\f0f9"
}

.fa-icon-medkit:before {
    content: "\f0fa"
}

.fa-icon-fighter-jet:before {
    content: "\f0fb"
}

.fa-icon-beer:before {
    content: "\f0fc"
}

.fa-icon-h-square:before {
    content: "\f0fd"
}

.fa-icon-plus-square:before {
    content: "\f0fe"
}

.fa-icon-angle-double-left:before {
    content: "\f100"
}

.fa-icon-angle-double-right:before {
    content: "\f101"
}

.fa-icon-angle-double-up:before {
    content: "\f102"
}

.fa-icon-angle-double-down:before {
    content: "\f103"
}

.fa-icon-angle-left:before {
    content: "\f104"
}

.fa-icon-angle-right:before {
    content: "\f105"
}

.fa-icon-angle-up:before {
    content: "\f106"
}

.fa-icon-angle-down:before {
    content: "\f107"
}

.fa-icon-desktop:before {
    content: "\f108"
}

.fa-icon-laptop:before {
    content: "\f109"
}

.fa-icon-tablet:before {
    content: "\f10a"
}

.fa-icon-mobile-phone:before,
.fa-icon-mobile:before {
    content: "\f10b"
}

.fa-icon-circle-o:before {
    content: "\f10c"
}

.fa-icon-quote-left:before {
    content: "\f10d"
}

.fa-icon-quote-right:before {
    content: "\f10e"
}

.fa-icon-spinner:before {
    content: "\f110"
}

.fa-icon-circle:before {
    content: "\f111"
}

.fa-icon-mail-reply:before,
.fa-icon-reply:before {
    content: "\f112"
}

.fa-icon-github-alt:before {
    content: "\f113"
}

.fa-icon-folder-o:before {
    content: "\f114"
}

.fa-icon-folder-open-o:before {
    content: "\f115"
}

.fa-icon-smile-o:before {
    content: "\f118"
}

.fa-icon-frown-o:before {
    content: "\f119"
}

.fa-icon-meh-o:before {
    content: "\f11a"
}

.fa-icon-gamepad:before {
    content: "\f11b"
}

.fa-icon-keyboard-o:before {
    content: "\f11c"
}

.fa-icon-flag-o:before {
    content: "\f11d"
}

.fa-icon-flag-checkered:before {
    content: "\f11e"
}

.fa-icon-terminal:before {
    content: "\f120"
}

.fa-icon-code:before {
    content: "\f121"
}

.fa-icon-mail-reply-all:before,
.fa-icon-reply-all:before {
    content: "\f122"
}

.fa-icon-star-half-empty:before,
.fa-icon-star-half-full:before,
.fa-icon-star-half-o:before {
    content: "\f123"
}

.fa-icon-location-arrow:before {
    content: "\f124"
}

.fa-icon-crop:before {
    content: "\f125"
}

.fa-icon-code-fork:before {
    content: "\f126"
}

.fa-icon-unlink:before,
.fa-icon-chain-broken:before {
    content: "\f127"
}

.fa-icon-question:before {
    content: "\f128"
}

.fa-icon-info:before {
    content: "\f129"
}

.fa-icon-exclamation:before {
    content: "\f12a"
}

.fa-icon-superscript:before {
    content: "\f12b"
}

.fa-icon-subscript:before {
    content: "\f12c"
}

.fa-icon-eraser:before {
    content: "\f12d"
}

.fa-icon-puzzle-piece:before {
    content: "\f12e"
}

.fa-icon-microphone:before {
    content: "\f130"
}

.fa-icon-microphone-slash:before {
    content: "\f131"
}

.fa-icon-shield:before {
    content: "\f132"
}

.fa-icon-calendar-o:before {
    content: "\f133"
}

.fa-icon-fire-extinguisher:before {
    content: "\f134"
}

.fa-icon-rocket:before {
    content: "\f135"
}

.fa-icon-maxcdn:before {
    content: "\f136"
}

.fa-icon-chevron-circle-left:before {
    content: "\f137"
}

.fa-icon-chevron-circle-right:before {
    content: "\f138"
}

.fa-icon-chevron-circle-up:before {
    content: "\f139"
}

.fa-icon-chevron-circle-down:before {
    content: "\f13a"
}

.fa-icon-html5:before {
    content: "\f13b"
}

.fa-icon-css3:before {
    content: "\f13c"
}

.fa-icon-anchor:before {
    content: "\f13d"
}

.fa-icon-unlock-alt:before {
    content: "\f13e"
}

.fa-icon-bullseye:before {
    content: "\f140"
}

.fa-icon-ellipsis-h:before {
    content: "\f141"
}

.fa-icon-ellipsis-v:before {
    content: "\f142"
}

.fa-icon-rss-square:before {
    content: "\f143"
}

.fa-icon-play-circle:before {
    content: "\f144"
}

.fa-icon-ticket:before {
    content: "\f145"
}

.fa-icon-minus-square:before {
    content: "\f146"
}

.fa-icon-minus-square-o:before {
    content: "\f147"
}

.fa-icon-level-up:before {
    content: "\f148"
}

.fa-icon-level-down:before {
    content: "\f149"
}

.fa-icon-check-square:before {
    content: "\f14a"
}

.fa-icon-pencil-square:before {
    content: "\f14b"
}

.fa-icon-external-link-square:before {
    content: "\f14c"
}

.fa-icon-share-square:before {
    content: "\f14d"
}

.fa-icon-compass:before {
    content: "\f14e"
}

.fa-icon-toggle-down:before,
.fa-icon-caret-square-o-down:before {
    content: "\f150"
}

.fa-icon-toggle-up:before,
.fa-icon-caret-square-o-up:before {
    content: "\f151"
}

.fa-icon-toggle-right:before,
.fa-icon-caret-square-o-right:before {
    content: "\f152"
}

.fa-icon-euro:before,
.fa-icon-eur:before {
    content: "\f153"
}

.fa-icon-gbp:before {
    content: "\f154"
}

.fa-icon-dollar:before,
.fa-icon-usd:before {
    content: "\f155"
}

.fa-icon-rupee:before,
.fa-icon-inr:before {
    content: "\f156"
}

.fa-icon-cny:before,
.fa-icon-rmb:before,
.fa-icon-yen:before,
.fa-icon-jpy:before {
    content: "\f157"
}

.fa-icon-ruble:before,
.fa-icon-rouble:before,
.fa-icon-rub:before {
    content: "\f158"
}

.fa-icon-won:before,
.fa-icon-krw:before {
    content: "\f159"
}

.fa-icon-bitcoin:before,
.fa-icon-btc:before {
    content: "\f15a"
}

.fa-icon-file:before {
    content: "\f15b"
}

.fa-icon-file-text:before {
    content: "\f15c"
}

.fa-icon-sort-alpha-asc:before {
    content: "\f15d"
}

.fa-icon-sort-alpha-desc:before {
    content: "\f15e"
}

.fa-icon-sort-amount-asc:before {
    content: "\f160"
}

.fa-icon-sort-amount-desc:before {
    content: "\f161"
}

.fa-icon-sort-numeric-asc:before {
    content: "\f162"
}

.fa-icon-sort-numeric-desc:before {
    content: "\f163"
}

.fa-icon-thumbs-up:before {
    content: "\f164"
}

.fa-icon-thumbs-down:before {
    content: "\f165"
}

.fa-icon-youtube-square:before {
    content: "\f166"
}

.fa-icon-youtube:before {
    content: "\f167"
}

.fa-icon-xing:before {
    content: "\f168"
}

.fa-icon-xing-square:before {
    content: "\f169"
}

.fa-icon-youtube-play:before {
    content: "\f16a"
}

.fa-icon-dropbox:before {
    content: "\f16b"
}

.fa-icon-stack-overflow:before {
    content: "\f16c"
}

.fa-icon-instagram:before {
    content: "\f16d"
}

.fa-icon-flickr:before {
    content: "\f16e"
}

.fa-icon-adn:before {
    content: "\f170"
}

.fa-icon-bitbucket:before {
    content: "\f171"
}

.fa-icon-bitbucket-square:before {
    content: "\f172"
}

.fa-icon-tumblr:before {
    content: "\f173"
}

.fa-icon-tumblr-square:before {
    content: "\f174"
}

.fa-icon-long-arrow-down:before {
    content: "\f175"
}

.fa-icon-long-arrow-up:before {
    content: "\f176"
}

.fa-icon-long-arrow-left:before {
    content: "\f177"
}

.fa-icon-long-arrow-right:before {
    content: "\f178"
}

.fa-icon-apple:before {
    content: "\f179"
}

.fa-icon-windows:before {
    content: "\f17a"
}

.fa-icon-android:before {
    content: "\f17b"
}

.fa-icon-linux:before {
    content: "\f17c"
}

.fa-icon-dribbble:before {
    content: "\f17d"
}

.fa-icon-skype:before {
    content: "\f17e"
}

.fa-icon-foursquare:before {
    content: "\f180"
}

.fa-icon-trello:before {
    content: "\f181"
}

.fa-icon-female:before {
    content: "\f182"
}

.fa-icon-male:before {
    content: "\f183"
}

.fa-icon-gittip:before {
    content: "\f184"
}

.fa-icon-sun-o:before {
    content: "\f185"
}

.fa-icon-moon-o:before {
    content: "\f186"
}

.fa-icon-archive:before {
    content: "\f187"
}

.fa-icon-bug:before {
    content: "\f188"
}

.fa-icon-vk:before {
    content: "\f189"
}

.fa-icon-weibo:before {
    content: "\f18a"
}

.fa-icon-renren:before {
    content: "\f18b"
}

.fa-icon-pagelines:before {
    content: "\f18c"
}

.fa-icon-stack-exchange:before {
    content: "\f18d"
}

.fa-icon-arrow-circle-o-right:before {
    content: "\f18e"
}

.fa-icon-arrow-circle-o-left:before {
    content: "\f190"
}

.fa-icon-toggle-left:before,
.fa-icon-caret-square-o-left:before {
    content: "\f191"
}

.fa-icon-dot-circle-o:before {
    content: "\f192"
}

.fa-icon-wheelchair:before {
    content: "\f193"
}

.fa-icon-vimeo-square:before {
    content: "\f194"
}

.fa-icon-turkish-lira:before,
.fa-icon-try:before {
    content: "\f195"
}

.fa-icon-plus-square-o:before {
    content: "\f196"
}

.fa-icon-space-shuttle:before {
    content: "\f197"
}

.fa-icon-slack:before {
    content: "\f198"
}

.fa-icon-envelope-square:before {
    content: "\f199"
}

.fa-icon-wordpress:before {
    content: "\f19a"
}

.fa-icon-openid:before {
    content: "\f19b"
}

.fa-icon-institution:before,
.fa-icon-bank:before,
.fa-icon-university:before {
    content: "\f19c"
}

.fa-icon-mortar-board:before,
.fa-icon-graduation-cap:before {
    content: "\f19d"
}

.fa-icon-yahoo:before {
    content: "\f19e"
}

.fa-icon-google:before {
    content: "\f1a0"
}

.fa-icon-reddit:before {
    content: "\f1a1"
}

.fa-icon-reddit-square:before {
    content: "\f1a2"
}

.fa-icon-stumbleupon-circle:before {
    content: "\f1a3"
}

.fa-icon-stumbleupon:before {
    content: "\f1a4"
}

.fa-icon-delicious:before {
    content: "\f1a5"
}

.fa-icon-digg:before {
    content: "\f1a6"
}

.fa-icon-pied-piper:before {
    content: "\f1a7"
}

.fa-icon-pied-piper-alt:before {
    content: "\f1a8"
}

.fa-icon-drupal:before {
    content: "\f1a9"
}

.fa-icon-joomla:before {
    content: "\f1aa"
}

.fa-icon-language:before {
    content: "\f1ab"
}

.fa-icon-fax:before {
    content: "\f1ac"
}

.fa-icon-building:before {
    content: "\f1ad"
}

.fa-icon-child:before {
    content: "\f1ae"
}

.fa-icon-paw:before {
    content: "\f1b0"
}

.fa-icon-spoon:before {
    content: "\f1b1"
}

.fa-icon-cube:before {
    content: "\f1b2"
}

.fa-icon-cubes:before {
    content: "\f1b3"
}

.fa-icon-behance:before {
    content: "\f1b4"
}

.fa-icon-behance-square:before {
    content: "\f1b5"
}

.fa-icon-steam:before {
    content: "\f1b6"
}

.fa-icon-steam-square:before {
    content: "\f1b7"
}

.fa-icon-recycle:before {
    content: "\f1b8"
}

.fa-icon-automobile:before,
.fa-icon-car:before {
    content: "\f1b9"
}

.fa-icon-cab:before,
.fa-icon-taxi:before {
    content: "\f1ba"
}

.fa-icon-tree:before {
    content: "\f1bb"
}

.fa-icon-spotify:before {
    content: "\f1bc"
}

.fa-icon-deviantart:before {
    content: "\f1bd"
}

.fa-icon-soundcloud:before {
    content: "\f1be"
}

.fa-icon-database:before {
    content: "\f1c0"
}

.fa-icon-file-pdf-o:before {
    content: "\f1c1"
}

.fa-icon-file-word-o:before {
    content: "\f1c2"
}

.fa-icon-file-excel-o:before {
    content: "\f1c3"
}

.fa-icon-file-powerpoint-o:before {
    content: "\f1c4"
}

.fa-icon-file-photo-o:before,
.fa-icon-file-picture-o:before,
.fa-icon-file-image-o:before {
    content: "\f1c5"
}

.fa-icon-file-zip-o:before,
.fa-icon-file-archive-o:before {
    content: "\f1c6"
}

.fa-icon-file-sound-o:before,
.fa-icon-file-audio-o:before {
    content: "\f1c7"
}

.fa-icon-file-movie-o:before,
.fa-icon-file-video-o:before {
    content: "\f1c8"
}

.fa-icon-file-code-o:before {
    content: "\f1c9"
}

.fa-icon-vine:before {
    content: "\f1ca"
}

.fa-icon-codepen:before {
    content: "\f1cb"
}

.fa-icon-jsfiddle:before {
    content: "\f1cc"
}

.fa-icon-life-bouy:before,
.fa-icon-life-buoy:before,
.fa-icon-life-saver:before,
.fa-icon-support:before,
.fa-icon-life-ring:before {
    content: "\f1cd"
}

.fa-icon-circle-o-notch:before {
    content: "\f1ce"
}

.fa-icon-ra:before,
.fa-icon-rebel:before {
    content: "\f1d0"
}

.fa-icon-ge:before,
.fa-icon-empire:before {
    content: "\f1d1"
}

.fa-icon-git-square:before {
    content: "\f1d2"
}

.fa-icon-git:before {
    content: "\f1d3"
}

.fa-icon-hacker-news:before {
    content: "\f1d4"
}

.fa-icon-tencent-weibo:before {
    content: "\f1d5"
}

.fa-icon-qq:before {
    content: "\f1d6"
}

.fa-icon-wechat:before,
.fa-icon-weixin:before {
    content: "\f1d7"
}

.fa-icon-send:before,
.fa-icon-paper-plane:before {
    content: "\f1d8"
}

.fa-icon-send-o:before,
.fa-icon-paper-plane-o:before {
    content: "\f1d9"
}

.fa-icon-history:before {
    content: "\f1da"
}

.fa-icon-circle-thin:before {
    content: "\f1db"
}

.fa-icon-header:before {
    content: "\f1dc"
}

.fa-icon-paragraph:before {
    content: "\f1dd"
}

.fa-icon-sliders:before {
    content: "\f1de"
}

.fa-icon-share-alt:before {
    content: "\f1e0"
}

.fa-icon-share-alt-square:before {
    content: "\f1e1"
}

.fa-icon-bomb:before {
    content: "\f1e2"
}

.fa-icon-soccer-ball-o:before,
.fa-icon-futbol-o:before {
    content: "\f1e3"
}

.fa-icon-tty:before {
    content: "\f1e4"
}

.fa-icon-binoculars:before {
    content: "\f1e5"
}

.fa-icon-plug:before {
    content: "\f1e6"
}

.fa-icon-slideshare:before {
    content: "\f1e7"
}

.fa-icon-twitch:before {
    content: "\f1e8"
}

.fa-icon-yelp:before {
    content: "\f1e9"
}

.fa-icon-newspaper-o:before {
    content: "\f1ea"
}

.fa-icon-wifi:before {
    content: "\f1eb"
}

.fa-icon-calculator:before {
    content: "\f1ec"
}

.fa-icon-paypal:before {
    content: "\f1ed"
}

.fa-icon-google-wallet:before {
    content: "\f1ee"
}

.fa-icon-cc-visa:before {
    content: "\f1f0"
}

.fa-icon-cc-mastercard:before {
    content: "\f1f1"
}

.fa-icon-cc-discover:before {
    content: "\f1f2"
}

.fa-icon-cc-amex:before {
    content: "\f1f3"
}

.fa-icon-cc-paypal:before {
    content: "\f1f4"
}

.fa-icon-cc-stripe:before {
    content: "\f1f5"
}

.fa-icon-bell-slash:before {
    content: "\f1f6"
}

.fa-icon-bell-slash-o:before {
    content: "\f1f7"
}

.fa-icon-trash:before {
    content: "\f1f8"
}

.fa-icon-copyright:before {
    content: "\f1f9"
}

.fa-icon-at:before {
    content: "\f1fa"
}

.fa-icon-eyedropper:before {
    content: "\f1fb"
}

.fa-icon-paint-brush:before {
    content: "\f1fc"
}

.fa-icon-birthday-cake:before {
    content: "\f1fd"
}

.fa-icon-area-chart:before {
    content: "\f1fe"
}

.fa-icon-pie-chart:before {
    content: "\f200"
}

.fa-icon-line-chart:before {
    content: "\f201"
}

.fa-icon-lastfm:before {
    content: "\f202"
}

.fa-icon-lastfm-square:before {
    content: "\f203"
}

.fa-icon-toggle-off:before {
    content: "\f204"
}

.fa-icon-toggle-on:before {
    content: "\f205"
}

.fa-icon-bicycle:before {
    content: "\f206"
}

.fa-icon-bus:before {
    content: "\f207"
}

.fa-icon-ioxhost:before {
    content: "\f208"
}

.fa-icon-angellist:before {
    content: "\f209"
}

.fa-icon-cc:before {
    content: "\f20a"
}

.fa-icon-shekel:before,
.fa-icon-sheqel:before,
.fa-icon-ils:before {
    content: "\f20b"
}

.fa-icon-meanpath:before {
    content: "\f20c"
}

@font-face {
    font-family: 'Pe-icon-7-stroke';
    src: url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/fonts/pe-icon-7-stroke/css/../../../../../fonts/pe-icon-7-stroke/Pe-icon-7-stroke.eot?d7yf1v');
    src: url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/fonts/pe-icon-7-stroke/css/../../../../../fonts/pe-icon-7-stroke/Pe-icon-7-stroke.eot?#iefixd7yf1v') format('embedded-opentype'), url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/fonts/pe-icon-7-stroke/css/../../../../../fonts/pe-icon-7-stroke/Pe-icon-7-stroke.woff?d7yf1v') format('woff'), url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/fonts/pe-icon-7-stroke/css/../../../../../fonts/pe-icon-7-stroke/Pe-icon-7-stroke.ttf?d7yf1v') format('truetype'), url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/fonts/pe-icon-7-stroke/css/../../../../../fonts/pe-icon-7-stroke/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke') format('svg');
    font-weight: normal;
    font-style: normal
}

[class^="pe-7s-"],
[class*=" pe-7s-"] {
    display: inline-block;
    font-family: 'Pe-icon-7-stroke';
    speak: none;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.pe-7s-album:before {
    content: "\e6aa"
}

.pe-7s-arc:before {
    content: "\e6ab"
}

.pe-7s-back-2:before {
    content: "\e6ac"
}

.pe-7s-bandaid:before {
    content: "\e6ad"
}

.pe-7s-car:before {
    content: "\e6ae"
}

.pe-7s-diamond:before {
    content: "\e6af"
}

.pe-7s-door-lock:before {
    content: "\e6b0"
}

.pe-7s-eyedropper:before {
    content: "\e6b1"
}

.pe-7s-female:before {
    content: "\e6b2"
}

.pe-7s-gym:before {
    content: "\e6b3"
}

.pe-7s-hammer:before {
    content: "\e6b4"
}

.pe-7s-headphones:before {
    content: "\e6b5"
}

.pe-7s-helm:before {
    content: "\e6b6"
}

.pe-7s-hourglass:before {
    content: "\e6b7"
}

.pe-7s-leaf:before {
    content: "\e6b8"
}

.pe-7s-magic-wand:before {
    content: "\e6b9"
}

.pe-7s-male:before {
    content: "\e6ba"
}

.pe-7s-map-2:before {
    content: "\e6bb"
}

.pe-7s-next-2:before {
    content: "\e6bc"
}

.pe-7s-paint-bucket:before {
    content: "\e6bd"
}

.pe-7s-pendrive:before {
    content: "\e6be"
}

.pe-7s-photo:before {
    content: "\e6bf"
}

.pe-7s-piggy:before {
    content: "\e6c0"
}

.pe-7s-plugin:before {
    content: "\e6c1"
}

.pe-7s-refresh-2:before {
    content: "\e6c2"
}

.pe-7s-rocket:before {
    content: "\e6c3"
}

.pe-7s-settings:before {
    content: "\e6c4"
}

.pe-7s-shield:before {
    content: "\e6c5"
}

.pe-7s-smile:before {
    content: "\e6c6"
}

.pe-7s-usb:before {
    content: "\e6c7"
}

.pe-7s-vector:before {
    content: "\e6c8"
}

.pe-7s-wine:before {
    content: "\e6c9"
}

.pe-7s-cloud-upload:before {
    content: "\e68a"
}

.pe-7s-cash:before {
    content: "\e68c"
}

.pe-7s-close:before {
    content: "\e680"
}

.pe-7s-bluetooth:before {
    content: "\e68d"
}

.pe-7s-cloud-download:before {
    content: "\e68b"
}

.pe-7s-way:before {
    content: "\e68e"
}

.pe-7s-close-circle:before {
    content: "\e681"
}

.pe-7s-id:before {
    content: "\e68f"
}

.pe-7s-angle-up:before {
    content: "\e682"
}

.pe-7s-wristwatch:before {
    content: "\e690"
}

.pe-7s-angle-up-circle:before {
    content: "\e683"
}

.pe-7s-world:before {
    content: "\e691"
}

.pe-7s-angle-right:before {
    content: "\e684"
}

.pe-7s-volume:before {
    content: "\e692"
}

.pe-7s-angle-right-circle:before {
    content: "\e685"
}

.pe-7s-users:before {
    content: "\e693"
}

.pe-7s-angle-left:before {
    content: "\e686"
}

.pe-7s-user-female:before {
    content: "\e694"
}

.pe-7s-angle-left-circle:before {
    content: "\e687"
}

.pe-7s-up-arrow:before {
    content: "\e695"
}

.pe-7s-angle-down:before {
    content: "\e688"
}

.pe-7s-switch:before {
    content: "\e696"
}

.pe-7s-angle-down-circle:before {
    content: "\e689"
}

.pe-7s-scissors:before {
    content: "\e697"
}

.pe-7s-wallet:before {
    content: "\e600"
}

.pe-7s-safe:before {
    content: "\e698"
}

.pe-7s-volume2:before {
    content: "\e601"
}

.pe-7s-volume1:before {
    content: "\e602"
}

.pe-7s-voicemail:before {
    content: "\e603"
}

.pe-7s-video:before {
    content: "\e604"
}

.pe-7s-user:before {
    content: "\e605"
}

.pe-7s-upload:before {
    content: "\e606"
}

.pe-7s-unlock:before {
    content: "\e607"
}

.pe-7s-umbrella:before {
    content: "\e608"
}

.pe-7s-trash:before {
    content: "\e609"
}

.pe-7s-tools:before {
    content: "\e60a"
}

.pe-7s-timer:before {
    content: "\e60b"
}

.pe-7s-ticket:before {
    content: "\e60c"
}

.pe-7s-target:before {
    content: "\e60d"
}

.pe-7s-sun:before {
    content: "\e60e"
}

.pe-7s-study:before {
    content: "\e60f"
}

.pe-7s-stopwatch:before {
    content: "\e610"
}

.pe-7s-star:before {
    content: "\e611"
}

.pe-7s-speaker:before {
    content: "\e612"
}

.pe-7s-signal:before {
    content: "\e613"
}

.pe-7s-shuffle:before {
    content: "\e614"
}

.pe-7s-shopbag:before {
    content: "\e615"
}

.pe-7s-share:before {
    content: "\e616"
}

.pe-7s-server:before {
    content: "\e617"
}

.pe-7s-search:before {
    content: "\e618"
}

.pe-7s-film:before {
    content: "\e6a5"
}

.pe-7s-science:before {
    content: "\e619"
}

.pe-7s-disk:before {
    content: "\e6a6"
}

.pe-7s-ribbon:before {
    content: "\e61a"
}

.pe-7s-repeat:before {
    content: "\e61b"
}

.pe-7s-refresh:before {
    content: "\e61c"
}

.pe-7s-add-user:before {
    content: "\e6a9"
}

.pe-7s-refresh-cloud:before {
    content: "\e61d"
}

.pe-7s-paperclip:before {
    content: "\e69c"
}

.pe-7s-radio:before {
    content: "\e61e"
}

.pe-7s-note2:before {
    content: "\e69d"
}

.pe-7s-print:before {
    content: "\e61f"
}

.pe-7s-network:before {
    content: "\e69e"
}

.pe-7s-prev:before {
    content: "\e620"
}

.pe-7s-mute:before {
    content: "\e69f"
}

.pe-7s-power:before {
    content: "\e621"
}

.pe-7s-medal:before {
    content: "\e6a0"
}

.pe-7s-portfolio:before {
    content: "\e622"
}

.pe-7s-like2:before {
    content: "\e6a1"
}

.pe-7s-plus:before {
    content: "\e623"
}

.pe-7s-left-arrow:before {
    content: "\e6a2"
}

.pe-7s-play:before {
    content: "\e624"
}

.pe-7s-key:before {
    content: "\e6a3"
}

.pe-7s-plane:before {
    content: "\e625"
}

.pe-7s-joy:before {
    content: "\e6a4"
}

.pe-7s-photo-gallery:before {
    content: "\e626"
}

.pe-7s-pin:before {
    content: "\e69b"
}

.pe-7s-phone:before {
    content: "\e627"
}

.pe-7s-plug:before {
    content: "\e69a"
}

.pe-7s-pen:before {
    content: "\e628"
}

.pe-7s-right-arrow:before {
    content: "\e699"
}

.pe-7s-paper-plane:before {
    content: "\e629"
}

.pe-7s-delete-user:before {
    content: "\e6a7"
}

.pe-7s-paint:before {
    content: "\e62a"
}

.pe-7s-bottom-arrow:before {
    content: "\e6a8"
}

.pe-7s-notebook:before {
    content: "\e62b"
}

.pe-7s-note:before {
    content: "\e62c"
}

.pe-7s-next:before {
    content: "\e62d"
}

.pe-7s-news-paper:before {
    content: "\e62e"
}

.pe-7s-musiclist:before {
    content: "\e62f"
}

.pe-7s-music:before {
    content: "\e630"
}

.pe-7s-mouse:before {
    content: "\e631"
}

.pe-7s-more:before {
    content: "\e632"
}

.pe-7s-moon:before {
    content: "\e633"
}

.pe-7s-monitor:before {
    content: "\e634"
}

.pe-7s-micro:before {
    content: "\e635"
}

.pe-7s-menu:before {
    content: "\e636"
}

.pe-7s-map:before {
    content: "\e637"
}

.pe-7s-map-marker:before {
    content: "\e638"
}

.pe-7s-mail:before {
    content: "\e639"
}

.pe-7s-mail-open:before {
    content: "\e63a"
}

.pe-7s-mail-open-file:before {
    content: "\e63b"
}

.pe-7s-magnet:before {
    content: "\e63c"
}

.pe-7s-loop:before {
    content: "\e63d"
}

.pe-7s-look:before {
    content: "\e63e"
}

.pe-7s-lock:before {
    content: "\e63f"
}

.pe-7s-lintern:before {
    content: "\e640"
}

.pe-7s-link:before {
    content: "\e641"
}

.pe-7s-like:before {
    content: "\e642"
}

.pe-7s-light:before {
    content: "\e643"
}

.pe-7s-less:before {
    content: "\e644"
}

.pe-7s-keypad:before {
    content: "\e645"
}

.pe-7s-junk:before {
    content: "\e646"
}

.pe-7s-info:before {
    content: "\e647"
}

.pe-7s-home:before {
    content: "\e648"
}

.pe-7s-help2:before {
    content: "\e649"
}

.pe-7s-help1:before {
    content: "\e64a"
}

.pe-7s-graph3:before {
    content: "\e64b"
}

.pe-7s-graph2:before {
    content: "\e64c"
}

.pe-7s-graph1:before {
    content: "\e64d"
}

.pe-7s-graph:before {
    content: "\e64e"
}

.pe-7s-global:before {
    content: "\e64f"
}

.pe-7s-gleam:before {
    content: "\e650"
}

.pe-7s-glasses:before {
    content: "\e651"
}

.pe-7s-gift:before {
    content: "\e652"
}

.pe-7s-folder:before {
    content: "\e653"
}

.pe-7s-flag:before {
    content: "\e654"
}

.pe-7s-filter:before {
    content: "\e655"
}

.pe-7s-file:before {
    content: "\e656"
}

.pe-7s-expand1:before {
    content: "\e657"
}

.pe-7s-exapnd2:before {
    content: "\e658"
}

.pe-7s-edit:before {
    content: "\e659"
}

.pe-7s-drop:before {
    content: "\e65a"
}

.pe-7s-drawer:before {
    content: "\e65b"
}

.pe-7s-download:before {
    content: "\e65c"
}

.pe-7s-display2:before {
    content: "\e65d"
}

.pe-7s-display1:before {
    content: "\e65e"
}

.pe-7s-diskette:before {
    content: "\e65f"
}

.pe-7s-date:before {
    content: "\e660"
}

.pe-7s-cup:before {
    content: "\e661"
}

.pe-7s-culture:before {
    content: "\e662"
}

.pe-7s-crop:before {
    content: "\e663"
}

.pe-7s-credit:before {
    content: "\e664"
}

.pe-7s-copy-file:before {
    content: "\e665"
}

.pe-7s-config:before {
    content: "\e666"
}

.pe-7s-compass:before {
    content: "\e667"
}

.pe-7s-comment:before {
    content: "\e668"
}

.pe-7s-coffee:before {
    content: "\e669"
}

.pe-7s-cloud:before {
    content: "\e66a"
}

.pe-7s-clock:before {
    content: "\e66b"
}

.pe-7s-check:before {
    content: "\e66c"
}

.pe-7s-chat:before {
    content: "\e66d"
}

.pe-7s-cart:before {
    content: "\e66e"
}

.pe-7s-camera:before {
    content: "\e66f"
}

.pe-7s-call:before {
    content: "\e670"
}

.pe-7s-calculator:before {
    content: "\e671"
}

.pe-7s-browser:before {
    content: "\e672"
}

.pe-7s-box2:before {
    content: "\e673"
}

.pe-7s-box1:before {
    content: "\e674"
}

.pe-7s-bookmarks:before {
    content: "\e675"
}

.pe-7s-bicycle:before {
    content: "\e676"
}

.pe-7s-bell:before {
    content: "\e677"
}

.pe-7s-battery:before {
    content: "\e678"
}

.pe-7s-ball:before {
    content: "\e679"
}

.pe-7s-back:before {
    content: "\e67a"
}

.pe-7s-attention:before {
    content: "\e67b"
}

.pe-7s-anchor:before {
    content: "\e67c"
}

.pe-7s-albums:before {
    content: "\e67d"
}

.pe-7s-alarm:before {
    content: "\e67e"
}

.pe-7s-airplay:before {
    content: "\e67f"
}

@font-face {
    font-family: 'revicons';
    src: url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../fonts/revicons/revicons.eot?5510888');
    src: url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../fonts/revicons/revicons.eot?5510888#iefix') format('embedded-opentype'), url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../fonts/revicons/revicons.woff?5510888') format('woff'), url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../fonts/revicons/revicons.ttf?5510888') format('truetype'), url('https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../fonts/revicons/revicons.svg?5510888#revicons') format('svg');
    font-weight: normal;
    font-style: normal
}

[class^="revicon-"]:before,
[class*=" revicon-"]:before {
    font-family: "revicons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em
}

.revicon-search-1:before {
    content: '\e802'
}

.revicon-pencil-1:before {
    content: '\e831'
}

.revicon-picture-1:before {
    content: '\e803'
}

.revicon-cancel:before {
    content: '\e80a'
}

.revicon-info-circled:before {
    content: '\e80f'
}

.revicon-trash:before {
    content: '\e801'
}

.revicon-left-dir:before {
    content: '\e817'
}

.revicon-right-dir:before {
    content: '\e818'
}

.revicon-down-open:before {
    content: '\e83b'
}

.revicon-left-open:before {
    content: '\e819'
}

.revicon-right-open:before {
    content: '\e81a'
}

.revicon-angle-left:before {
    content: '\e820'
}

.revicon-angle-right:before {
    content: '\e81d'
}

.revicon-left-big:before {
    content: '\e81f'
}

.revicon-right-big:before {
    content: '\e81e'
}

.revicon-magic:before {
    content: '\e807'
}

.revicon-picture:before {
    content: '\e800'
}

.revicon-export:before {
    content: '\e80b'
}

.revicon-cog:before {
    content: '\e832'
}

.revicon-login:before {
    content: '\e833'
}

.revicon-logout:before {
    content: '\e834'
}

.revicon-video:before {
    content: '\e805'
}

.revicon-arrow-combo:before {
    content: '\e827'
}

.revicon-left-open-1:before {
    content: '\e82a'
}

.revicon-right-open-1:before {
    content: '\e82b'
}

.revicon-left-open-mini:before {
    content: '\e822'
}

.revicon-right-open-mini:before {
    content: '\e823'
}

.revicon-left-open-big:before {
    content: '\e824'
}

.revicon-right-open-big:before {
    content: '\e825'
}

.revicon-left:before {
    content: '\e836'
}

.revicon-right:before {
    content: '\e826'
}

.revicon-ccw:before {
    content: '\e808'
}

.revicon-arrows-ccw:before {
    content: '\e806'
}

.revicon-palette:before {
    content: '\e829'
}

.revicon-list-add:before {
    content: '\e80c'
}

.revicon-doc:before {
    content: '\e809'
}

.revicon-left-open-outline:before {
    content: '\e82e'
}

.revicon-left-open-2:before {
    content: '\e82c'
}

.revicon-right-open-outline:before {
    content: '\e82f'
}

.revicon-right-open-2:before {
    content: '\e82d'
}

.revicon-equalizer:before {
    content: '\e83a'
}

.revicon-layers-alt:before {
    content: '\e804'
}

.revicon-popup:before {
    content: '\e828'
}

.rev_slider_wrapper {
    position: relative;
    z-index: 0
}

.rev_slider {
    position: relative;
    overflow: visible
}

.tp-overflow-hidden {
    overflow: hidden
}

.tp-simpleresponsive img,
.rev_slider img {
    max-width: none !important;
    transition: none;
    margin: 0;
    padding: 0;
    border-width: 0;
    border: none
}

.rev_slider .no-slides-text {
    font-weight: bold;
    text-align: center;
    padding-top: 80px
}

.rev_slider>ul,
.rev_slider_wrapper>ul,
.tp-revslider-mainul>li,
.rev_slider>ul>li,
.rev_slider>ul>li:before,
.tp-revslider-mainul>li:before,
.tp-simpleresponsive>ul,
.tp-simpleresponsive>ul>li,
.tp-simpleresponsive>ul>li:before,
.tp-revslider-mainul>li,
.tp-simpleresponsive>ul>li {
    list-style: none !important;
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: visible;
    overflow-y: visible;
    list-style-type: none !important;
    background-image: none;
    background-position: 0 0;
    text-indent: 0;
    top: 0;
    left: 0
}

.tp-revslider-mainul>li,
.rev_slider>ul>li,
.rev_slider>ul>li:before,
.tp-revslider-mainul>li:before,
.tp-simpleresponsive>ul>li,
.tp-simpleresponsive>ul>li:before,
.tp-revslider-mainul>li,
.tp-simpleresponsive>ul>li {
    visibility: hidden
}

.tp-revslider-slidesli,
.tp-revslider-mainul {
    padding: 0 !important;
    margin: 0 !important;
    list-style: none !important
}

.rev_slider li.tp-revslider-slidesli {
    position: absolute !important
}

.rev_slider .tp-caption,
.rev_slider .caption {
    position: relative;
    visibility: hidden;
    white-space: nowrap;
    display: block
}

.rev_slider .tp-mask-wrap .tp-caption,
.rev_slider .tp-mask-wrap *:last-child,
.wpb_text_column .rev_slider .tp-mask-wrap .tp-caption,
.wpb_text_column .rev_slider .tp-mask-wrap *:last-child {
    margin-bottom: 0
}

.tp-carousel-wrapper {
    cursor: url(https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/openhand.cur), move
}

.tp-carousel-wrapper.dragged {
    cursor: url(https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/closedhand.cur), move
}

.tp-caption {
    z-index: 1
}

.tp_inner_padding {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    max-height: none !important
}

.tp-caption {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    position: absolute;
    -webkit-font-smoothing: antialiased !important
}

.tp-forcenotvisible,
.tp-hide-revslider,
.tp-caption.tp-hidden-caption {
    visibility: hidden !important;
    display: none !important
}

.rev_slider embed,
.rev_slider iframe,
.rev_slider object,
.rev_slider video {
    max-width: none !important
}

.rev_slider_wrapper {
    width: 100%
}

.fullscreen-container {
    position: relative;
    padding: 0
}

.fullwidthbanner-container {
    position: relative;
    padding: 0;
    overflow: hidden
}

.fullwidthbanner-container .fullwidthabanner {
    width: 100%;
    position: relative
}

.tp-static-layers {
    position: absolute;
    z-index: 505;
    top: 0;
    left: 0
}

.tp-caption .frontcorner {
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 0 solid transparent;
    border-top: 40px solid #00A8FF;
    position: absolute;
    left: -40px;
    top: 0
}

.tp-caption .backcorner {
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 40px solid transparent;
    border-bottom: 40px solid #00A8FF;
    position: absolute;
    right: 0;
    top: 0
}

.tp-caption .frontcornertop {
    width: 0;
    height: 0;
    border-left: 40px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 40px solid #00A8FF;
    position: absolute;
    left: -40px;
    top: 0
}

.tp-caption .backcornertop {
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 40px solid transparent;
    border-top: 40px solid #00A8FF;
    position: absolute;
    right: 0;
    top: 0
}

.tp-layer-inner-rotation {
    position: relative !important
}

img.tp-slider-alternative-image {
    width: 100%;
    height: auto
}

.noFilterClass {
    -webkit-filter: none !important;
            filter: none !important
}

.rs-background-video-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    z-index: 0
}

.tp-caption.coverscreenvideo {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute
}

.caption.fullscreenvideo,
.tp-caption.fullscreenvideo {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%
}

.caption.fullscreenvideo iframe,
.caption.fullscreenvideo video,
.tp-caption.fullscreenvideo iframe,
.tp-caption.fullscreenvideo iframe video {
    width: 100% !important;
    height: 100% !important;
    display: none
}

.fullcoveredvideo video,
.fullscreenvideo video {
    background: #000
}

.fullcoveredvideo .tp-poster {
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.videoisplaying .html5vid .tp-poster {
    display: none
}

.tp-video-play-button {
    background: #000;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #FFF;
    z-index: 3;
    margin-top: -25px;
    margin-left: -25px;
    line-height: 50px !important;
    text-align: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    z-index: 4;
    opacity: 0;
    transition: opacity 300ms ease-out !important
}

.tp-caption .html5vid {
    width: 100% !important;
    height: 100% !important
}

.tp-video-play-button i {
    width: 50px;
    height: 50px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    line-height: 50px !important;
    font-size: 40px !important
}

.tp-caption:hover .tp-video-play-button {
    opacity: 1
}

.tp-caption .tp-revstop {
    display: none;
    border-left: 5px solid #fff !important;
    border-right: 5px solid #fff !important;
    margin-top: 15px !important;
    line-height: 20px !important;
    vertical-align: top;
    font-size: 25px !important
}

.videoisplaying .revicon-right-dir {
    display: none
}

.videoisplaying .tp-revstop {
    display: inline-block
}

.videoisplaying .tp-video-play-button {
    display: none
}

.tp-caption:hover .tp-video-play-button {
    display: block
}

.fullcoveredvideo .tp-video-play-button {
    display: none !important
}

.fullscreenvideo .fullscreenvideo video {
    object-fit: contain !important
}

.fullscreenvideo .fullcoveredvideo video {
    object-fit: cover !important
}

.tp-video-controls {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    opacity: 0;
    transition: opacity .3s;
    background-image: linear-gradient(bottom, rgb(0, 0, 0) 13%, rgb(50, 50, 50) 100%);
    background-image: -ms-linear-gradient(bottom, rgb(0, 0, 0) 13%, rgb(50, 50, 50) 100%);
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.13, rgb(0, 0, 0)), color-stop(1, rgb(50, 50, 50)));
    display: table;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box
}

.tp-caption:hover .tp-video-controls {
    opacity: .9
}

.tp-video-button {
    background: rgba(0, 0, 0, .5);
    border: 0;
    color: #EEE;
    border-radius: 3px;
    cursor: pointer;
    line-height: 12px;
    font-size: 12px;
    color: #fff;
    padding: 0;
    margin: 0;
    outline: none
}

.tp-video-button:hover {
    cursor: pointer
}

.tp-video-button-wrap,
.tp-video-seek-bar-wrap,
.tp-video-vol-bar-wrap {
    padding: 0 5px;
    display: table-cell
}

.tp-video-seek-bar-wrap {
    width: 80%
}

.tp-video-vol-bar-wrap {
    width: 20%
}

.tp-volume-bar,
.tp-seek-bar {
    width: 100%;
    cursor: pointer;
    outline: none;
    line-height: 12px;
    margin: 0;
    padding: 0
}

.rs-fullvideo-cover {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: transparent;
    z-index: 5
}

.rs-background-video-layer video::-webkit-media-controls {
    display: none !important
}

.tp-dottedoverlay {
    background-repeat: repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3
}

.tp-dottedoverlay.twoxtwo {
    background: url(https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../img/assets/gridtile.png)
}

.tp-dottedoverlay.twoxtwowhite {
    background: url(https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../img/assets/gridtile_white.png)
}

.tp-dottedoverlay.threexthree {
    background: url(https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../img/assets/gridtile_3x3.png)
}

.tp-dottedoverlay.threexthreewhite {
    background: url(https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../img/assets/gridtile_3x3_white.png)
}

.tp-shadowcover {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    position: absolute;
    z-index: -1
}

.tp-shadow1 {
    box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.8)
}

.tp-shadow2:before,
.tp-shadow2:after,
.tp-shadow3:before,
.tp-shadow4:after {
    z-index: -2;
    position: absolute;
    content: "";
    bottom: 10px;
    left: 10px;
    width: 50%;
    top: 85%;
    max-width: 300px;
    background: transparent;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.8);
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg)
}

.tp-shadow2:after,
.tp-shadow4:after {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
    right: 10px;
    left: auto
}

.tp-shadow5 {
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset
}

.tp-shadow5:before,
.tp-shadow5:after {
    content: "";
    position: absolute;
    z-index: -2;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.6);
    top: 30%;
    bottom: 0;
    left: 20px;
    right: 20px;
    border-radius: 100px / 20px
}

.tp-button {
    padding: 6px 13px 5px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    height: 30px;
    cursor: pointer;
    color: #fff !important;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6) !important;
    font-size: 15px;
    line-height: 45px !important;
    font-family: arial, sans-serif;
    font-weight: bold;
    letter-spacing: -1px;
    text-decoration: none
}

.tp-button.big {
    color: #fff;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
    font-weight: bold;
    padding: 9px 20px;
    font-size: 19px;
    line-height: 57px !important
}

.purchase:hover,
.tp-button:hover,
.tp-button.big:hover {
    background-position: bottom, 15px 11px
}

.tp-button.green,
.tp-button:hover.green,
.purchase.green,
.purchase:hover.green {
    background-color: #21a117;
    box-shadow: 0 3px 0 0 #104d0b
}

.tp-button.blue,
.tp-button:hover.blue,
.purchase.blue,
.purchase:hover.blue {
    background-color: #1d78cb;
    box-shadow: 0 3px 0 0 #0f3e68
}

.tp-button.red,
.tp-button:hover.red,
.purchase.red,
.purchase:hover.red {
    background-color: #cb1d1d;
    box-shadow: 0 3px 0 0 #7c1212
}

.tp-button.orange,
.tp-button:hover.orange,
.purchase.orange,
.purchase:hover.orange {
    background-color: #ff7700;
    box-shadow: 0 3px 0 0 #a34c00
}

.tp-button.darkgrey,
.tp-button.grey,
.tp-button:hover.darkgrey,
.tp-button:hover.grey,
.purchase.darkgrey,
.purchase:hover.darkgrey {
    background-color: #555;
    box-shadow: 0 3px 0 0 #222
}

.tp-button.lightgrey,
.tp-button:hover.lightgrey,
.purchase.lightgrey,
.purchase:hover.lightgrey {
    background-color: #888;
    box-shadow: 0 3px 0 0 #555
}

.rev-btn,
.rev-btn:visited {
    outline: none !important;
    box-shadow: none !important;
    text-decoration: none !important;
    line-height: 44px;
    font-size: 17px;
    font-weight: 500;
    padding: 12px 35px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    cursor: pointer
}

.rev-btn.rev-uppercase,
.rev-btn.rev-uppercase:visited {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 900
}

.rev-btn.rev-withicon i {
    font-size: 15px;
    font-weight: normal;
    position: relative;
    top: 0;
    -webkit-transition: all 0.2s ease-out !important;
    -moz-transition: all 0.2s ease-out !important;
    -o-transition: all 0.2s ease-out !important;
    -ms-transition: all 0.2s ease-out !important;
    margin-left: 10px !important
}

.rev-btn.rev-hiddenicon i {
    font-size: 15px;
    font-weight: normal;
    position: relative;
    top: 0;
    -webkit-transition: all 0.2s ease-out !important;
    -moz-transition: all 0.2s ease-out !important;
    -o-transition: all 0.2s ease-out !important;
    -ms-transition: all 0.2s ease-out !important;
    opacity: 0;
    margin-left: 0 !important;
    width: 0 !important
}

.rev-btn.rev-hiddenicon:hover i {
    opacity: 1 !important;
    margin-left: 10px !important;
    width: auto !important
}

.rev-btn.rev-medium,
.rev-btn.rev-medium:visited {
    line-height: 36px;
    font-size: 14px;
    padding: 10px 30px
}

.rev-btn.rev-medium.rev-withicon i {
    font-size: 14px;
    top: 0
}

.rev-btn.rev-medium.rev-hiddenicon i {
    font-size: 14px;
    top: 0
}

.rev-btn.rev-small,
.rev-btn.rev-small:visited {
    line-height: 28px;
    font-size: 12px;
    padding: 7px 20px
}

.rev-btn.rev-small.rev-withicon i {
    font-size: 12px;
    top: 0
}

.rev-btn.rev-small.rev-hiddenicon i {
    font-size: 12px;
    top: 0
}

.rev-maxround {
    border-radius: 30px
}

.rev-minround {
    border-radius: 3px
}

.rev-burger {
    position: relative;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    padding: 22px 0 0 14px;
    border-radius: 50%;
    border: 1px solid rgba(51, 51, 51, 0.25);
    tap-highlight-color: transparent;
    cursor: pointer
}

.rev-burger span {
    display: block;
    width: 30px;
    height: 3px;
    background: #333;
    transition: .7s;
    pointer-events: none;
    -webkit-transform-style: flat !important;
            transform-style: flat !important
}

.rev-burger span:nth-child(2) {
    margin: 3px 0
}

#dialog_addbutton .rev-burger:hover :first-child,
.open .rev-burger :first-child,
.open.rev-burger :first-child {
    transform: translateY(6px) rotate(-45deg);
    -webkit-transform: translateY(6px) rotate(-45deg)
}

#dialog_addbutton .rev-burger:hover :nth-child(2),
.open .rev-burger :nth-child(2),
.open.rev-burger :nth-child(2) {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    opacity: 0
}

#dialog_addbutton .rev-burger:hover :last-child,
.open .rev-burger :last-child,
.open.rev-burger :last-child {
    transform: translateY(-6px) rotate(-135deg);
    -webkit-transform: translateY(-6px) rotate(-135deg)
}

.rev-burger.revb-white {
    border: 2px solid rgba(255, 255, 255, 0.2)
}

.rev-burger.revb-white span {
    background: #fff
}

.rev-burger.revb-whitenoborder {
    border: 0
}

.rev-burger.revb-whitenoborder span {
    background: #fff
}

.rev-burger.revb-darknoborder {
    border: 0
}

.rev-burger.revb-darknoborder span {
    background: #333
}

.rev-burger.revb-whitefull {
    background: #fff;
    border: none
}

.rev-burger.revb-whitefull span {
    background: #333
}

.rev-burger.revb-darkfull {
    background: #333;
    border: none
}

.rev-burger.revb-darkfull span {
    background: #fff
}

@-webkit-keyframes rev-ani-mouse {
    0% {
        opacity: 1;
        top: 29%
    }

    15% {
        opacity: 1;
        top: 50%
    }

    50% {
        opacity: 0;
        top: 50%
    }

    100% {
        opacity: 0;
        top: 29%
    }
}

@keyframes rev-ani-mouse {
    0% {
        opacity: 1;
        top: 29%
    }

    15% {
        opacity: 1;
        top: 50%
    }

    50% {
        opacity: 0;
        top: 50%
    }

    100% {
        opacity: 0;
        top: 29%
    }
}

.rev-scroll-btn {
    display: inline-block;
    position: relative;
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
    width: 35px;
    height: 55px;
    box-sizing: border-box;
    border: 3px solid white;
    border-radius: 23px
}

.rev-scroll-btn>* {
    display: inline-block;
    line-height: 18px;
    font-size: 13px;
    font-weight: normal;
    color: #7f8c8d;
    color: #ffffff;
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 2px
}

.rev-scroll-btn>*:hover,
.rev-scroll-btn>*:focus,
.rev-scroll-btn>*.active {
    color: #ffffff
}

.rev-scroll-btn>*:hover,
.rev-scroll-btn>*:focus,
.rev-scroll-btn>*:active,
.rev-scroll-btn>*.active {
    filter: alpha(opacity=80)
}

.rev-scroll-btn.revs-fullwhite {
    background: #fff
}

.rev-scroll-btn.revs-fullwhite span {
    background: #333
}

.rev-scroll-btn.revs-fulldark {
    background: #333;
    border: none
}

.rev-scroll-btn.revs-fulldark span {
    background: #fff
}

.rev-scroll-btn span {
    position: absolute;
    display: block;
    top: 29%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin: -4px 0 0 -4px;
    background: white;
    border-radius: 50%;
    -webkit-animation: rev-ani-mouse 2.5s linear infinite;
    animation: rev-ani-mouse 2.5s linear infinite
}

.rev-scroll-btn.revs-dark {
    border-color: #333
}

.rev-scroll-btn.revs-dark span {
    background: #333
}

.rev-control-btn {
    position: relative;
    display: inline-block;
    z-index: 5;
    color: #FFF;
    font-size: 20px;
    line-height: 60px;
    font-weight: 400;
    font-style: normal;
    font-family: Raleway;
    text-decoration: none;
    text-align: center;
    background-color: #000;
    border-radius: 50px;
    text-shadow: none;
    background-color: rgba(0, 0, 0, 0.50);
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    cursor: pointer
}

.rev-cbutton-dark-sr {
    border-radius: 3px
}

.rev-cbutton-light {
    color: #333;
    background-color: rgba(255, 255, 255, 0.75)
}

.rev-cbutton-light-sr {
    color: #333;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.75)
}

.rev-sbutton {
    line-height: 37px;
    width: 37px;
    height: 37px
}

.rev-sbutton-blue {
    background-color: #3B5998
}

.rev-sbutton-lightblue {
    background-color: #00A0D1
}

.rev-sbutton-red {
    background-color: #DD4B39
}

.tp-bannertimer {
    visibility: hidden;
    width: 100%;
    height: 5px;
    background: #fff;
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    z-index: 200;
    top: 0
}

.tp-bannertimer.tp-bottom {
    top: auto;
    bottom: 0 !important;
    height: 5px
}

.tp-simpleresponsive img {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none
}

.tp-caption img {
    background: transparent;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)";
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, endColorstr=#00FFFFFF);
    zoom: 1
}

.caption.slidelink a div,
.tp-caption.slidelink a div {
    width: 3000px;
    height: 1500px;
    background: url(https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../img/assets/coloredbg.png) repeat
}

.tp-caption.slidelink a span {
    background: url(https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../img/assets/coloredbg.png) repeat
}

.tp-shape {
    width: 100%;
    height: 100%
}

.tp-caption .rs-starring {
    display: inline-block
}

.tp-caption .rs-starring .star-rating {
    float: none
}

.tp-caption .rs-starring .star-rating {
    color: #FFC321 !important;
    display: inline-block;
    vertical-align: top
}

.tp-caption .rs-starring .star-rating,
.tp-caption .rs-starring-page .star-rating {
    position: relative;
    height: 1em;
    width: 5.4em;
    font-family: star
}

.tp-caption .rs-starring .star-rating:before,
.tp-caption .rs-starring-page .star-rating:before {
    content: "\73\73\73\73\73";
    color: #E0DADF;
    float: left;
    top: 0;
    left: 0;
    position: absolute
}

.tp-caption .rs-starring .star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em;
    font-size: 1em !important
}

.tp-caption .rs-starring .star-rating span:before,
.tp-caption .rs-starring .star-rating span:before {
    content: "\53\53\53\53\53";
    top: 0;
    position: absolute;
    left: 0
}

.tp-caption .rs-starring .star-rating {
    color: #FFC321 !important
}

.tp-caption .rs-starring .star-rating,
.tp-caption .rs-starring-page .star-rating {
    font-size: 1em !important;
    font-family: star
}

.tp-loader {
    top: 50%;
    left: 50%;
    z-index: 10000;
    position: absolute
}

.tp-loader.spinner0 {
    width: 40px;
    height: 40px;
    background-color: #fff;
    background: url(https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../img/assets/loader.gif) no-repeat center center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    margin-top: -20px;
    margin-left: -20px;
    -webkit-animation: tp-rotateplane 1.2s infinite ease-in-out;
    animation: tp-rotateplane 1.2s infinite ease-in-out;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px
}

.tp-loader.spinner1 {
    width: 40px;
    height: 40px;
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    margin-top: -20px;
    margin-left: -20px;
    -webkit-animation: tp-rotateplane 1.2s infinite ease-in-out;
    animation: tp-rotateplane 1.2s infinite ease-in-out;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px
}

.tp-loader.spinner5 {
    background: url(https://warehouse.iqit-commerce.com/modules/revsliderprestashop/views/css/rs-plugin/css/../../../img/assets/loader.gif) no-repeat 10px 10px;
    background-color: #fff;
    margin: -22px -22px;
    width: 44px;
    height: 44px;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px
}

@-webkit-keyframes tp-rotateplane {
    0% {
        -webkit-transform: perspective(120px)
    }

    50% {
        -webkit-transform: perspective(120px) rotateY(180deg)
    }

    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
    }
}

@keyframes tp-rotateplane {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
                transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    }

    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
                transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    }

    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
                transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
    }
}

.tp-loader.spinner2 {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    background-color: #ff0000;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    -webkit-animation: tp-scaleout 1.0s infinite ease-in-out;
    animation: tp-scaleout 1.0s infinite ease-in-out
}

@-webkit-keyframes tp-scaleout {
    0% {
        -webkit-transform: scale(0.0)
    }

    100% {
        -webkit-transform: scale(1.0);
        opacity: 0
    }
}

@keyframes tp-scaleout {
    0% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0)
    }

    100% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
        opacity: 0
    }
}

.tp-loader.spinner3 {
    margin: -9px 0 0 -35px;
    width: 70px;
    text-align: center
}

.tp-loader.spinner3 .bounce1,
.tp-loader.spinner3 .bounce2,
.tp-loader.spinner3 .bounce3 {
    width: 18px;
    height: 18px;
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: tp-bouncedelay 1.4s infinite ease-in-out;
    animation: tp-bouncedelay 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.tp-loader.spinner3 .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s
}

.tp-loader.spinner3 .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s
}

@-webkit-keyframes tp-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0.0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes tp-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0.0);
                transform: scale(0.0)
    }

    40% {
        -webkit-transform: scale(1.0);
                transform: scale(1.0)
    }
}

.tp-loader.spinner4 {
    margin: -20px 0 0 -20px;
    width: 40px;
    height: 40px;
    text-align: center;
    -webkit-animation: tp-rotate 2.0s infinite linear;
    animation: tp-rotate 2.0s infinite linear
}

.tp-loader.spinner4 .dot1,
.tp-loader.spinner4 .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: tp-bounce 2.0s infinite ease-in-out;
    animation: tp-bounce 2.0s infinite ease-in-out;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15)
}

.tp-loader.spinner4 .dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s
}

@-webkit-keyframes tp-rotate {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes tp-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}

@-webkit-keyframes tp-bounce {

    0%,
    100% {
        -webkit-transform: scale(0.0)
    }

    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes tp-bounce {

    0%,
    100% {
        -webkit-transform: scale(0.0);
                transform: scale(0.0)
    }

    50% {
        -webkit-transform: scale(1.0);
                transform: scale(1.0)
    }
}

.tp-thumbs.navbar,
.tp-bullets.navbar,
.tp-tabs.navbar {
    border: none;
    min-height: 0;
    margin: 0;
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0
}

.tp-tabs,
.tp-thumbs,
.tp-bullets {
    position: absolute;
    display: block;
    z-index: 1000;
    top: 0;
    left: 0
}

.tp-tab,
.tp-thumb {
    cursor: pointer;
    position: absolute;
    opacity: 0.5;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box
}

.tp-arr-imgholder,
.tp-videoposter,
.tp-thumb-image,
.tp-tab-image {
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.tp-tab:hover,
.tp-tab.selected,
.tp-thumb:hover,
.tp-thumb.selected {
    opacity: 1
}

.tp-tab-mask,
.tp-thumb-mask {
    box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important
}

.tp-tabs,
.tp-thumbs {
    box-sizing: content-box !important;
    -webkit-box-sizing: content-box !important;
    -moz-box-sizing: content-box !important
}

.tp-bullet {
    width: 15px;
    height: 15px;
    position: absolute;
    background: #fff;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer
}

.tp-bullet.selected,
.tp-bullet:hover {
    background: #fff
}

.tp-bannertimer {
    background: #000;
    background: rgba(0, 0, 0, 0.15);
    height: 5px
}

.tparrows {
    cursor: pointer;
    background: #000;
    background: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 1000
}

.tparrows:hover {
    background: #000
}

.tparrows:before {
    font-family: "revicons";
    font-size: 15px;
    color: #fff;
    display: block;
    line-height: 40px;
    text-align: center
}

.tparrows.tp-leftarrow:before {
    content: '\e824'
}

.tparrows.tp-rightarrow:before {
    content: '\e825'
}

.dddwrappershadow {
    box-shadow: 0 45px 100px rgba(0, 0, 0, 0.4)
}

.hglayerinfo {
    position: fixed;
    bottom: 0;
    left: 0;
    color: #FFF;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.75);
    padding: 5px 10px;
    z-index: 2000;
    white-space: normal
}

.hginfo {
    position: absolute;
    top: -2px;
    left: -2px;
    color: #e74c3c;
    font-size: 12px;
    font-weight: 600;
    background: #000;
    padding: 2px 5px
}

.indebugmode .tp-caption:hover {
    border: 1px dashed #c0392b !important
}

.helpgrid {
    border: 2px dashed #c0392b;
    position: absolute;
    top: 0;
    peft: 0;
    z-index: 0
}

.tp-caption.roundedimage img {
    border-radius: 300px
}

.tp-caption.roundedimage img {
    border-radius: 300px
}

.tp-caption.roundedimage img {
    border-radius: 300px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
    color: #0084B4 !important
}

.tp-caption.Twitter-Content a:hover {
    color: #0084B4 !important;
    text-decoration: underline !important
}

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
    color: #0084B4 !important
}

.tp-caption.Twitter-Content a:hover {
    color: #0084B4 !important;
    text-decoration: underline !important
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    line-height: 140%
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.feature-round {
    color: #000;
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    width: 100px;
    height: 100px;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    padding: 35px 35px 35px 35px;
    background-color: transparent;
    border-radius: 50px 50px 50px 50px;
    border-width: 0;
    border-color: #000000;
    border-style: none
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption.Twitter-Content a,
.tp-caption.Twitter-Content a:visited {
    color: #0084B4 !important
}

.tp-caption.Twitter-Content a:hover {
    color: #0084B4 !important;
    text-decoration: underline !important
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    line-height: 140%
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.feature-round {
    color: #000;
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    width: 100px;
    height: 100px;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    padding: 35px 35px 35px 35px;
    background-color: transparent;
    border-radius: 50px 50px 50px 50px;
    border-width: 0;
    border-color: #000000;
    border-style: none
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    line-height: 140%
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.feature-round {
    color: #000;
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    width: 100px;
    height: 100px;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    padding: 35px 35px 35px 35px;
    background-color: transparent;
    border-radius: 50px 50px 50px 50px;
    border-width: 0;
    border-color: #000000;
    border-style: none
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    line-height: 140%
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.feature-round {
    color: #000;
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    width: 100px;
    height: 100px;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    padding: 35px 35px 35px 35px;
    background-color: transparent;
    border-radius: 50px 50px 50px 50px;
    border-width: 0;
    border-color: #000000;
    border-style: none
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    line-height: 140%
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.feature-round {
    color: #000;
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    width: 100px;
    height: 100px;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    padding: 35px 35px 35px 35px;
    background-color: transparent;
    border-radius: 50px 50px 50px 50px;
    border-width: 0;
    border-color: #000000;
    border-style: none
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    line-height: 140%
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.feature-round {
    color: #000;
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    width: 100px;
    height: 100px;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    padding: 35px 35px 35px 35px;
    background-color: transparent;
    border-radius: 50px 50px 50px 50px;
    border-width: 0;
    border-color: #000000;
    border-style: none
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    line-height: 140%
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.feature-round {
    color: #000;
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    width: 100px;
    height: 100px;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    padding: 35px 35px 35px 35px;
    background-color: transparent;
    border-radius: 50px 50px 50px 50px;
    border-width: 0;
    border-color: #000000;
    border-style: none
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Open Sans", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    line-height: 140%
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.feature-round {
    color: #000;
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    width: 100px;
    height: 100px;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    padding: 35px 35px 35px 35px;
    background-color: transparent;
    border-radius: 50px 50px 50px 50px;
    border-width: 0;
    border-color: #000000;
    border-style: none
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    line-height: 140%
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.feature-round {
    color: #000;
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    width: 100px;
    height: 100px;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    padding: 35px 35px 35px 35px;
    background-color: transparent;
    border-radius: 50px 50px 50px 50px;
    border-width: 0;
    border-color: #000000;
    border-style: none
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    line-height: 140%
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.feature-round {
    color: #000;
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    width: 100px;
    height: 100px;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    padding: 35px 35px 35px 35px;
    background-color: transparent;
    border-radius: 50px 50px 50px 50px;
    border-width: 0;
    border-color: #000000;
    border-style: none
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.largeredbtn {
    font-family: "Raleway", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 60px;
    color: #fff !important;
    text-decoration: none;
    padding-left: 40px;
    padding-right: 80px;
    padding-top: 22px;
    padding-bottom: 22px;
    background: rgb(234, 91, 31);
    background: linear-gradient(to bottom, rgba(234, 91, 31, 1) 0, rgba(227, 58, 12, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ea5b1f', endColorstr='#e33a0c', GradientType=0)
}

.largeredbtn:hover {
    background: rgb(227, 58, 12);
    background: linear-gradient(to bottom, rgba(227, 58, 12, 1) 0, rgba(234, 91, 31, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e33a0c', endColorstr='#ea5b1f', GradientType=0)
}

.fullrounded img {
    border-radius: 400px
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    line-height: 140%
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.tp-caption a {
    color: #296169;
    text-shadow: none;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #296169
}

.feature-round {
    color: #000;
    background: #fff;
    background: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    width: 100px;
    height: 100px;
    line-height: 14px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    padding: 35px 35px 35px 35px;
    background-color: transparent;
    border-radius: 50px 50px 50px 50px;
    border-width: 0;
    border-color: #000000;
    border-style: none
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption a {
    color: #ff7302;
    text-shadow: none;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out
}

.tp-caption a:hover {
    color: #ffa902
}

.tp-caption.lightgrey_divider {
    text-decoration: none;
    background-color: rgb(235, 235, 235);
    background-color: rgba(235, 235, 235, 1);
    border-width: 0;
    border-color: rgb(34, 34, 34);
    border-style: none
}

.tp-caption.large_bold_grey {
    font-size: 60px;
    line-height: 60px;
    font-weight: 800;
    font-family: "Open Sans";
    color: rgb(102, 102, 102);
    text-decoration: none;
    background-color: transparent;
    padding: 1px 4px 0;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.medium_thin_grey {
    font-size: 34px;
    line-height: 30px;
    font-weight: 300;
    font-family: "Open Sans";
    color: rgb(102, 102, 102);
    text-decoration: none;
    background-color: transparent;
    padding: 1px 4px 0;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.small_thin_grey {
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    font-family: "Open Sans";
    color: rgb(117, 117, 117);
    text-decoration: none;
    background-color: transparent;
    padding: 1px 4px 0;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.large_bold_darkblue {
    font-size: 58px;
    line-height: 60px;
    font-weight: 800;
    font-family: "Open Sans";
    color: rgb(52, 73, 94);
    text-decoration: none;
    background-color: transparent;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.medium_bg_darkblue {
    font-size: 20px;
    line-height: 20px;
    font-weight: 800;
    font-family: "Open Sans";
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: rgb(52, 73, 94);
    padding: 10px;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.medium_bold_red {
    font-size: 24px;
    line-height: 30px;
    font-weight: 800;
    font-family: "Open Sans";
    color: rgb(227, 58, 12);
    text-decoration: none;
    background-color: transparent;
    padding: 0;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.medium_light_red {
    font-size: 21px;
    line-height: 26px;
    font-weight: 300;
    font-family: "Open Sans";
    color: rgb(227, 58, 12);
    text-decoration: none;
    background-color: transparent;
    padding: 0;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.medium_bg_red {
    font-size: 20px;
    line-height: 20px;
    font-weight: 800;
    font-family: "Open Sans";
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: rgb(227, 58, 12);
    padding: 10px;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.medium_bold_orange {
    font-size: 24px;
    line-height: 30px;
    font-weight: 800;
    font-family: "Open Sans";
    color: rgb(243, 156, 18);
    text-decoration: none;
    background-color: transparent;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.medium_bg_orange {
    font-size: 20px;
    line-height: 20px;
    font-weight: 800;
    font-family: "Open Sans";
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: rgb(243, 156, 18);
    padding: 10px;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.medium_bg_asbestos {
    font-size: 20px;
    line-height: 20px;
    font-weight: 800;
    font-family: "Open Sans";
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: rgb(127, 140, 141);
    padding: 10px;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.large_bold_white {
    font-size: 58px;
    line-height: 60px;
    font-weight: 800;
    font-family: "Open Sans";
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: transparent;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.medium_light_white {
    font-size: 30px;
    line-height: 36px;
    font-weight: 300;
    font-family: "Open Sans";
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: transparent;
    padding: 0;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.grassfloor {
    text-decoration: none;
    background-color: rgb(160, 179, 151);
    background-color: rgba(160, 179, 151, 1);
    border-width: 0;
    border-color: rgb(34, 34, 34);
    border-style: none
}

.tp-caption.mediumlarge_light_white {
    font-size: 34px;
    line-height: 40px;
    font-weight: 300;
    font-family: "Open Sans";
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: transparent;
    padding: 0;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.mediumlarge_light_white_center {
    font-size: 34px;
    line-height: 40px;
    font-weight: 300;
    font-family: "Open Sans";
    color: #ffffff;
    text-decoration: none;
    background-color: transparent;
    padding: 0 0 0 0;
    border-width: 0;
    border-color: rgb(255, 214, 88);
    border-style: none
}

.tp-caption.fullbg_gradient {
    text-decoration: none;
    background-color: #490202;
    border-width: 0;
    border-color: rgb(0, 0, 0);
    border-style: none
}

.tp-caption.light_heavy_70_shadowed {
    font-size: 70px;
    line-height: 70px;
    font-weight: 900;
    font-family: Raleway;
    color: #ffffff;
    text-decoration: none;
    background-color: transparent;
    text-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
    border-width: 0;
    border-color: rgb(0, 0, 0);
    border-style: none
}

.tp-caption.black_heavy_60 {
    font-size: 60px;
    line-height: 60px;
    font-weight: 900;
    font-family: Raleway;
    color: rgb(0, 0, 0);
    text-decoration: none;
    background-color: transparent;
    text-shadow: none;
    border-width: 0;
    border-color: rgb(0, 0, 0);
    border-style: none
}

.tp-caption.black_bold_bg_20 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 900;
    font-family: Raleway;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: rgb(0, 0, 0);
    padding: 5px 8px;
    text-shadow: none;
    border-width: 0;
    border-color: rgb(0, 0, 0);
    border-style: none
}

.tp-caption.green_bold_bg_20 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 900;
    font-family: Raleway;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: rgb(134, 181, 103);
    padding: 5px 8px;
    text-shadow: none;
    border-width: 0;
    border-color: rgb(0, 0, 0);
    border-style: none
}

.tp-caption.fullrounded {
    border-radius: 400px 400px 400px 400px;
    border-width: 0;
    border-color: rgb(34, 34, 34);
    border-style: none
}

.tp-caption.white_thin_34 {
    font-size: 35px;
    line-height: 35px;
    font-weight: 200;
    font-family: Raleway;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: transparent;
    text-shadow: none;
    border-width: 0;
    border-color: rgb(0, 0, 0);
    border-style: none
}

.tp-caption.white_heavy_70 {
    font-size: 70px;
    line-height: 70px;
    font-weight: 900;
    font-family: Raleway;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: transparent;
    border-width: 0;
    border-color: rgb(0, 0, 0);
    border-style: none
}

.tp-caption.noshadow {}

.tp-caption.arrowicon {
    line-height: 1px;
    border-width: 0;
    border-color: rgb(34, 34, 34);
    border-style: none
}

.tp-caption.white_bold_bg_20 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 900;
    font-family: Raleway;
    color: rgb(0, 0, 0);
    text-decoration: none;
    background-color: rgb(255, 255, 255);
    padding: 5px 8px;
    text-shadow: none;
    border-width: 0;
    border-color: rgb(0, 0, 0);
    border-style: none
}

.tp-caption.red_bold_bg_20 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 900;
    font-family: Raleway;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: rgb(224, 51, 0);
    padding: 5px 8px;
    text-shadow: none;
    border-width: 0;
    border-color: rgb(0, 0, 0);
    border-style: none
}

.tp-caption.blue_bold_bg_20 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 900;
    font-family: Raleway;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: rgb(53, 152, 220);
    padding: 5px 8px;
    text-shadow: none;
    border-width: 0;
    border-color: rgb(0, 0, 0);
    border-style: none
}

.tp-caption.light_heavy_40 {
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
    font-family: Raleway;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: transparent;
    text-shadow: none;
    border-width: 0;
    border-color: rgb(0, 0, 0);
    border-style: none
}

.tp-caption.light_heavy_70 {
    font-size: 70px;
    line-height: 70px;
    font-weight: 900;
    font-family: Raleway;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: transparent;
    text-shadow: none;
    border-width: 0;
    border-color: rgb(0, 0, 0);
    border-style: none
}

.tparrows:before {
    line-height: normal
}

#slidetopcontent {
    background: #353535;
    color: #949494 !important;
    padding: 0;
    max-height: 0;
    position: relative;
    overflow: hidden;
    transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out
}

#slidetopcontent.showed {
    max-height: 800px;
    padding: 20px 0
}

#slidetopcontentShower:before {
    content: "\f107";
    font-family: "FontAwesome";
    font-size: 26px;
    width: 24px;
    height: 15px;
    text-align: center;
    display: block
}

#slidetopcontentShower {
    cursor: pointer;
    width: 24px;
    height: 15px;
    position: absolute;
    top: 2px;
    left: 50%;
    margin-left: -12px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    transition: opacity 300ms ease-in-out;
    -moz-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    zoom: 1;
    filter: alpha(opacity=70);
    opacity: 0.7
}

#slidetopcontentShower:hover {
    filter: alpha(opacity=100);
    opacity: 1.0
}

#slidetopcontentShower.showed {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    top: 13px
}

#slidetopcontent.showed {
    height: auto;
    overflow: visible;
    padding: 20px 0
}

#slidetopcontent ul {
    list-style-type: none
}

#slidetopcontent li img {
    width: 100%;
    filter: alpha(opacity=100);
    opacity: 1.0;
    height: auto;
    zoom: 1;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    transition: opacity 300ms ease-in-out;
    -moz-transition: opacity 300ms ease-in-out;
    -webkit-transition: opacity 300ms ease-in-out;
    display: block
}

#slidetopcontent li img:hover {
    filter: alpha(opacity=85);
    opacity: 0.85
}

.slidetopcontent .rte {
    line-height: 16px
}

#slidetopcontent .rte li {
    list-style-type: disc;
    float: none;
    margin-left: 20px
}

.slidetopcontent h1 {
    padding: 0;
    background: none
}

.slidetopcontent h4 {
    padding: 0 0 10px 0;
    font-size: 20px;
    line-height: 1.2em;
    color: #666;
    text-transform: none;
    background: none;
    color: #eeeeee
}

#slidetopcontent p {
    padding-left: 0
}

#footer1 #textbannersmodule {
    margin-top: 20px
}

#textbannersmodule ul {
    list-style-type: none
}

#textbannersmodule li {
    list-style-type: none;
    margin-bottom: 20px;
    text-align: center
}

#textbannersmodule li:last-child .txtbanner {
    border-right: none;
    border-bottom: none;
    padding-bottom: 0
}

#textbannersmodule li .circle {
    width: 85px;
    height: 85px;
    margin: 0 auto;
    margin-bottom: 10px
}

#textbannersmodule a:hover {
    text-decoration: none
}

#textbannersmodule li img {
    width: 100%
}

#textbannersmodule .txtbanner {
    border-right: 1px solid #dddddd
}

@media (max-width:767px) {
    #textbannersmodule .txtbanner {
        border-right: none;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 20px
    }
}

#textbannersmodule .txtbanner span {
    transition: all 300ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -webkit-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out
}

#textbannersmodule .txtbanner:hover span {
    zoom: 1;
    filter: alpha(opacity=70);
    opacity: 0.7
}

#textbannersmodule li.last-item .txtbanner {
    border-right: none
}

#textbannersmodule .txttitle {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    display: block
}

#textbannersmodule .txtlegend {
    display: block;
    font-size: 14px;
    margin-top: 3px
}

#textbannersmodule.no_borders .txtbanner {
    border: none !important
}

#textbannersmodule.iconleft li {
    text-align: left
}

#textbannersmodule.iconleft a {
    padding-top: 5px;
    display: block
}

#textbannersmodule.iconleft .circle {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 15px
}

#textbannersmodule.iconleft .circle i {
    line-height: 60px;
    font-size: 18px
}

#textbannersmodule .txtbanner1 .circle {
    color: ;
    background-color:
}

#textbannersmodule .txtbanner1 .circle:hover {
    color: ;
    background-color:
}

#textbannersmodule .txtbanner1 .txttitle {
    color:
}

#textbannersmodule .txtbanner1 .txtlegend {
    color:
}

#textbannersmodule .txtbanner2 .circle {
    color: ;
    background-color:
}

#textbannersmodule .txtbanner2 .circle:hover {
    color: ;
    background-color:
}

#textbannersmodule .txtbanner2 .txttitle {
    color:
}

#textbannersmodule .txtbanner2 .txtlegend {
    color:
}

#textbannersmodule .txtbanner3 .circle {
    color: ;
    background-color:
}

#textbannersmodule .txtbanner3 .circle:hover {
    color: ;
    background-color:
}

#textbannersmodule .txtbanner3 .txttitle {
    color:
}

#textbannersmodule .txtbanner3 .txtlegend {
    color:
}

ul#header_links {
    list-style-type: none;
    float: left
}

@media (max-width:999px) {
    ul#header_links {
        display: none
    }
}

ul#header_links li {
    float: left;
    padding: 0 10px
}

ul#header_links a {
    text-align: center
}

#twitter_block_module {
    border-top: 1px solid #dddddd;
    padding: 20px 0
}

#twitter_block_module .tw_title {
    border-right: 1px solid #dddddd
}

#twitter_block_module .tw_title h4 {
    font-weight: normal;
    font-size: 16px;
    line-height: 60px;
    margin: 0
}

#twitter_block_module .tw_title h4 i {
    font-size: 24px;
    margin-right: 10px;
    position: relative;
    top: 2px
}

@media (max-width:1000px) {
    #twitter_block_module .tw_title h4 span {
        display: none
    }
}

#twitter_block_module .tw_content {
    height: 60px;
    overflow: hidden
}

#twitter_block_module .tw_content ul {
    margin: 0;
    padding: 0;
    margin-left: 10px
}

#twitter_block_module .tw_content ul li {
    height: 50px;
    margin: 5px 0;
    padding: 0
}

#twitter_block_module .tweet {
    margin: 0
}

#twitter_block_module .timePosted {
    font-style: italic
}

#twitter_block_module .user {
    clear: left
}

#twitter_block_module .user span {
    display: none
}

#twitter_block_module .user img {
    width: 50px;
    margin-right: 10px
}

#twitter_block_module .interact {
    display: none;
    float: left;
    margin-top: -10px
}

#twitter_block_module .interact a {
    margin-left: 5px;
    float: left;
    font-size: 0
}

#twitter_block_module .interact a:before {
    font-size: 14px;
    font-family: "FontAwesome"
}

#twitter_block_module .twitter_reply_icon:before {
    content: "\F112"
}

#twitter_block_module .twitter_retweet_icon:before {
    content: "\F079"
}

#twitter_block_module .twitter_fav_icon:before {
    content: "\F005"
}

#footer #twitter_block_module {
    border-bottom: 1px solid #CECECE;
    padding-top: 0;
    border-top: none;
    margin-bottom: 20px
}

.iqitmegamenu-wrapper {
    z-index: 1;
    clear: both
}

.iqitmegamenu-wrapper.cbp-hor-width-0 {
    padding-left: 0;
    padding-right: 0
}

.cbp-hor-width-1 .container {
    padding-left: 0;
    padding-right: 0
}

.iqitmegamenu .container .row {
    margin-left: 0;
    margin-right: 0
}

.cbp-sticky.cbp-sticky-transparent {
    opacity: 0.85;
    transition: opacity 180ms ease-in-out
}

.cbp-sticky.cbp-sticky-transparent:hover {
    opacity: 1
}

#iqitmegamenu-horizontal.cbp-sticky {
    position: fixed !important;
    top: 0 !important;
    width: 100% !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
    z-index: 90;
    left: 0;
    -webkit-backface-visibility: hidden
}

@media (max-width:999px) {
    #iqitmegamenu-horizontal.cbp-sticky {
        position: static !important
    }
}

#iqitmegamenu-horizontal.cbp-sticky .cbp-hrmenu-tab>a>.cbp-legend {
    display: none
}

.cbp-hrmenu {
    width: 100%
}

@media (max-width:999px) {
    .cbp-hrmenu {
        display: none
    }
}

.cbp-hrmenu ul {
    margin: 0;
    padding: 0;
    list-style-type: none
}

.cbp-hrmenu .col-xs-12 {
    float: left
}

.cbp-hrmenu .cbp-tab-title {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px
}

.cbp-hrmenu .cbp-manufacturers {
    margin-top: -10px
}

.cbp-hrmenu .cbp-manufacturers li {
    margin-top: 10px
}

.cbp-hrmenu .cbp-manufacturers li:hover {
    opacity: 0.85
}

.cbp-hrmenu .cbp-vertical-title {
    transition: all 180ms ease-in-out;
    padding: 0 15px
}

.cbp-hrmenu .cbp-vertical-title .icon {
    margin-right: 10px
}

.cbp-hrmenu .cbp-categories-row {
    margin-top: -10px
}

.cbp-hrmenu .cbp-categories-row>div {
    margin-top: 10px
}

.cbp-hrmenu .menu_row {
    position: static
}

.cbp-hrmenu .cbp-empty-column .cbp-menu-column-inner>.menu_row {
    margin-top: -20px
}

.cbp-hrmenu .cbp-tabs-container {
    width: 100%
}

.cbp-hrmenu .cbp-tabs-container .img-responsive {
    width: 100%
}

.cbp-hrmenu .cbp-absolute-column {
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 2;
    width: auto !important
}

.cbp-hrmenu .cbp-column-title {
    display: block;
    margin-bottom: 8px
}

.cbp-hrmenu .cbp-column-title .cbp-legend.cbp-legend-inner {
    top: -2px
}

.cbp-hrmenu .cbp-column-title .cbp-legend.cbp-legend-inner .cbp-legend-arrow:before {
    top: -1px
}

.cbp-hrmenu .cbp-column-title.cbp-column-title-inline {
    padding: 0 !important;
    border: none !important;
    margin: 0 !important;
    margin-right: 10px !important;
    display: inline-block;
    vertical-align: middle
}

.cbp-hrmenu .cbp-menu-column {
    margin-top: 20px
}

.cbp-hrmenu .cbp-menu-column .row {
    margin-left: -10px;
    margin-right: -10px
}

.cbp-hrmenu .cbp-mainlink-iicon,
.cbp-hrmenu .cbp-mainlink-icon {
    width: auto;
    display: inline;
    vertical-align: middle;
    margin-right: 4px
}

.cbp-hrmenu .cbp-onlyicon .cbp-mainlink-iicon,
.cbp-hrmenu .cbp-onlyicon .cbp-mainlink-icon {
    margin-right: 0
}

.cbp-hrmenu .cbp-legend {
    display: inline-block;
    top: -15px;
    right: 5px;
    background-color: #999999;
    color: #ffffff;
    position: absolute;
    font-weight: normal;
    font-size: 10px;
    padding: 4px 6px;
    text-transform: none;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15)
}

.cbp-hrmenu .cbp-legend .cbp-legend-arrow:before {
    font-family: "FontAwesome";
    content: "\F0D7";
    font-size: 15px;
    position: absolute;
    bottom: -10px;
    right: 5px
}

.cbp-hrmenu .cbp-legend.cbp-legend-inner {
    position: relative;
    top: 0;
    margin-left: 5px;
    right: 0;
    padding: 2px 4px;
    vertical-align: middle
}

.cbp-hrmenu .cbp-legend.cbp-legend-inner .cbp-legend-arrow:before {
    content: "\f0d9";
    bottom: 0;
    top: 0;
    margin-top: 1px;
    z-index: 2;
    right: auto;
    left: -4px
}

.cbp-hrmenu .cbp-tab-content {
    border-left: 1px solid #cecece;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 0;
    vertical-align: top
}

.cbp-hrmenu .cbp-products-big * {
    box-sizing: border-box
}

.cbp-hrmenu .cbp-products-big .quick-view {
    font-size: 0
}

.cbp-hrmenu .cbp-products-big .online-label,
.cbp-hrmenu .cbp-products-big .new-label,
.cbp-hrmenu .cbp-products-big .sale-label {
    box-sizing: content-box
}

.cbp-hrmenu .cbp-products-big li.ajax_block_product {
    text-align: center;
    margin-bottom: 10px
}

.cbp-hrmenu .cbp-products-big li.ajax_block_product:hover {
    padding-top: 0;
    padding-bottom: 0
}

.cbp-hrmenu .cbp-products-big li.ajax_block_product:hover .functional-buttons-grid {
    visibility: visible;
    opacity: 0.9;
    margin-bottom: 0
}

.cbp-hrmenu .cbp-products-list .product-name,
.cbp-hrmenu .cbp-products-list .product-name-container {
    height: auto;
    line-height: inherit
}

.cbp-hrmenu .cbp-products-list>li {
    padding-top: 9px;
    padding-bottom: 9px
}

.cbp-hrmenu .cbp-products-list .product-image-container {
    float: left;
    margin-right: 10px;
    max-width: 60px;
    min-width: 30px
}

.cbp-hrmenu .cbp-hrsub-tabs-names {
    margin-right: -10px;
    margin-left: -10px
}

.cbp-hrmenu .cbp-hrsub-tabs-names li {
    position: relative;
    border-bottom: 1px solid #cecece
}

.cbp-hrmenu .cbp-hrsub-tabs-names li .cbp-inner-border-hider {
    position: absolute;
    top: 0;
    background: white;
    display: block;
    height: 100%;
    z-index: 1;
    display: none
}

.cbp-hrmenu .cbp-hrsub-tabs-names li.active .cbp-inner-border-hider {
    display: block
}

.cbp-hrmenu .cbp-hrsub-tabs-names li a {
    position: relative;
    display: block;
    transition: none;
    padding: 14px 16px
}

.cbp-hrmenu.cbp-submenu-notarrowed .cbp-submenu-aindicator {
    display: none
}

.cbp-hrmenu .cbp-submenu-aindicator {
    margin-left: 3px
}

.cbp-hrmenu .cbp-submenu-it-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    height: 15px
}

.cbp-hrmenu.cbp-menu-centered>ul {
    text-align: center
}

.cbp-hrmenu.cbp-menu-centered>ul>li {
    text-align: left;
    float: none
}

.cbp-hrmenu .cbp-links li {
    margin: 3px 0;
    padding-left: 2px;
    position: relative
}

.cbp-hrmenu .cbp-links li:last-child {
    margin-bottom: 0
}

.cbp-hrmenu .cbp-links li.cbp-hrsub-haslevel2>div>a:after,
.cbp-hrmenu .cbp-links li .cbp-hrsub-haslevel3>div>a:after {
    content: "\f0da";
    display: inline;
    font-family: "FontAwesome";
    padding-left: 4px;
    opacity: 0.2;
    position: relative;
    transition: opacity 0.2s ease
}

.cbp-hrmenu .cbp-links li a {
    padding-left: 10px;
    display: inline-block
}

.cbp-hrmenu .cbp-links li a:before {
    content: "\f105";
    display: inline;
    font-family: "FontAwesome";
    position: absolute;
    left: 0;
    transition: left 0.2s ease, right 0.2s ease
}

.cbp-hrmenu .cbp-links li a:hover:before {
    left: 3px
}

.cbp-hrmenu .cbp-links.cbp-valinks-vertical li {
    display: inline-block;
    margin: 0;
    margin-right: 12px;
    padding: 0
}

.cbp-hrmenu .cbp-links.cbp-valinks-vertical.cbp-valinks-vertical2 {
    display: inline-block;
    vertical-align: middle
}

.cbp-hrmenu.cbp-vertical>ul>li {
    float: none;
    display: block
}

.cbp-vertical-on-top {
    float: left;
    width: 250px;
    position: relative
}

.cbp-vertical-on-top>.cbp-vertical>ul {
    position: absolute;
    visibility: hidden;
    opacity: 0
}

.cbp-vertical-on-top.cbp-vert-expanded>.cbp-vertical>ul {
    visibility: visible !important;
    opacity: 1 !important
}

.cbp-vertical-on-top nav#cbp-hrmenu1 {
    margin-bottom: 0
}

#index .cbp-homepage-expanded>.cbp-vertical>ul {
    visibility: visible;
    opacity: 1
}

#index .cbp-homepage-expanded-search>.cbp-vertical>ul {
    visibility: hidden;
    opacity: 0
}

#index .cbp-sticky .cbp-homepage-expanded>.cbp-vertical>ul {
    visibility: hidden;
    opacity: 0
}

#index .cbp-sticky .cbp-vert-expanded>.cbp-vertical>ul {
    visibility: visible;
    opacity: 1
}

.cbp-hrmenu.cbp-vertical {
    width: 100%;
    margin-bottom: 20px
}

.cbp-hrmenu.cbp-vertical .cbp-submenu-aindicator {
    position: absolute;
    right: 8px;
    margin-top: 1px
}

.cbp-hrmenu.cbp-vertical .cbp-legend-vertical {
    top: -8px;
    right: 14px
}

.cbp-hrmenu.cbp-vertical .cbp-triangle-container {
    z-index: 101;
    left: auto;
    width: auto;
    top: 0;
    bottom: 0;
    height: 24px
}

.cbp-hrmenu.cbp-vertical>ul {
    width: 100%;
    max-width: 100%;
    padding: 0
}

.cbp-hrmenu.cbp-vertical>ul>li>a,
.cbp-hrmenu.cbp-vertical>ul>li .span.cbp-main-link {
    padding-left: 15px;
    padding-right: 15px;
    display: block
}

.cbp-hrmenu.cbp-vertical>ul>li:first-child>a,
.cbp-hrmenu.cbp-vertical>ul>li:first-child .span.cbp-main-link {
    border-top: none
}

.cbp-hrmenu.cbp-vertical .cbp-hrsub-wrapper {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: 100
}

.cbp-hrmenu.cbp-vertical .cbp-hropen .cbp-hrsub-wrapper {
    visibility: visible;
    opacity: 1.0
}

.cbp-hrmenu>ul,
.cbp-hrmenu .cbp-hrsub-inner {
    margin: 0 auto
}

.cbp-hrmenu>ul>li {
    display: inline-block;
    float: left
}

.cbp-hrmenu>ul>li>a,
.cbp-hrmenu>ul>li>span.cbp-main-link {
    display: inline-block;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.2s ease, background-color 0.2s ease
}

.cbp-hrmenu>ul>li:first-child>a,
.cbp-hrmenu>ul>li:first-child>span.cbp-main-link {
    border-left: none !important
}

.cbp-hrmenu.cbp-arrowed .cbp-triangle-container {
    display: block
}

.cbp-hrmenu .cbp-triangle-top {
    width: 0;
    height: 0;
    position: absolute;
    z-index: 1;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    top: 1px
}

.cbp-hrmenu .cbp-triangle-top-back {
    width: 0;
    height: 0;
    position: absolute;
    left: -1px;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: 13px solid #EAEAEA
}

.cbp-hrmenu .cbp-triangle-container {
    display: none;
    left: 0;
    position: absolute;
    right: 0;
    width: 24px;
    margin: auto;
    visibility: hidden;
    opacity: 0;
    z-index: 2
}

.cbp-hrmenu .cbp-triangle-left,
#columns .cbp-hrmenu .cbp-triangle-left {
    position: absolute;
    width: 0;
    height: 0;
    left: 1px;
    z-index: 1;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #fff
}

.cbp-hrmenu .cbp-triangle-left-back,
#columns .cbp-hrmenu .cbp-triangle-left-back {
    position: absolute;
    width: 0;
    height: 0;
    top: -1px;
    left: 0;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-right: 13px solid #EAEAEA
}

.cbp-horizontal.cbp-arrowed .cbp-triangle-container {
    position: absolute
}

.cbp-vertical.cbp-arrowed .cbp-hrsub-inner {
    margin-left: 12px
}

.cbp-horizontal.cbp-arrowed .cbp-hrsub-inner {
    margin-top: 12px
}

.cbp-hrmenu .cbp-hrsub {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    display: none;
    padding-bottom: 20px;
    padding: 0 !important
}

.cbp-hropen .cbp-hrsub {
    z-index: 1
}

.cbp-hropen .cbp-triangle-container,
.cbp-hropen .cbp-hrsub {
    visibility: visible;
    opacity: 1.0
}

.cbp-hrmenu .cbp-hrsub-inner {
    background: #fff;
    border: 1px solid #EAEAEA
}

.cbp-hrmenu .cbp-hrsub-inner>.row {
    margin-left: 10px;
    margin-right: 10px
}

.cbp-hrmenu .cbp-hrsub-inner>.row:last-child {
    margin-bottom: 20px
}

.cbp-hrsub-wide .cbp-hrsub-inner .container>.row:last-child {
    margin-bottom: 20px
}

.cbp-hrmenu .cbp-notfit {
    right: 0
}

.cbp-hrmenu .cbp-show {
    display: block
}

.cbp-category-link-w {
    position: relative;
    display: inline-block
}

.cbp-hrmenu ul.cbp-hrsub-level2 {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 100%;
    margin-left: 10px;
    margin-top: -20px;
    padding: 8px 10px;
    z-index: 3;
    width: auto;
    background: #fff;
    border: 1px solid #EAEAEA;
    white-space: nowrap
}

.cbp-hrmenu ul.cbp-hrsub-level3 {
    left: 101%
}

.cbp-hrsub-haslevel3:hover .cbp-hrsub-level2 {
    visibility: visible;
    opacity: 1.0
}

.cbp-hrsub-haslevel2:hover>div>ul.cbp-hrsub-level2 {
    visibility: visible;
    opacity: 1.0
}

.cbp-hrmenu .cbp-tab-pane {
    display: none
}

.cbp-hrmenu .cbp-tab-pane>div>.row:last-child {
    margin-bottom: 20px
}

.cbp-hrmenu .cbp-hropen .cbp-tab-pane.active {
    visibility: visible;
    opacity: 1.0
}

.cbp-hrmenu .cbp-tab-pane.active {
    display: table-cell
}

.cbp-hor-width-0 .cbp-hrsub-wide .cbp-hrsub {
    width: 100%;
    left: 0
}

.cbp-hrsub-wide.cbp-hrsub-widec .cbp-hrsub-inner {
    width: 100%;
    max-width: 100%
}

.cbp-hrsub-narrow {
    position: relative
}

.cbp-fade .cbp-hrsub,
.cbp-fade .cbp-triangle-container {
    transition: visibility 180ms ease-in-out, opacity 180ms ease-in-out
}

.cbp-fade .cbp-hrsub-level2 {
    transition: visibility 180ms ease-in-out, opacity 180ms ease-in-out
}

.cbp-fade .cbp-hrsub-wrapper {
    transition: visibility 180ms ease-in-out, opacity 180ms ease-in-out
}

.cbp-fade.cbp-vertical>ul {
    transition: visibility 180ms ease-in-out, opacity 180ms ease-in-out
}

.cbp-fade-slide-bottom .cbp-hrsub {
    transition: all 180ms ease-in-out;
    margin-top: 15px
}

.cbp-fade-slide-bottom .cbp-triangle-container {
    transition: all 180ms ease-in-out;
    bottom: -15px
}

.cbp-fade-slide-bottom .cbp-hropen .cbp-hrsub {
    transition: all 180ms ease-in-out;
    margin-top: 0
}

.cbp-fade-slide-bottom .cbp-hropen .cbp-triangle-container {
    bottom: 0
}

.cbp-fade-slide-bottom .cbp-hrsub-level2 {
    transition: visibility 180ms ease-in-out, opacity 180ms ease-in-out
}

.cbp-fade-slide-top .cbp-hrsub {
    transition: all 180ms ease-in-out;
    margin-top: -15px;
    z-index: -20
}

.cbp-fade-slide-top .cbp-triangle-container {
    transition: all 180ms ease-in-out;
    bottom: 15px
}

.cbp-fade-slide-top .cbp-hropen .cbp-hrsub {
    transition: all 180ms ease-in-out;
    margin-top: 0;
    z-index: 1
}

.cbp-fade-slide-top .cbp-hropen .cbp-triangle-container {
    bottom: 0
}

.cbp-fade-slide-top .cbp-hrsub-level2 {
    transition: visibility 180ms ease-in-out, opacity 180ms ease-in-out
}

.cbp-hrmenu .cbp-hrsub-inner:before,
.cbp-hrmenu .cbp-hrsub-inner:after {
    content: "";
    display: table
}

.cbp-hrmenu .cbp-hrsub-inner:after {
    clear: both
}

.cbp-hrsub h4 {
    color: #afdefa;
    padding: 2em 0 0.6em;
    margin: 0;
    font-size: 160%;
    font-weight: 300
}

@media (min-width:1000px) {
    .cbp-spmenu-push-toright {
        left: 0 !important
    }
}

#iqitmegamenu-mobile {
    display: none;
    text-align: left
}

@media (max-width:999px) {
    #iqitmegamenu-mobile {
        display: block
    }
}

#iqitmegamenu-mobile ul,
#iqitmegamenu-mobile li {
    list-style-type: none
}

#iqitmegamenu-mobile #iqitmegamenu-shower {
    text-transform: uppercase;
    padding: 12px 15px;
    cursor: pointer;
    line-height: 36px
}

#iqitmegamenu-mobile .iqitmegamenu-icon {
    margin-right: 10px;
    color: #000;
    display: block;
    background-color: #fff;
    font-weight: bold;
    font-size: 24px;
    float: left;
    width: 36px;
    text-align: center;
    cursor: pointer
}

#iqitmegamenu-mobile .iqitmegamenu-accordion {
    list-style: none;
    padding: 0;
    overflow: hidden;
    height: auto;
    max-height: 0;
    margin: 0;
    opacity: 0;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease;
    -webkit-transition-delay: 0s;
    transition: all 0.5s ease 0s
}

#iqitmegamenu-mobile .iqitmegamenu-accordion.showedmenu {
    border-top: none;
    padding: 12px;
    max-height: 9999px;
    opacity: 1;
    -moz-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease;
    -webkit-transition-delay: 0s;
    transition: all 0.5s ease 0s
}

#iqitmegamenu-mobile .iqitmegamenu-accordion>li {
    border-bottom: 1px dashed #dddddd
}

#iqitmegamenu-mobile .iqitmegamenu-accordion>li:last-child {
    border-bottom: none
}

#iqitmegamenu-mobile .iqitmegamenu-accordion>li>a {
    text-transform: uppercase;
    display: block;
    padding: 10px 0
}

#iqitmegamenu-mobile .iqitmegamenu-accordion>li ul {
    background-color: #f8f8f8
}

#iqitmegamenu-mobile .iqitmegamenu-accordion>li ul a {
    display: block;
    padding: 10px 0;
    border-top: 1px dashed #dddddd
}

#iqitmegamenu-mobile .iqitmegamenu-accordion ul {
    list-style: none;
    padding: 0 0 0 0
}

#iqitmegamenu-mobile .iqitmegamenu-accordion ul li {
    font-weight: normal;
    cursor: auto;
    padding: 0 0 0 7px
}

#iqitmegamenu-mobile .iqitmegamenu-accordion a {
    text-decoration: none
}

#iqitmegamenu-mobile .iqitmegamenu-accordion a:hover {
    text-decoration: none
}

#iqitmegamenu-accordion {
    display: none
}

#iqitmegamenu-accordion div.responsiveInykator {
    display: block;
    font-size: 24px;
    float: right;
    padding: 6px 0;
    position: relative;
    top: 7px;
    width: 32px;
    text-align: center;
    cursor: pointer
}

#iqitmegamenu-accordion>li ul {
    display: none
}

@media (max-width:999px) {
    #iqitmegamenu-accordion {
        display: block
    }
}

#iqitmegamenu-accordion.cbp-spmenu div.responsiveInykator {
    border: none
}

#iqitmegamenu-accordion.cbp-spmenu li a:hover {
    box-shadow: inset -134px 0 10px -133px rgba(0, 0, 0, 0.4)
}

#iqitmegamenu-accordion.cbp-spmenu>li a {
    text-transform: uppercase;
    font-weight: bold
}

#iqitmegamenu-accordion.cbp-spmenu>li ul {
    padding: 0 10px;
    display: none;
    font-size: 90%;
    box-shadow: inset -134px 0 10px -133px rgba(0, 0, 0, 0.4)
}

#iqitmegamenu-accordion.cbp-spmenu>li ul ul {
    box-shadow: none
}

#iqitmegamenu-accordion.cbp-spmenu>li ul ul a:hover {
    box-shadow: none
}

#iqitmegamenu-accordion.cbp-spmenu>li ul div.responsiveInykator {
    background: none;
    margin-right: 0;
    color: #fff
}

#iqitmegamenu-accordion.cbp-spmenu>li ul ul {
    margin: 0
}

#iqitmegamenu-accordion.cbp-spmenu>li ul ul a {
    text-transform: none;
    border: none
}

#iqitmegamenu-accordion.cbp-spmenu>li ul ul ul a {
    text-transform: none;
    font-weight: normal
}

#iqitmegamenu-mobile .iqitmegamenu-accordion li a {
    transition: all 0.2s ease
}

.cbp-spmenu div.responsiveInykator {
    margin-right: 10px
}

.cbp-spmenu-body {
    overflow-x: hidden;
    position: relative;
    left: 0;
    transition: all 0.2s ease
}

.cbp-spmenu-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 5102;
    visibility: hidden;
    opacity: 0;
    display: none
}

@media (max-width:999px) {
    .cbp-spmenu-overlay {
        display: block
    }
}

.cbp-spmenu-overlay-show {
    visibility: visible;
    opacity: 0.2
}

.cbp-spmenu {
    background: #47a3da;
    position: fixed;
    z-index: 5103;
    box-shadow: inset -134px 0 10px -133px rgba(0, 0, 0, 0.4);
    overflow-y: auto
}

.cbp-spmenu h3 {
    color: #afdefa;
    font-size: 1.9em;
    padding: 20px;
    margin: 0;
    font-weight: 300;
    background: #0d77b6
}

.cbp-spmenu a {
    display: block;
    color: #fff;
    font-size: 1.1em;
    font-weight: 300
}

.cbp-spmenu>li>ul>li>a:hover {
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 23px
}

.cbp-spmenu a:active {
    background: #afdefa;
    color: #47a3da
}

.cbp-spmenu-vertical {
    width: 240px;
    height: 100%;
    top: 0
}

.cbp-spmenu-vertical a {
    border-bottom: 1px solid #258ecd;
    padding: 1em
}

.cbp-spmenu-left {
    left: -240px
}

.cbp-spmenu-left.cbp-spmenu-open {
    left: 0
}

.cbp-spmenu-push {
    overflow-x: hidden;
    position: relative;
    left: 0
}

.cbp-spmenu-push-toright {
    left: 240px
}

.cbp-spmenu-push-toleft {
    left: -240px
}

.cbp-spmenu,
.cbp-spmenu-push {
    transition: all 0.2s ease
}

@media screen and (max-width:55.1875em) {
    .cbp-spmenu-top {
        top: -110px
    }

    .cbp-spmenu-bottom {
        bottom: -110px
    }
}

@media screen and (max-height:26.375em) {
    .cbp-spmenu-vertical {
        font-size: 90%;
        width: 190px
    }

    .cbp-spmenu-left,
    .cbp-spmenu-push-toleft {
        left: -190px
    }

    .cbp-spmenu-right {
        right: -190px
    }

    .cbp-spmenu-push-toright {
        left: 190px
    }
}

.container-xs-height {
    display: table;
    padding-left: 0;
    padding-right: 0
}

.row-xs-height {
    display: table-row
}

.row-xs-height:before {
    display: none
}

.row-xs-height:after {
    display: none
}

.col-xs-height {
    display: table-cell;
    float: none;
    vertical-align: top
}

#iqitmegamenu-horizontal #search_block_top_contentm {
    width: auto;
    float: right;
    position: relative;
    width: 260px;
    z-index: 3;
    right: 0;
    padding: 0
}

#iqitmegamenu-horizontal #search_block_top {
    position: absolute;
    top: 0;
    width: 250px;
    right: 0;
    bottom: 0;
    margin: auto
}

#iqitmegamenu-horizontal #search_block_top.iqit-search-c {
    top: 100%;
    bottom: auto
}

#iqitmegamenu-horizontal .iqit-search-cm {
    width: auto !important;
    margin-right: 0 !important
}

#iqitmegamenu-horizontal .iqit-search-cm .iqit-search-shower {
    margin-top: 0 !important;
    width: auto
}

#iqitmegamenu-horizontal .iqit-search-cm .iqit-search-shower .icon-search {
    margin-bottom: 0
}

@media (max-width:999px) {
    #iqitmegamenu-horizontal>.container {
        position: relative
    }
}

@media (max-width:767px) {
    #iqitmegamenu-horizontal #search_block_top_contentm {
        width: 100%;
        margin-bottom: 10px
    }

    #iqitmegamenu-horizontal #search_block_top_contentm.iqit-search-ncm {
        position: static;
        width: 100%;
        margin: 0 auto !important
    }

    #iqitmegamenu-horizontal #search_block_top_contentm.iqit-search-ncm .iqit-search {
        position: static;
        width: auto
    }
}

@media screen and (max-width:999px) {
    #iqitmegamenu-horizontal #search_block_top_contentm {
        position: absolute;
        right: 20px;
        top: 10px;
        bottom: 10px;
        margin-right: 0 !important
    }
}

.cbp-hor-width-1 #iqitmegamenu-horizontal #search_block_top_contentm {
    margin-right: 15px
}

@media (max-width:767px) {
    #iqitmegamenu-horizontal {
        background: transparent !important;
        border: none !important
    }
}

#cbp-close-mobile {
    padding: 12px 13px;
    text-align: right;
    box-shadow: inset -134px 0 10px -133px rgba(0, 0, 0, 0.4)
}

.inline-cell #cbp-hrmenu {
    position: static
}

#iqitmegamenu-horizontal {
    border-top: 1px none #cecece;
    border-bottom: 1px none #cecece;
    border-left: 1px none #cecece;
    border-right: 1px none #cecece;
    background-color: #000000
}

.cbp-horizontal .cbp-legend {
    background-color: #CA5058;
    color: #ffffff
}

.cbp-horizontal .cbp-legend .cbp-legend-arrow {
    color: #CA5058
}

.cbp-horizontal>ul>li.cbp-hropen>a,
.cbp-horizontal>ul>li.cbp-hropen>a:hover {
    background-color: #fafafa;
    color: #000000
}

#iqitmegamenu-horizontal .iqit-search-shower-i .icon-search {
    color: #ffffff;
    line-height: 45px
}

.cbp-horizontal>ul>li>a,
.cbp-horizontal>ul>li>span.cbp-main-link {
    color: #ffffff;
    line-height: 45px;
    padding-left: 14px;
    max-width: 400px;
    padding-right: 14px;
    text-transform: uppercase;
    font-size: 14px;
    border-left: 1px none #cecece
}

#iqitmegamenu-horizontal #search_block_top_contentm .iqit-search-shower-i {
    padding-left: 14px;
    padding-right: 14px
}

.cbp-horizontal .cbp-tab-title {
    line-height: 15px
}

@media (min-width:1320px) {
    .cbp-horizontal .cbp-tab-title {
        line-height: 15px
    }

    .cbp-horizontal>ul>li>a,
    .cbp-horizontal>ul>li>span.cbp-main-link {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px
    }

    #iqitmegamenu-horizontal #search_block_top_contentm .iqit-search-shower-i {
        padding-left: 20px;
        padding-right: 20px
    }
}

.cbp-vertical-on-top .cbp-vertical-title {
    line-height: 45px
}

#iqitmegamenu-horizontal #search_block_top_contentm {
    width: 210px
}

#iqitmegamenu-horizontal #search_block_top {
    width: 200px
}

#iqitmegamenu-horizontal #search_block_top .search_query {
    line-height: 30px;
    height: 30px;
    color: #777777 !important;
    background-color: #ffffff;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 1px solid #cecece !important
}

#iqitmegamenu-horizontal #search_block_top .search-cat-select {
    background-color: #ffffff
}

#iqitmegamenu-horizontal .search-cat-selector .selector span:after {
    border-right: 1px solid #cecece
}

#iqitmegamenu-horizontal #search_block_top .search-cat-selector .selector span:after {
    line-height: 32px
}

#iqitmegamenu-horizontal #search_block_top .search-cat-select,
#iqitmegamenu-horizontal #search_block_top .search-cat-selector .selector,
#iqitmegamenu-horizontal #search_block_top .search-cat-selector .selector span {
    line-height: 30px;
    height: 30px
}

#iqitmegamenu-horizontal #search_block_top .search-cat-selector .selector span {
    color: #777777 !important
}

#iqitmegamenu-horizontal #search_block_top_contentm {
    height: 45px !important
}

#iqitmegamenu-horizontal #search_block_top {
    height: 30px
}

#iqitmegamenu-horizontal #search_block_top .search_query::-webkit-input-placeholder {
    color: #777777 !important
}

#iqitmegamenu-horizontal #search_block_top .search_query:-moz-placeholder {
    color: #777777 !important
}

#iqitmegamenu-horizontal #search_block_top .search_query::-moz-placeholder {
    color: #777777 !important
}

#iqitmegamenu-horizontal #search_block_top .search_query:-ms-input-placeholder {
    color: #777777 !important
}

#iqitmegamenu-horizontal #search_block_top .button-search:before {
    color: #777777 !important
}

#iqitmegamenu-horizontal #search_block_top .button-search {
    top: 0;
    line-height: 30px
}

.cbp-horizontal>ul>li>a .cbp-mainlink-icon,
.cbp-horizontal>ul>li>a .cbp-mainlink-iicon {
    font-size: 14px;
    max-height: 14px
}

#search_block_top_contentm .iqit-search-shower-i .icon-search {
    font-size: 14px
}

.cbp-hrmenu .cbp-hrsub-inner,
.cbp-hrmenu ul.cbp-hrsub-level2 {
    border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
    border-left: 1px solid #cecece;
    border-right: 1px solid #cecece;
    background-color: #ffffff
}

.cbp-hrmenu .cbp-triangle-top {
    border-bottom-color: #ffffff;
    top: 1px
}

.cbp-hrmenu .cbp-triangle-left,
#columns .cbp-hrmenu .cbp-triangle-left {
    border-color: transparent;
    border-right-color: #ffffff;
    left: 1px
}

.cbp-hrmenu .cbp-triangle-top-back {
    left: -1px;
    border-bottom: 13px solid #cecece;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent
}

.cbp-hrmenu .cbp-triangle-left-back,
#columns .cbp-hrmenu .cbp-triangle-left-back {
    left: -13px;
    border-right: 13px solid #cecece;
    border-bottom: 13px solid transparent;
    border-left: 13px solid transparent
}

.cbp-hrmenu .menu_column {
    border-color: #cecece
}

.cbp-hrmenu .cbp-hrsub-inner,
.cbp-hrmenu ul.cbp-hrsub-level2 {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15)
}

.cbp-hrmenu .cbp-hrsub-tabs-names li .cbp-inner-border-hider {
    width: 1px;
    right: -1px
}

.cbp-hrmenu .cbp-hrsub-tabs-names li {
    border-bottom: 1px solid #cecece
}

.cbp-hrmenu .cbp-tab-pane {
    border-left: 1px solid #cecece
}

.is_rtl .cbp-hrmenu .cbp-tab-pane {
    border-right: 1px solid #cecece
}

.cbp-hrmenu .cbp-hrsub-inner .cbp-column-title,
.cbp-hrmenu .cbp-hrsub-inner a.cbp-column-title:link {
    font-size: 13px;
    line-height: 17px;
    color: #777777;
    text-transform: uppercase;
    font-weight: bold
}

.cbp-hrmenu .cbp-hrsub-inner a.cbp-column-title:hover {
    color: #333333
}

.cbp-hrmenu .cbp-hrsub-inner .cbp-column-title {
    padding-bottom: 6px;
    border-bottom: 1px solid #cecece
}

.cbp-hrmenu .cbp-hrsub-inner {
    font-size: 12px;
    line-height: 16px;
    color: #777777
}

.cbp-hrmenu .cbp-hrsub-inner a,
.cbp-hrmenu .cbp-hrsub-inner a:link {
    color: #777777
}

.cbp-hrmenu .cbp-hrsub-inner a:hover {
    color: #333333
}

.cbp-vertical-title {
    background-color: #000000;
    color: #ffffff;
    font-size: 14px;
    line-height: 45px;
    text-transform: uppercase
}

.cbp-vertical-title:hover {
    background-color: #282828;
    color: #ffffff
}

.cbp-hrmenu.cbp-vertical>ul {
    border-top: 1px none #cecece;
    border-bottom: 1px solid #cecece;
    border-left: 1px solid #cecece;
    border-right: 1px solid #cecece;
    background-color: transparent
}

.cbp-vertical .cbp-legend {
    background-color: #CA5058;
    color: #ffffff
}

.cbp-vertical .cbp-legend .cbp-legend-arrow {
    color: #CA5058
}

.cbp-vertical>ul>li.cbp-hropen>a,
.cbp-vertical>ul>li.cbp-hropen>a:hover {
    background-color: #fafafa;
    color: #000000
}

.cbp-vertical>ul>li>a,
.cbp-vertical>ul>li>span.cbp-main-link {
    color: #777777;
    padding-top: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-size: 14px;
    border-top: 1px solid #cecece
}

.cbp-vertical>ul>li>a .cbp-mainlink-icon,
.cbp-vertical>ul>li>a .cbp-mainlink-iicon {
    font-size: 14px;
    max-height: 14px
}

.cbp-vertical-on-top .cbp-hrmenu.cbp-vertical>ul {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15)
}

.cbp-hrmenu>ul>li.cbp-hrmenu-tab-1>a,
.cbp-hrmenu>ul>li.cbp-hrmenu-tab-1>span.cbp-main-link {
    background-color: #474747
}

.cbp-hrmenu li.cbp-hrmenu-tab-2 .menu-element-id-9>.cbp-menu-column-inner {
    background-color: #f1f1f1;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px
}

.cbp-hrmenu li.cbp-hrmenu-tab-2 .menu-element-id-9>.cbp-menu-column-inner ul.cbp-hrsub-level2 {
    background-color: #f1f1f1 !important
}

.cbp-hrmenu li.cbp-hrmenu-tab-2 .menu-element-id-7 {
    right: -100px;
    bottom: -70px
}

.cbp-hrmenu>ul>li.cbp-hrmenu-tab-4>a,
.cbp-hrmenu>ul>li.cbp-hrmenu-tab-4>span.cbp-main-link {
    background-color: #e16565;
    color: #ffffff
}

.cbp-hrmenu>ul>li.cbp-hropen.cbp-hrmenu-tab-4>a,
.cbp-hrmenu>ul>li.cbp-hropen.cbp-hrmenu-tab-4>a:hover {
    background-color: #ad3c3c;
    color: #ffffff
}

.cbp-hrmenu li.cbp-hrmenu-tab-4 .cbp-legend-main {
    background-color: #000000;
    color: #ffffff
}

.cbp-hrmenu li.cbp-hrmenu-tab-4 .cbp-legend-main .cbp-legend-arrow {
    color: #000000
}

.cbp-hrmenu li.cbp-hrmenu-tab-10 .cbp-hrsub-inner {
    background-image: url(http://warehouse.iqit-commerce.com/img/cms/sky.png);
    background-repeat: repeat-x;
    background-position: center top
}

.cbp-hrmenu li.cbp-hrmenu-tab-10 ul.cbp-hrsub-level2 {}

.cbp-hrmenu li.cbp-hrmenu-tab-10 .menu-element-id-4>.cbp-menu-column-inner {
    background-color: #ffffff;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px
}

.cbp-hrmenu li.cbp-hrmenu-tab-10 .menu-element-id-4>.cbp-menu-column-inner ul.cbp-hrsub-level2 {
    background-color: #ffffff !important
}

.cbp-hrmenu li.cbp-hrmenu-tab-10 .menu-element-id-11>.cbp-menu-column-inner {
    margin-top: -10px
}

.cbp-hrmenu li.cbp-hrmenu-tab-10 .menu-element-id-11>.cbp-menu-column-inner ul.cbp-hrsub-level2 {}

.cbp-hrmenu li.cbp-hrmenu-tab-10 .menu-element-id-12>.cbp-menu-column-inner {
    margin-top: -10px
}

.cbp-hrmenu li.cbp-hrmenu-tab-10 .menu-element-id-12>.cbp-menu-column-inner ul.cbp-hrsub-level2 {}

.cbp-hrmenu li.cbp-hrmenu-tab-10 .menu-element-id-13>.cbp-menu-column-inner {
    margin-top: -10px
}

.cbp-hrmenu li.cbp-hrmenu-tab-10 .menu-element-id-13>.cbp-menu-column-inner ul.cbp-hrsub-level2 {}

.cbp-hrmenu .cbp-hrsub-inner .cbp-tabs-names li a {
    background-color: #fbfbfb;
    color: #777777
}

.cbp-hrmenu .cbp-submenu-it-indicator {
    color: #777777
}

.cbp-tabs-names li a:hover,
.cbp-hrmenu .cbp-hrsub-tabs-names li.active a,
.cbp-tabs-names li .cbp-inner-border-hider {
    background-color: #ffffff;
    color: #777777
}

.cbp-hrmenu li.active .cbp-submenu-it-indicator {
    color: #777777
}

.cbp-hrmenu .innertabcontent-6 .menu-element-id-5>.cbp-menu-column-inner {
    border-top-style: dotted;
    border-top-width: 1px;
    border-top-color: #cecece;
    padding-top: 10px
}

.cbp-hrmenu .innertabcontent-6 .menu-element-id-5>.cbp-menu-column-inner ul.cbp-hrsub-level2 {}

.cbp-hrmenu .innertabcontent-6 .menu-element-id-3>.cbp-menu-column-inner {
    border-top-style: dotted;
    border-top-width: 1px;
    border-right-style: dotted;
    border-right-width: 1px;
    border-bottom-style: dotted;
    border-bottom-width: 1px;
    border-left-style: dotted;
    border-left-width: 1px;
    border-top-color: #e16565;
    border-right-color: #e16565;
    border-bottom-color: #e16565;
    border-left-color: #e16565;
    margin-top: -10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px
}

.cbp-hrmenu .innertabcontent-6 .menu-element-id-3>.cbp-menu-column-inner ul.cbp-hrsub-level2 {}

.cbp-hrmenu .innertabcontent-6 .menu-element-id-3>.cbp-menu-column-inner .cbp-column-title {
    color: #e16565 !important
}

.cbp-hrmenu .innertabcontent-6 .menu-element-id-9>.cbp-menu-column-inner {
    border-top-style: dotted;
    border-top-width: 1px;
    border-top-color: #cecece;
    margin-top: -10px;
    padding-top: 10px
}

.cbp-hrmenu .innertabcontent-6 .menu-element-id-9>.cbp-menu-column-inner ul.cbp-hrsub-level2 {}

.cbp-hrmenu .innertabcontent-8 {
    background-image: url(http://warehouse.iqit-commerce.com/img/cms/face.jpg);
    background-repeat: no-repeat;
    background-position: right top
}

.cbp-hrmenu .innertabcontent-8 ul.cbp-hrsub-level2 {}

.cbp-hrmenu .innertabcontent-9 {
    background-image: url(http://warehouse.iqit-commerce.com/img/cms/pattern16.png);
    background-repeat: repeat;
    background-position: center bottom
}

.cbp-hrmenu .innertabcontent-9 ul.cbp-hrsub-level2 {}

.cbp-hrmenu .innertabcontent-9 .menu-element-id-4>.cbp-menu-column-inner {
    background-color: #ffffff;
    border-top-style: dotted;
    border-top-width: 1px;
    border-right-style: dotted;
    border-right-width: 1px;
    border-bottom-style: dotted;
    border-bottom-width: 1px;
    border-left-style: dotted;
    border-left-width: 1px;
    border-top-color: #cecece;
    border-right-color: #cecece;
    border-bottom-color: #cecece;
    border-left-color: #cecece;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px
}

.cbp-hrmenu .innertabcontent-9 .menu-element-id-4>.cbp-menu-column-inner ul.cbp-hrsub-level2 {
    background-color: #ffffff !important
}

#iqitmegamenu-mobile #iqitmegamenu-shower {
    background-color: #000000;
    color: #ffffff;
    border: 1px solid #cecece
}

#iqitmegamenu-mobile .iqitmegamenu-icon {
    color: #000000;
    background-color: #ffffff
}

#iqitmegamenu-accordion {
    background-color: #ffffff;
    color: #777777;
    border-bottom: 1px solid #cecece;
    border-left: 1px solid #cecece;
    border-right: 1px solid #cecece
}

#iqitmegamenu-accordion {
    background-color: #ffffff;
    color: #777777
}

#iqitmegamenu-mobile .iqitmegamenu-accordion>li ul {
    background-color: #f8f8f8
}

#iqitmegamenu-accordion.cbp-spmenu>li ul,
#cbp-close-mobile {
    background-color: #f8f8f8
}

#cbp-close-mobile,
#cbp-close-mobile:active,
#cbp-close-mobile:hover {
    color: #777777
}

#iqitmegamenu-mobile .iqitmegamenu-accordion>li ul a {
    border-top: 1px solid #cecece
}

#iqitmegamenu-mobile .iqitmegamenu-accordion>li {
    border-bottom: 1px solid #cecece
}

.cbp-spmenu-vertical a {
    border-bottom: 1px solid #cecece
}

#iqitmegamenu-accordion.cbp-spmenu>li ul div.responsiveInykator {
    color: #777777
}

#iqitmegamenu-mobile .iqitmegamenu-accordion li a,
.cbp-spmenu a {
    color: #777777
}

#iqitmegamenu-mobile .iqitmegamenu-accordion li a:hover {
    color: #777777;
    background-color: #e5e5e5;
    padding-left: 10px
}

.cbp-spmenu a:hover {
    color: #777777;
    background-color: #e5e5e5
}

#iqitmegamenu-accordion div.responsiveInykator {
    color: #777777
}

.ajax_block_product {
    transition: box-shadow 0.3s ease
}

.ajax_block_product .button-container {
    margin-top: 3px
}

.ajax_block_product:hover {
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.15);
    z-index: 3;
    padding-top: 9px;
    padding-bottom: 9px
}

.product_list.list .ajax_block_product:hover,
.product_list.table .ajax_block_product:hover {
    padding-top: 0;
    padding-bottom: 0
}

.product_list.list .ajax_block_product:hover .product-container,
.product_list.table .ajax_block_product:hover .product-container {
    border-color: transparent
}

.product_list.list .ajax_block_product:hover+li .product-container,
.product_list.table .ajax_block_product:hover+li .product-container {
    border-color: transparent
}

.simpleblog-posts .post-item:hover {
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.15)
}

.breadcrumb {
    background: transparent !important;
    color: #777777 !important;
    border-bottom: 1px none #d6d4d4
}

.breadcrumb a,
.breadcrumb a:link {
    color: #777777 !important
}

.pl-quantity-input-wrapper {
    display: none !important
}

.new-label {
    color: #ffffff !important;
    background: #6ad4ff !important
}

.sale-label,
.price-percent-reduction,
#reduction_percent,
#reduction_amount {
    color: #ffffff !important;
    background: #f13340 !important
}

.online-label {
    color: #777777 !important;
    background: #ffffff !important
}

#availability_value,
.cart_avail .label-success,
.availabile_product {
    color: #ffffff !important;
    background: #46B64F !important
}

ul.product_list .availability span.out-of-stock,
 .availability span.out-of-stock,
#availability_statut #availability_value.warning_inline,
#availability_value.label-warning,
#availability_value.label-danger,
#last_quantities,
ul.product_list .availability .available-dif,
 .availability .available-dif {
    color: #ff7430 !important;
    background: #ffffff !important
}

.price.product-price,
.our_price_display,
.special-price {
    color: #f13340 !important
}

div.star:after {
    color: #f13340 !important
}

.yotpo .yotpo-bottomline .icon-star,
.yotpo .yotpo-bottomline .icon-half-star,
.yotpo .yotpo-bottomline .icon-empty-star,
.yotpo-stars .icon-star,
.yotpo-stars .icon-empty-star,
.yotpo-stars .yotpo-icon-star,
.yotpo-stars .yotpo-icon-empty-star,
.yotpo-stars .yotpo-icon-half-star,
.yotpo-stars .icon-half-star {
    color: #f13340 !important
}

.button.button-small {
    border: 1px none #d6d4d4 !important;
    color: #ffffff !important;
    background: #6f6f6f !important
}

.button.button-small:hover {
    color: #ffffff !important;
    background: #575757 !important
}

.button.button-medium {
    border: 1px none #d6d4d4 !important;
    color: #ffffff !important;
    background: #6f9642 !important
}

.button.button-medium:hover {
    color: #ffffff !important;
    background: #6f9642 !important
}

.button.ajax_add_to_cart_button,
.button.lnk_view {
    border: 1px none #d6d4d4 !important;
    color: #ffffff !important;
    background: #6f6f6f !important
}

.button.ajax_add_to_cart_button:hover,
.button.lnk_view:hover {
    color: #ffffff !important;
    background: #575757 !important
}

.box-info-product .exclusive {
    border: 1px none #d6d4d4 !important;
    color: #ffffff !important;
    background: #6f6f6f !important
}

.box-info-product .exclusive:hover {
    color: #ffffff !important;
    background: #575757 !important
}

.page-heading,
.pb-center-column h1 {
    font-size: 20px;
    line-height: 26px
}

.page-subheading {
    font-size: 16px;
    line-height: 16px
}

.nav-tabs>li>a,
.block .title_block,
.block h4,
h3.page-product-heading {
    font-size: 13px;
    line-height: 13px
}

.footer-container #footer1 h4,
.footer-container #footer1 h4 a,
.footer-container #footer h4,
.footer-container #footer h4 a {
    font-size: 13px;
    line-height: 13px
}

body {
    font-size: 13px;
    line-height: 17px
}

#center-layered-nav .layered_filter_center .layered_subtitle_heading,
#center-layered-nav .layeredSortBy {
    font-size: 13px
}

.pb-center-column .product-title {
    font-size: 11px
}

.product-name {
    font-size: 13px;
    line-height: 17px
}

.price.product-price {
    font-size: 13px
}

.online-label,
.new-label,
.sale-label,
ul.product_list .availability span,
.slick_carousel .availability span {
    font-size: 9px
}

.breadcrumb {
    font-size: 11px
}

.btn {
    font-size: 12px;
    line-height: 15px
}

body {
    font-family: 'Open Sans', sans-serif !important
}

body {
    background-color: #ffffff !important
}

header .banner {
    background-color: #000000 !important
}

header .nav {
    background-color: #f8f8f8 !important
}

header .nav {
    color: #777777 !important
}

header .nav a,
header .nav a:link,
#slidetopcontentShower,
.bt_compare,
#languages-block-top div.current,
#currencies-block-top div.current {
    color: #777777 !important
}

header .nav a:hover,
#slidetopcontentShower:hover,
.bt_compare:hover,
#languages-block-top div.current:hover,
#currencies-block-top div.current:hover {
    color: #333333 !important
}

header .nav {
    border-bottom: 1px none #d6d4d4;
    border-top: 1px none #d6d4d4
}

#languages-block-top div.current.active div,
#languages-block-top div.current.active,
#languages-block-top ul,
#languages-block-top a,
#languages-block-top a:link,
#currencies-block-top div.current.active div,
#currencies-block-top div.current.active,
#currencies-block-top ul,
#currencies-block-top a,
#currencies-block-top a:link {
    color: #777777 !important;
    background-color: #ffffff !important
}

.header-container {
    background-color: transparent !important
}

.container-header,
.sticky-header {
    background-color: #ffffff !important
}

.header-container {
    border-bottom: 1px none #d6d4d4 !important
}

.container-header .form-control {
    color: #9c9b9b !important;
    background: #ffffff !important;
    border: 1px solid #cecece !important
}

.search-cat-selector .selector span:after {
    border-right: 1px solid #cecece
}

.is_rtl #search_block_top .search-cat-selector .selector span {
    border-left: 1px solid #cecece
}

#search_block_top .search-cat-selector .selector span {
    color: #9c9b9b !important
}

#search_block_top .form-control:-moz-placeholder {
    color: #9c9b9b !important
}

#search_block_top .form-control::-moz-placeholder {
    color: #9c9b9b !important
}

#search_block_top .form-control:-ms-input-placeholder {
    color: #9c9b9b !important
}

#search_block_top .form-control::-webkit-input-placeholder {
    color: #9c9b9b !important
}

#search_block_top .button-search:before {
    color: #9c9b9b !important
}

.container-header {
    color: #777777
}

.header_user_info,
.header_user_info a,
.header_user_info a:link {
    color: #777777
}

.header_user_info a:hover {
    color: #333333
}

.shopping_cart>a:first-child span.cart_name,
.shopping_cart>a:first-child span.cart_name:before {
    color: #ffffff;
    background: #333333
}

.alt-cart .more_info .ajax_cart_quantity {
    color: #eeeeee;
    background: #333333
}

.shopping_cart .more_info {
    color: #777777;
    background: #eeeeee
}

#header .cart_block {
    color: ;
    background:
}

#header .cart_block a,
#header .cart_block a:link {
    color:
}

#header .cart_block a:hover {
    color:
}

#header .cart_block {
    border: 1px solid #cecece
}

#header .cart_block * {
    border-color: #cecece
}

.cart_block .cart-buttons {
    background: #f6f6f6
}

.mobile-main-wrapper {
    background: #ffffff
}

.mobile-condensed-header {
    border-bottom: 1px solid #d6d4d4
}

#mh-sticky>.mobile-h-logo {
    background: #ffffff
}

header .mh-button>span {
    color: #777777
}

header .mh-button>span:hover,
header .mh-button.active>span {
    color: #333
}

.mobile-style3 .mh-button>span:hover,
.mobile-style3 .mh-button.active>span {
    background: #F6F6F6
}

.mobile-style3 .mh-button {
    border-right: 1px solid #d6d4d4
}

header .mobile-condensed-header .mh-drop {
    background: #ffffff;
    color: #777777
}

header .mobile-condensed-header .mh-drop a,
header .mobile-condensed-header .mh-drop a:link,
header .mobile-condensed-header .mh-drop a:hover {
    background: #ffffff;
    color: #777777
}

.mh-search-module .form-control {
    color: #777777 !important;
    background: #ffffff !important;
    border: 1px solid #d6d4d4 !important
}

.mh-search-module .search-cat-selector .selector span:after {
    border-right: 1px solid #d6d4d4
}

.is_rtl .mh-search-module .search-cat-selector .selector span {
    border-left: 1px solid #d6d4d4
}

.mh-search-module .search-cat-selector .selector span {
    color: #777777 !important
}

.mh-search-module .form-control:-moz-placeholder {
    color: #777777 !important
}

.mh-search-module .form-control::-moz-placeholder {
    color: #777777 !important
}

.mh-search-module .form-control:-ms-input-placeholder {
    color: #777777 !important
}

.mh-search-module .form-control::-webkit-input-placeholder {
    color: #777777 !important
}

.mh-search-module .button-search:before {
    color: #777777 !important
}

.columns-container {
    background-color: transparent !important
}

#columns,
body.content_only {
    background-color: #ffffff !important
}

body.content_only {
    background-image: none !important
}

#columns .content-inner .block .title_block,
#columns .content-inner .block h4 {
    border-bottom: 1px solid #dddddd !important
}

.page-heading {
    border-bottom: 1px solid #dddddd;
    border-color: #dddddd !important
}

.not-index #columns .content-inner #center_column *,
#index #columns .content-inner #center_column>div>section,
#index #columns .content-inner #center_column>div>div,
#index #columns .content-inner #center_column>div>section *,
#index #columns .content-inner #center_column>div>div:not(#iqitcontentcreator) *,
#index #columns .content-inner #center_column>div>#iqitcontentcreator .iqitcontent-column-inner>div,
#index #columns .content-inner #center_column>div>#iqitcontentcreator .iqitcontent-column-inner>section,
#index #columns .content-inner #center_column>div>#iqitcontentcreator .iqitcontent-column-inner>div *,
#index #columns .content-inner #center_column>div>#iqitcontentcreator .iqitcontent-column-inner>section *,
#columns .content-inner #right_column *,
#columns .content-inner .form-control,
#product_comments_block_tab div.comment .comment_details,
#product_comments_block_tab div.comment {
    border-color: #dddddd
}

#quantity_wanted_p input {
    border-color: #dddddd !important
}

#columns .content-inner .nav-tabs>li>a {
    border-color: transparent !important
}

#columns .content-inner #thumbs_list li a {
    border-color: #ffffff
}

#columns .content-inner #thumbs_list li a:hover,
#columns .content-inner #thumbs_list li a.shown {
    border-color: #dddddd !important
}

#columns .content-inner .nav-tabs>li.active>a,
#columns .content-inner .nav-tabs>li.active>a:hover,
#columns .content-inner .nav-tabs>li.active>a:focus,
#columns .content-inner .nav-tabs>li>a:hover {
    border-color: #dddddd !important
}

body.content_only *,
body.content_only .form-control,
#product_comments_block_tab div.comment .comment_details,
#product_comments_block_tab div.comment {
    border-color: #dddddd
}

#quantity_wanted_p input {
    border-color: #dddddd !important
}

body.content_only .nav-tabs>li>a {
    border-color: transparent
}

body.content_only #thumbs_list li a {
    border-color: #ffffff
}

body.content_only #thumbs_list li a:hover,
body.content_only #thumbs_list li a.shown {
    border-color: #dddddd
}

body.content_only .nav-tabs>li.active>a,
body.content_only .nav-tabs>li.active>a:hover,
body.content_only .nav-tabs>li.active>a:focus,
body.content_only .nav-tabs>li>a:hover {
    border-color: #dddddd;
    border-bottom-color: transparent
}


.button-qty {
    background-color: #9c9b9b;
    color: #ffffff
}

#columns .content-inner,
body.content_only,
#layer_cart,
#thumbs_list .slick-prev,
#thumbs_list .slick-next {
    color: #777777 !important
}

.not-index #columns .content-inner #center_column a,
.not-index #columns .content-inner #center_column a:link,
#index #columns .content-inner #center_column>div>section a,
#index #columns .content-inner #center_column>div>div:not(#iqitcontentcreator) a,
#index #columns .content-inner #center_column>div>#iqitcontentcreator .iqitcontent-column-inner>div a,
#index #columns .content-inner #center_column>div>#iqitcontentcreator .iqitcontent-column-inner>section a,
#index #columns .content-inner #center_column>div>section a:link,
#index #columns .content-inner #center_column>div>div:not(#iqitcontentcreator) a:link,
#index #columns .content-inner #center_column>div>#iqitcontentcreator .iqitcontent-column-inner>div a:link,
#index #columns .content-inner #center_column>div>#iqitcontentcreator .iqitcontent-column-inner>section a:link,
#columns .content-inner #right_column a,
#columns .content-inner #right_column a:link,
#columns .content-inner #left_column .block a,
#columns .content-inner #left_column .block a:link,
body.content_only a,
body.content_only a:link {
    color: #777777
}

.not-index #columns .content-inner #center_column a:hover,
#index #columns .content-inner #center_column>div>section a:hover,
#index #columns .content-inner #center_column>div>div:not(#iqitcontentcreator) a:hover,
#index #columns .content-inner #center_column>div>#iqitcontentcreator .iqitcontent-column-inner>div a:hover,
#index #columns .content-inner #center_column>div>#iqitcontentcreator .iqitcontent-column-inner>section a:hover,
#columns .content-inner #right_column a:hover,
#columns .content-inner #left_column .block a:hover,
body.content_only a:hover {
    color: #333333
}

#columns .content-inner .block .title_block,
#columns .content-inner .block h4,
#columns .content-inner .block .title_block a,
#columns .content-inner .block h4 a,
#columns .content-inner .nav-tabs>li>a,
.pb-center-column h1,
.page-heading,
h3.page-product-heading {
    color: #777777 !important
}

body.content_only .block .title_block,
body.content_only .block h4,
body.content_only .block .title_block a,
body.content_only .block h4 a,
body.content_only .nav-tabs>li>a,
.pb-center-column h1,
.page-heading,
h3.page-product-heading {
    color: #777777 !important
}

#columns .content-inner #center_column .form-control {
    border: 1px solid #d6d4d4
}

.box,
#facebook_block,
#cmsinfo_block,
.table tfoot tr,
.ph_simpleblog .simpleblog-posts .post-content,
ul.step li.step_done {
    background: #f8f8f8;
    color: #777777
}

.table>thead>tr>th {
    background: #f8f8f8;
    color: #777777
}

#center-layered-nav .layered_filter_center .active.layered_subtitle_heading,
#center-layered-nav .layered_filter_center .active.layered_subtitle_heading div,
#center-layered-nav .layered_filter_center>ul,
#center-layered-nav a,
#center-layered-nav:link {
    color: #777777 !important;
    background-color: #ffffff !important
}



.alert-success {
    background-color: #55c65e !important;
    color: #ffffff !important
}

.alert-info {
    background-color: #5192f3 !important;
    color: #ffffff !important
}

.alert-warning {
    background-color: #fe9126 !important;
    color: #ffffff !important
}

.alert-danger {
    background-color: #f3515c !important;
    color: #ffffff !important
}

.footer-container {
    background-color: #ffffff !important
}

.footer-container {
    padding-bottom: 0
}

.footer-container .footer-container-inner1 {
    background-color: #f8f8f8 !important
}

.footer-container .footer-container-inner1 {
    border-bottom-color: #f8f8f8 !important
}

.footer-container .footer-container-inner1 {
    border-top: 1px solid #cecece
}

.footer-container #footer1 h4 {
    border-bottom: 1px solid #cecece
}

.footer-container #footer1 .bullet li {
    border-top: 1px solid #cecece
}

.footer-container #footer h4 {
    border-bottom: 1px solid #cecece
}



#footer input[type="text"] {
    border: 1px solid #cecece
}

.footer-container .footer-container-inner1,
.footer-container .footer-container-inner1 #block_contact_infos>div ul li i {
    color: #777777 !important
}

.footer-container .footer-container-inner1 a,
.footer-container .footer-container-inner1 a:link,
.footer-container .footer-container-inner1 .bullet li a:before {
    color: #777777 !important
}

.footer-container .footer-container-inner1 a:hover {
    color: #333333 !important
}

.footer-container .footer-container-inner1 h4,
.footer-container .footer-container-inner1 h4 a,
.footer-container .footer-container-inner1 h4 a:link,
.footer-container .footer-container-inner1 h4 a:hover {
    color: #555454 !important
}

.footer-container .footer-container-inner {
    background-color: #f8f8f8 !important
}

.footer-container .footer-container-inner {
    border-bottom: 1px solid #cecece;
    border-top: 1px solid #cecece
}

#footer .form-control {
    color: #9c9b9b;
    background-color: #ffffff
}

#footer #newsletter_block_left .form-group .button-small {
    color: #9c9b9b !important
}

.footer-container .footer-container-inner,
.footer-container .footer-container-inner #block_contact_infos>div ul li i {
    color: #777777 !important
}

.footer-container .footer-container-inner a,
.footer-container .footer-container-inner a:link,
.footer-container .footer-container-inner .bullet li a:before {
    color: #777777 !important
}

.footer-container .footer-container-inner a:hover {
    color: #333333 !important
}

.footer-container .footer-container-inner h4,
.footer-container .footer-container-inner h4 a,
.footer-container .footer-container-inner h4 a:link,
.footer-container .footer-container-inner h4 a:hover {
    color: #555454 !important
}

.social_block_mod li a {
    background-color: #99999b !important
}

.social_block_mod li a:before {
    color: #ffffff !important
}

#toTop {
    background-color: ;
    color:
}

#toTop:hover {
    background-color: ;
    color:
}

.columns-container {
    overflow: hidden !important
}

#header #mh-sticky.sticked .cart_block .cart_block_list dl.products {
    max-height: 120px
}

#cbp-close-mobile,
.cbp-spmenu,
#iqitmegamenu-accordion.cbp-spmenu li a:hover,
#iqitmegamenu-accordion.cbp-spmenu>li ul {
    box-shadow: none !important
}

.cbp-spmenu-push-toright #page {
    box-shadow: 0 6px 30px 0 rgba(8, 0, 0, 0.38);
    z-index: 5103;
    position: relative
}

.cbp-spmenu-overlay {
    z-index: 5104;
    left: 240px
}

.iqitfreedeliverycount-cartpage {
    text-align: right;
    padding: 20px;
    background: #f8f8f8;
    margin-bottom: 10px
}

.iqitfreedeliverycount-cartpage .ifdc-remaining {
    font-size: 18px
}

.iqitfreedeliverycount-cartpage .ifdc-icon i {
    font-size: 64px;
    line-height: 52px;
    float: left
}

.iqitfreedeliverycount-product {
    margin-bottom: 8px
}

.iqitfreedeliverycount-detach,
.iqitfreedeliverycount-product {
    background: #f8f8f8;
    padding: 6px 10px;
    border: 1px solid #e7e7e7;
    margin-top: 8px;
    font-weight: normal
}

.iqitfreedeliverycount-detach .ifdc-remaining-price,
.iqitfreedeliverycount-product .ifdc-remaining-price {
    font-weight: bold;
    text-decoration: underline
}

.iqitfreedeliverycount-detach .fd-table-table,
.iqitfreedeliverycount-product .fd-table-table {
    display: table
}

.iqitfreedeliverycount-detach .ifdc-icon,
.iqitfreedeliverycount-product .ifdc-icon {
    font-size: 24px;
    line-height: 24px;
    padding-right: 10px
}

.iqitfreedeliverycount-detach .fd-table-cell,
.iqitfreedeliverycount-product .fd-table-cell {
    display: table-cell;
    vertical-align: middle
}

.cart-prices .iqitfreedeliverycount-detach {
    width: 100%
}

.iqitfreedeliverycount-detach.hidden-detach {
    display: none
}

.iqitfreedeliverycount .ifdc-txt {
    font-size: 11px;
    width: 100%;
    clear: both
}

.iqitfreedeliverycount .ifdc-txt-content {
    border-top: 1px dotted;
    display: inline-block;
    padding-top: 5px
}

.iqitfreedeliverycount-detach .ifdc-txt-content,
.iqitfreedeliverycount-product .ifdc-txt-content {
    width: 100%;
    margin-top: 5px
}

.iqitfreedeliverycount .ifdc-txt p:last-child {
    margin: 0;
    padding: 0
}

.iqitfreedeliverycount {
    Background: #E7692A;
    border: 1px solid #AB3E07;
    color: #ffffff
}

.iqitfreedeliverycount .ifdc-txt-content {
    border-color: #ffffff
}

#index .columns-container {
    overflow: hidden
}

#iqitcontentcreator {
    background: none !important;
    margin-bottom: 20px;
    margin-top: -20px
}

#iqitcontentcreator .fullheight-row {
    height: 100%;
    min-height: 100vh !important
}

#iqitcontentcreator .iqitcontent-column {
    margin-top: 20px
}

#iqitcontentcreator .tab-content .tab-pane {
    margin-top: 0
}

@media (max-width:767px) {

    #iqitcontentcreator>.iqitcontent_row .hidden-xs,
    #iqitcontentcreator #grid-creator-wrapper .first-rows-wrapper>.iqitcontent_row .hidden-xs {
        display: none !important
    }
}

@media (min-width:768px) and (max-width:999px) {

    #iqitcontentcreator>.iqitcontent_row .hidden-sm,
    #iqitcontentcreator #grid-creator-wrapper .first-rows-wrapper>.iqitcontent_row .hidden-sm {
        display: none !important
    }
}

#iqitcontentcreator .empty-column .iqitcontent-column-inner>.iqitcontent_row {
    margin-top: -20px
}

#iqitcontentcreator .iqit-legend-inner {
    position: relative;
    top: 0;
    margin-left: 5px;
    right: 0;
    padding: 2px 4px;
    background: #cecece;
    display: inline;
    font-size: 11px;
    vertical-align: middle
}

#iqitcontentcreator .iqit-legend-inner .legend-arrow {
    color: #cecece
}

#iqitcontentcreator .iqit-legend-inner .legend-arrow:before {
    content: "\f0d9";
    bottom: 0;
    font-family: "FontAwesome";
    top: 0;
    position: absolute;
    margin-top: 0;
    z-index: 2;
    right: auto;
    font-size: 18px;
    left: -5px
}

#iqitcontentcreator .title_block {
    display: block
}

#iqitcontentcreator .fullheight-banner {
    -js-display: flex;
    display: flex;
    align-self: stretch;
    flex-direction: column
}

#iqitcontentcreator .fullheight-banner .iqitcontent-column-inner {
    -js-display: flex;
    display: flex;
    align-self: stretch
}

#iqitcontentcreator .iqitcolumn-title-bg .title_block {
    padding: 12px
}

#iqitcontentcreator .iqitcolumn-title-bg.alternative-slick-arrows>div>div>div .slick-next {
    right: 5px
}

#iqitcontentcreator .iqitcolumn-title-bg.alternative-slick-arrows>div>div>div .slick-prev {
    right: 35px
}

#iqitcontentcreator .manufacturers .iqitmanufacuter-logo a {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

#iqitcontentcreator .manufacturers .iqitmanufacuter-logo a img {
    margin: 0 auto
}

#iqitcontentcreator .manufacturers .iqitmanufacuter-logo a:hover img {
    opacity: 0.6
}

#iqitcontentcreator .iqitcarousel {
    min-height: 85px
}

#iqitcontentcreator .iqitcarousel .ajax_block_product {
    display: block
}

#iqitcontentcreator .iqitcolumn-have-title>div>div>.iqitcarousel {
    margin-top: -11px
}

#iqitcontentcreator ul.product_list {
    padding-top: 11px
}

#iqitcontentcreator .iqitcarousel-wrapper {
    padding: 0 10px
}

#iqitcontentcreator .manufacturers .slick-list {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0
}

#iqitcontentcreator .iqitcontent-column-inner .block {
    margin-bottom: 0
}

#iqitcontentcreator .iqit-fullwidth {
    margin: 0 -500%;
    padding: 0 500%
}

#iqitcontentcreator .fullwidth-row-container {
    width: calc(100vw - 17px);
    margin-left: calc((-50vw + 8px) + 50%)
}

@media (max-width:1030px) {
    #iqitcontentcreator .fullwidth-row-container {
        width: calc(100vw);
        margin-left: calc(-50vw + 50%)
    }
}

#iqitcontentcreator .parallax-row {
    min-height: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center top
}

@media (max-width:1030px) {
    #iqitcontentcreator .parallax-row {
        background-attachment: scroll
    }
}

#iqitcontentcreator .parallax-row .iqit-fullwidth {
    min-height: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center top
}

#iqitcontentcreator .nopadding-row>.iqitcontent-column {
    padding: 0
}

#iqitcontentcreator .valign-center-row {
    -js-display: flex;
    display: flex;
    flex-wrap: wrap
}

#iqitcontentcreator .valign-center-row:before,
#iqitcontentcreator .valign-center-row:after {
    width: 0
}

#iqitcontentcreator .valign-center-row>.iqitcontent-column {
    align-self: center
}

#iqitcontentcreator .valign-center-row .fullheight-banner {
    -js-display: flex;
    display: flex;
    align-self: stretch;
    flex-direction: column
}

#iqitcontentcreator .valign-center-row .fullheight-banner .iqitcontent-column-inner {
    -js-display: flex;
    display: flex;
    align-self: stretch
}

#iqitcontentcreator .iqit-banner-image {
    display: block;
    width: 100%;
    background-size: cover;
    background-position: center center;
    transition: opacity 0.3s ease-in-out;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden
}

#iqitcontentcreator .iqit-banner-image img {
    opacity: 0;
    visibility: hidden
}

#iqitcontentcreator .iqit-banner-image:hover {
    opacity: 0.85
}

#iqitcontentcreator .banner-image {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    width: 100%;
    transition: opacity 0.3s ease-in-out
}

#iqitcontentcreator .banner-image:hover {
    opacity: 0.85
}

@media (max-width:767px) {
    #iqitcontentcreator .iqitcontent-column.col-xs-12 {
        clear: both;
        float: none
    }
}

@media (min-width:768px) and (max-width:999px) {
    #iqitcontentcreator .iqitcontent-column.col-sm-12 {
        clear: both;
        float: none
    }
}

@media (min-width:1000px) {
    #iqitcontentcreator .iqitcontent-column.col-md-12 {
        clear: both;
        float: none
    }
}

.flexboxtweener #iqitcontentcreator .fullheight-row .fullheight-banner {
    -js-display: flex;
    display: -ms-flexbox;
    -ms-flex-item-align: stretch
}

.flexboxtweener #iqitcontentcreator .fullheight-row .fullheight-banner .iqitcontent-column-inner {
    -js-display: flex;
    display: -ms-flexbox;
    -ms-flex-item-align: stretch
}

#iqitcontentcreator .iqitcontent-element-id-4>.iqitcontent-column-inner .slick-dots {
    display: block !important
}

#iqitcontentcreator .iqitcontent-element-id-4>.iqitcontent-column-inner .slick-slider.slick_carousel_style {
    margin-bottom: 20px
}

#iqitcontentcreator .iqitcontent-element-id-10 {}

#iqitcontentcreator .iqitcontent-element-id-11>.iqitcontent-column-inner {
    margin-right: -10px
}

#iqitcontentcreator .iqitcontent-element-id-16>.iqitcontent-column-inner {
    margin-top: -10px
}

#iqitcontentcreator .iqitcontent-element-id-13>.iqitcontent-column-inner {
    margin-left: -10px
}

#iqitcontentcreator .iqitcontent-element-id-19>.iqitcontent-column-inner {
    margin-top: -10px
}

#iqitcontentcreator .iqitcontent-element-id-5>.iqitcontent-column-inner .iqit-legend-inner {
    background-color: rgb(225, 101, 101)
}

#iqitcontentcreator .iqitcontent-element-id-5>.iqitcontent-column-inner .iqit-legend-inner .legend-arrow {
    color: rgb(225, 101, 101)
}

#iqitcontentcreator .iqitcontent-element-id-5>.iqitcontent-column-inner .slick-dots {
    display: block !important
}

#iqitcontentcreator .iqitcontent-element-id-5>.iqitcontent-column-inner .slick-slider.slick_carousel_style {
    margin-bottom: 20px
}

#iqitcontentcreator .iqitcontent-element-id-6>.iqitcontent-column-inner {
    margin-left: -10px
}

#iqitcontentcreator .iqitcontent-element-id-6>.iqitcontent-column-inner .slick-dots {
    display: block !important
}

#iqitcontentcreator .iqitcontent-element-id-6>.iqitcontent-column-inner .slick-slider.slick_carousel_style {
    margin-bottom: 20px
}

#iqitcontentcreator .iqitcontent-element-id-7>.iqitcontent-column-inner .slick-dots {
    display: block !important
}

#iqitcontentcreator .iqitcontent-element-id-7>.iqitcontent-column-inner .slick-slider.slick_carousel_style {
    margin-bottom: 20px
}

#iqitcontentcreator .iqitcontent-element-id-8>.iqitcontent-column-inner .slick-dots {
    display: block !important
}

#iqitcontentcreator .iqitcontent-element-id-8>.iqitcontent-column-inner .slick-slider.slick_carousel_style {
    margin-bottom: 20px
}

#iqitcontentcreator .iqitcontent-element-id-9>.iqitcontent-column-inner .slick-dots {
    display: block !important
}

#iqitcontentcreator .iqitcontent-element-id-9>.iqitcontent-column-inner .slick-slider.slick_carousel_style {
    margin-bottom: 20px
}

#iqitcontentcreator .iqitcontent-element-id-26>.iqitcontent-column-inner {
    margin-top: -20px
}

#iqitcontentcreator .iqitcontent-element-id-28>.iqitcontent-column-inner {
    margin-top: -20px
}

.product-container .available-sizes {
    background: #fff;
    position: absolute;
    right: 0;
    bottom: 33px;
    border: 1px solid #cecece;
    z-index: 3;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.3s ease, opacity 0.3s ease
}

.ajax_block_product .hovered .available-sizes {
    visibility: visible;
    opacity: 0.9
}

.product_list_small .available-sizes {
    display: none
}

.product-container .available-sizes strong {
    border-bottom: 1px solid #cecece;
    padding: 2px 6px
}

.product-container .available-sizes ul {
    margin: 0
}

.product-container .available-sizes ul li {
    border-bottom: 1px dotted #cecece;
    display: block;
    padding: 0
}
#search_block_top select,
#search_block_top input {
  font-size: 13px !important;
}

.search_block_top .button-search {
  top: 8px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 15px !important;
}

.swiper-button-prev,
.swiper-button-next {
  background: rgba(0, 0, 0, 0.5);
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: #ffffff !important;
}

#iqitmegamenu-horizontal {
  background-color: #6f9642;
}

.swiper-container {
  z-index: 0 !important;
}

.header-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#header .shopping_cart {
    padding-top: 0px;
}

#header .list-inline-item:not(:last-child) {
    margin-right: 5px !important;
    margin-left: 5px !important;
}

.cbp-categories-row >.col-xs-6 {
  width: 50% !important;
}
.cbp-category-link-w > a{
  text-decoration: none !important;
  background: none !important;
  padding-left: 10px !important;
  font-size: 1em !important;
}

.product-hover-shadow:hover {
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.15);
}
.add-to-wishlist-btn:hover {
  color: white;
}

.deal-carousel-item {
  margin: 10px 10px !important;
}
.deal-carousel-item:hover {
  transition: box-shadow 0.3s ease;
  border: 1px solid transparent; /* Initial border */
  border-radius: 5px; /* Adjust for rounded corners */
  border-color: #ddd; /* Light gray border */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

#languages-block-top img{
  vertical-align: middle !important;
}

.flag-top-icon{
  padding: 0 10px !important;
}

#search_block_top.issearchcontent {
  margin-top: 10px;
}

.deal-image{
  padding: 20px 20px 0px 20px !important;
}

a {
  text-decoration: none !important;
}

.footer-social-icon {
  background-color: #777777 !important;
  color: white;
  border: #777777 !important;
  padding: 4px 7px;
}

.swiper-pagination-bullet-active{
  background: #6f9642 !important;
}

.fa-chevron-down {
  color: #6f9642 !important;
  text-decoration: none;
}

.newsLetter{
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: stretch !important;
}

.service-info{
 border: unset !important;
}

#textbannersmodule .txttitle{
  font-size: 17px !important;
}

#textbannersmodule li .circle{
  width: 72px !important;
  height: 72px !important;
}

#textbannersmodule .txttitle{
  font-weight: 500 !important;
}

.circle i{
  font-size: 20px !important;
  line-height: 72px !important;
}

.footer-container #footer h4{
  border-bottom:  unset !important
}

ul.bullet{
  padding-left: 0 !important;
}

.footer-icon-fb {
  background-color: #0766ff !important;
}
.footer-icon-ig {
  background-color: #ff2f40 !important;
}
.footer-icon-twitter {
  background-color: #3d8bc5 !important;
}
.footer-icon-yt {
  background-color: #ff0033 !important;
}
.footer-icon-tiktok {
  background-color: #1f1f1f !important;
}

/* #userBrowsing .swiper-slide {
  max-width: 230px !important;
} */

.footer-social-icon i {
  height: 12px;
  width: 12px;
}

.sell-page label {
  font-size: 13px !important;
}

h4{
  color: black !important;
}

h5{
  color: black !important;
}

label{
  color: black !important;
}

.adduser-phone {
  padding: 4.5px 55px !important;
}
.react-tel-input .form-control{
  font-size: 1rem;
}

input::-webkit-input-placeholder {
  color: #999999 !important;
}

input::placeholder {
  color: #999999 !important;
}

/* For WebKit browsers (Chrome, Safari, Edge) */
input::-webkit-input-placeholder {
  color: #999999 !important;
}

/* For Mozilla Firefox */
input::-moz-placeholder {
  color: #999999 !important;
}

/* For Microsoft Edge and Internet Explorer */
input:-ms-input-placeholder {
  color: #999999 !important;
}

/* For older Microsoft Edge */
input::-ms-input-placeholder {
  color: #999999 !important;
}

ol.stepper {
  --default-b: lightgrey;
  --default-c: black;
  --active-b: #6f9642;
  --active-c: white;
  --circle: 3.5em; /* size of circle */
  --b: 5px; /* line thickness */
  
  display: flex;
  list-style: none;
  justify-content: space-between;
  background: 
    linear-gradient(var(--default-b) 0 0) no-repeat
    50% calc((var(--circle) - var(--b))/2)/100% var(--b);
  counter-reset: step;
  margin: 20px;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  counter-reset: step;
  overflow: hidden;
}
ol.stepper li {
  display: grid;
  place-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-family: inherit;
  position: relative;
}
ol.stepper li::before {
  content: counter(step) " ";
  counter-increment: step;
  display: grid;
  place-content: center;
  aspect-ratio: 1;
  height: var(--circle);
  border: 5px solid #fff;
  box-sizing: border-box;
  background: var(--active-b);
  color: var(--active-c);
  border-radius: 50%;
  font-family: inherit;
  z-index: 1;
}
ol.stepper li.active ~ li::before{
  background: var(--default-b);
  color: var(--default-c);
}
ol.stepper li.active::after {
  content: "";
  position: absolute;
  height: var(--b);
  right: 100%;
  top: calc((var(--circle) - var(--b))/2);
  width: 100vw;
  background: var(--active-b);
}


@media (max-width: 600px) {
  ol.stepper {
   display: grid;
   grid-gap: 20px;
   gap: 20px;
  background: 
    linear-gradient(var(--default-b) 0 0) no-repeat
    calc((var(--circle) - var(--b))/2) 50%/ var(--b) 100%;
  }
  ol.stepper li {
    display: flex;
  }
  ol.stepper li.active::after {
    content: "";
    position: absolute;
    width: var(--b);
    bottom: 100%;
    left: calc((var(--circle) - var(--b))/2);
    top: auto;
    right: auto;
    height: 100vw;
    background: var(--active-b);
  }
}

.removeBottomMargin {
  margin-bottom: 0 !important;
}

#footer .form-control{
  color: black !important;
}

.dropcenter.btn-group > button{
  text-transform: unset !important;
}


.btn-bg-color {
  background-color: #6f9642!important;
}

.custom-rounded-image {
  width: 72px;
  height: 72px;
  border: 1px solid #ccc;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.custom-rounded-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.order-table th {
  text-align: center !important;
}

.order-table td {
  text-align: center !important;
}
td .rounded-pill {
  font-size: 11px !important;
  padding: 2px 12px !important;
}

.custom-icon-size i {
  width: 30px !important;
  margin-right: 1rem !important;
}


.font600{
  font-weight: 600 !important;
}

hr:not([size]) {
  height: 1px !important;
}

.grayIcon{
  width: 30px !important;
  height: 30px !important;
  display: inline-block;
  background-color: var(--bs-secondary);
  color: #fff !important;
  display: grid
;
  place-items: center;
  border-radius: 50%;
  font-size: 14px !important;
}

.unrated {
  color: #777777 !important;
}

.cart_product_img {
  height: 100px !important;
  width: 100px !important;
  padding: 19px !important;
  vertical-align: middle;
  text-align: center;
  line-height: 0 !important;
}

/* styles.css */
@media screen and (min-width: 768px) { /* Adjust 768px to the breakpoint that you consider as desktop */
    .desktop-margin-top {
      margin-top: -10px;
    }
  }

  @media screen and (max-width: 768px) { /* Adjust 768px to the breakpoint that you consider as desktop */
    .desktop-margin-top {
      margin-top: 25px;
    }
  }

/* @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"; */
body {
  /* font-family: "Poppins", sans-serif; */
  background: #fafafa;
}

p {
  /* font-family: "Poppins", sans-serif; */
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}
.sidebar-logo {
  height: 35px;
  width: 217px;
}
.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  min-width: 275px;
  max-width: 275px;
  background: #ffffff;
  color: #737373;
  transition: all 0.3s;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
}
#sidebar::-webkit-scrollbar {
  width: 0 !important;
}
#sidebar.active {
  margin-left: -275px;
}
#sidebar.inactive {
  margin-left: 275px !important;
}

#sidebar .sidebar-header {
  padding: 20px;
  /* background: #6d7fcc; */
}

#sidebar ul.components {
  padding: 20px 0;
}
.sidebarLink {
  padding-left: 25px !important;
  font-size: 14px !important;
  text-decoration: none;
  color: #737373;
}
.component-p {
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: 20px;
}
#sidebar ul p {
  color: #fff;
  padding: 10px;
}
#sidebar ul li {
  padding-top: 10px;
}
#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
.avatar {
  vertical-align: middle;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
}
#sidebar ul li a:hover {
  color: #6f9642;
  background: #fff;
}
.sidebar-profile {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
}
#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #6f9642;
  background: #f9f9f9;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}
.admin-name {
  font-size: 14px;
  font-weight: 300;
}
.sidebarLink i {
  font-size: 14px;
  padding-right: 20px;
}
a.article,
a.article:hover {
  background: #6f9642 !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  position: absolute;
  width: calc(100% - 275px);
  left: 275px;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
  background-color: #f7f7f7;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    margin-left: -275px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
  #content {
    left: 0;
    width: 100%;
    background-color: #f7f7f7;
  }
}

/* * {
  font-family: Circular Std Bold;
} */
body {
  margin: 0px !important;
  background: white !important;
  padding: 0 !important;
  font-size: 12px !important;
}

.bg-mask {
  background-image: url(/static/media/bg-mask.74b03b2d.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  min-height: 100vh;
}
.main-logo {
  position: absolute;
  left: 50px;
  top: 10%;
}
.main-container {
  margin-left: 50px;
  margin-right: 50px;
  min-height: 100vh;
  position: relative;
}
.main-container-content {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  width: 100%;
}
.landing-container {
  text-align: center;
}
.main-container-landing {
  padding-top: 50px;
}
.login-form {
  margin-top: 32px;
}
.form-input {
  background-color: transparent !important;
  color: white !important;
  font-size: 20px !important;
  height: 60px !important;
}
.form-input:focus {
  outline: none !important;
  border-color: white !important;
  box-shadow: none !important;
}
.form-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.541) !important;
}
.form-input::placeholder {
  color: rgba(255, 255, 255, 0.541) !important;
}
.form-submit-button {
  background-color: #f5f5f5 !important;
  color: #6f9642 !important;
  border: #f5f5f5 !important;
}
.non-deco-link,
.non-deco-link:hover {
  text-decoration: none !important;
}
.non-deco-link:hover {
  color: white;
}
.logo {
  width: -webkit-max-content;
  width: max-content;
  height: 40px !important;
}
.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.launching-text {
  font-size: 40px;
  font-weight: 600;
}
.launching-text-next {
  color: #aaaaaa;
  font-size: 20px;
  text-align: center;
}
.notify-inp {
  border-top-left-radius: 1.25rem !important;
  border-bottom-left-radius: 1.25rem !important;
  background-color: #f5f6f8 !important;
  color: #aaaaaa !important;
  border-right-color: transparent;
  font-size: 19px !important;
  outline: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.notify-inp:focus {
  outline: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.notify-inp::-webkit-input-placeholder {
  font-weight: 100 !important;
  color: #aaaaaa !important;
}
.notify-inp::placeholder {
  font-weight: 100 !important;
  color: #aaaaaa !important;
}
.email-notify {
  background-color: #f5f6f8;
  border-radius: 1.25rem;
}
.btn-default-outline {
  color: #6f9642 !important;
  background-color: transparent !important;
  border: 1px solid #6f9642 !important;
  outline: #6f9642 !important;
}
.btn-default-outline2 {
  color: #6f9642 !important;
  background-color: transparent !important;
  outline: #6f9642 !important;
}
.btn-default {
  color: white !important;
  background-color: #6f9642 !important;
  border: #6f9642 !important;
  padding: 7px 30px !important;
}
.btn-default-toggle {
  color: white !important;
  background-color: #6f9642 !important;
  border: #6f9642 !important;
  /* padding: 7px 30px !important; */
}
.btn-notify-curved {
  border-radius: 1.25rem !important;
  padding: 10px 30px !important;
}
/* input[type="date"]:before {
  content: attr(placeholder) !important;
  color: rgba(255, 255, 255, 0.541);
  margin-right: 0.5em;
} */
input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}
.counter-text-1 {
  font-size: 69px;
  font-weight: 500;
}
.text-default {
  color: #6f9642 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.p-light-grey {
  color: #949494;
}
/* .search-input {
  margin-left: 25px;
} */
.search-nav {
  border-style: solid;
  border-color: #b1b1b1 transparent #b1b1b1 #b1b1b1;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-width: 1px;
}
.search-input input {
  padding-left: 25px !important;
}
.search {
  /* background: url("https://img.icons8.com/ios-glyphs/20/000000/search--v1.png")
    top left no-repeat; */
  background-position: left center;
  outline: 0 !important;
  color: #aaaaaa !important;
}
.search::-webkit-input-placeholder {
  color: #aaaaaa !important;
}
.search::placeholder {
  color: #aaaaaa !important;
}
/* .tb-equal-margin {
  margin-top: auto !important;
  margin-bottom: auto !important;
} */
.fa-stack[data-count]:after {
  position: absolute;
  right: 15%;
  top: 1%;
  content: attr(data-count);
  font-size: 52%;
  padding: 0.6em;
  border-radius: 999px;
  line-height: 0.75em;
  color: white;
  background: #6f9642;
  text-align: center;
  min-width: 2em;
  font-weight: bold;
}
.ftw-400 {
  font-weight: 400;
}
th {
  font-weight: 500 !important;
}
.pt-18 {
  padding-top: 18px !important;
  color: #a3a3a3;
}
.color-grey-table {
  color: #a3a3a3;
}
hr:not([size]) {
  height: 2px !important;
}
hr {
  background-color: #b5b5b5 !important;
}
.lower-text {
  color: white;
}

#myTable_filter {
  float: left !important;
}
#myTable_filter input {
  background-color: white;
}
.float-right {
  float: right;
}
.dataTables_length {
  margin-top: 8px;
}
.table.dataTable.no-footer {
  border-bottom: none !important;
}
/* tr {
  border-bottom: 1px solid #dedede !important;
} */
thead tr {
  border-bottom: 1px solid #dedede !important;
}
table.dataTable tbody tr {
  background-color: transparent;
}
table.dataTable thead th {
  border-bottom: 1px solid #dedede !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  border-radius: 50px !important;
  background-color: #6f9642 !important;
  color: white !important;
  background: #6f9642;
  border: #6f9642;
}
table.dataTable tbody th,
table.dataTable tbody td {
  color: #a3a3a3;
  font-weight: 300 !important;
}

#myTable_info,
#myTable_length,
#myTable_length > label > select {
  color: #a3a3a3;
  font-weight: 300 !important;
}
.modal-title {
  font-size: 34px;
  font-weight: 400;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
button.close {
  background-color: transparent;
  border: 0;
}
button.close > span {
  font-size: 30px !important;
  color: #b8b8b8;
}
label {
  font-size: 16px;
  margin-bottom: 5px;
}
.modal-formgroup > input::-webkit-input-placeholder {
  color: #b8b8b8 !important;
}
.modal-formgroup > input::placeholder {
  color: #b8b8b8 !important;
}
.btn-white {
  color: black !important;
  background-color: white !important;
  padding: 6px 30px !important;
  border: 1px solid #b8b8b8 !important;
}
.modal-footer {
  padding: 1.25rem !important;
}
.btn-action,
.btn-action:hover {
  color: #fff;
  background-color: none;
  border-color: none;
}
.dropdown-link {
  text-decoration: none;
  color: #212529;
}
.update-user-label {
  color: #cecece !important;
}
.userupdate-control::-webkit-input-placeholder {
  color: #333333 !important;
}
.userupdate-control::placeholder {
  color: #333333 !important;
}
.update-img {
  border-radius: 100px !important;
  height: 150px !important;
  width: 150px !important;
}
.status-update-button {
  background-color: #cecece66 !important;
  border: #cecece66 !important;
  color: #6f9642 !important;
  border-radius: 25px !important;
  padding: 5px 45px !important;
}
.react-tel-input .form-control {
  width: 100% !important;
  background: transparent !important;
  color: white;
}
.react-tel-input .form-control::-webkit-input-placeholder {
  color: #ced4da;
}
.react-tel-input .form-control::placeholder {
  color: #ced4da;
}
.adduser-phone {
  padding: 4.5px 14px 4.5px 60px !important;
  color: black !important;
}
#myTable_previous:hover {
  color: white !important;
  background-color: #6f9642 !important;
}
#myTable_next:hover {
  color: white !important;
  background-color: #6f9642 !important;
}
.landing-topbar {
  background-color: #f5f6f8;
}
.lt-text {
  color: #787878;
}
.lt-text i {
  font-size: 12px;
}
.lt-divider {
  color: #cccccc;
  height: 25px;
  border-left: 2px solid;
}
.lt-ul {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  float: right;
}
.lt-un {
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  float: right;
}
.lt-un li {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.lt-ul li {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.lt-text i.user-icon {
  font-size: 18px;
  margin-right: 10px;
}

.ln-text {
  font-size: 16px;
  color: #787878;
}
.ln-text i {
  font-size: 20px;
}
.mycart-text {
  font-size: 12px;
  color: #787878;
}
.ln-price {
  font-size: 14px;
}
.price-chevron {
  font-size: 10px;
  color: #787878;
}
.btn-ln-search-cat {
  border-color: none !important;
}
.dropdown .btn-secondary {
  border: none !important;
}
.dropdown .btn-secondary:focus {
  box-shadow: none !important;
}
.divider-right-margin {
  padding-right: 20px;
}
.nav-search {
  border: none !important;
}
.nav-search:focus {
  outline: 0;
  border: none !important;
  box-shadow: none !important;
}
.nav-search::-webkit-input-placeholder {
  color: #bebebe;
}
.nav-search::placeholder {
  color: #bebebe;
}
.bt-default-nav-search {
  color: white !important;
  background-color: #6f9642 !important;
  border: #6f9642 !important;
  padding: 9px 12px !important;
  border-radius: 25px;
}
.search-addon {
  margin-left: 0px !important;
}
.landing-signin {
  border-bottom: none !important;
  padding: 0px 10px !important;
}
.landing-signin > button.close > span {
  color: black !important;
}
.default-p {
  color: #6d6d6d;
  font-size: 15px;
}
.user-inputs {
  height: 50px;
  color: black !important;
}
.forget-content {
  font-size: 13px;
}
.border-radius-25 {
  border-radius: 25px !important;
}
.td-none {
  text-decoration: none;
}
.terms-signup {
  color: #0d6efd !important;
}
.img-otp {
  height: 145px !important;
}
.otp-input div {
  justify-content: space-around;
}
.otp-input div input {
  width: 50px !important;
  height: 50px !important;
  border: 1px solid #bcbcbc !important;
  border-radius: 5px;
}
.otp-input div input:focus {
  border: 1px solid #6f9642 !important;
  outline: #6f9642;
}
.td {
  color: #707070 !important;
}
.admin-nav-toggle {
  top: 50px !important;
  left: 35px !important;
}
.container-unav {
  margin: 0px 80px;
}

.nav-menu-item {
  color: #333333;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
}
.nav-submenu-item {
  color: #333333;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
}
.nav-submenu-item {
  border-bottom: none !important;
}
.szh-menu__item {
  border-bottom: 1px solid #eeeeee;
  padding: 6px;
  font-size: 13px;
  font-weight: 400;
  color: #787878;
  background: white !important;
}
.szh-menu__submenu > .szh-menu__item::after {
  color: #040921 !important;
}

.nav-menu-item-active {
  border-color: white white #6f9642 white;
  font-size: 14px;
  color: #6f9642;
  border-width: 2px;
  border-style: solid;
  padding: 10px;
}
.browse-categories {
  width: 100%;
  padding: 5px 5px;
}
.szh-menu {
  top: 2px !important;
  width: -webkit-fill-available !important;
}
.szh-menu-container {
  width: auto !important;
}
.browse-menu {
  color: black !important;
  font-size: 12px !important;
  text-transform: none !important;
  background-color: #f1f1f1 !important;
  padding: 9px 15px !important;
  box-shadow: none !important;
  width: 100% !important;
}
.szh-menu__submenu > .szh-menu__item::after {
  color: black;
}
.service-info {
  border: 1px solid #c1c1c1;
  padding: 25px;
}
.service-content {
  align-items: center;
}
.service-icon i {
  color: #6f9642;
  font-size: 30px;
}
.service-content-detail {
  margin-left: 20px;
}
.detail-heading {
  font-weight: 500 !important;
}
.detail-desc {
  color: #8a8a8a;
}
.deal-carousel-item {
  margin-left: 10px;
  margin-right: 10px;
  border: none !important;
}
.deal-carousel-item-body {
  text-align: center;
}
.country-list .country {
  color: #212529;
  text-align: left;
}
.lt-ul-first {
  float: left;
}
.dotd-rate {
  font-size: 13px;
}
.dotd-rate i {
  margin-right: 8px;
}
.unrated {
  color: #cccccc;
}
.rated {
  color: #6f9642;
}
.price-strike {
  color: #cccccc;
  text-decoration: line-through;
}
.deal-product-img {
  max-height: 300px;
  opacity: 1;
  display: block;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.deal-image {
  position: relative;
  text-align: center;
}
.deal-image img {
  height: 150px;
  width: 100%;
  object-fit: contain;
  max-width: 200px;
  text-align: center;
  cursor: pointer;
}
.deal-image:hover .deal-product-img {
  opacity: 0.3;
}

.deal-image:hover .image-text {
  opacity: 1;
}
.image-text {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 35%;
  left: 10%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.image-remove-text {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 25%;
  left: 10%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.image-text-button {
  background-color: #6f9642 !important;
  color: white;
  border: #6f9642 !important;
  padding: 4px 7px;
}
.profile-image-block {
  position: relative;
}
.profile-img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  /* background-color: #008cba; */
}

.profile-image-block:hover .profile-img-overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.popular-head-btn {
  background-color: transparent !important;
  color: #cccccc !important;
  padding: 5px 25px !important;
  border-color: #cccccc !important;
}
.popular-head-btn-active {
  background-color: #6f9642 !important;
  padding: 5px 25px !important;
  color: #ffffff !important;
  border-color: #6f9642 !important;
}
.bd-none {
  border: none !important;
}
.landing-newsletter {
  background-color: #6f9642;
}
.landing-newsletter-input {
  background-color: transparent !important;
  color: #ffffff !important;
}
.landing-newsletter-input::-webkit-input-placeholder {
  color: #ffffff !important;
}
.landing-newsletter-input::placeholder {
  color: #ffffff !important;
}
.button-newsletter-landing {
  padding: 8px 20px;
}
.footer-Links-heading {
  color: #333333 !important;
  font-size: 16px;
}
.footer-Links-heading-child {
  color: #ababab;
  margin-top: 10px;
  font-size: 13px;
}
.notification-menu {
  min-width: 15rem !important;
  top: 30px !important;
  left: 35px !important;
}
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #eeee;
}
[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 12px;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: #6f9642;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
}
.notification-menu.show {
  max-height: 85vh;
  overflow-y: auto;
}
.navbar-toggler {
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}
/* .slick-track {
  width: 100vw !important;
} */
.admin-note-item {
  margin: 5px 0px;
  padding: 5px;
  cursor: pointer;
}
.admin-note-item:hover {
  background: #6f964233;
}
label {
  font-size: 14px !important;
}
.btn {
  font-size: 13px !important;
}
.h5,
h5 {
  font-size: 1rem !important;
}
.h2,
h2 {
  font-size: 1.5rem !important;
}
.h3,
h3 {
  font-size: calc(1rem + 0.6vw) !important;
}
.h3,
h3 {
  font-size: calc(1.2rem + 0.3vw) !important;
}
.no-store-img {
  height: 30vh !important;
}
.cstore-cardheader {
  background: #fff !important;
  border-bottom: none !important;
}
.cstore-cardheader button {
  border: #bcbcbc;
  border-radius: 50%;
  padding: 0px 9px;
}
.cstore-card {
  border: 1.5px solid rgba(0, 0, 0, 0.125) !important;
}
.cs-bi-radios {
  padding: 6px 30px 2px 15px;
  border: 1.5px solid #dfdfdf !important;
  border-radius: 5%;
  margin-left: 10px;
}
.cs-bi-radios-input {
  border: #6f9642;
}
.cs-bi-radios-input:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -2px;
  position: relative;
  background-color: #fff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid #6f9642;
}

.cs-bi-radios-input:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -2px;
  position: relative;
  background-color: #6f9642;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid white;
}
.store-img {
  border-radius: 100px !important;
  height: 100px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.cs-admin-sidelink > li {
  margin-top: 5px;
  padding: 5px 0px;
  cursor: pointer;
  padding-left: 10px;
}
.cs-admin-sidelink > .active {
  background-color: #f9f9f9;
  color: #6f9642 !important;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  padding: 10px;
}
.btn-success {
  background-color: #6f9642 !important;
  border: #6f9642 !important;
}

.cs-admin-tabs div {
  font-size: 18px;
  margin-left: 12px;
  margin-bottom: 5px;
}
.cs-admin-tabs .active {
  border-bottom: 3px solid #6f9642;
  color: #6f9642;
}
.cs-admin-tab-bottom {
  position: absolute;
  top: 85%;
  width: 100%;
  border-bottom: 1px solid #c7c7c7 !important;
}
.store-tabItem a {
  color: #333333 !important;
  cursor: pointer;
}
.store-tabItem .active {
  border-style: solid !important;
  border-color: transparent transparent #6f9642 transparent !important;
}
.rated-yellow {
  color: #fbbf24;
}
.reviewer-img img {
  border-radius: 100px;
  height: 60px;
  width: 60px;
}
.store-bg {
  position: relative;
}
.avg-rating {
  color: #fbbf24;
  font-size: 60px;
}
.store-bg .row {
  /* position: absolute; */
  bottom: 0px;

  width: 100%;
}
.text-orange {
  color: #fb5402;
}
.store-about h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400 !important;
}
.store-policies h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400 !important;
}
.store-text {
  color: white;
}

.default-check-color:checked {
  background-color: #6f9642 !important;
  border-color: #6f9642 !important;
}
.default-check-color {
  height: 1.5em !important;
  width: 1.5em !important;
  border-color: #6f9642 !important;
}
.form-check-inline label {
  margin-left: 10px;
}
.product-field {
  border: 1px solid #bdbdbd !important;
  color: #1d1b1bd3 !important;
}
.product-form .row {
  margin-top: 10px;
}
.setting-ul li {
  padding: 10px;
  cursor: pointer;
  color: #8e9093;
}
.setting-ul li.active {
  border-left: 5px solid #6f9642;
  box-shadow: 0 2px 10px rgb(16 30 54 / 15%) !important;
  color: #1c2121 !important;
}
.row .sidebox-right {
  border-left: 1px solid #8e9093;
}
.sidebox-header {
  color: #1c2121;
  background-color: #edeff2;
  padding: 10px;
}
.list-style-none {
  list-style-type: none;
}
.product-desc-ul li {
  color: #999999;
  padding: 5px 0px;
}
.align-center {
  align-items: center !important;
}
.product-side-box {
  border: 1px solid #c1c1c1;
  padding: 10px;
  border-radius: 5px;
}
/* .text-secondary {
  color: #cccccc !important;
} */
.product-side-box .row {
  margin-top: 10px;
  margin-bottom: 25px;
}
.text-light-grey {
  color: #c1c1c1d5 !important;
}
.product-categories {
  margin-top: 5px;
}
.product-categories div {
  padding: 0 8px;
}
.side-divider {
  border-left: 1px solid #bdbdbd;
}
.product-ratings {
  margin-top: 10px;
}
.product-ratings div span {
  font-size: 13px;
}
.badge-warning {
  background-color: #fbbf24 !important;
  color: #fff !important;
}
.product-rating-stat {
  color: #787878 !important;
  margin-left: 10px;
}
.product-price {
  color: #000000;
  font-size: 26px;
}
.product-price-tag {
  color: #bdbdbd;
  font-size: 16px;
  margin-left: 15px;
}
.product-quantity div {
  margin-right: 10px;
}
.counter-btn {
  color: #6f9642;
  border: none;
  border-radius: 50%;
}
.minus-btn {
  padding: 0px 8px;
}
.plus-btn {
  padding: 0px 7px;
}
.product-quantity {
  font-size: 16px;
}
.product-variant div {
  margin-right: 10px;
}
.varient-color-btn {
  border: none;
  border-radius: 50%;
  padding: 0px;
  background-color: white;
}
.variant-input {
  border: 1px solid transparent !important;
  border-bottom: 1px solid #bdbdbd !important;
  height: 30px;
}

.variant-input:active {
  border: 1px solid transparent !important;
  border-color: white !important;
}
.cart-caret {
  padding: 0px 2px !important;
  background-color: transparent;
}
.cart-list {
  top: 20px !important;
  left: 15px !important;
  width: 18rem !important;
}

.cart-item-detail p,
h6 {
  margin-bottom: 0px !important;
}
.cart-list-item {
  display: block;
  position: relative;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.remove-cart-item {
  position: absolute;
  right: 12px;
  top: 2px;
  cursor: pointer;
}
.active-variant-value {
  border: 2px solid #6f9642;
}
.valueVariantImage {
  cursor: pointer;
}
.paginate-prev,
.paginate-next,
.paginate-item {
  border-radius: 50% !important;
}
.paginate-prev i,
.paginate-next i {
  color: #707070;
}
.paginate-item {
  color: #6f9642 !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.page-item .active {
  color: #fff !important;
  background-color: #6f9642;
}
.order-summary-box {
  border: 1px solid #c7c7c7;
}
.summary-detail {
  padding: 15px 15px 5px 15px;
}
.summary-detail div {
  font-size: 15px;
}
.summary-attrib {
  color: #787878;
}
.order-summary-box .section-1 {
  margin-bottom: 25px;
}
.cartItem-remove {
  position: absolute;
  top: 2px;
  right: 2px;
}
.cartItem-remove a i {
  border-radius: 50%;
  background-color: #fb5402;
  color: white;
  padding: 4px 5px !important;
}
.secondary-border {
  border-bottom: 1px solid #c7c7c7;
}
.summary-detail-total {
  padding: 5px 15px 5px 15px;
}
.summary-detail-total .summary-attrib {
  font-size: 20px;
  color: #000000;
}
.summary-detail-total .summary-attrib-val {
  font-size: 20px;
  color: #6f9642;
}
.user_rating_star span {
  margin: 0px 5px;
}
.order-detail-tab {
  cursor: pointer;
  font-weight: 600;
}
.order-detail-tab .nav-link {
  color: #495057;
}
.order-detail-tab .nav-link:hover {
  color: #495057;
}
.order-detail-tab .nav-link.active {
  border-color: #fff #fff #6f9642 !important;
  border-bottom: 3px solid;
}
.text-orange-light {
  color: #fb5402;
}
.swiper-button-prev,
.swiper-button-next {
  color: #6f9642 !important;
}
.order_details_box {
  border-radius: 25%;
  border: 1px solid #c7c7c7;
}
.step-line {
  background-color: #c7c7c7;
}
.steps-wizard {
  position: relative;
  background-color: #c7c7c7;
  height: 1.5px;
  margin: 0px 4vw;
}
.step-circle {
  border: 1px solid #c7c7c7;
  color: white;
  background-color: #6f9642;
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 50%;
  margin: auto;
}
.step-circle-white {
  border: 1px solid #6f9642;
  color: black;
  background-color: white;
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 50%;
  margin: auto;
}
.step-circle-white .step-circle-count {
  position: absolute;
  top: 20%;
  left: 40%;
  font-weight: 600;
  font-size: 14px;
}
.step-circle .step-circle-count {
  position: absolute;
  top: 20%;
  left: 40%;
  font-weight: 600;
  font-size: 14px;
}
#chat .you .triangle {
  border-color: transparent transparent #f6f6f6 transparent;
  margin-left: 15px;
}
#chat .triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
}
#chat .you .message {
  background-color: #f6f6f6;
}
#chat .message {
  padding: 10px;
  color: black;
  line-height: 25px;
  display: inline-block;
  width: 100%;
  text-align: left;
  border-radius: 5px;
}
.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
  -webkit-transition: 0.5s !important;
  transition: 0.5s !important;
}
.table-responsive::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  -webkit-transition: 0.5s !important;
  transition: 0.5s !important;
}
/* Track */
.table-responsive::-webkit-scrollbar-track {
  background: #fff;
}
/* Handle */
.table-responsive::-webkit-scrollbar-thumb {
  background: #6f9642;
  border-radius: 5px;
  -webkit-transition: 0.5s !important;
  transition: 0.5s !important;
}
.search-container {
  height: 100vh;
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 15px;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none !important;
}
.footer-category-item {
  border-bottom: none !important;
  padding: 15px;
}
.browse-menu-item {
  padding: 4px 10px !important;
}
.MuiMenuItem-root {
  border-bottom: 1px solid #eeeeee !important;
}
.css-3wb1p2-MuiTypography-root {
  padding: 0px !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  overflow-y: hidden;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper::-webkit-scrollbar {
  display: none !important;
}
.css-3wb1p2-MuiTypography-root {
  font-size: 13px !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.bg-default {
  background-color: #6f9642;
}
.order-number-link {
  font-size: 20px;
}
.border-line-2px {
  border: 2px solid #dfdfdf;
}
.border-bottom-1px {
  border-bottom: 1px solid #dfdfdf;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
.refund-reason-card {
  background-color: #f6f6f6 !important;
  color: #101010;
  border: transparent !important;
}
.refund-order-summary {
  border: 1px solid #c7c7c7 !important;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
}
.refund-order-summary h5 {
  font-size: 16px;
}
.refund-subtotal td {
  font-size: 15px;
}
.refund-total td {
  font-size: 20px;
}
.customer-detail-text {
  font-size: 16px;
}
.bg-default {
  background-color: #6f9642 !important;
}
.refund-detail-card {
  border-radius: 15px !important;
}
.refund-detail-card .card-header {
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}
.basic-refund-card {
  border: 1px solid #c7c7c7;
  border-radius: 15px;
  padding: 10px;
}


.slick-prev, .slick-next {
  /* background:  black !important; */
  /* border-radius: 50% !important; */
  z-index: 999;
}


.slick-next {
  right: 25px !important;
}
.slick-prev {
  left: 12px !important;
}


.slick-prev:before, .slick-next:before {
  font-size: 40px;
  color: black;
}
/*Media Queries*/
@media only screen and (max-width: 768px) {
  .launching-text {
    font-size: 30px;
    font-weight: 600;
  }
  .counter-text-1 {
    font-size: 40px;
    font-weight: 500;
  }
}
@media only screen and (max-width: 768px) {
  .lt {
    display: grid !important;
  }
  .navbar-text {
    flex-direction: inherit !important;
  }
}
@media only screen and (max-width: 768px) {
  .store-bg img {
    opacity: 0.3;
  }
  .store-company-logo {
    opacity: 1 !important;
  }
  /* .store-text {
    color: #fb5402 !important;
  } */
  .bg-mask {
    background-image: none;
    min-height: 100vh;
    background-position-x: right;
  }
  .main-container {
    margin-left: 10px;
    margin-right: 10px;
  }
  .form-input {
    color: #6f9642 !important;
  }
  .form-input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.541);
  }
  .form-input::placeholder {
    color: rgba(255, 255, 255, 0.541);
  }
  .form-input:focus {
    outline: none !important;
    border-color: #6f9642 !important;
    box-shadow: none !important;
  }
  .text-green-sm,
  .lower-text {
    color: #6f9642 !important;
  }
  .container-unav {
    margin: 0px 50px;
  }
}
@media only screen and (max-width: 1000px) {
  .bg-mask {
    background-image: none;
    min-height: 100vh;
    background-position-x: right;
  }
  .form-input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.541) !important;
  }
  .form-input::placeholder {
    color: rgba(255, 255, 255, 0.541) !important;
  }
  .text-green-sm,
  .lower-text {
    color: #6f9642 !important;
  }
  .main-logo {
    display: none;
  }
  .container-unav {
    margin: 0px 50px;
  }
  .lt-ul {
    float: left;
  }
}

.card-counter {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  background-color: #6f96421c;
  height: 100px;
  border-radius: 5px;
  transition: 0.3s linear all;
  border: 1px solid #6f9642;
}

.card-counter:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.card-counter.primary {
  background-color: #007bff;
  color: #fff;
}

.card-counter.danger {
  background-color: #ef5350;
  color: #fff;
}

.card-counter.success {
  background-color: #66bb6a;
  color: #fff;
}

.card-counter.info {
  background-color: #26c6da;
  color: #fff;
}

.card-counter i {
  font-size: 30px;
  opacity: 1;
  color: #6f9642;
}

.card-counter .count-numbers {
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 32px;
  display: block;
}

.card-counter .count-name {
  position: absolute;
  right: 35px;
  top: 65px;
  font-style: italic;
  text-transform: capitalize;
  opacity: 0.8;
  display: block;
  font-size: 18px;
}

.chatting {
  /* ===== MENU ===== */
  /* === CONVERSATIONS === */
}
.chatting .container {
  padding: 0;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  height: 700px;
}
.chatting .menu {
  float: left;
  height: 700px;
  width: 70px;
  background: #4768b5;
  background: linear-gradient(#4768b5, #35488e);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
}
.chatting .menu .items {
  list-style: none;
  margin: auto;
  padding: 0;
}
.chatting .menu .items .item {
  height: 70px;
  border-bottom: 1px solid #6f9642;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9fb5ef;
  font-size: 17pt;
}
.chatting .menu .items .item-active {
  background-color: #5172c3;
  color: #fff;
}
.chatting .menu .items .item:hover {
  cursor: pointer;
  background-color: #6f9642;
  color: #cfe5ff;
}
.chatting .discussions {
  padding: 0px;
  width: 20%;
  height: 700px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: #6f9642;
  display: inline-block;
}
.chatting .discussions .discussion {
  width: 100%;
  height: 90px;
  background-color: #fafafa;
  border-bottom: solid 1px #e0e0e0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.chatting .discussions .search {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e0e0e0;
}
.chatting .discussions .search .searchbar {
  height: 40px;
  background-color: #fff;
  width: 70%;
  padding: 0 20px;
  border-radius: 50px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.chatting .discussions .search .searchbar input {
  margin-left: 15px;
  height: 38px;
  width: 100%;
  border: none;
  font-family: "Montserrat", sans-serif;
}
.chatting .discussions .search .searchbar *::-webkit-input-placeholder {
  color: #e0e0e0;
}
.chatting .discussions .search .searchbar input *:-moz-placeholder {
  color: #e0e0e0;
}
.chatting .discussions .search .searchbar input *::-moz-placeholder {
  color: #e0e0e0;
}
.chatting .discussions .search .searchbar input *:-ms-input-placeholder {
  color: #e0e0e0;
}
.chatting .discussions .message-active {
  width: 98.5%;
  height: 90px;
  background-color: #fff;
  border-bottom: solid 1px #e0e0e0;
}
.chatting .discussions .discussion .photo {
  margin-left: 20px;
  display: block;
  width: 45px;
  height: 45px;
  background: #e6e7ed;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.chatting .online {
  position: relative;
  top: 30px;
  left: 35px;
  width: 13px;
  height: 13px;
  background-color: #8bc34a;
  border-radius: 13px;
  border: 3px solid #fafafa;
}
.chatting .desc-contact {
  height: 43px;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chatting .discussions .discussion .name {
  margin: 0 0 0 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 11pt;
  color: #515151;
}
.chatting .discussions .discussion .message {
  margin: 6px 0 0 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 9pt;
  color: #515151;
}
.chatting .timer {
  margin-left: 15%;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  padding: 3px 8px;
  color: #bbb;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
}
.chatting .chat {
  width: 79%;
  height: calc(75vh - 25px);
  position: relative !important;
  padding: 0px;
}
.chatting .header-chat {
  background-color: #fff;
  height: 90px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}
.chatting .chat .header-chat .icon {
  margin-left: 30px;
  color: #515151;
  font-size: 14pt;
}
.chatting .chat .header-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: #515151;
}
.chatting .chat .header-chat .right {
  position: absolute;
  right: 40px;
}
.chatting .chat .messages-chat {
  padding: 25px 35px;
}
.chatting .chat .messages-chat .message {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.chatting .chat .messages-chat .message .photo {
  display: block;
  width: 45px;
  height: 45px;
  background: #e6e7ed;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.chatting .chat .messages-chat .text-chat {
  margin: 0 35px;
  background-color: #f6f6f6;
  padding: 15px;
  border-radius: 12px;
}
.chatting .text-only {
  margin-left: 45px;
}
.chatting .time {
  font-size: 10px;
  color: lightgrey;
  margin-bottom: 10px;
  margin-left: 85px;
}
.chatting .response-time {
  float: right;
  margin-right: 40px !important;
}
.chatting .response {
  /* float: right; */
  margin-right: 0px !important;
  margin-left: auto;
  /* flexbox alignment rule */
}
.chatting .response .text-chat {
  background-color: #e3effd !important;
}
.chatting .footer-chat {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  background-color: transparent;
  border-top: 2px solid #eee;
}
.chatting .chat .footer-chat .icon {
  margin-left: 30px;
  color: #c0c0c0;
  font-size: 14pt;
}
.chatting .chat .footer-chat .send {
  color: #fff;
  background-color: #6f9642;
  position: absolute;
  right: 50px;
  padding: 12px 12px 12px 12px;
  border-radius: 50px;
  font-size: 14pt;
}
.chatting .chat .footer-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: #515151;
}
.chatting .chat .footer-chat .right {
  position: absolute;
  right: 40px;
}
.chatting .write-message {
  border: none !important;
  width: 85%;
  height: 50px;
  margin-left: 20px;
  padding: 10px;
}

.chatting .footer-chat *::-webkit-input-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}
.chatting .footer-chat input *:-moz-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}
.chatting .footer-chat input *::-moz-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
  margin-left: 5px;
}
.chatting .footer-chat input *:-ms-input-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}
.chatting .clickable {
  cursor: pointer;
}
.write-message:focus,
.write-message:focus {
  outline: none !important;
}
.messages-chat {
  overflow-y: scroll;
  height: calc(100% - 250px);
  overflow-x: hidden;
}
.reciever-image {
  display: block;
  width: 45px;
  height: 45px;
  background: #e6e7ed;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.invoice {
  background: #fff;
  padding: 20px;
}

.invoice-company {
  font-size: 20px;
}

.invoice-header {
  margin: 0 -20px;
  background: #f0f3f4;
  padding: 20px;
}

.invoice-date,
.invoice-from,
.invoice-to {
  display: table-cell;
  width: 1%;
}

.invoice-from,
.invoice-to {
  padding-right: 20px;
}

.invoice-date .date,
.invoice-from strong,
.invoice-to strong {
  font-size: 16px;
  font-weight: 600;
}

.invoice-date {
  text-align: right;
  padding-left: 20px;
}

.invoice-price {
  background: #f0f3f4;
  display: table;
  width: 100%;
}

.invoice-price .invoice-price-left,
.invoice-price .invoice-price-right {
  display: table-cell;
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
  width: 75%;
  position: relative;
  vertical-align: middle;
}

.invoice-price .invoice-price-left .sub-price {
  display: table-cell;
  vertical-align: middle;
  padding: 0 20px;
}

.invoice-price small {
  font-size: 12px;
  font-weight: 400;
  display: block;
}

.invoice-price .invoice-price-row {
  display: table;
  float: left;
}

.invoice-price .invoice-price-right {
  width: 25%;
  background: #6f9642;
  color: #fff;
  font-size: 28px;
  text-align: right;
  vertical-align: bottom;
  font-weight: 300;
}

.invoice-price .invoice-price-right small {
  display: block;
  opacity: 0.6;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
}

.invoice-footer {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  font-size: 10px;
}

.invoice-note {
  color: #999;
  margin-top: 80px;
  font-size: 85%;
}

.invoice > div:not(.invoice-footer) {
  margin-bottom: 20px;
}

.btn.btn-white,
.btn.btn-white.disabled,
.btn.btn-white.disabled:focus,
.btn.btn-white.disabled:hover,
.btn.btn-white[disabled],
.btn.btn-white[disabled]:focus,
.btn.btn-white[disabled]:hover {
  color: #2d353c;
  background: #fff;
  border-color: #d9dfe3;
}

:root {
    --theme-color-1 : #547032;
    --theme-color-2 : #46B64F;
    --theme-dark-gray : #6C757D;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}


.breadcrumb-wrapper * {
    color: #777777 !important;
}
.ant-breadcrumb > ol {
    align-items: center !important;
}

.breadcrumb-wrapper .ant-breadcrumb-separator {
    margin-inline: 5px !important;
    opacity: .5 !important;
}

.ant-breadcrumb > ol li > a ,
.ant-breadcrumb > ol li > span {
    font-size: 12px !important;
    height: auto !important;
}



.product-details-img-wrapper {
    aspect-ratio: 1;
    border: 1px solid #ddd;
    border-radius: 4px !important;
    overflow: hidden;
}

.product-details-img-wrapper.video-wrapper {
    display: grid !important;
    place-items: center;
}

.product-details-img-wrapper > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.product-img .slick-slider .slick-next,
.product-img .slick-slider .slick-prev {
    background: rgba(0, 0, 0, 0.5);
    height: 40px !important;
    width: 40px !important;
    border-radius: 50% !important;
    display: grid;
    place-items: center;
    border-radius: 4px;
}



.product-img .slick-slider .slick-next::before {
    content: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='white' stroke-width='0' viewBox='0 0 24 24' aria-hidden='true' height='1.5em' width='1.5em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
    display: inline-block;
    vertical-align: middle;
    font-size: 28px;
    margin-left: 2px;
}

.product-img .slick-slider .slick-prev::before {
    content: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='white' stroke-width='0' viewBox='0 0 24 24' aria-hidden='true' height='1.5em' width='1.5em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
    display: inline-block;
    vertical-align: middle;
    font-size: 28px;
}


.product-img .slick-dots li {
    width: 8px !important;
    height: 8px !important;
    background-color: #000;
    opacity: .2;
    border-radius: 100%;
    padding: 0 !important;
    margin: 0 4px !important;
}
.product-img .slick-dots li.slick-active {
    background-color: #547032 !important;
    background-color: var(--theme-color-1) !important;
    opacity: 1 !important;
}

.product-img .slick-dots button::before {
    display: none !important;
}



#short_description_content > * {
    font-weight: 500 !important;
}


.product-categories a {
    color: #6C757D;
    color: var(--theme-dark-gray);
}

.btn-share-and-wishlist span {
    display: inline-block;
    --size : 30px;
    width: var(--size);
    height: var(--size);
    background-color: #547032;
    background-color: var(--theme-color-1);
    display: grid;
    place-items: center;
    color: #fff;
    border-radius: 50%;
    margin-left: 5px;
    padding: 0 !important;
    cursor: pointer;
    transition: all .3s linear;
}
.btn-share-and-wishlist span:hover {
    background-color: #6C757D;
    background-color: var(--theme-dark-gray);
}
.btn-share-and-wishlist span > i {
    margin: 0 !important;
    font-size: 16px !important;
    color: #fff !important;
}

.product-quantity {
    position: relative;
}

.product-quantity .counter-btn {
    position: absolute;
    right: 12px;
    top: 5px;
    padding: 0;
    background-color: transparent;
    font-size: 12px !important;
}

.product-quantity .counter-btn.minus-btn {
    top: initial;
    bottom: 10px;
}


.product-side-box-wrapper {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 4px;
}

.product-side-box-wrapper .product-side-box{
    border: 0;
    padding: 0;
    border-radius: 0;
}

.product-side-box-wrapper .product-side-box .row {
    margin-top: 0;
    margin-bottom: 0;
    --bs-gutter-x : 1rem;
    align-items: center;
}

.product-side-box-wrapper .product-side-box .row i {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-color: var(--bs-secondary);
    color: #fff !important;
    display: grid;
    place-items: center;
    border-radius: 50%;
    font-size: 14px !important;
    margin: auto;
}

.product-side-box-wrapper .product-side-box .row:not(:last-child) {
    margin-bottom: 15px !important;
}



/** css for header */
.reactSelect-country__control{
    min-height: auto !important;
    height: 26px !important;
    width: 90px;
    border-radius: 0px !important;
    padding: 0px !important;
    box-shadow: none !important;
    border-width: 1px !important;
}
.reactSelect-country__control--is-focused {
    border-color: #547032 !important;
    border-color: var(--theme-color-1) !important;
}
.reactSelect-country__indicator-separator {
    height: 16px !important;
    align-self: center !important;
}
.reactSelect-country__control * {
    padding: 0px !important;
    margin : 0px !important;
    box-shadow: none !important;
    border-width: 1px !important;
}
.reactSelect-country__control .reactSelect-country__value-container {
    padding-left: 5px !important;
}

/** responsive header */

@media (max-width: 999px) {
    header .desktop-header {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .container-header {
        padding: initial !important;
    }
}

.cbp-spmenu-left {
    padding: 0 !important;
}
.cbp-spmenu-left.nav-show {
    left: 0 !important;
}

@media (max-width: 991px) {
    #header-logo .logo  {
        max-width: 200px !important;
        width: auto;
        object-fit: contain;
        object-position: center;
        max-height: 40px;
        height: auto !important;
    }

    .mySwiper .swiper-slide > img {
        height: 225px !important;
        object-fit: cover;
        object-position: top;
    }
}
#textbannersmodule li .circle {
    z-index: initial;
}
#mobile-menu {
    width: 240px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    border-right: 1px solid #ddd;
    box-shadow: inset -134px 0 10px -133px rgba(0,0,0,0.05);
    z-index: 10000;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: all .3s cubic-bezier(0.215, 0.610, 0.355, 1);
}

#mobile-menu.nav-show {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}

#mobile-menu .rc-menu {
    border : 0;
    box-shadow: none;
    padding: 0;
}

#mobile-menu .rc-menu-item-active, 
#mobile-menu .rc-menu-submenu-active > .rc-menu-submenu-title {
    background-color: var(--bs-gray-300) !important;
}

.rc-menu-submenu + .rc-menu-submenu,
.rc-menu-submenu + .rc-menu-item,
.rc-menu-item + .rc-menu-submenu ,
.rc-menu-item + .rc-menu-item  {
    border-top: 1px solid #bbb;
}
