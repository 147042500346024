:root {
    --theme-color-1 : #547032;
    --theme-color-2 : #46B64F;
    --theme-dark-gray : #6C757D;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}


.breadcrumb-wrapper * {
    color: #777777 !important;
}
.ant-breadcrumb > ol {
    align-items: center !important;
}

.breadcrumb-wrapper .ant-breadcrumb-separator {
    margin-inline: 5px !important;
    opacity: .5 !important;
}

.ant-breadcrumb > ol li > a ,
.ant-breadcrumb > ol li > span {
    font-size: 12px !important;
    height: auto !important;
}



.product-details-img-wrapper {
    aspect-ratio: 1;
    border: 1px solid #ddd;
    border-radius: 4px !important;
    overflow: hidden;
}

.product-details-img-wrapper.video-wrapper {
    display: grid !important;
    place-items: center;
}

.product-details-img-wrapper > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.product-img .slick-slider .slick-next,
.product-img .slick-slider .slick-prev {
    background: rgba(0, 0, 0, 0.5);
    height: 40px !important;
    width: 40px !important;
    border-radius: 50% !important;
    display: grid;
    place-items: center;
    border-radius: 4px;
}



.product-img .slick-slider .slick-next::before {
    content: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='white' stroke-width='0' viewBox='0 0 24 24' aria-hidden='true' height='1.5em' width='1.5em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
    display: inline-block;
    vertical-align: middle;
    font-size: 28px;
    margin-left: 2px;
}

.product-img .slick-slider .slick-prev::before {
    content: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='white' stroke-width='0' viewBox='0 0 24 24' aria-hidden='true' height='1.5em' width='1.5em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z' clip-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
    display: inline-block;
    vertical-align: middle;
    font-size: 28px;
}


.product-img .slick-dots li {
    width: 8px !important;
    height: 8px !important;
    background-color: #000;
    opacity: .2;
    border-radius: 100%;
    padding: 0 !important;
    margin: 0 4px !important;
}
.product-img .slick-dots li.slick-active {
    background-color: var(--theme-color-1) !important;
    opacity: 1 !important;
}

.product-img .slick-dots button::before {
    display: none !important;
}



#short_description_content > * {
    font-weight: 500 !important;
}


.product-categories a {
    color: var(--theme-dark-gray);
}

.btn-share-and-wishlist span {
    display: inline-block;
    --size : 30px;
    width: var(--size);
    height: var(--size);
    background-color: var(--theme-color-1);
    display: grid;
    place-items: center;
    color: #fff;
    border-radius: 50%;
    margin-left: 5px;
    padding: 0 !important;
    cursor: pointer;
    transition: all .3s linear;
}
.btn-share-and-wishlist span:hover {
    background-color: var(--theme-dark-gray);
}
.btn-share-and-wishlist span > i {
    margin: 0 !important;
    font-size: 16px !important;
    color: #fff !important;
}

.product-quantity {
    position: relative;
}

.product-quantity .counter-btn {
    position: absolute;
    right: 12px;
    top: 5px;
    padding: 0;
    background-color: transparent;
    font-size: 12px !important;
}

.product-quantity .counter-btn.minus-btn {
    top: initial;
    bottom: 10px;
}


.product-side-box-wrapper {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 4px;
}

.product-side-box-wrapper .product-side-box{
    border: 0;
    padding: 0;
    border-radius: 0;
}

.product-side-box-wrapper .product-side-box .row {
    margin-top: 0;
    margin-bottom: 0;
    --bs-gutter-x : 1rem;
    align-items: center;
}

.product-side-box-wrapper .product-side-box .row i {
    width: 30px;
    height: 30px;
    display: inline-block;
    background-color: var(--bs-secondary);
    color: #fff !important;
    display: grid;
    place-items: center;
    border-radius: 50%;
    font-size: 14px !important;
    margin: auto;
}

.product-side-box-wrapper .product-side-box .row:not(:last-child) {
    margin-bottom: 15px !important;
}



/** css for header */
.reactSelect-country__control{
    min-height: auto !important;
    height: 26px !important;
    width: 90px;
    border-radius: 0px !important;
    padding: 0px !important;
    box-shadow: none !important;
    border-width: 1px !important;
}
.reactSelect-country__control--is-focused {
    border-color: var(--theme-color-1) !important;
}
.reactSelect-country__indicator-separator {
    height: 16px !important;
    align-self: center !important;
}
.reactSelect-country__control * {
    padding: 0px !important;
    margin : 0px !important;
    box-shadow: none !important;
    border-width: 1px !important;
}
.reactSelect-country__control .reactSelect-country__value-container {
    padding-left: 5px !important;
}

/** responsive header */

@media (max-width: 999px) {
    header .desktop-header {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .container-header {
        padding: initial !important;
    }
}

.cbp-spmenu-left {
    padding: 0 !important;
}
.cbp-spmenu-left.nav-show {
    left: 0 !important;
}

@media (max-width: 991px) {
    #header-logo .logo  {
        max-width: 200px !important;
        width: auto;
        object-fit: contain;
        object-position: center;
        max-height: 40px;
        height: auto !important;
    }

    .mySwiper .swiper-slide > img {
        height: 225px !important;
        object-fit: cover;
        object-position: top;
    }
}
#textbannersmodule li .circle {
    z-index: initial;
}
#mobile-menu {
    width: 240px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    border-right: 1px solid #ddd;
    box-shadow: inset -134px 0 10px -133px rgba(0,0,0,0.05);
    z-index: 10000;
    transform: translateX(-100%);
    transition: all .3s cubic-bezier(0.215, 0.610, 0.355, 1);
}

#mobile-menu.nav-show {
    transform: translateX(0%);
}

#mobile-menu .rc-menu {
    border : 0;
    box-shadow: none;
    padding: 0;
}

#mobile-menu .rc-menu-item-active, 
#mobile-menu .rc-menu-submenu-active > .rc-menu-submenu-title {
    background-color: var(--bs-gray-300) !important;
}

.rc-menu-submenu + .rc-menu-submenu,
.rc-menu-submenu + .rc-menu-item,
.rc-menu-item + .rc-menu-submenu ,
.rc-menu-item + .rc-menu-item  {
    border-top: 1px solid #bbb;
}