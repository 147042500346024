/* @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"; */
body {
  /* font-family: "Poppins", sans-serif; */
  background: #fafafa;
}

p {
  /* font-family: "Poppins", sans-serif; */
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}
.sidebar-logo {
  height: 35px;
  width: 217px;
}
.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  min-width: 275px;
  max-width: 275px;
  background: #ffffff;
  color: #737373;
  transition: all 0.3s;
  position: fixed;
  height: 100vh;
  overflow-y: auto;
}
#sidebar::-webkit-scrollbar {
  width: 0 !important;
}
#sidebar.active {
  margin-left: -275px;
}
#sidebar.inactive {
  margin-left: 275px !important;
}

#sidebar .sidebar-header {
  padding: 20px;
  /* background: #6d7fcc; */
}

#sidebar ul.components {
  padding: 20px 0;
}
.sidebarLink {
  padding-left: 25px !important;
  font-size: 14px !important;
  text-decoration: none;
  color: #737373;
}
.component-p {
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: 20px;
}
#sidebar ul p {
  color: #fff;
  padding: 10px;
}
#sidebar ul li {
  padding-top: 10px;
}
#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
.avatar {
  vertical-align: middle;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
}
#sidebar ul li a:hover {
  color: #6f9642;
  background: #fff;
}
.sidebar-profile {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
}
#sidebar ul li.active > a,
a[aria-expanded="true"] {
  color: #6f9642;
  background: #f9f9f9;
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}
.admin-name {
  font-size: 14px;
  font-weight: 300;
}
.sidebarLink i {
  font-size: 14px;
  padding-right: 20px;
}
a.article,
a.article:hover {
  background: #6f9642 !important;
  color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  position: absolute;
  width: calc(100% - 275px);
  left: 275px;
  padding: 20px;
  min-height: 100vh;
  transition: all 0.3s;
  background-color: #f7f7f7;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    margin-left: -275px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
  #content {
    left: 0;
    width: 100%;
    background-color: #f7f7f7;
  }
}
