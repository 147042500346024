#search_block_top select,
#search_block_top input {
  font-size: 13px !important;
}

.search_block_top .button-search {
  top: 8px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 15px !important;
}

.swiper-button-prev,
.swiper-button-next {
  background: rgba(0, 0, 0, 0.5);
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: #ffffff !important;
}

#iqitmegamenu-horizontal {
  background-color: #6f9642;
}

.swiper-container {
  z-index: 0 !important;
}

.header-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#header .shopping_cart {
    padding-top: 0px;
}

#header .list-inline-item:not(:last-child) {
    margin-right: 5px !important;
    margin-left: 5px !important;
}

.cbp-categories-row >.col-xs-6 {
  width: 50% !important;
}
.cbp-category-link-w > a{
  text-decoration: none !important;
  background: none !important;
  padding-left: 10px !important;
  font-size: 1em !important;
}

.product-hover-shadow:hover {
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.15);
}
.add-to-wishlist-btn:hover {
  color: white;
}

.deal-carousel-item {
  margin: 10px 10px !important;
}
.deal-carousel-item:hover {
  transition: box-shadow 0.3s ease;
  border: 1px solid transparent; /* Initial border */
  border-radius: 5px; /* Adjust for rounded corners */
  border-color: #ddd; /* Light gray border */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

#languages-block-top img{
  vertical-align: middle !important;
}

.flag-top-icon{
  padding: 0 10px !important;
}

#search_block_top.issearchcontent {
  margin-top: 10px;
}

.deal-image{
  padding: 20px 20px 0px 20px !important;
}

a {
  text-decoration: none !important;
}

.footer-social-icon {
  background-color: #777777 !important;
  color: white;
  border: #777777 !important;
  padding: 4px 7px;
}

.swiper-pagination-bullet-active{
  background: #6f9642 !important;
}

.fa-chevron-down {
  color: #6f9642 !important;
  text-decoration: none;
}

.newsLetter{
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: stretch !important;
}

.service-info{
 border: unset !important;
}

#textbannersmodule .txttitle{
  font-size: 17px !important;
}

#textbannersmodule li .circle{
  width: 72px !important;
  height: 72px !important;
}

#textbannersmodule .txttitle{
  font-weight: 500 !important;
}

.circle i{
  font-size: 20px !important;
  line-height: 72px !important;
}

.footer-container #footer h4{
  border-bottom:  unset !important
}

ul.bullet{
  padding-left: 0 !important;
}

.footer-icon-fb {
  background-color: #0766ff !important;
}
.footer-icon-ig {
  background-color: #ff2f40 !important;
}
.footer-icon-twitter {
  background-color: #3d8bc5 !important;
}
.footer-icon-yt {
  background-color: #ff0033 !important;
}
.footer-icon-tiktok {
  background-color: #1f1f1f !important;
}

/* #userBrowsing .swiper-slide {
  max-width: 230px !important;
} */

.footer-social-icon i {
  height: 12px;
  width: 12px;
}

.sell-page label {
  font-size: 13px !important;
}

h4{
  color: black !important;
}

h5{
  color: black !important;
}

label{
  color: black !important;
}

.adduser-phone {
  padding: 4.5px 55px !important;
}
.react-tel-input .form-control{
  font-size: 1rem;
}

input::placeholder {
  color: #999999 !important;
}

/* For WebKit browsers (Chrome, Safari, Edge) */
input::-webkit-input-placeholder {
  color: #999999 !important;
}

/* For Mozilla Firefox */
input::-moz-placeholder {
  color: #999999 !important;
}

/* For Microsoft Edge and Internet Explorer */
input:-ms-input-placeholder {
  color: #999999 !important;
}

/* For older Microsoft Edge */
input::-ms-input-placeholder {
  color: #999999 !important;
}

ol.stepper {
  --default-b: lightgrey;
  --default-c: black;
  --active-b: #6f9642;
  --active-c: white;
  --circle: 3.5em; /* size of circle */
  --b: 5px; /* line thickness */
  
  display: flex;
  list-style: none;
  justify-content: space-between;
  background: 
    linear-gradient(var(--default-b) 0 0) no-repeat
    50% calc((var(--circle) - var(--b))/2)/100% var(--b);
  counter-reset: step;
  margin: 20px;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  counter-reset: step;
  overflow: hidden;
}
ol.stepper li {
  display: grid;
  place-items: center;
  gap: 5px;
  font-family: inherit;
  position: relative;
}
ol.stepper li::before {
  content: counter(step) " ";
  counter-increment: step;
  display: grid;
  place-content: center;
  aspect-ratio: 1;
  height: var(--circle);
  border: 5px solid #fff;
  box-sizing: border-box;
  background: var(--active-b);
  color: var(--active-c);
  border-radius: 50%;
  font-family: inherit;
  z-index: 1;
}
ol.stepper li.active ~ li::before{
  background: var(--default-b);
  color: var(--default-c);
}
ol.stepper li.active::after {
  content: "";
  position: absolute;
  height: var(--b);
  right: 100%;
  top: calc((var(--circle) - var(--b))/2);
  width: 100vw;
  background: var(--active-b);
}


@media (max-width: 600px) {
  ol.stepper {
   display: grid;
   gap: 20px;
  background: 
    linear-gradient(var(--default-b) 0 0) no-repeat
    calc((var(--circle) - var(--b))/2) 50%/ var(--b) 100%;
  }
  ol.stepper li {
    display: flex;
  }
  ol.stepper li.active::after {
    content: "";
    position: absolute;
    width: var(--b);
    bottom: 100%;
    left: calc((var(--circle) - var(--b))/2);
    top: auto;
    right: auto;
    height: 100vw;
    background: var(--active-b);
  }
}

.removeBottomMargin {
  margin-bottom: 0 !important;
}

#footer .form-control{
  color: black !important;
}

.dropcenter.btn-group > button{
  text-transform: unset !important;
}


.btn-bg-color {
  background-color: #6f9642!important;
}

.custom-rounded-image {
  width: 72px;
  height: 72px;
  border: 1px solid #ccc;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.custom-rounded-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.order-table th {
  text-align: center !important;
}

.order-table td {
  text-align: center !important;
}
td .rounded-pill {
  font-size: 11px !important;
  padding: 2px 12px !important;
}

.custom-icon-size i {
  width: 30px !important;
  margin-right: 1rem !important;
}


.font600{
  font-weight: 600 !important;
}

hr:not([size]) {
  height: 1px !important;
}

.grayIcon{
  width: 30px !important;
  height: 30px !important;
  display: inline-block;
  background-color: var(--bs-secondary);
  color: #fff !important;
  display: grid
;
  place-items: center;
  border-radius: 50%;
  font-size: 14px !important;
}

.unrated {
  color: #777777 !important;
}

.cart_product_img {
  height: 100px !important;
  width: 100px !important;
  padding: 19px !important;
  vertical-align: middle;
  text-align: center;
  line-height: 0 !important;
}
