/* styles.css */
@media screen and (min-width: 768px) { /* Adjust 768px to the breakpoint that you consider as desktop */
    .desktop-margin-top {
      margin-top: -10px;
    }
  }

  @media screen and (max-width: 768px) { /* Adjust 768px to the breakpoint that you consider as desktop */
    .desktop-margin-top {
      margin-top: 25px;
    }
  }