.chatting {
  /* ===== MENU ===== */
  /* === CONVERSATIONS === */
}
.chatting .container {
  padding: 0;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  height: 700px;
}
.chatting .menu {
  float: left;
  height: 700px;
  width: 70px;
  background: #4768b5;
  background: -webkit-linear-gradient(#4768b5, #35488e);
  background: -o-linear-gradient(#4768b5, #35488e);
  background: -moz-linear-gradient(#4768b5, #35488e);
  background: linear-gradient(#4768b5, #35488e);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
}
.chatting .menu .items {
  list-style: none;
  margin: auto;
  padding: 0;
}
.chatting .menu .items .item {
  height: 70px;
  border-bottom: 1px solid #6f9642;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9fb5ef;
  font-size: 17pt;
}
.chatting .menu .items .item-active {
  background-color: #5172c3;
  color: #fff;
}
.chatting .menu .items .item:hover {
  cursor: pointer;
  background-color: #6f9642;
  color: #cfe5ff;
}
.chatting .discussions {
  padding: 0px;
  width: 20%;
  height: 700px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  background-color: #6f9642;
  display: inline-block;
}
.chatting .discussions .discussion {
  width: 100%;
  height: 90px;
  background-color: #fafafa;
  border-bottom: solid 1px #e0e0e0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.chatting .discussions .search {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e0e0e0;
}
.chatting .discussions .search .searchbar {
  height: 40px;
  background-color: #fff;
  width: 70%;
  padding: 0 20px;
  border-radius: 50px;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.chatting .discussions .search .searchbar input {
  margin-left: 15px;
  height: 38px;
  width: 100%;
  border: none;
  font-family: "Montserrat", sans-serif;
}
.chatting .discussions .search .searchbar *::-webkit-input-placeholder {
  color: #e0e0e0;
}
.chatting .discussions .search .searchbar input *:-moz-placeholder {
  color: #e0e0e0;
}
.chatting .discussions .search .searchbar input *::-moz-placeholder {
  color: #e0e0e0;
}
.chatting .discussions .search .searchbar input *:-ms-input-placeholder {
  color: #e0e0e0;
}
.chatting .discussions .message-active {
  width: 98.5%;
  height: 90px;
  background-color: #fff;
  border-bottom: solid 1px #e0e0e0;
}
.chatting .discussions .discussion .photo {
  margin-left: 20px;
  display: block;
  width: 45px;
  height: 45px;
  background: #e6e7ed;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.chatting .online {
  position: relative;
  top: 30px;
  left: 35px;
  width: 13px;
  height: 13px;
  background-color: #8bc34a;
  border-radius: 13px;
  border: 3px solid #fafafa;
}
.chatting .desc-contact {
  height: 43px;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chatting .discussions .discussion .name {
  margin: 0 0 0 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 11pt;
  color: #515151;
}
.chatting .discussions .discussion .message {
  margin: 6px 0 0 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 9pt;
  color: #515151;
}
.chatting .timer {
  margin-left: 15%;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  padding: 3px 8px;
  color: #bbb;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
}
.chatting .chat {
  width: 79%;
  height: calc(75vh - 25px);
  position: relative !important;
  padding: 0px;
}
.chatting .header-chat {
  background-color: #fff;
  height: 90px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}
.chatting .chat .header-chat .icon {
  margin-left: 30px;
  color: #515151;
  font-size: 14pt;
}
.chatting .chat .header-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: #515151;
}
.chatting .chat .header-chat .right {
  position: absolute;
  right: 40px;
}
.chatting .chat .messages-chat {
  padding: 25px 35px;
}
.chatting .chat .messages-chat .message {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.chatting .chat .messages-chat .message .photo {
  display: block;
  width: 45px;
  height: 45px;
  background: #e6e7ed;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.chatting .chat .messages-chat .text-chat {
  margin: 0 35px;
  background-color: #f6f6f6;
  padding: 15px;
  border-radius: 12px;
}
.chatting .text-only {
  margin-left: 45px;
}
.chatting .time {
  font-size: 10px;
  color: lightgrey;
  margin-bottom: 10px;
  margin-left: 85px;
}
.chatting .response-time {
  float: right;
  margin-right: 40px !important;
}
.chatting .response {
  /* float: right; */
  margin-right: 0px !important;
  margin-left: auto;
  /* flexbox alignment rule */
}
.chatting .response .text-chat {
  background-color: #e3effd !important;
}
.chatting .footer-chat {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  background-color: transparent;
  border-top: 2px solid #eee;
}
.chatting .chat .footer-chat .icon {
  margin-left: 30px;
  color: #c0c0c0;
  font-size: 14pt;
}
.chatting .chat .footer-chat .send {
  color: #fff;
  background-color: #6f9642;
  position: absolute;
  right: 50px;
  padding: 12px 12px 12px 12px;
  border-radius: 50px;
  font-size: 14pt;
}
.chatting .chat .footer-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
  color: #515151;
}
.chatting .chat .footer-chat .right {
  position: absolute;
  right: 40px;
}
.chatting .write-message {
  border: none !important;
  width: 85%;
  height: 50px;
  margin-left: 20px;
  padding: 10px;
}

.chatting .footer-chat *::-webkit-input-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}
.chatting .footer-chat input *:-moz-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}
.chatting .footer-chat input *::-moz-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
  margin-left: 5px;
}
.chatting .footer-chat input *:-ms-input-placeholder {
  color: #c0c0c0;
  font-size: 13pt;
}
.chatting .clickable {
  cursor: pointer;
}
.write-message:focus,
.write-message:focus {
  outline: none !important;
}
.messages-chat {
  overflow-y: scroll;
  height: calc(100% - 250px);
  overflow-x: hidden;
}
.reciever-image {
  display: block;
  width: 45px;
  height: 45px;
  background: #e6e7ed;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
